<template>
  <v-container>
    <v-form ref="form" v-model="valid" class="pa-3">
      <v-row>
        <h3>Voeg een positie toe</h3>
      </v-row>
      <v-row align="center" justify="center">
        <v-text-field
          v-model="positionName"
          label="Positie naam"
          required
        ></v-text-field>
        <v-col style="margin-top: 0px; padding-top: 0px" align="end">
          <v-btn
            style="margin-top: 0px; padding-top: 0px"
            :disabled="!valid && !adding"
            color="success"
            class="mr-4"
            @click="createPosition"
          >
            Positie toevoegen
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    adding: false,
    valid: true,
    positionName: "",
  }),

  methods: {

    async createPosition() {
      const { dispatch } = this.$store;
      if (this.positionName != "") {
        if (this.$store.state.users.user.role <= 2) {
          this.adding = true;
          let fields = {
            name: this.positionName,
          };
          await dispatch("positions/createPosition", fields).then(
            dispatch("alerts/success", "Positie toegevoegd")
          );
          dispatch("positions/getAllPositions");
          this.adding = false;
          this.clear()
          this.$emit('close-dialog');
        } else {
          dispatch("alerts/warn", "Je bent niet bevoegd om dit te doen");
          this.adding = false;
        }
      } else {
        dispatch("alerts/warn", "Geef de positie een naam");
        this.adding = false;
      }
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>