import { leadService } from "../../services/lead.service";
import axios from "axios";
import config from "../../../config";
import { authHeader } from "../../utils/auth-header";

export const leads = {
  namespaced: true,
  state: {
    leads: {},
    leadFlow: {},
    leadsForPeriod: {},
    leadsByVac: {},
    channels: {},
    vacancies: {},
    activeLead: {},
    filter: {},
    pagination: {},
    leadsByMonth: {},
  },
  getters: {
    getleadsByMonth: (state) => state.leadsByMonth,
    getLeadFlow: (state) => state.leadFlow,
    getLeadsForPeriod: (state) => state.leadsForPeriod,
  },
  actions: {
    createLead({ dispatch, commit }, lead) {
      return new Promise((resolve, reject) => {
        leadService.createLead(lead).then((lead) => resolve(lead));
      });
    },
    updateLead({ dispatch, commit }, { lead, leadId }) {
      return new Promise((resolve, reject) => {
        leadService.updateLead(lead, leadId).then((lead) => resolve(lead));
      });
    },
    getAllLeads({ commit }) {
      return new Promise((resolve, reject) => {
        leadService.getAllLeads().then((leads) => {
          commit("getAllLeadsSuccess", leads);
          resolve(leads);
        });
      });
    },
    getLead({ commit }, leadId) {
      return new Promise((resolve, reject) => {
        leadService.getLead(leadId).then((lead) => {
          commit("getAllLeadSuccess", lead);
          resolve(lead);
        });
      });
    },

    getLeadsByVacancyId({ commit }, vacancyId) {
      return new Promise((resolve, reject) => {
        leadService.getLeadsByVacancyId(vacancyId).then((leads) => {
          commit("getLeadsByVacSuccess", leads);
          resolve(leads);
        });
      });
    },

    addStatusToLead({ dispatch, commit }, leadStatus) {
      return new Promise((resolve, reject) => {
        leadService
          .addStatusToLead(leadStatus)
          .then((leadStatus) => resolve(leadStatus));
      });
    },

    getAllLeadsWithFlow({ commit }) {
      return new Promise((resolve, reject) => {
        leadService.getAllLeadsWithFlow().then((leads) => {
          commit("getAllLeadsFlowSuccess", leads);
          resolve(leads);
        });
      });
    },

    async getLeadsPerPeriod({ commit }, { params }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(
          `${config.apiUrl}/leadsPerPeriod/?startDate=${params.startDate}&endDate=${params.endDate}`,
          request
        )
          .then(handleResponse)
          .then((data) => {
            commit("getLeadsPerPeriodSuccess", data);
          });
      } catch (error) {
        console.log("error");
      }
    },

    // --------------------------------------------------------------------------------

    getAllChannels({ commit }) {
      return new Promise((resolve, reject) => {
        leadService.getAllChannels().then((lead_channels) => {
          commit("getAllChannelsSuccess", lead_channels);
          resolve(lead_channels);
        });
      });
    },

    getAllVacancies({ commit }) {
      return new Promise((resolve, reject) => {
        leadService.getAllVacancies().then((vacancies) => {
          commit("getAllVacanciesSuccess", vacancies);
          resolve(vacancies);
        });
      });
    },
    updatePagination({ commit }, pagination) {
      commit("setPaginationState", pagination);
    },
    async DeleteLeadById({ dispatch, commit }, leadId) {
      try {
        const request = {
          method: "DELETE",
          headers: authHeader(),
        };
        await fetch(
          `${config.apiUrl}/leads/deleteLead/${leadId}`,
          request
        ).then(handleResponse);
        // axios.defaults.headers.common["Authorization"] = `${
        //   authHeader().Authorization
        // }`;
        // const { data } = await axios.delete(
        //   `${config.apiUrl}/leads/deleteLead/${leadId}`,
        //   {}
        // );
        dispatch("alerts/success", "Lead Deleted", { root: true });
      } catch (error) {
        dispatch("alerts/error", "Lead cannot be deleted", { root: true });
        console.log("error");
      }
    },
    async getLeadsByMonth({ commit }, month) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(`${config.apiUrl}/leads/leadsByMonth/${month}`, request)
          .then(handleResponse)
          .then((data) => {
            commit("getLeadByMonthSuccess", data.leads);
          });
      } catch (error) {
        console.log("error");
      }
    },
  },
  mutations: {
    getAllLeadsSuccess(state, items) {
      state.leads = items.leads;
    },
    getAllLeadsFlowSuccess(state, items) {
      state.leadFlow = items.leads;
    },
    getLeadsPerPeriodSuccess(state, items) {
      state.leadsForPeriod = items.leads;
    },
    getAllChannelsSuccess(state, items) {
      state.channels = items.lead_channels;
    },
    getAllVacanciesSuccess(state, items) {
      state.vacancies = items.vacancies;
    },
    getAllLeadSuccess(state, items) {
      state.activeLead = items.lead;
    },
    setPaginationState(state, pagination) {
      state.pagination = pagination;
    },
    getLeadByMonthSuccess(state, items) {
      state.leadsByMonth = items;
    },
    getLeadsByVacSuccess(state, items) {
      state.leadsByVac = items.leads;
    },
  },
};
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
