<template>
  <v-container class="pa-5">
    <v-form ref="form">
      <v-alert v-model="showAlert" type="warning">
        {{ warningText }}
      </v-alert>
      <span class="text-h5">{{ leadName }}: {{ leadContactNo }}</span>
      <v-container>
        <v-row justify="center">
          <v-col md="2">
            <v-btn color="green" :disabled="saving" @click="awnsered()">
              <v-icon>mdi-phone</v-icon>
              Opgenomen
            </v-btn>
          </v-col>
          <v-col md="2">
            <v-btn color="red" :disabled="saving" @click="voicemail()">
              <v-icon>mdi-phone-missed</v-icon>
              Voicemail
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
export default {
  props: {
    type: Number,
    leadID: Number,
    leadUserID: Number,
    vmCount: Number,
    leadName: String,
    leadContactNo: String,
    additional: String,
  },
  data: () => ({
    planIntake: "yes",
    warningText: "",
    spokenTo: "no",
    showAlert: false,
    saving: false,
    data_fields: {
      time: null,
      selectedRecruiterId: null,
      contactMomentText: null,
      contactMomentStatus: null,
    },
  }),
  computed: {},
  created() {},

  methods: {
    async voicemail() {
      this.saving = true;
      const leadId = this.leadID;
      const { dispatch } = this.$store;
      var flow = {
        lead_id: leadId,
      };
      flow.vm_date = new Date();
      flow.vm_count = this.vmCount + 1;
      await dispatch("flow/updateFlow", { flow, leadId }).then((flow) => {
        dispatch("alerts/success", "Bell poging opgeslagen");
      });
      await this.addStatusToUser("Gebeld maar nam niet op", 3);
      // await dispatch("leads/getAllLeadsWithFlow");
      switch (this.type) {
        case 1:
          await dispatch(
            "leads/getLeadsByVacancyId",
            parseInt(this.additional)
          );
          break;
        case 2:
          await dispatch("lead_users/getLeadUser", this.leadUserID);
          break;
        case 3:
          await dispatch(
            "lead_users/searchLeadUsersWithLeads",
            this.additional
          );
          break;
        case 4:
          await dispatch("lead_users/getLeadUsers");
          break;
        case 5:
          var period = JSON.parse(this.additional);
          var params = {
            startDate: null,
            endDate: null,
          };
          if (period[0] > period[1]) {
            params = {
              startDate: period[1],
              endDate: period[0],
            };
          } else {
            params = {
              startDate: period[0],
              endDate: period[1],
            };
          }
          await dispatch("leads/getLeadsPerPeriod", { params });
          break;
        default:
          break;
      }
      this.saving = false;
      this.$emit("close-dialog");
    },
    async awnsered() {
      this.saving = true;
      const leadId = this.leadID;
      const { dispatch } = this.$store;
      var flow = {
        lead_id: leadId,
      };
      flow.first_date = new Date();
      await dispatch("flow/updateFlow", { flow, leadId }).then((flow) => {
        dispatch("alerts/success", "Contact opgeslagen");
      });
      await this.addStatusToUser("Eerste contact", 1);
      //await dispatch("leads/getAllLeadsWithFlow");
      switch (this.type) {
        case 1:
          await dispatch(
            "leads/getLeadsByVacancyId",
            parseInt(this.additional)
          );
          break;
        case 2:
          await dispatch("lead_users/getLeadUser", this.leadUserID);
          break;
        case 3:
          await dispatch(
            "lead_users/searchLeadUsersWithLeads",
            this.additional
          );
          break;
        case 4:
          await dispatch("lead_users/getLeadUsers");
          break;
        case 5:
          var period = JSON.parse(this.additional);
          var params = {
            startDate: null,
            endDate: null,
          };
          if (period[0] > period[1]) {
            params = {
              startDate: period[1],
              endDate: period[0],
            };
          } else {
            params = {
              startDate: period[0],
              endDate: period[1],
            };
          }
          await dispatch("leads/getLeadsPerPeriod", { params });
          break;
        default:
          break;
      }
      this.saving = false;
      this.$emit("close-dialog");
      const path = `/profiel/${this.leadUserID}`;
      if (this.$route.path !== path) this.$router.push(path);
    },

    async addStatusToUser(cm_notes, cm_type) {
      const leadUserId = this.leadUserID;
      const { dispatch } = this.$store;
      let newContactMoment = {
        lead_user_id: leadUserId,
        type: cm_type,
        comunicator_id: this.$store.state.users.user.id,
        user_status_id: null,
        notes: cm_notes,
      };
      await dispatch("contact_moments/createContactMoment", newContactMoment);

      return;
    },
  },
};
</script>

<style scoped></style>
