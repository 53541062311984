<template>
 <v-card :loading="!loaded">
    <v-container class="pa-5" v-if="loaded">
      <v-form ref="form">
        <v-alert v-model="showAlert" type="warning">
          {{ warningText }}
        </v-alert>
        <v-card-title>
          <span class="text-h5">Intake</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <span class="subtitle-2">Ge intaked?</span>
            <v-radio-group v-model="intake" row>
              <v-radio label="Ja" value="yes"></v-radio>
              <v-radio label="Nee" value="no"></v-radio>
            </v-radio-group>
            <div v-if="this.intake === 'yes'">
              <span class="subtitle-2"> Community manager toewijzen? </span>
              <v-radio-group v-model="scheduleManager" row>
                <v-radio label="Ja" value="yes"></v-radio>
                <v-radio label="Nee" value="no"></v-radio>
              </v-radio-group>
              <div v-if="this.scheduleManager === 'yes'">
                <v-row>
                  <v-col>
                    <v-select
                      :items="communityManagers"
                      v-model="data_fields.selectedManagerId"
                      item-text="full_name"
                      item-value="id"
                      label="Community manager"
                      required
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-select
                      :items="lead_statuses"
                      v-model="data_fields.contactMomentStatus"
                      item-text="name"
                      item-value="id"
                      label="Status"
                      required
                    ></v-select>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div v-if="this.intake === 'no'">
              <span class="subtitle-2">Opnieuw plannen?</span>
              <v-radio-group v-model="scheduleAgain" row>
                <v-radio label="Ja" value="yes"></v-radio>
                <v-radio label="Nee" value="no"></v-radio>
              </v-radio-group>
              <div v-if="this.scheduleAgain === 'yes'">
                <v-row>
                  <v-col>
                    <v-select
                      :items="employees"
                      v-model="data_fields.selectedRecruiterId"
                      item-text="full_name"
                      item-value="id"
                      label="Recruiter"
                      required
                    ></v-select>
                  </v-col>
                  <v-col>
                    <DateTimePicker v-model="data_fields.time" />
                  </v-col>
                </v-row>
              </div>
            </div>
            <div
              v-if="
                (this.intake === 'yes' && this.scheduleManager === 'no') ||
                (this.intake === 'no' && this.scheduleAgain === 'no')
              "
            >
              <v-row>
                <v-col>
                  <v-select
                    :items="lead_statuses"
                    v-model="data_fields.contactMomentStatus"
                    item-text="name"
                    item-value="id"
                    label="Status"
                    required
                  ></v-select>
                </v-col>
              </v-row>
            </div>
            <v-textarea
              outlined
              name="input-7-1"
              label="Contact Notities"
              auto-grow
              v-model="data_fields.contactMomentText"
            ></v-textarea>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="submit()"
            :disabled="saving"
          >
            Opslaan
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import DateTimePicker from "./DateTimePicker";
export default {
  components: { DateTimePicker },
  props: {
    leadID: Number,
    leadUserID: Number,
  },
  data: () => ({
    intake: "yes",
    warningText: "",
    scheduleAgain: "yes",
    scheduleManager: "yes",
    showAlert: false,
    saving: false,
    data_fields: {
      time: null,
      selectedRecruiterId: null,
      selectedManagerId: null,
      contactMomentText: null,
      contactMomentStatus: null,
    },
  }),
  computed: {

    loaded(){
      if(this.employees !== null && this.lead_statuses !== null && this.communityManagers !== null){
        return true
      }else{
        return false
      }
    },

    ...mapState({
      communityManagers: (state) =>
        state.users.all.items.employees
          .filter((e) => e.role !== null) //Removed this as roles are a bit odd at the momment
          .sort((a, b) => {
            if (a.first_name < b.first_name) {
              return -1;
            }
            if (a.first_name > b.first_name) {
              return 1;
            }
            return 0;
          })
          .map((e) => {
            return {
              id: e.id,
              full_name: e.first_name + " " + e.last_name,
            };
          }),

      employees: (state) =>
        state.users.all.items.employees
          .filter((e) => e.role !== null) //Removed this as roles are a bit odd at the momment
          .sort((a, b) => {
            if (a.first_name < b.first_name) {
              return -1;
            }
            if (a.first_name > b.first_name) {
              return 1;
            }
            return 0;
          })
          .map((e) => {
            return {
              id: e.id,
              full_name: e.first_name + " " + e.last_name,
            };
          }),

      lead_statuses: (state) =>
        state.statuses.lead_statuses
        .filter((e) => e.name !== null)
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }),
    }),
  },
  methods: {
    async submit() {
      this.saving = true;
      const leadId = this.leadID;
      const { dispatch } = this.$store;
      var flow = {
        lead_id: leadId,
      };
      if (this.intake === "yes") {
        if (this.scheduleManager === "yes") {
          if (
            this.data_fields.selectedManagerId !== null &&
            this.data_fields.contactMomentText !== null &&
            this.data_fields.contactMomentStatus !== null
          ) {
            flow.placed_cm = this.data_fields.selectedManagerId;
            flow.intake_completed = true;
            await dispatch("flow/updateFlow", { flow, leadId }).then((flow) => {
              dispatch("alerts/success", "Intake opgeslagen");
            });
            await this.addStatusToUser(
              this.data_fields.contactMomentText,
              1,
              this.data_fields.contactMomentStatus
            );
            await dispatch("leads/getAllLeadsWithFlow");
            this.resetDialog();
            this.$emit("close-dialog");
          } else {
            this.warningText =
              "Kies een community manager, status en vul in notities!";
            this.showAlert = true;
            this.saving = false;
          }
        } else {
          if (
            this.data_fields.contactMomentText !== null &&
            this.data_fields.contactMomentStatus !== null
          ) {
            flow.intake_completed = true;
            await dispatch("flow/updateFlow", { flow, leadId }).then((flow) => {
              dispatch("alerts/success", "Intake opgeslagen");
            });
            await this.addStatusToUser(this.data_fields.contactMomentText, 1, this.data_fields.contactMomentStatus);
            await dispatch("leads/getAllLeadsWithFlow");
            this.resetDialog();
            this.$emit("close-dialog");
          } else {
            this.warningText = "Kies een status en vul in notities!";
            this.showAlert = true;
            this.saving = false;
          }
        }
      } else if (this.intake === "no") {
        if (this.scheduleAgain === "yes") {
          if (
            this.data_fields.time !== null &&
            this.data_fields.selectedRecruiterId !== null &&
            this.data_fields.contactMomentText !== null
          ) {
            flow.intake_completed = null;
            flow.intake_rec = this.data_fields.selectedRecruiterId;
            flow.intake_date = this.data_fields.time;
            await dispatch("flow/updateFlow", { flow, leadId }).then((flow) => {
              dispatch("alerts/success", "Nieuw intake gepland");
            });
            await this.addStatusToUser(this.data_fields.contactMomentText, 1, null);
            await dispatch("leads/getAllLeadsWithFlow");
            this.resetDialog();
            this.$emit("close-dialog");
          } else {
            this.warningText = "Kies een recruiter, tijd en vul in notities!";
            this.showAlert = true;
            this.saving = false;
          }
        } else {
          if (
            this.data_fields.contactMomentText !== null &&
            this.data_fields.contactMomentStatus !== null
          ) {
            flow.intake_completed = false;
            await dispatch("flow/updateFlow", { flow, leadId }).then((flow) => {
              dispatch("alerts/success", "Intake afgerond");
            });
            await this.addStatusToUser(
              this.data_fields.contactMomentText,
              1,
              this.data_fields.contactMomentStatus
            );
            await dispatch("leads/getAllLeadsWithFlow");
            this.resetDialog();
            this.$emit("close-dialog");
          } else {
            this.warningText = "Kies een status en vul in notities!";
            this.showAlert = true;
            this.saving = false;
          }
        }
      }
    },

    async addStatusToUser(cm_notes, cm_type, leadStatusId) {
      const leadUserId = this.leadUserID;
      const { dispatch } = this.$store;
      let newContactMoment = {
        lead_user_id: leadUserId,
        type: cm_type,
        comunicator_id: this.$store.state.users.user.id,
        user_status_id: null,
        notes: cm_notes,
      };
      if (leadStatusId != null) {
        let leadUserStatus = {
          lead_user_id: leadUserId,
          lead_status_id: leadStatusId,
        };
        await dispatch("lead_users/addStatusToLeadUser", leadUserStatus).then(
          (res) => {
            newContactMoment.user_status_id = res.newLeadUserStatus.id;
          }
        );
      }
      await dispatch("contact_moments/createContactMoment", newContactMoment);

      return;
    },

    resetDialog() {
      this.intake = "yes";
      this.scheduleAgain = "yes";
      this.scheduleManager = "yes";
      this.showAlert = false;
      this.data_fields.selectedRecruiterId = null;
      this.data_fields.time = null;
      this.data_fields.selectedManagerId = null;
      this.saving = false;
      this.data_fields.contactMomentText = null;
      this.data_fields.contactMomentStatus = null;
      this.warningText = "";
    },
  },
};
</script>

<style scoped></style>
