<template>
  <div v-if="activeLead.id">
    <v-dialog v-model="dialogEditPerson" max-width="600px">
      <v-card>
        <CreateLeadUser
          v-on:close-dialog="dialogEditPerson = false"
          type="1"
          :parentID="activeLead.id"
          :formData="activeLead"
        ></CreateLeadUser>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditLead" max-width="600px">
      <v-card>
        <CreateLead
          v-on:close-dialog="dialogEditLead = false"
          type="1"
          :formData="leadToEdit"
        ></CreateLead>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteLead" max-width="450px">
      <v-card>
        <DeleteLead
          v-on:close-dialog="dialogDeleteLead = false"
          type="1"
          :formData="leadToDelete"
        ></DeleteLead>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAddContactMoment" max-width="600px">
      <v-card>
        <CreateContactMoment
          v-on:close-dialog="dialogAddContactMoment = false"
          :type="contactMomentDialogType"
          :parentID="activeLead.id"
          :leadUserName="activeLead.first_name + ' ' + activeLead.last_name"
          :formData="contactMomentToEdit"
          :lead="activeMainLead"
        ></CreateContactMoment>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogInterest" max-width="600px">
      <v-card>
        <CreateInterest
          v-on:close-dialog="dialogInterest = false"
          :type="dialogInterestType"
          :leadUserId="activeLead.id"
        ></CreateInterest>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="intakeFormDialog"
      persistent
      :overlay-opacity="opacity"
      width="750"
      min-height="800"
    >
      <v-card style="width: 750px; height: 100%">
        <IntakeForm
          v-on:close-dialog="intakeFormDialog = false"
          :user="activeLead"
          :lead="activeMainLead"
        ></IntakeForm>
      </v-card>
    </v-dialog>

    <v-container fluid>
      <!--Header Section-->
      <v-row>
        <v-col>
          <h1>
            <v-btn class="mb-1" icon color="primary" @click="$router.go(-1)">
              <v-icon x-large dark>mdi-chevron-left-box</v-icon>
            </v-btn>
            {{ activeLead.first_name + " " + activeLead.last_name }}
            <v-chip :color="latestStatus.color"
              ><strong>{{ latestStatus.name }}</strong></v-chip
            >
          </h1>
        </v-col>
        <v-col justify="end" align="end">
          <h1>ID: {{ activeLead.id }}</h1>
        </v-col>
      </v-row>
    </v-container>

    <!-- New layout -->
    <v-container fluid class="pt-0">
      <v-row>
        <!-- Persoon -->
        <v-col xl="4" lg="5" md="6" sm="12">
          <v-container fluid class="pl-0 pr-0">
            <v-col class="pa-0">
              <v-card min-height="380">
                <v-row :style="cardHeaders" class="ma-0">
                  <v-row class="pa-5">
                    <v-col md="8">
                      <v-row>
                        <v-icon medium>mdi-account</v-icon>
                        <h3>
                          <strong>{{
                            `${activeLead.first_name} ${activeLead.last_name}`
                          }}</strong>
                        </h3>
                      </v-row>
                    </v-col>
                    <v-col align="end" class="pa-0">
                      <v-btn
                        height="20px"
                        depressed
                        color="primary"
                        dense
                        class="mr-2"
                        @click="openIntakeForm()"
                      >
                        Intake
                      </v-btn>
                      <span
                        v-if="
                          activeMainLead.flow && activeMainLead.flow.first_date
                        "
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="green" dark v-bind="attrs" v-on="on">
                              mdi-phone
                            </v-icon>
                          </template>
                          <span>{{
                            toLocalDate(activeMainLead.flow.first_date)
                          }}</span>
                        </v-tooltip>
                      </span>
                      <v-badge
                        v-else-if="
                          activeMainLead.flow &&
                            activeMainLead.flow.vm_date &&
                            activeMainLead.flow.vm_count
                        "
                        bordered
                        color="error"
                        :content="activeMainLead.flow.vm_count"
                        overlap
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              elevation="0"
                              color="error"
                              small
                              @click="
                                openFlowSpoken(activeLead, activeMainLead)
                              "
                              class="pl-1 pr-3"
                              icon
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon>mdi-phone-missed</v-icon>
                            </v-btn>
                          </template>
                          <span>{{
                            toLocalDate(activeMainLead.flow.vm_date)
                          }}</span>
                        </v-tooltip>
                      </v-badge>
                      <v-btn
                        v-else-if="
                          activeMainLead.flow && activeMainLead.flow.id
                        "
                        elevation="0"
                        color="primary"
                        small
                        @click="openFlowSpoken(activeLead, activeMainLead)"
                        icon
                      >
                        <v-icon color="red" dark> mdi-phone </v-icon>
                      </v-btn>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            medium
                            @click="dialogEditPerson = true"
                            class="pl-2"
                            >mdi-pencil-box</v-icon
                          >
                        </template>
                        <span>Persoon Bijwerken</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-row>
                <v-row class="mt-0">
                  <v-col class="pt-0">
                    <v-row class="pl-5 mt-0 pb-0">
                      <v-col class="pb-0">
                        <span>
                          <v-icon medium class="pr-1">mdi-phone</v-icon
                          >{{ activeLead.tel_number }}
                          <br />
                          <v-icon medium class="pr-1">mdi-email</v-icon
                          ><a :href="'mailto:' + activeLead.email">{{
                            activeLead.email
                          }}</a>
                          <br />

                          <p
                            class="mb-0"
                            v-if="
                              activeLead.address != undefined &&
                                activeLead.address.street != ''
                            "
                          >
                            <v-icon>mdi-home</v-icon>
                            {{ activeLead.address.street }},
                            {{ activeLead.address.postalcode }}
                            {{
                              activeLead.address.houseno +
                                (activeLead.address.addition
                                  ? " " + activeLead.address.addition
                                  : "")
                            }},
                            {{ activeLead.address.region }}
                          </p>
                          <p
                            class="mb-0"
                            v-else-if="activeLead.region != undefined"
                          >
                            <v-icon>mdi-home</v-icon> {{ activeLead.region }}
                          </p>
                          <p class="mb-0" v-else>
                            <v-icon>mdi-home</v-icon> Adres onbekend
                          </p>
                          <v-icon medium class="pr-0">mdi-calendar</v-icon>
                          <p
                            style="display: inline"
                            v-if="activeLead.dob != undefined"
                          >
                            {{ formatDate(activeLead.dob) }}
                          </p>
                          <p style="display: inline" v-else>Geb. onbekend</p>
                          <br />
                          <v-icon medium class="pr-0">mdi-account</v-icon>
                          <p
                            style="display: inline"
                            v-if="activeLead.gender != undefined"
                          >
                            {{ activeLead.gender }}
                          </p>
                          <p style="display: inline" v-else>
                            Geslacht onbekend
                          </p>
                          <br />
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="pr-1"
                                v-bind="attrs"
                                v-on="on"
                                medium
                                >mdi-currency-eur</v-icon
                              >
                            </template>
                            <span>Salarisindicatie</span>
                          </v-tooltip>
                          <p
                            style="display: inline"
                            v-if="activeLead.sal_indication != undefined"
                          >
                            {{ activeLead.sal_indication }}
                          </p>
                          <p style="display: inline" v-else>Onbekend</p>
                          <br />
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="pr-1"
                                v-bind="attrs"
                                v-on="on"
                                medium
                                >mdi-file-document-edit</v-icon
                              >
                            </template>
                            <span>Contracttermijn</span>
                          </v-tooltip>
                          <p
                            style="display: inline"
                            v-if="activeLead.contract_term != undefined"
                          >
                            {{ activeLead.contract_term }}
                          </p>
                          <p style="display: inline" v-else>Onbekend</p>
                          <br />
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="pr-1"
                                v-bind="attrs"
                                v-on="on"
                                medium
                                >mdi-clock-time-nine</v-icon
                              >
                            </template>
                            <span>Betrekkingsomvang</span>
                          </v-tooltip>
                          <p
                            style="display: inline"
                            v-if="activeLead.contract_type != undefined"
                          >
                            {{ activeLead.contract_type }}
                          </p>
                          <p style="display: inline" v-else>Onbekend</p>
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row class="mt-0">
                      <v-col class="pt-0">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="pr-3"
                              v-bind="attrs"
                              v-on="on"
                              :color="activeLead.car ? 'green' : 'red'"
                              medium
                              >mdi-car</v-icon
                            >
                          </template>
                          <span>{{
                            activeLead.car ? `Heeft een auto` : `Geen auto`
                          }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="pr-3"
                              v-bind="attrs"
                              v-on="on"
                              :color="
                                activeLead.drivers_license ? 'green' : 'red'
                              "
                              medium
                              >mdi-card-account-details</v-icon
                            >
                          </template>
                          <span>{{
                            activeLead.drivers_license
                              ? `Heeft rijbewijs`
                              : `Geen rijbewijs`
                          }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="pr-3"
                              v-bind="attrs"
                              v-on="on"
                              :color="
                                activeLead.contract_signed ? 'green' : 'red'
                              "
                              medium
                              >mdi-file-document-edit</v-icon
                            >
                          </template>
                          <span>{{
                            activeLead.contract_signed
                              ? `Contract getekend op ${formatDate(
                                  activeLead.contract
                                )}`
                              : `Contract niet getekend`
                          }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="pr-1"
                              v-bind="attrs"
                              v-on="on"
                              :color="activeLead.bullhorn ? 'green' : 'red'"
                              medium
                              >mdi-bullhorn</v-icon
                            >
                          </template>
                          <span>{{
                            activeLead.bullhorn
                              ? `Staat in Bullhorn`
                              : `Staat niet in Bullhorn`
                          }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <h4><v-icon class="pr-1">mdi-earth</v-icon>Talen</h4>
                    <p
                      v-if="
                        activeLead.languages == undefined ||
                          activeLead.languages[0] == undefined
                      "
                    >
                      Geen talen bekend
                    </p>
                    <v-card
                      class="overflow-y-auto"
                      max-height="100"
                      max-width="200"
                      elevation="0"
                    >
                      <v-list disabled dense>
                        <v-list-item
                          v-for="(item, i) in activeLead.languages"
                          :key="i"
                          class="pl-1 mb-1"
                          style="min-height: 0px"
                        >
                          <v-list-item-icon class="ma-0 mr-1">
                            <CountryFlag :country="item.flag" />
                          </v-list-item-icon>
                          <v-list-item-content class="pt-0 pb-0 mt-0">
                            <v-list-item-title
                              v-text="item.name"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row
                  class="pl-3 pr-3 mt-0 pb-4"
                  justify="center"
                  align="center"
                  ><v-col md="11"><v-divider></v-divider></v-col
                ></v-row>
                <v-row class="pl-5 pr-3 mt-0 pb-0">
                  <v-col class="pt-0">
                    <h4><v-icon>mdi-clipboard</v-icon>Notities</h4>
                    <v-card
                      class="overflow-y-auto pl-1"
                      max-height="100"
                      elevation="0"
                    >
                      {{ activeLead.notes }}
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-container>
        </v-col>
        <!-- Contact Momenten -->
        <v-col xl="4" lg="4" md="6">
          <v-container fluid class="pl-0 pr-0">
            <v-card min-height="380">
              <v-row :style="cardHeaders" class="ma-0">
                <v-row class="pa-5">
                  <v-col md="10">
                    <v-row>
                      <v-icon medium>mdi-book-open-blank-variant</v-icon>
                      <h3><strong>Contactmomenten</strong></h3>
                    </v-row>
                  </v-col>
                  <v-col md="2" align="end" class="pa-0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          medium
                          @click="createNewContactMoment"
                          >mdi-plus-box</v-icon
                        >
                      </template>
                      <span>Nieuw contactmoment</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-row>
              <v-row>
                <v-col class="pt-0">
                  <v-card
                    class="overflow-y-auto"
                    max-height="340"
                    elevation="0"
                  >
                    <v-container
                      v-for="item in activeLead.contact_moments
                        .slice()
                        .reverse()"
                      :key="item.id"
                      fluid
                    >
                      <v-row>
                        <v-col md="10" class="pb-0 pt-0">
                          <h4 class="pb-1">
                            {{ item.status.name }} |
                            {{ toLocalDate(item.createdAt) }}
                            |
                            {{
                              item.communicator.first_name +
                                " " +
                                item.communicator.last_name
                            }}
                            <span v-if="item.vacancy_id"
                              >|
                              <a @click="openVacancy(item.vacancy_id)"
                                >Vacature Openen</a
                              ></span
                            >
                          </h4>
                        </v-col>
                        <v-col align="end" md="2" class="pb-0 pt-0 pr-0">
                          <v-btn
                            icon
                            color="primary"
                            @click="editContactMoment(item)"
                          >
                            <v-icon medium dark>mdi-pencil-box</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0">
                        <v-col
                          ><p>
                            <span v-if="item.user_status">
                              <h5>
                                Status na contact moment:
                                <v-chip :color="item.user_status.status.color"
                                  ><strong>{{
                                    item.user_status.status.name
                                  }}</strong></v-chip
                                >
                              </h5></span
                            ><span
                              ><h5>Notities:</h5>
                              {{ item.notes }}</span
                            >
                          </p></v-col
                        ></v-row
                      >
                      <v-divider></v-divider>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-container>
        </v-col>
        <!-- Lead -->
        <v-col xl="4" lg="3" md="6" sm="12">
          <v-container fluid class="pl-0 pr-0">
            <v-card min-height="380">
              <v-row :style="cardHeaders" class="ma-0">
                <v-row class="pa-5">
                  <v-col md="10">
                    <v-row>
                      <v-icon medium>mdi-text-box-check</v-icon>
                      <h3><strong>Leads</strong></h3>
                    </v-row>
                  </v-col>
                </v-row>
              </v-row>
              <v-row>
                <v-col>
                  <v-card
                    class="pa-3 overflow-y-auto"
                    max-height="340"
                    elevation="0"
                  >
                    <v-container class="pl-6 pt-0">
                      <v-row
                        class="pa-0"
                        v-for="item in activeLead.leads.slice().reverse()"
                        :key="item.id"
                      >
                        <v-container class="mb-2 pb-0">
                          <v-row class="mb-2">
                            <v-col md="5" class="pa-0">
                              <p style="margin-bottom: 0">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      medium
                                      class="pr-1"
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-calendar</v-icon
                                    >
                                  </template>
                                  <span>Solicitatie datum</span>
                                </v-tooltip>
                                {{ formatDate(item.applied_date) }}
                              </p>
                              <p style="margin-bottom: 0">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      medium
                                      class="pr-1"
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-home-assistant</v-icon
                                    >
                                  </template>
                                  <span>Kanaal</span>
                                </v-tooltip>
                                {{ item.channel.name }}
                              </p>
                              <p style="margin-bottom: 0">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      medium
                                      class="pr-1"
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-badge-account</v-icon
                                    >
                                  </template>
                                  <span>Recruiter</span>
                                </v-tooltip>
                                <span v-if="item.rec_id">
                                  {{ item.recruiter.first_name }}
                                  {{ item.recruiter.last_name }}</span
                                ><span v-else> Niet toegewezen</span>
                              </p>
                              <p style="margin-bottom: 0">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      medium
                                      class="pr-1"
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-badge-account-outline</v-icon
                                    >
                                  </template>
                                  <span>Recruitment Assistent</span>
                                </v-tooltip>
                                <span v-if="item.ra_id">
                                  {{ item.recruitment_assistant.first_name }}
                                  {{
                                    item.recruitment_assistant.last_name
                                  }}</span
                                ><span v-else> Niet toegewezen</span>
                              </p>
                            </v-col>
                            <v-col md="5" class="pa-0">
                              <p style="margin-bottom: 0">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      medium
                                      class="pr-1"
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-text-box-multiple</v-icon
                                    >
                                  </template>
                                  <span>Sollicitatie Vacature</span>
                                </v-tooltip>
                                <a @click="openVacancy(item.vacancy.id)">{{
                                  item.vacancy.name
                                }}</a>
                              </p>
                              <p
                                style="margin-bottom: 0"
                                v-if="item.exit_vacancy"
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      medium
                                      class="pr-1"
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-text-box-multiple-outline</v-icon
                                    >
                                  </template>
                                  <span>Exit Vacature</span>
                                </v-tooltip>
                                <a @click="openVacancy(item.exit_vacancy.id)">{{
                                  item.exit_vacancy.name
                                }}</a>
                              </p>
                            </v-col>
                            <v-col class="">
                              <v-row justify="end">
                                <v-tooltip
                                  bottom
                                  v-if="item.id == activeMainLead.id"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      medium
                                      color="green"
                                      >mdi-check-bold</v-icon
                                    >
                                  </template>
                                  <span>Actieve Lead</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      medium
                                      color="primary"
                                      @click="editLead(item)"
                                      >mdi-pencil-box</v-icon
                                    >
                                  </template>
                                  <span>Lead ID: {{ item.id }}</span>
                                </v-tooltip>
                              </v-row>
                              <v-row justify="end">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      medium
                                      color="red"
                                      @click="deleteLead(item)"
                                      >mdi-delete-forever</v-icon
                                    >
                                  </template>
                                  <span>Delete</span>
                                </v-tooltip>
                              </v-row>
                            </v-col>
                          </v-row>
                          <v-divider></v-divider>
                        </v-container>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-container>
        </v-col>
        <!-- Documenten -->
        <v-col xl="4" lg="5" md="6" sm="12">
          <v-container fluid class="pl-0 pr-0">
            <v-col>
              <v-row>
                <DocumentsSection
                  :documents="activeLead.documents"
                  :parentID="activeLead.id"
                  :type="1"
                  :parsedCv="activeLead.parsedCv"
                  :textCv="activeLead.textCv"
                />
              </v-row>
            </v-col>
          </v-container>
        </v-col>
        <!-- Status -->
        <v-col xl="3" lg="4" md="6" sm="12">
          <v-container fluid class="pl-0 pr-0">
            <v-card min-height="380">
              <v-row :style="cardHeaders" class="ma-0">
                <v-row class="pa-5">
                  <v-col md="10">
                    <v-row>
                      <v-icon medium>mdi-format-list-bulleted</v-icon>
                      <h3><strong>Status Geschiedenis</strong></h3>
                    </v-row>
                  </v-col>
                </v-row>
              </v-row>
              <v-row>
                <v-col class="pt-0">
                  <v-card
                    elevation="0"
                    class="pa-0 pr-3 overflow-y-auto"
                    max-height="300"
                  >
                    <v-timeline dense>
                      <v-timeline-item
                        v-for="item in activeLead.lead_statuses
                          .slice()
                          .reverse()"
                        :key="item.id"
                        :color="item.status.color"
                        name="status"
                        small
                      >
                        <v-row>
                          <v-col>
                            <h4>
                              {{ toLocalDate(item.createdAt) }} -
                              {{ item.status.name }}
                            </h4>
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </v-timeline>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-container>
        </v-col>
        <!-- Flow -->
        <v-col
          xl="4"
          lg="3"
          md="6"
          sm="12"
          v-if="activeMainLead && activeMainLead.flow"
        >
          <v-container fluid class="pl-0 pr-0">
            <v-card min-height="380">
              <v-row :style="cardHeaders" class="ma-0">
                <v-row class="pa-5">
                  <v-col md="10">
                    <v-row>
                      <v-icon medium>mdi-text-box-check</v-icon>
                      <h3><strong>Flow</strong></h3>
                    </v-row>
                  </v-col>
                </v-row>
              </v-row>
              <v-row class="mt-0">
                <v-col>
                  <v-card
                    class="pl-3 pr-3 overflow-y-auto"
                    max-height="340"
                    elevation="0"
                  >
                    <h4>Eerste Contact</h4>
                    <p
                      style="display: inline"
                      v-if="activeMainLead.flow.first_date"
                      class="pl-3"
                    >
                      {{ toLocalDate(activeMainLead.flow.first_date) }}
                    </p>
                    <p class="pl-3" style="display: inline" v-else>
                      Nog niet gesproken
                    </p>
                    <h4>Intake</h4>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Datum</th>
                            <th class="text-left">Recruiter</th>
                            <th class="text-left">Afgerond</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr key="1">
                            <td v-if="activeMainLead.flow.intake_date">
                              {{ toLocalDate(activeMainLead.flow.intake_date) }}
                            </td>
                            <td v-else>-</td>
                            <td v-if="activeMainLead.flow.rec">
                              {{ fullName(activeMainLead.flow.rec) }}
                            </td>
                            <td v-else>-</td>
                            <td>
                              <v-checkbox
                                disabled
                                v-model="activeMainLead.flow.intake_completed"
                              ></v-checkbox>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <h4>Geplaatst</h4>
                    <p
                      style="display: inline"
                      v-if="activeMainLead.flow.placed_date != undefined"
                      class="pl-3"
                    >
                      Door {{ fullName(activeMainLead.flow.cm) }} op
                      {{
                        toLocalDate(activeMainLead.flow.placed_date).substring(
                          0,
                          9
                        )
                      }}
                    </p>
                    <p class="pl-3" style="display: inline" v-else>
                      Nog niet geplaatst
                    </p>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-container>
        </v-col>
        <!-- Kaart -->
        <v-col
          xl="3"
          lg="5"
          md="6"
          sm="12"
          v-if="activeLead.address != undefined && !incompleteAddress"
        >
          <v-container v-if="!incompleteAddress" fluid class="pl-0 pr-0">
            <v-card min-height="380">
              <v-row :style="cardHeaders" class="ma-0">
                <v-row class="pa-5">
                  <v-col md="10">
                    <v-row>
                      <v-icon medium>mdi-map</v-icon>
                      <h3><strong>Locatie</strong></h3>
                    </v-row>
                  </v-col>
                </v-row>
              </v-row>
              <v-row style="overflow: hidden; height: 336px" class="pa-0 ma-0">
                <GoogleMap
                  v-if="activeLead.address != undefined"
                  :address="activeLead.address"
                  @latlng-calculated="onLatlngCalculated"
                ></GoogleMap>
              </v-row>
            </v-card>
          </v-container>
        </v-col>
        <v-col xl="3" lg="4" md="6" sm="12">
          <v-container fluid class="pl-0 pr-0">
            <v-card min-height="380">
              <v-row :style="cardHeaders" class="ma-0">
                <v-row class="pa-5">
                  <v-col md="10">
                    <v-row>
                      <v-icon medium>mdi-thumb-up</v-icon>
                      <h3><strong>Interesses</strong></h3>
                    </v-row>
                  </v-col>
                  <v-col md="2" align="end" class="pa-0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          medium
                          @click="createNewInterest"
                          >mdi-plus-box</v-icon
                        >
                      </template>
                      <span>Nieuw interesses</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-row>
              <v-row>
                <v-col class="pa-0 pl-3 pr-3">
                  <InterestsSection
                    :leadUserId="activeLead.id"
                  ></InterestsSection>
                </v-col>
              </v-row>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
      <v-bottom-sheet v-model="dialogSpeak" inset>
        <v-sheet class="text-center">
          <SpokenDatePicker
            :type="2"
            v-on:close-dialog="dialogSpeak = false"
            :leadID="activeLeadId"
            :leadUserID="activeLeadUserId"
            :leadName="activeLeadName"
            :leadContactNo="activeLeadContactNo"
            :vmCount="activeLeadVmCount"
          />
        </v-sheet>
      </v-bottom-sheet>
    </v-container>
  </div>
</template>

<script>
//import ViewIntake from "../components/dialogs/ViewIntake.vue";
import DeleteLead from "../components/dialogs/DeleteLead.vue";
import IntakeForm from "../components/dialogs/IntakeForm.vue";
import CreateLead from "../components/dialogs/CreateLead.vue";
import CreateLeadUser from "../components/dialogs/CreateLeadUser.vue";
import CountryFlag from "vue-country-flag";
import CreateContactMoment from "../components/dialogs/CreateContactMoment.vue";
import GoogleMap from "../components/GoogleMap.vue";
import DocumentsSection from "../components/DocumentsSection.vue";
import InterestsSection from "../components/InterestsSection.vue";
import CreateInterest from "../components/dialogs/CreateInterest";
import SpokenDatePicker from "../components/dialogs/SpokenDatePicker";
import { incompleteAddress } from "../helpers/google";
export default {
  components: {
    CreateContactMoment,
    GoogleMap,
    CreateLead,
    DeleteLead,
    CountryFlag,
    CreateLeadUser,
    DocumentsSection,
    InterestsSection,
    CreateInterest,
    IntakeForm,
    SpokenDatePicker,
    //ViewIntake,
  },
  data: () => ({
    panel: [0],
    dialogEditLead: false,
    dialogDeleteLead: false,
    dialogEditPerson: false,
    dialogAddContactMoment: false,
    leadToEdit: {},
    leadToDelete: {},
    contactMomentToEdit: {},
    contactMomentDialogType: "0",
    dialogInterestType: "0",
    dialogInterest: false,
    intakeFormDialog: false,
    opacity: 0.9,
    dialogSpeak: false,
    activeLeadId: null,
    activeLeadUserId: null,
    activeLeadName: null,
    activeLeadContactNo: null,
    activeFlowCmId: null,
    activeLeadVmCount: null,
  }),
  computed: {
    activeLead() {
      return this.$store.state.lead_users.active_lead_user;
    },
    activeMainLead() {
      return this.activeLead.leads[this.activeLead.leads.length - 1];
    },
    latestStatus() {
      if (this.activeLead.lead_statuses.length > 0) {
        return this.activeLead.lead_statuses[
          this.activeLead.lead_statuses.length - 1
        ].status;
      } else {
        return { name: "Geen", color: "#FFD700" };
      }
    },
    incompleteAddress() {
      return incompleteAddress(this.activeLead.address);
    },
    cardHeaders() {
      if (this.$vuetify.theme.dark) {
        return "background: #2a2a2a";
      } else {
        return "background: #b3b3b3";
      }
    },
  },
  created() {
    this.getUser();
  },

  methods: {
    async getUser() {
      const { dispatch } = this.$store;
      await dispatch("lead_users/getLeadUser", this.$route.params.id);

      await dispatch("users/getAll");
    },

    async onLatlngCalculated(event) {
      const { dispatch } = this.$store;
      let lead_user = JSON.parse(JSON.stringify(this.activeLead)); // Make copy of object
      lead_user.address.lat = event.lat;
      lead_user.address.lng = event.lng;
      lead_user.address = JSON.stringify(lead_user.address);
      lead_user.languages = JSON.stringify(lead_user.languages);
      await dispatch("lead_users/updateLeadUser", {
        lead_user,
        userId: lead_user.id,
      });
    },

    toLocalDate(date) {
      var utcDate = new Date(date);
      const localeString = utcDate.toLocaleString("nl-NL");
      return localeString.substring(0, localeString.length - 3);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    fullName(emp) {
      return emp.first_name + " " + emp.last_name;
    },

    openIntakeForm() {
      this.intakeFormDialog = true;
    },

    editLead(item) {
      this.leadToEdit = item;
      this.dialogEditLead = true;
    },
    async deleteLead(item) {
      const { dispatch } = this.$store;
      if (this.$store.state.users.user.role === 0) {
        this.leadToDelete = item;
        this.dialogDeleteLead = true;
      } else {
        dispatch("alerts/warn", "Only admins can delete leads!");
      }
    },
    editContactMoment(item) {
      this.contactMomentToEdit = item;
      this.contactMomentDialogType = "1";
      this.dialogAddContactMoment = true;
    },
    createNewContactMoment() {
      this.contactMomentDialogType = "0";
      this.dialogAddContactMoment = true;
    },
    createNewInterest() {
      this.dialogInterestType = "0";
      this.dialogInterest = true;
    },
    openVacancy(id) {
      this.$router.push(`/vacature/${id}`);
    },
    async fileDownload() {
      const { dispatch } = this.$store;
      await dispatch("file_upload/downloadDocument", 5);
    },
    iconColor(item) {
      if (item) {
        return "green";
      } else {
        return "red";
      }
    },
    openFlowSpoken(activeLead, activeMainLead) {
      this.activeLeadId = activeMainLead.flow.lead_id;
      this.activeLeadUserId = activeLead.id;
      this.activeLeadName = `${activeLead.first_name} ${activeLead.last_name}`;
      this.activeLeadContactNo = activeLead.tel_number;
      this.activeLeadVmCount = activeMainLead.flow.vm_count;
      this.dialogSpeak = true;
    },
  },
};
</script>
