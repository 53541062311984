import axios from "axios";
import config from "../../../config";
import { authHeader } from "../../utils/auth-header";

export const teamup = {
  namespaced: true,
  state: {
    subCalendars: [],
  },
  getters: {
    getSubCalendars: (state) => state.subCalendars,
  },

  actions: {
    async getAllSubcalendars({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(`${config.apiUrl}/getSubCalendars`, request)
          .then(handleResponse)
          .then((data) => {
            commit("saveSubCalendarCollection", data.subcalendars);
          });
        // axios.defaults.headers.common["Authorization"] = `${
        //   authHeader().Authorization
        // }`;
        // const { data } = await axios.get(`${config.apiUrl}/getSubCalendars`);
        // commit("saveSubCalendarCollection", data.subcalendars);
      } catch (error) {
        console.log("error");
      }
    },
    async createEvent({ dispatch, commit }, payload) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(payload),
        };
        await fetch(`${config.apiUrl}/saveEvent`, request).then(handleResponse);
        // axios.defaults.headers.common["Authorization"] = `${
        //   authHeader().Authorization
        // }`;
        // const { data } = await axios.post(
        //   `${config.apiUrl}/saveEvent`,
        //   payload
        // );
        dispatch("alerts/success", "TeamUp event successfully created", {
          root: true,
        });
      } catch (error) {
        dispatch("alerts/warn", "TeamUp event failed to create", {
          root: true,
        });
        console.log(error);
      }
    },
  },
  mutations: {
    saveSubCalendarCollection(state, payload) {
      state.subCalendars = payload;
    },
  },
};
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
