<template>
  <v-container>
    <v-form ref="form" v-model="valid" class="pa-3">
      <v-row>
        <h3>Voeg een rol toe</h3>
      </v-row>
      <v-row align="center" justify="center">
        <v-text-field
          v-model="role_name"
          label="Rol naam"
          required
        ></v-text-field>
        <v-col style="margin-top: 0px; padding-top: 0px" align="end">
          <v-btn
            style="margin-top: 0px; padding-top: 0px"
            :disabled="!valid && !adding"
            color="success"
            class="mr-4"
            @click="createRole"
          >
            Rol toevoegen
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    adding: false,
    valid: true,
    role_name: "",
  }),

  methods: {
    ...mapActions("interests", ["getRoles"]),
    async createRole() {
      const { dispatch } = this.$store;
      if (this.role_name != "") {
        this.adding = true;
        let role = {
          name: this.role_name,
        };
        await dispatch("interests/createRole", role).then((res) => {
          dispatch("alerts/success", "Rol toegevoegd");
          this.getRoles();
          this.adding = false;
          this.clear();
          this.$emit("close-dialog");
        });
      } else {
        this.adding = false;
        dispatch("alerts/warn", "Geef de rol een naam");
      }
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>