import { contactService } from '../../services/contact.service'

export const contact = {
    namespaced: true,
    state: {
        
    },
    actions: {
        getContacts({ commit }, id) {
            commit('getAllRequest')
            return new Promise((resolve, reject) => {
                contactService.getContactsWithCompanyId(id)
                    .then(
                        contacts => {
                            commit('getAllSuccess', contacts)
                            resolve(contacts)
                        },
                        error => commit('getAllFailure', error)
                    );
            })
        },
        createContact({ dispatch, commit }, contact) {
            return new Promise((resolve, reject) => {
                contactService.createContact(contact)
                .then(contact => {
                    resolve(contact)
                },
                    error => commit('getAllFailure', error)
                );
            })
        },
        updateContact({ dispatch, commit }, { contact, id }) {
            return new Promise((resolve, reject) => {
                contactService.updateContact(contact, id)
                    .then(resolve(contact))
            });
        },
    },
    mutations: {
        getAllRequest(state) {
            state.status = { loading: true };
        },
        getAllSuccess(state, contacts) {
            state.status = { loading: false };
            state.contacts = { contacts };
        },
        getAllFailure(state, error) {
            state.status = { loading: false };
            state.all = { error };
        },
        getContactRequest(state) {
            state.status = { loading: true };
        }
    }
}