<template>
  <div v-if="activeVacancy && currentUser">
    <v-dialog v-model="dialogShowVacancy" max-width="600px">
      <v-card>
        <CreateVacancy
          v-on:close-dialog="dialogShowVacancy = false"
          type="1"
          :formData="activeVacancy.vacancy"
        ></CreateVacancy>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogInactive" max-width="600px">
      <v-card class="pb-1" color="primary">
        <v-alert class="pb-0" prominent type="info">
          <v-row align="center">
            <v-col class="grow">
              Weet je zeker dat je deze vacature inactief wilt maken?
            </v-col>
            <v-col class="shrink">
              <v-btn color="green" @click="makeInactive">Bevestig</v-btn>
            </v-col>
            <v-col class="shrink">
              <v-btn color="grey" @click="dialogInactive = false"
                >Annuleer</v-btn
              >
            </v-col>
          </v-row>
        </v-alert>
      </v-card>
    </v-dialog>
    <div class="pl-5 pr-5">
      <v-row>
        <v-col md="10">
          <h1 class="pb-1">
            <v-btn class="mb-1" icon color="primary" @click="$router.go(-1)">
              <v-icon x-large dark>mdi-chevron-left-box</v-icon>
            </v-btn>
            {{ activeVacancy.vacancy.id }} - {{ activeVacancy.vacancy.name }}
            <v-chip :color="latestVacancyStatus.color"
              ><strong>{{ latestVacancyStatus.name }}</strong></v-chip
            >
            <v-btn
              v-if="currentUser.role < 2 && !activeVacancy.vacancy.inactive"
              class="ml-2"
              color="red"
              @click="dialogInactive = true"
            >
              Maak inactief
            </v-btn>
          </h1>
        </v-col>
        <v-col align="end" md="2">
          <v-btn icon color="primary" @click="dialogShowVacancy = true">
            <v-icon large dark>mdi-pencil-box</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-card class="pa-4 mb-5 ml-5 mr-5" elevation="4">
      <v-row>
        <v-col>
          <v-col>
            <h3>Details</h3>
            <p>
              <a
                v-if="!!activeVacancy.vacancy.vacancy_link"
                :href="activeVacancy.vacancy.vacancy_link"
                >{{ activeVacancy.vacancy.vacancy_link }}<br
              /></a>
              Vacatureduur: {{ formatDate(activeVacancy.vacancy.start_date) }} -
              {{ formatDate(activeVacancy.vacancy.vacancy_close_date) }}
              <br />
              {{ activeVacancy.vacancy.employment_type }},
              {{ activeVacancy.vacancy.employment_length }}
              <br />
              Mensen nodig: {{ activeVacancy.vacancy.people_needed }}
              <br />
              Salarisindicatie: €{{ activeVacancy.vacancy.sal_indication }}
              <br />
              Tarief: €{{ activeVacancy.vacancy.tariff }}
              <span v-if="activeVacancy.vacancy.position"
                ><br />
                Oude Positie: {{ activeVacancy.vacancy.position.name }}
              </span>
              <span v-if="activeVacancy.vacancy.job">
                <br />
                Rol: {{ activeVacancy.vacancy.job.role.name }}
                <br />
                Industrie: {{ activeVacancy.vacancy.job.industry.name }}
              </span>
            </p>
            <h3>Omschrijving</h3>
            <p>{{ activeVacancy.vacancy.desc }}</p>
            <div v-if="!!activeVacancy.vacancy.other_info">
              <h3>Aanvullende informatie</h3>
              <p>{{ activeVacancy.vacancy.other_info }}</p>
            </div>
            <h3>Community Manager</h3>
            <p>
              {{ activeVacancy.vacancy.community_manager.first_name }}
              {{ activeVacancy.vacancy.community_manager.last_name }}
            </p>
            <h3>Recruiter</h3>
            <div v-if="activeVacancy.vacancy.recruiter">
              <p>
                {{ fullName(activeVacancy.vacancy.recruiter) }}
              </p>
            </div>
            <div v-else class="mt-2 mb-2">
              <v-btn @click="selfAssignRecruiter()" color="primary"
                >Jezelf toewijzen</v-btn
              >
            </div>
            <h3>Contactpersoon</h3>
            <p v-if="activeVacancy.vacancy.contact_person">
              <span v-if="activeVacancy.vacancy.contact_person.first_name">
                {{ activeVacancy.vacancy.contact_person.first_name }}</span
              >
              {{ " " }}
              <span v-if="activeVacancy.vacancy.contact_person.last_name">
                {{ activeVacancy.vacancy.contact_person.last_name }}</span
              >
              <br />
              <span v-if="activeVacancy.vacancy.contact_person.department">{{
                activeVacancy.vacancy.contact_person.department
              }}</span>
              <br />
              <span v-if="activeVacancy.vacancy.contact_person.mail">{{
                activeVacancy.vacancy.contact_person.mail
              }}</span>
              <br />
              <span v-if="activeVacancy.vacancy.contact_person.phone">{{
                activeVacancy.vacancy.contact_person.phone
              }}</span>
              <br />
            </p>
            <p v-else>Niet gevonden</p>
            <div v-if="hasTerms()">
              <h3>Voorwaarden</h3>
              <div v-if="!!activeVacancy.vacancy.terms.min_days">
                Minimale dagen: {{ activeVacancy.vacancy.terms.min_days }}
                <br />
              </div>
              <div v-if="!!activeVacancy.vacancy.terms.extra_remarks">
                Overige opmerkingen:
                {{ activeVacancy.vacancy.terms.extra_remarks }}<br />
              </div>
              <div v-if="!!activeVacancy.vacancy.terms.dresscode">
                Kleding voorschrift:
                {{ activeVacancy.vacancy.terms.dresscode }} <br />
              </div>
              <div v-if="!!activeVacancy.vacancy.terms.extra_requirements">
                Overige vereisten:
                {{ activeVacancy.vacancy.terms.extra_requirements }} <br />
              </div>
              <div v-if="!!activeVacancy.vacancy.terms.cao">
                CAO: {{ activeVacancy.vacancy.terms.cao }}<br />
              </div>
              <div v-if="!!activeVacancy.vacancy.terms.initial_scale">
                Startschaal: {{ activeVacancy.vacancy.terms.initial_scale
                }}<br />
              </div>
            </div>
          </v-col>
        </v-col>
        <v-col>
          <v-col>
            <a @click="openCompany(activeVacancy.vacancy.company_id)"
              ><h3>{{ activeVacancy.vacancy.company.name }}</h3></a
            >
            <a :href="activeVacancy.vacancy.company.site_url">{{
              activeVacancy.vacancy.company.site_url
            }}</a>
            <p>Telefoonnummer: {{ activeVacancy.vacancy.company.phone }}</p>

            <h3>Adres</h3>
            <p>
              {{ activeVacancy.vacancy.address.street }},
              {{ activeVacancy.vacancy.address.houseno
              }}{{ activeVacancy.vacancy.address.addition }}
              <br />
              {{ activeVacancy.vacancy.address.postalcode }}
              <br />
              {{ activeVacancy.vacancy.address.region }}
            </p>
            <GoogleMap
              style="overflow: hidden; height: 336px"
              :address="activeVacancy.vacancy.address"
              @latlng-calculated="onLatlngCalculated"
            ></GoogleMap>
          </v-col>
        </v-col>
      </v-row>
    </v-card>
    <v-divider></v-divider>
    <SubTable
      class="pa-5"
      type="5"
      v-bind:data="leadsAdjustedList"
      :parentID="activeVacancy.vacancy.id"
    ></SubTable>
    <v-divider></v-divider>
    <VacancyRecommend :parentID="activeVacancy.vacancy.id"></VacancyRecommend>
  </div>
</template>

<script>
import VacancyRecommend from "../components/dialogs/VacancyRecommend.vue";
import CreateVacancy from "../components/dialogs/CreateVacancy.vue";
import GoogleMap from "../components/GoogleMap.vue";
import SubTable from "../components/SubTables.vue";
import { mapGetters, mapState } from "vuex";
export default {
  components: { CreateVacancy, GoogleMap, SubTable, VacancyRecommend },
  data: () => ({
    dialogShowVacancy: false,
    dialogInactive: false,
    leads_list: [],
    currentUser: null,
    vacancyTerms: [],
  }),
  computed: {
    leadsAdjustedList() {
      return this.leads.map((e) => {
        var mapped = {
          id: e.id,
          lead_user: e.lead_user,
          campaign_id: e.campaign_id,
          channel_id: e.channel_id,
          channel_name: e.channel.name,
          lead_status_name: this.latestStatus(e.user).name,
          lead_status_color: this.latestStatus(e.user).color,
          user_name: this.fullName(e.user),
          user_dob: this.itemExists(this.formatDate(e.user.dob)),
          gender: this.itemExists(e.user.gender),
          has_driverslicense: e.user.drivers_license,
          has_car: e.user.car,
          rec_id: e.rec_id,
          recruiter_name: this.fullName(e.recruiter),
          ra_id: e.ra_id,
          recruitment_assistant_name: this.fullName(e.recruitment_assistant),
          applied_date: this.formatDate(e.applied_date),
          user_region: this.itemExists(e.user.region),
          vacancy_id: e.vacancy_id,
          exit_vacancy_id: e.exit_vacancy_id,
          company_name: this.itemExists(e.vacancy.company.name),

          tel_number: e.user.tel_number,
          flow: false,
        };

        if (e.flow != null) {
          mapped.first_date = this.toLocalDate(e.flow.first_date);
          mapped.vm_date = this.toLocalDate(e.flow.vm_date);
          mapped.intake_date = this.toLocalDate(e.flow.intake_date);
          mapped.intake_completed = e.flow.intake_completed;
          mapped.placed_date = this.toLocalDate(e.flow.placed_date);
          mapped.rec = e.flow.rec;
          mapped.rec_full_name = this.fullName(e.flow.rec);
          mapped.rec_ass_full_name = this.fullName(e.flow.rec_ass);
          mapped.placed_cm = e.flow.placed_cm;
          mapped.vm_count = e.flow.vm_count;
          mapped.flow = true;
        }

        return mapped;
      });
    },
    latestVacancyStatus() {
      if (this.activeVacancy.vacancy.vacancy_status) {
        return this.activeVacancy.vacancy.vacancy_status;
      } else {
        return { name: "Geen", color: "#FFD700" };
      }
    },
    ...mapState({
      activeVacancy: (state) => state.vacancy.activeVacancy,
      leads: (state) => state.leads.leadsByVac,
    }),
    ...mapGetters("users", ["getLoggedIn"]),
    ...mapGetters("leads", ["getLeadFlow"]),
  },
  async created() {
    this.getVacancy();
  },
  methods: {
    async getVacancy() {
      const { dispatch } = this.$store;
      await dispatch("vacancy/getVacancy", this.$route.params.id).then(
        (res) => {
          this.vacancyTerms = res.vacancy.searchTerms;
        }
      );
      await dispatch("leads/getLeadsByVacancyId", this.$route.params.id);
      await dispatch("users/getSelf").then((res) => (this.currentUser = res));
    },

    toLocalDate(date) {
      if (!date) return null;
      var utcDate = new Date(date);
      const localeString = utcDate.toLocaleString("nl-NL");
      return localeString.substring(0, localeString.length - 3);
    },

    async onLatlngCalculated(event) {
      const { dispatch } = this.$store;
      let vacancy = JSON.parse(JSON.stringify(this.activeVacancy.vacancy)); // Make copy of object
      vacancy.address.lat = event.lat;
      vacancy.address.lng = event.lng;
      vacancy.address = JSON.stringify(vacancy.address);
      vacancy.terms = JSON.stringify(vacancy.terms);

      const id = vacancy.id;
      await dispatch("vacancy/updateVacancy", { vacancy, id });
      await dispatch("vacancy/getVacancy", id);
    },

    async selfAssignRecruiter() {
      if (!this.activeVacancy.vacancy.recruiter) {
        const user = this.$store.state.users.user;
        const { dispatch } = this.$store;
        if (user.role <= 4) {
          const id = this.activeVacancy.vacancy.id;
          let vacancy = {
            rec_id: user.id,
          };
          if (this.latestVacancyStatus.name === "Nieuw") {
            vacancy.status = 2; // Open
          }
          await dispatch("vacancy/updateVacancy", { vacancy, id }).then(
            (vacancy) => {
              dispatch("vacancy/getVacancy", id);
              dispatch("alerts/success", "Recruiter toegewezen");
            }
          );
        } else {
          dispatch("alerts/warn", "Je bent niet bevoegd om dit te doen");
        }
      }
    },

    async makeInactive() {
      const { dispatch } = this.$store;
      let vacancy = { inactive: true };

      const id = this.activeVacancy.vacancy.id;
      await dispatch("vacancy/updateVacancy", { vacancy, id }).then((res) => {
        dispatch(
          "alerts/success",
          "Vacature inactief gemaakt, u wordt teruggestuurd naar de vorige pagina over 3 seconden"
        );
        setTimeout(() => {
          this.$router.go(-1);
        }, 3000);
      });
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    fullName(item) {
      if (item == null || item == "") return "Niet toegewezen";
      return item.first_name + " " + item.last_name;
    },

    latestStatus(item) {
      if (item.lead_statuses.length > 0) {
        return item.lead_statuses[item.lead_statuses.length - 1].status;
      } else {
        return { name: "Geen", color: "#FFD700" };
      }
    },
    latestLead(item) {
      if (item.leads.length > 0) {
        return item.leads[item.leads.length - 1];
      } else {
        return undefined;
      }
    },
    itemExists(item) {
      if (item == null || item == "") return "Onbekend";
      return item;
    },
    hasTerms() {
      if (
        !!this.activeVacancy.vacancy.terms.min_days ||
        !!this.activeVacancy.vacancy.terms.extra_remarks ||
        !!this.activeVacancy.vacancy.terms.dresscode ||
        !!this.activeVacancy.vacancy.terms.extra_requirements ||
        !!this.activeVacancy.vacancy.terms.cao ||
        !!this.activeVacancy.vacancy.terms.initial_scale
      )
        return true;
      return false;
    },
    openCompany(id) {
      this.$router.push(`/bedrijf/${id}`);
    },
  },
};
</script>
