import config from '../../config';
import { authHeader } from '../utils/auth-header';

export const dashboardService = {
    getGlobalAnalytics,
    getRecruiterAnalytics,
    getRecruitmentAssistentAnalytics
};

function getGlobalAnalytics(startDate, endDate) {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/dashboard/${startDate}/${endDate}`, request)
        .then(handleResponse)
        .then(dashboard => {
            return dashboard
        });
}

function getRecruiterAnalytics(recId, startDate, endDate) {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/dashboard/recruiter/${recId}/${startDate}/${endDate}`, request)
        .then(handleResponse)
        .then(dashboard => {
            return dashboard
        });
}

function getRecruitmentAssistentAnalytics(raId, startDate, endDate) {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/dashboard/ra/${raId}/${startDate}/${endDate}`, request)
        .then(handleResponse)
        .then(dashboard => {
            return dashboard
        });
}
// ---------------------------------------------------------------

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}