<template>
  <v-dialog v-model="display" :width="340">
    <template v-slot:activator="{ on }">
      <v-text-field :value="formattedDate" v-on="on" readonly> </v-text-field>
    </template>
    <v-card>
      <v-card-text class="px-0 py-0">
        <v-date-picker locale="nl-NL" v-model="date" full-width></v-date-picker>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions" :parent="this">
          <v-btn color="grey lighten-1" text @click.native="clearHandler"
            >CLEAR</v-btn
          >
          <v-btn color="green darken-1" text @click="okHandler">OK</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      display: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  computed: {
    formattedDate() {
      return this.toLocalDate(this.date);
    },
    selectedDatetime() {
      if (this.date) {
        return new Date(this.value).toUTCString();
      }
      return null;
    },
    dateSelected() {
      return !this.date;
    },
  },
  methods: {
    okHandler() {
      this.resetPicker();
      this.$emit("input", this.date);
    },
    clearHandler() {
      this.resetPicker();
      this.date = "";
      this.$emit("input", null);
    },
    resetPicker() {
      this.display = false;
    },
    toLocalDate(date) {
      var utcDate = new Date(date);
      const localeString = utcDate.toLocaleDateString("nl-NL");
      return localeString;
    },
  },
};
</script>
