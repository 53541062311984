var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":!_vm.loaded}},[_c('v-container',{staticClass:"pa-5"},[(_vm.loaded)?_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.contactMomentStatusesList,"item-text":"name","item-value":"id","required":"","rules":[function (v) { return !!v || 'Type is vereist'; }],"label":"Contact Moment Type","persistent-hint":"","hint":"Wat voor soort contact moment"},model:{value:(_vm.formDataFields.type),callback:function ($$v) {_vm.$set(_vm.formDataFields, "type", $$v)},expression:"formDataFields.type"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.vacanciesList,"item-text":"name","item-value":"id","label":"Vacature","persistent-hint":"","hint":"Ging dit moment over een vacature?"},model:{value:(_vm.formDataFields.vacancy_id),callback:function ($$v) {_vm.$set(_vm.formDataFields, "vacancy_id", $$v)},expression:"formDataFields.vacancy_id"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-select',{attrs:{"items":_vm.contactPeopleList,"item-text":_vm.fullName,"item-value":"id","required":"","rules":[function (v) { return !!v || 'Type is vereist'; }] && [
                function (v) { return v.length > 0 || 'Type is vereist'; } ],"label":"Contactpersonen","persistent-hint":"","hint":"Bedrijf contact persoon toevoegen","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(_vm.fullName(item)))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.formDataFields.contact_people.length - 1)+" others) ")]):_vm._e()]}}],null,false,270134721),model:{value:(_vm.formDataFields.contact_people),callback:function ($$v) {_vm.$set(_vm.formDataFields, "contact_people", $$v)},expression:"formDataFields.contact_people"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-select',{attrs:{"items":_vm.employeesList,"item-text":_vm.fullName,"item-value":"id","label":"Personeel","persistent-hint":"","hint":"Personeel dat meedoet in het contact moment","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(_vm.fullName(item)))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.formDataFields.employees.length - 1)+" others) ")]):_vm._e()]}}],null,false,1854940588),model:{value:(_vm.formDataFields.employees),callback:function ($$v) {_vm.$set(_vm.formDataFields, "employees", $$v)},expression:"formDataFields.employees"}})],1)],1),_c('v-row',{staticClass:"ml-1 mr-1"},[_c('DateTimePicker',{model:{value:(_vm.data_fields.createdAt),callback:function ($$v) {_vm.$set(_vm.data_fields, "createdAt", $$v)},expression:"data_fields.createdAt"}})],1),_c('v-row',{staticClass:"pb-0 mb-n8"},[_c('v-col',{attrs:{"md":"12"}},[_c('h5',[_vm._v("Notities")])])],1),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('quillEditor',{attrs:{"options":_vm.editorOption},model:{value:(_vm.data_fields.notes),callback:function ($$v) {_vm.$set(_vm.data_fields, "notes", $$v)},expression:"data_fields.notes"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":!_vm.valid || _vm.adding,"color":"success"},on:{"click":_vm.buttonClick}},[_vm._v(" "+_vm._s(_vm.types[_vm.type].buttonText)+" ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }