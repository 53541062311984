import config from '../../config';
import { authHeader } from '../utils/auth-header';
export const statusService = {
    createContactMomentStatus,
    updateContactMomentStatus,
    getAllContactMomentStatus,
    createLeadStatus,
    updateLeadStatus,
    getAllLeadStatus,
    createVacancyStatus,
    updateVacancyStatus,
    getAllVacancyStatus,
};
function createContactMomentStatus(contact_moment_status) {
    const request = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(contact_moment_status)
    }
    return fetch(`${config.apiUrl}/contact-moment-status`, request)
        .then(handleResponse)
        .then(contact_moment_status => {
            return contact_moment_status;
        });
}

function updateContactMomentStatus(contact_moment_status, contact_moment_status_id) {
    const request = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(contact_moment_status)
    }
    return fetch(`${config.apiUrl}/contact-moment-status/${contact_moment_status_id}`, request)
        .then(handleResponse)
        .then(contact_moment_status => {
            return contact_moment_status;
        });
}

function getAllContactMomentStatus() {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/contact-moment-status`, request)
        .then(handleResponse)
        .then(contact_moment_statuses => {
            return contact_moment_statuses;
        });
}

// -----------------------------------------------------------------

function updateLeadStatus(lead_status, statusId) {
    const request = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(lead_status)
    }
    return fetch(`${config.apiUrl}/lead-status/${statusId}`, request)
        .then(handleResponse)
        .then(lead_status => {
            return lead_status;
        });
}

function createLeadStatus(lead_status) {
    const request = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(lead_status)
    }
    return fetch(`${config.apiUrl}/lead-status`, request)
        .then(handleResponse)
        .then(lead_status => {
            return lead_status;
        });
}

function getAllLeadStatus() {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/lead-status`, request)
        .then(handleResponse)
        .then(lead_statuses => {
            return lead_statuses;
        });
}

// ---------------------------------------------------------------

function updateVacancyStatus(vacancy_status, statusId) {
    const request = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(vacancy_status)
    }
    return fetch(`${config.apiUrl}/vacancy-status/${statusId}`, request)
        .then(handleResponse)
        .then(lead_status => {
            return lead_status;
        });
}

function createVacancyStatus(vacancy_status) {
    const request = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(vacancy_status)
    }
    return fetch(`${config.apiUrl}/vacancy-status`, request)
        .then(handleResponse)
        .then(vacancy_status => {
            return vacancy_status;
        });
}

function getAllVacancyStatus() {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/vacancy-status`, request)
        .then(handleResponse)
        .then(vacancy_statuses => {
            return vacancy_statuses;
        });
}

// ---------------------------------------------------------------

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}