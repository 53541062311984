<template>
  <v-container class="pa-0">
    <v-card tile max-width="360px" style="opacity: 0.95">
      <v-card outlined tile class="pl-2 pt-2">
        
        <v-row class="mb-0">
          <v-col class="pb-0" md="6">
            <h3>Notificaties</h3>
          </v-col>
          <v-spacer />
          <v-col class="pb-0 pt-1" style="text-align: right">
            <v-btn class="ma-0 mt-1" text icon style="right: -12px" @click="readAllNotifications()">
              <v-icon color="red">mdi-delete-restore</v-icon>
            </v-btn>
            <v-btn
              class="ma-0 mt-1"
              text
              icon
              style="right: -2px"
              @click="togglePanel()"
            >
              <v-icon color="red">mdi-close</v-icon>
            </v-btn>
            
          </v-col>
        </v-row>
      </v-card>
      <v-card class="overflow-y-auto pa-0 ma-0" max-height="220">
      <v-card
        outlined
        tile
        v-for="(item, i) in getNotifList.slice().reverse()"
        :key="i"
        class="pl-2 pt-1 pr-2 pb-1"
        style="margin-right: 3px"
      >
        <v-row>
          <v-col class="pb-0" md="6">
            <p class="ma-0">
              <strong>{{ item.title }}</strong>
            </p>
          </v-col>
          <v-spacer />
          <v-col class="pb-0 pt-0" style="text-align: right">
            <v-btn
              class="ma-0 mt-1"
              text
              icon
              style="right: -20px"
              v-if="item.linkTo"
              @click="openLink(item.linkTo)"
            >
              <v-icon small color="blue">mdi-open-in-new</v-icon>
            </v-btn>
            <v-btn class="ma-0 mt-1" text icon style="right: -10px" @click="markNotificationRead(item.id)">
              <v-icon small color="red">mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-0 mb-1">
          <v-col class="pb-0"  md="8">
            <p class="ma-0">{{ item.message }}</p>
          </v-col>
          <v-col class="pb-0" style="text-align: right">
            <p class="ma-0">{{ formatTimeStamp(item.timestamp) }}</p>
          </v-col>
        </v-row>
      </v-card>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters("notifications", ["getNotifList", "notifsFetched"]),
    darkTheme() {
      return this.$vuetify.theme.dark;
    },
  },
  created() {
    if(!this.notifsFetched){
      this.callgetCall();
    }
  },
  methods: {
    ...mapActions("notifications", ["getAllNotifications", "markNotificationRead", "readAllNotifications", "togglePanel"]),
    async callgetCall() {
      await this.getAllNotifications();
    },
    formatTimeStamp(timestamp) {
      const date = timestamp.substring(5, 10);
      const time = timestamp.substring(11, 16);
      return date + " " + time;
    },
    openLink(url) {
      window.open(url);
      //this.$router.push(url).catch(() => {});
    },
  },
};
</script>