<template>
  <v-container fluid>
    <v-app-bar>
      <v-container fluid>
        <v-row>
          <v-col class="pt-7" md="3" lg="5"><h1>Analyse</h1></v-col>
          <v-col class="pt-10" md="4" lg="4">
            <v-autocomplete
              label="Bedrijf"
              :loading="!loadStatus.clientsLoaded"
              v-if="loadStatus.clientsLoaded"
              :items="clients.clients"
              item-text="name"
              item-value="id"
              v-model="search.selectedClient"
            />
            <v-progress-linear
              v-else
              class="mt-4"
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-col>
          <v-col class="pt-10">
            <v-row>
              <v-col>
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedDateRangeApplication"
                      label="Datumbereik"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="search.dateRange"
                    locale="nl-NL"
                    color="green"
                    header-color="primary"
                    range
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-btn class="mb-1" icon color="primary" @click="getData">
                <v-icon x-large dark>mdi-feature-search</v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-progress-linear
      v-if="loadStatus.fetchingData"
      indeterminate
    ></v-progress-linear>
    <v-card class="mt-5 pa-5" v-if="loadStatus.analyticsFetched">
      <v-row>
        <v-col md="4" lg="3"
          ><StarRating
            :count="calc.waarderingVanTally"
            :rating="calc.waarderingVan"
            title="Waardering doeners"
            icon="mdi-card-account-details-star"
            color="#85e3ff"
        /></v-col>
        <v-col md="4" lg="3"
          ><StarRating
            :count="calc.waarderingVoorTally"
            :rating="calc.waarderingVoor"
            title="Waardering door doeners"
            icon="mdi-account-star"
            color="#6eb5ff"
        /></v-col>
        <v-col md="4" lg="3"
          ><StarRating
            :count="calc.manageTally"
            :rating="calc.manageScore"
            title="Management"
            icon="mdi-account-tie"
            color="#ffcbc1"
        /></v-col>
        <v-col md="4" lg="3"
          ><StarRating
            :count="calc.werkTally"
            :rating="calc.werkScore"
            title="Werk"
            icon="mdi-office-building"
            color="#bffcc6"
        /></v-col>
      </v-row>
      <v-row>
        <v-col :lg="graphs.waarderingGraphSize">
          <v-card>
            <v-row style="position: absolute; right: 0" class="pr-3 pt-3">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-btn
                      style="position: inline"
                      class="mb-1"
                      icon
                      color="primary"
                      @click="recalcGraphWaardering()"
                    >
                      <v-icon small>{{
                        graphs.waarderingGraphInterval == "Day" ? "D" : "M"
                      }}</v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>{{
                  graphs.waarderingGraphInterval == "Day"
                    ? "Interval Maand"
                    : "Interval Dag"
                }}</span>
              </v-tooltip>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-btn
                      style="position: inline"
                      class="mb-1"
                      icon
                      color="primary"
                      @click="resizeGraphWaardering()"
                    >
                      <v-icon small>{{
                        graphs.waarderingGraphSize == 6
                          ? "mdi-arrow-expand-horizontal"
                          : "mdi-arrow-collapse-horizontal"
                      }}</v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>{{
                  graphs.waarderingGraphSize == 6 ? "Vergroot" : "Verklein"
                }}</span>
              </v-tooltip>
            </v-row>
            <LineChart :data="waarderingChartData" />
          </v-card>
        </v-col>
        <v-col :lg="graphs.eventsGraphSize">
          <v-card>
            <v-row style="position: absolute; right: 0" class="pr-3 pt-3">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-btn
                      style="position: inline"
                      class="mb-1"
                      icon
                      color="primary"
                      @click="recalcGraphEvents()"
                    >
                      <v-icon small>{{
                        graphs.eventsGraphInterval == "Day" ? "D" : "M"
                      }}</v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>{{
                  graphs.eventsGraphInterval == "Day"
                    ? "Interval Maand"
                    : "Interval Dag"
                }}</span>
              </v-tooltip>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-btn
                      style="position: inline"
                      class="mb-1"
                      icon
                      color="primary"
                      @click="resizeGraphEvents()"
                    >
                      <v-icon small>{{
                        graphs.eventsGraphSize == 6
                          ? "mdi-arrow-expand-horizontal"
                          : "mdi-arrow-collapse-horizontal"
                      }}</v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>{{
                  graphs.eventsGraphSize == 6 ? "Vergroot" : "Verklein"
                }}</span>
              </v-tooltip>
            </v-row>
            <BarChart :data="eventsChartData" />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="2">
          <SmallStat
            :count="data.invites.sentInvites"
            title="Verstuurde Uitnodigingen"
            icon="mdi-email"
            color="#bffcc6"
            toolTipText="Aantal werkverzoeken door de klant gestuurd"
          />
        </v-col>
        <v-col md="2">
          <SmallStat
            :count="data.invites.accpetedInvites"
            title="Geaccepteerd Uitnodigingen"
            icon="mdi-email-open"
            color="#bffcc6"
            toolTipText="Aantal gestuurde werkverzoeken die geaccepteerd zijn"
          />
        </v-col>
        <v-col md="2">
          <SmallStat
            :count="data.invites.declinedInvites"
            title="Afgewezen Uitnodigingen"
            icon="mdi-email-alert"
            color="#bffcc6"
            toolTipText="Aantal gestuurde werkverzoeken die afgewezen zijn"
          />
        </v-col>
        <v-col md="2">
          <SmallStat
            :count="data.hartjes"
            title="Hartjes gegeven door klant"
            icon="mdi-cards-heart"
            color="#ff9cee"
            toolTipText="Aantal hartjes geven door klant aan doeners"
          />
        </v-col>
        <v-col md="2">
          <SmallStat
            :count="data.hartjesFor"
            title="Hartjes gegeven door doeners"
            icon="mdi-account-heart"
            color="#ff9cee"
            toolTipText="Aantal hartjes geven door doeners aan klant"
          />
        </v-col>
        <v-col md="2">
          <SmallStat
            :count="poolData.totalPoolIn"
            title="Pool Instroom"
            icon="mdi-account-group"
            color="#85e3ff"
            toolTipText="Hoeveel doeners er bij zijn gekomen in de pools"
          />
        </v-col>
        <v-col md="2">
          <SmallStat
            :count="poolData.totalPoolOut"
            title="Pool Uitstroom"
            icon="mdi-account-group-outline"
            color="#85e3ff"
            toolTipText="Hoeveel doeners de pools zijn verlaten"
          />
        </v-col>

        <v-col md="2">
          <SmallStat
            :count="calc.totalPlaced + calc.totalUnfilled"
            title="Diensten Gevraagd"
            icon="mdi-account"
            color="#e7ffac"
            toolTipText="Hoeveel diensten er gevraagd zijn"
          />
        </v-col>
        <v-col md="2">
          <SmallStat
            :count="calc.totalPlaced"
            title="Diensten Gevuld"
            icon="mdi-account-check"
            color="#e7ffac"
            :toolTipText="
              Math.round(
                (calc.totalPlaced / (calc.totalPlaced + calc.totalUnfilled)) *
                  100
              ) + '% van de gevraagde diensten zijn gevuld'
            "
          />
        </v-col>
        <v-col md="2">
          <SmallStat
            :count="calc.totalUnfilled"
            title="Diensten Niet Gevuld"
            icon="mdi-account-alert"
            color="#e7ffac"
            :toolTipText="
              Math.round(
                (calc.totalUnfilled / (calc.totalPlaced + calc.totalUnfilled)) *
                  100
              ) + '% van de gevraagde diensten zijn niet gevuld'
            "
          />
        </v-col>
        <v-col md="2">
          <SmallStat
            :count="calc.superLastRequested"
            title="Super Last Minute Diensten"
            icon="mdi-account-question"
            color="#e7ffac"
            toolTipText="Hoeveel diensten zijn binnen 24 uur van de dienst gevraagd"
          />
        </v-col>
        <v-col md="2">
          <SmallStat
            :count="calc.lastMinRequested"
            title="Last Minute Diensten"
            icon="mdi-account-arrow-left"
            color="#e7ffac"
            toolTipText="Hoeveel diensten zijn binnen 72 uur van de dienst gevraagd"
          />
        </v-col>
        <v-col md="2">
          <SmallStat
            :count="data.latecomers"
            title="Te laat"
            icon="mdi-account-clock"
            color="#ffabab"
            toolTipText="Aantal doeners die te laat waren voor een dienst"
          />
        </v-col>
        <v-col md="2">
          <SmallStat
            :count="data.cancellations"
            title="Niet gekomen"
            icon="mdi-account-remove"
            color="#ffabab"
            toolTipText="Aantal doeners die niet naar een dienst zijn gekomen"
          />
        </v-col>
        <v-col md="2">
          <SmallStat
            :count="calc.avgDateDif"
            title="Gem. tijd voor uitvraag"
            afterValueText=" dagen"
            icon="mdi-update"
            color="#ffcbc1"
            toolTipText="Hoeveel dagen gemideld vraagt de opdrachtgever een dienst uit"
          />
        </v-col>
        <v-col md="2">
          <SmallStat
            :count="calc.totalCanceled"
            title="Diensten Geannuleerd"
            icon="mdi-cancel"
            color="#ffcbc1"
            toolTipText="Hoeveel diensten door de opdrachtgever zijn geannuleerd"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12" class="ml-2 pb-0">
        <h1>Pools</h1>
        </v-col>
        <v-col md="12">
          <v-data-table
            v-model="poolTable.selected"
            :headers="poolTable.headers"
            :items="data.pools"
            :single-select="true"
            item-key="name"
            :show-select="false"
            class="elevation-1"
          >
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import StarRating from "../components/analytics/StarRating.vue";
import SmallStat from "../components/analytics/SmallStat.vue";
import LineChart from "../components/analytics/LineChart.vue";
import BarChart from "../components/analytics/BarChart.vue";
export default {
  components: { StarRating, LineChart, BarChart, SmallStat },
  data: () => ({
    search: {
      dateRange: null,
      selectedClient: null,
    },
    // used fpr keeping track when data is ready
    loadStatus: {
      clientsLoaded: false,
      fetchingData: false,
      analyticsFetched: false,
    },
    // data is used to house all fetched data from API
    data: {},
    // calc holds all data that is calculated client side
    calc: {
      waarderingVoor: 0,
      waarderingVoorTally: 0,
      waarderingVan: 0,
      waarderingVanTally: 0,
      waarderingChart: {},
      manageScore: 0,
      werkScore: 0,
      manageTally: 0,
      werkTally: 0,
      lastMin: [],
      superLast: [],
      avgDateDif: 0,
      lastMinFilled: 0,
      superLastFilled: 0,
      normalFilled: 0,
      totalCanceled: 0,
      totalPlaced: 0,
      totalUnfilled: 0,
      superLastRequested: 0,
      lastMinRequested: 0,
    },
    // calculated data from pools
    poolData: {
      totalPoolIn: 0,
      totalPoolOut: 0,
    },
    // all variables for graphs
    graphs: {
      waarderingGraphSize: 6,
      waarderingGraphInterval: "Month",
      eventsGraphSize: 6,
      eventsGraphInterval: "Month",
      eventsGraphData: {},
    },
    poolTable: {
      selected: [],
      headers: [
        {
          text: "Naam",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Doeners", value: "workers" },
        { text: "Shifts gevraagd", value: "shifts" },
        { text: "Gevuld", value: "totalFilledPool" },
        { text: "Gevuld buiten poule", value: "totalFilledNonPool" },
        { text: "Instroom", value: "inflow" },
        { text: "Uitstroom", value: "outflow" },
        { text: "Waardering Aan", value: "averageRatingClient" },
        { text: "Waardering Door", value: "averageRatingDoener" },
        { text: "Voorwaarden", value: "extra_rate" },
      ],
    },
  }),

  created() {
    this.initialize();
  },

  computed: {
    computedDateRangeApplication: {
      get() {
        return this.splitDateRange(this.search.dateRange);
      },
      set() {
        return null;
      },
    },
    clients() {
      return this.$store.state.clients;
    },
    waarderingChartData() {
      return this.calc.waarderingChart;
    },
    eventsChartData() {
      return this.graphs.eventsGraphData;
    },
  },

  methods: {
    async initialize() {
      const { dispatch } = this.$store;
      await dispatch("clients/getAllClients").then((clients) => {
        this.loadStatus.clientsLoaded = true;
      });
    },

    splitDateRange(date) {
      if (!date) return null;
      if (date.length === 0) return [];
      const date1 = this.formatDate(date[0]);
      const date2 = this.formatDate(date[1]);
      return `${date1} - ${date2}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    async getData() {
      this.loadStatus.fetchingData = true;
      this.loadStatus.analyticsFetched = false;
      const { dispatch } = this.$store;
      const startDate = this.search.dateRange[0];
      const endDate = this.search.dateRange[1];
      const clientId = this.search.selectedClient;
      await dispatch("clients/getData", { clientId, startDate, endDate }).then(
        (data) => {
          this.loadStatus.fetchingData = false;
          this.data = data;
        }
      );
      this.calcWaarderingScores();
      this.calcLastMin();
      this.calcPools();
    },
    calcWaarderingScores() {
      let counterFor = 0;
      let tallyFor = 0;
      let counterFrom = 0;
      let tallyFrom = 0;
      let chartData = {};
      let mangeScore = 0;
      let mangeTally = 0;
      let werkScore = 0;
      let werkTally = 0;
      this.data.ratings.forEach((rating) => {
        let shortDate = "";
        if (this.graphs.waarderingGraphInterval == "Day") {
          shortDate = rating.created_at.substring(0, 10);
        } else {
          shortDate = rating.created_at.substring(0, 7);
        }
        if (chartData[shortDate] == undefined) {
          chartData[shortDate] = {
            countFor: 0,
            countFrom: 0,
            tallyFor: 0,
            tallyFrom: 0,
          };
        }
        if (rating.client_rating == 0) {
          counterFor++;
          tallyFor += rating.rating;
          chartData[shortDate].countFor += rating.rating;
          chartData[shortDate].tallyFor++;
        } else {
          counterFrom++;
          tallyFrom += rating.rating;
          chartData[shortDate].countFrom += rating.rating;
          chartData[shortDate].tallyFrom++;
        }
        if (rating.management > 0) {
          mangeScore += rating.management;
          mangeTally++;
        }
        if (rating.nice_work > 0) {
          werkScore += rating.nice_work;
          werkTally++;
        }
      });
      this.calc.waarderingVoor =
        Math.round((tallyFor / counterFor / 2) * 100) / 100;
      this.calc.waarderingVan =
        Math.round((tallyFrom / counterFrom / 2) * 100) / 100;
      this.calc.waarderingVoorTally = counterFor;
      this.calc.waarderingVanTally = counterFrom;
      this.calc.manageScore =
        Math.round((((mangeScore / mangeTally) * 5) / 3) * 100) / 100;
      this.calc.werkScore =
        Math.round((((werkScore / werkTally) * 5) / 3) * 100) / 100;
      this.calc.manageTally = mangeTally;
      this.calc.werkTally = werkTally;
      let lineFor = [];
      let lineFrom = [];
      let lineLabels = [];
      for (const [key, value] of Object.entries(chartData)) {
        lineFor.push(
          Math.round((value.countFor / value.tallyFor / 2) * 100) / 100
        );
        lineFrom.push(
          Math.round((value.countFrom / value.tallyFrom / 2) * 100) / 100
        );
        lineLabels.push(key);
      }
      this.calc.waarderingChart = {};
      this.calc.waarderingChart = {
        labels: lineLabels,
        datasets: [
          {
            label: "Waardering Doeners",
            data: lineFrom,
            backgroundColor: "transparent",
            borderColor: "rgba(115, 216, 18, 0.50)",
            pointBackgroundColor: "rgba(115, 216, 18, 1)",
          },
          {
            label: "Waardering door Doeners",
            data: lineFor,
            backgroundColor: "transparent",
            borderColor: "rgba(1, 116, 188, 0.50)",
            pointBackgroundColor: "rgba(11, 71, 188, 1)",
          },
        ],
      };
      this.loadStatus.analyticsFetched = true;
    },
    resizeGraphWaardering() {
      if (this.graphs.waarderingGraphSize == 6) {
        this.graphs.waarderingGraphSize = 12;
      } else {
        this.graphs.waarderingGraphSize = 6;
      }
    },
    recalcGraphWaardering() {
      if (this.graphs.waarderingGraphInterval == "Day") {
        this.graphs.waarderingGraphInterval = "Month";
      } else {
        this.graphs.waarderingGraphInterval = "Day";
      }
      this.calcWaarderingScores();
    },
    calcLastMin() {
      let lastMin = [];
      let lastMinFilled = 0;
      let superLast = [];
      let superLastFilled = 0;
      let avgDateDif = 0;
      let normalFilled = 0;
      let totalCanceled = 0;
      let chartData = [];
      let totalPlaced = 0;
      let totalUnfilled = 0;
      let superLastRequested = 0;
      let lastMinRequested = 0;
      this.data.events.forEach((event) => {
        if (event.cancelled != 1) {
          let shortDate = "";
          if (this.graphs.eventsGraphInterval == "Day") {
            shortDate = event.created_at.substring(0, 10);
          } else {
            shortDate = event.created_at.substring(0, 7);
          }
          if (chartData[shortDate] == undefined) {
            chartData[shortDate] = {
              filled: 0,
              requested: 0,
              lmFilled: 0,
              lmRequested: 0,
              slFilled: 0,
              slRequested: 0,
            };
          }
          const create = new Date(event.created_at);
          const start = new Date(event.start_at);
          let dateDif = Math.round(Math.abs(start - create) / (1000 * 60 * 60));
          avgDateDif += dateDif;
          let filled = this.eventFilled(event);
          totalPlaced += filled.accepted;
          totalUnfilled += filled.unfilled;
          if (dateDif <= 24) {
            superLast.push(event);
            chartData[shortDate].slRequested += event.users_needed;
            chartData[shortDate].slFilled += filled.accepted;
            superLastRequested += filled.accepted + filled.unfilled;
            if (filled.bool) {
              superLastFilled++;
            }
          } else if (dateDif <= 72) {
            lastMin.push(event);
            chartData[shortDate].lmRequested += event.users_needed;
            chartData[shortDate].lmFilled += filled.accepted;
            lastMinRequested += filled.accepted + filled.unfilled;
            if (filled.bool) {
              lastMinFilled++;
            }
          } else {
            chartData[shortDate].requested += event.users_needed;
            chartData[shortDate].filled += filled.accepted;
            if (filled.bool) {
              normalFilled++;
            }
          }
        } else {
          totalCanceled += event.users_needed;
        }
      });
      avgDateDif = Math.round(avgDateDif / this.data.events.length / 24);
      this.calc.lastMin = lastMin;
      this.calc.lastMinFilled = lastMinFilled;
      this.calc.superLast = superLast;
      this.calc.superLastFilled = superLastFilled;
      this.calc.avgDateDif = avgDateDif;
      this.calc.normalFilled = normalFilled;
      this.calc.totalCanceled = totalCanceled;
      this.calc.totalPlaced = totalPlaced;
      this.calc.totalUnfilled = totalUnfilled;
      this.calc.superLastRequested = superLastRequested;
      this.calc.lastMinRequested = lastMinRequested;
      let barFill = [],
        barReq = [],
        barLmFill = [],
        barLmReq = [],
        barSlFill = [],
        barSlReq = [],
        barLabels = [];
      for (const [key, value] of Object.entries(chartData)) {
        barFill.push(value.filled);
        barReq.push(value.requested);
        barLmFill.push(value.lmFilled);
        barLmReq.push(value.lmRequested);
        barSlFill.push(value.slFilled);
        barSlReq.push(value.slRequested);
        barLabels.push(key);
      }
      this.graphs.eventsGraphData = {};
      this.graphs.eventsGraphData = {
        labels: barLabels,
        datasets: [
          {
            label: "Vraag",
            data: barReq,
            backgroundColor: "rgba(80, 30, 80, 0.5)",
            stack: "Vraag",
          },
          {
            label: "Gevuld",
            data: barFill,
            backgroundColor: "rgba(80, 30, 80, 1)",
            stack: "Gevuld",
          },
          {
            label: "LM Vraag",
            data: barLmReq,
            backgroundColor: "rgba(115, 216, 18, 0.5)",
            stack: "Vraag",
          },
          {
            label: "LM Gevuld",
            data: barLmFill,
            backgroundColor: "rgba(115, 216, 18, 1)",
            stack: "Gevuld",
          },
          {
            label: "SLM Vraag",
            data: barSlReq,
            backgroundColor: "rgba(200, 60, 0, 0.5)",
            stack: "Vraag",
          },
          {
            label: "SLM Gevuld",
            data: barSlFill,
            backgroundColor: "rgba(200, 60, 0, 1)",
            stack: "Gevuld",
          },
        ],
      };
    },
    resizeGraphEvents() {
      if (this.graphs.eventsGraphSize == 6) {
        this.graphs.eventsGraphSize = 12;
      } else {
        this.graphs.eventsGraphSize = 6;
      }
    },
    recalcGraphEvents() {
      if (this.graphs.eventsGraphInterval == "Day") {
        this.graphs.eventsGraphInterval = "Month";
      } else {
        this.graphs.eventsGraphInterval = "Day";
      }
      this.calcLastMin();
    },
    eventFilled(event) {
      let needed = event.users_needed;
      let accepted = 0;
      let unfilled = 0;
      event.placements.forEach((placement) => {
        if (
          placement.status == "accepted" ||
          placement.status == "confirmed" ||
          placement.status == "read_confirmation" ||
          placement.status == "topdoener"
        ) {
          accepted++;
        } else {
          unfilled++;
        }
      });
      let bool = accepted == needed;
      return { bool, accepted, unfilled };
    },
    calcPools() {
      let totalPoolIn = 0;
      let totalPoolOut = 0;
      this.data.pools.forEach((pool) => {
        totalPoolIn += pool.inflow;
        totalPoolOut += pool.outflow;
      });
      this.poolData.totalPoolIn = totalPoolIn;
      this.poolData.totalPoolOut = totalPoolOut;
    },
  },
};
</script>