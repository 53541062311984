<template>
  <v-card :loading="!loaded">
    <v-container class="pa-5">
      <v-form ref="form" v-model="valid" lazy-validation v-if="loaded">
        <v-container>
          <v-row>
            <v-col v-if="!editMode">
              <v-switch
                v-model="userSwitch"
                :label="`Gebruik bestaande persoon`"
                @change="useExistingLeads"
              ></v-switch>
            </v-col>
            <v-col>
              <v-autocomplete
                v-if="userSwitch && loadedUsers"
                :loading="!loadedUsers"
                :items="lead_users"
                :rules="[(v) => !!v || 'Persoon is vereist']"
                v-model="formDataFields.lead_user"
                required
                :item-text="fullName"
                item-value="id"
                label="Persoon"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="!userSwitch" class="mt-0">
            <v-container>
              <h2>Nieuw persoon</h2>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="data_fields_user.first_name"
                    :rules="[(v) => !!v || 'Voornaam is vereist']"
                    label="Voornaam"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="data_fields_user.last_name"
                    :rules="[(v) => !!v || 'Achternaam is vereist']"
                    label="Achternaam"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col>
                  <v-text-field
                    v-model="data_fields_user.email"
                    label="Email"
                    :error-messages="validateEmailOrPhone()"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="data_fields_user.tel_number"
                    label="Telefoonnummer"
                    :error-messages="validateEmailOrPhone()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
          <v-divider />
        </v-container>
        <v-row>
          <v-col>
            <v-autocomplete
              :items="vacancies"
              :rules="[(v) => !!v || 'Vacature is vereist']"
              v-model="formDataFields.vacancy_id"
              required
              :item-text="vacanyWithCompanyName"
              item-value="id"
              label="Vacature"
              @change="updateRecruiter(formDataFields.vacancy_id)"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :items="channels"
              :rules="[(v) => !!v || 'Bron is vereist']"
              v-model="formDataFields.channel_id"
              required
              item-text="name"
              item-value="id"
              label="Bron"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="formDataFields.campaign_id"
              label="Campagne ID"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-menu
              v-model="appliedMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formDataFields.applied_date"
                  label="Sollicitatiedatum"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[(v) => !!v || 'Datum is vereist']"
                  requiered
                ></v-text-field>
              </template>
              <v-date-picker
                locale="nl-NL"
                v-model="formDataFields.applied_date"
                @input="appliedMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6">
            <v-autocomplete
              :items="employees"
              :item-text="fullName"
              item-value="id"
              v-model="formDataFields.ra_id"
              label="Recruitment Assistant"
            ></v-autocomplete>
          </v-col>
          <v-col md="6">
            <v-autocomplete
              :items="employees"
              :item-text="fullName"
              item-value="id"
              v-model="formDataFields.rec_id"
              label="Recruiter"
            ></v-autocomplete>
          </v-col>
          <v-col v-if="editMode" md="6">
            <v-autocomplete
              :items="vacancies"
              v-model="formDataFields.exit_vacancy_id"
              item-text="name"
              item-value="id"
              label="Exit vacature"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <!--<v-row>
          <v-col>
            <v-textarea
              v-model="data_fields.notes"
              filled
              label="Notities"
            ></v-textarea>
          </v-col>
        </v-row>-->
        <v-card color="#385F73" dark class="pa-3">
          <v-text-field
            v-model="data_fields_file.title"
            label="Titel"
          ></v-text-field>

          <v-file-input
            show-size
            label="Kies een bestand"
            @change="selectFile"
          ></v-file-input>
        </v-card>
        <v-row justify="center" class="mt-3">
          <v-btn
            :disabled="!valid || adding"
            color="success"
            class="mr-4"
            @click="buttonClick"
          >
            {{ types[type].buttonText }}
          </v-btn>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    type: String,
    formData: Object,
    parentID: Number,
    period: Array,
  },
  data: () => ({
    loaded: false,
    loadedUsers: false,
    appliedMenu: false,
    adding: false,
    valid: false,
    userSwitch: false,
    editMode: false,
    data_fields_user: {
      first_name: "",
      last_name: "",
      email: "",
      tel_number: "",
    },
    data_fields: {
      lead_user: 0,
      vacancy_id: 0,
      channel_id: 0,
      campaign_id: "",
      ra_id: null,
      rec_id: null,
      exit_vacancy_id: 0,
      applied_date: "",
      notes: "",
    },
    data_fields_file: {
      currentFile: undefined,
      message: "",
      fileInfos: [],
      title: "",
    },
    types: [
      {
        buttonText: "Lead Aanmaken",
      },
      {
        buttonText: "Lead Aanpassen",
      },
    ],
  }),

  computed: {
    formDataFields() {
      this.updateFormFields();
      return this.data_fields;
    },
    ...mapState({
      vacancies: (state) =>
        state.filters.lists.vacancies
          .filter((e) => e.name != null)
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          }),

      channels: (state) => state.leads.channels,

      lead_users: (state) =>
        state.lead_users.lead_users.sort((a, b) => {
          if (a.first_name < b.first_name) {
            return -1;
          }
          if (a.first_name > b.first_name) {
            return 1;
          }
          return 0;
        }),

      employees: (state) =>
        state.filters.lists.employees
          .filter((e) => e.first_name != null)
          .sort((a, b) => {
            if (a.first_name < b.first_name) {
              return -1;
            }
            if (a.first_name > b.first_name) {
              return 1;
            }
            return 0;
          }),
    }),
  },

  created() {
    if (this.type == 1) {
      this.data_fields = this.formData;
      this.userSwitch = true;
      this.editMode = true;
    }
    this.initialize();
  },

  methods: {
    validateEmailOrPhone() {
      return this.data_fields_user.email || this.data_fields_user.tel_number
        ? ""
        : "Vul je telefoonnummer of email in";
    },

    async useExistingLeads() {
      if (this.userSwitch) {
        if (!this.loadedUsers) {
          const { dispatch } = this.$store;
          await dispatch("lead_users/getAllLeadUsers");
          this.loadedUsers = true;
        }
      }
    },

    fullName(item) {
      return item.first_name + " " + item.last_name;
    },

    vacanyWithCompanyName(item) {
      return item.name + " - " + item["company.name"];
    },

    selectFile(file) {
      this.data_fields_file.currentFile = file;
    },

    async initialize() {
      const { dispatch } = this.$store;
      await dispatch("leads/getAllChannels");
      if (this.parentID) this.data_fields.vacancy_id = this.parentID;
      this.loaded = true;
    },

    updateFormFields() {
      if (this.type == 1) {
        this.data_fields = this.formData;
      }
    },

    updateRecruiter(vacancy_id) {
      for (var i = 0; i < this.vacancies.length; i++) {
        if (vacancy_id == this.vacancies[i].id) {
          this.data_fields.rec_id = this.vacancies[i].rec_id;
          return;
        }
      }
    },

    buttonClick() {
      if (this.type == 0) {
        this.createLead();
      } else if (this.type == 1) {
        this.editLead();
      }
    },

    async editLead() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        this.adding = true;
        let lead = {
          lead_user: this.data_fields.lead_user,
          vacancy_id: this.data_fields.vacancy_id,
          channel_id: this.data_fields.channel_id,
          //campaign_id: this.data_fields.campaign_id,        --Not currently in use
          applied_date: this.data_fields.applied_date,
          //notes: this.data_fields.notes,
          ra_id: this.data_fields.ra_id,
          rec_id: this.data_fields.rec_id,
          exit_vacancy_id: this.data_fields.exit_vacancy_id,
        };
        let leadId = this.formData.id;
        if (this.$store.state.users.user.role <= 4) {
          await dispatch("leads/updateLead", { lead, leadId }).then((lead) => {
            dispatch("alerts/success", "Lead aangepast");
            dispatch("lead_users/getLeadUser", this.formData.lead_user);
          });
          this.$emit("close-dialog");
        } else {
          dispatch("alerts/warn", "Je bent niet bevoegd om dit te doen");
        }
      } else {
        dispatch("alerts/warn", "Niet alle velden zijn ingevuld");
      }
      this.adding = false;
    },

    async createLead() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        if (this.$store.state.users.user.role <= 4) {
          this.adding = true;
          let newLead = {
            lead_user: this.data_fields.lead_user,
            vacancy_id: this.data_fields.vacancy_id,
            channel_id: this.data_fields.channel_id,
            campaign_id: this.data_fields.campaign_id,
            applied_date: this.data_fields.applied_date,
            //notes: this.data_fields.notes,
            ra_id: this.data_fields.ra_id,
            rec_id: this.data_fields.rec_id,
          };

          if (!this.userSwitch) {
            let newLeadUser = {
              first_name: this.data_fields_user.first_name,
              last_name: this.data_fields_user.last_name,
              email: this.data_fields_user.email,
              tel_number: this.data_fields_user.tel_number,
            };
            await dispatch("lead_users/createLeadUser", newLeadUser).then(
              (lead_user) => {
                newLead.lead_user = lead_user.lead_user.id;
              }
            );
          }
          let leadUserStatus = {
            lead_user_id: newLead.lead_user,
            lead_status_id: 1,
          };

          await dispatch("lead_users/addStatusToLeadUser", leadUserStatus).then(
            dispatch("alerts/success", "Status toegevoegd")
          );

          let params = {
            startDate: null,
            endDate: null,
          };
          if (this.period[0] > this.period[1]) {
            params = {
              startDate: this.period[1],
              endDate: this.period[0],
            };
          } else {
            params = {
              startDate: this.period[0],
              endDate: this.period[1],
            };
          }

          dispatch("leads/getLeadsPerPeriod", { params });
          await dispatch("leads/createLead", newLead).then((lead) => {
            dispatch("alerts/success", "Lead aangemaakt");
            dispatch("leads/getLeadsPerPeriod", { params });
            dispatch("lead_users/getAllLeadUsersWithLead");
          });
          if (this.data_fields_file.currentFile) {
            var fileToUpload = this.data_fields_file.currentFile;
            let uploadData = {
              lead_user_id: newLead.lead_user,
              title: this.data_fields_file.title,
            };
            const formData = new FormData();
            formData.append("file", fileToUpload);
            dispatch("file_upload/uploadDocument", {
              fileToUpload,
              uploadData,
            });
          }

          this.clear();
          this.$emit("close-dialog");
        } else {
          dispatch("alerts/warn", "Je bent niet bevoegd om dit te doen");
        }
      } else {
        dispatch("alerts/warn", "Niet alle velden zijn ingevuld");
      }
      this.adding = false;
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped></style>
