<template>
  <v-container fill-height>
    <v-row align="center" justify="center" class="text-center">
      <v-col class="mb-3" />
      <v-col class="mb-6">
        <v-card elevation="4" max-width="100%" class="pa-8">
          <div class="alert alert-info" />
          <v-row justify="center">
          <v-img
            v-if="darkTheme"
            max-width="300"
            src="../assets/CLLBR_Logo_White.png"
          ></v-img>
          <v-img
            v-else
            max-width="300"
            src="../assets/CLLBR_Logo_Black.png"
          ></v-img>
          </v-row>
          <v-row justify="center" class="pt-5">
              <h1>Welkom in Collaber</h1>
          </v-row>
          <v-row>
              <p>De login gaat niet via het systeem zelf, als je op de login knop hier onder klikt wordt je gebracht naar een externe login pagina. Daar kan je dan inloggen en wordt je terug gebracht naar het CRM systeem.</p>
          </v-row>
          <v-row justify="center">
          <v-btn
              :disabled="!valid"
              color="primary"
              class="mr-4"
              @click="login"
            >
              Login
            </v-btn>
            </v-row>
        </v-card>
      </v-col>
      <v-col class="mb-3" />
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      twofa: "",
      valid: true,
      password: "",
      passwordRules: [(v) => !!v || "Password is required"],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      submitted: false,
      alignMent: "center",
    };
  },
  computed: {
    darkTheme(){
      return this.$vuetify.theme.dark
    }
  },
  created() {
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
    
  },
};
</script>