import config from "../../config";
import { authHeader } from "../utils/auth-header";
export const intakeDataService = {
  createIntake,
  getIntakeByUser,
};
function createIntake(intakeData) {
  const request = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(intakeData),
  };
  return fetch(`${config.apiUrl}/intake_data`, request)
    .then(handleResponse)
    .then((intakeData) => {
      return intakeData;
    });
}

function getIntakeByUser(userId) {
  const request = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/intake_data/${userId}`, request)
    .then(handleResponse)
    .then((userId) => {
      return userId;
    });
}

// ---------------------------------------------------------------

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
