<template>
  <v-container>
    <v-form ref="form" v-model="valid" class="pa-3">
      <v-row>
        <h3>Voeg een afdeling toe</h3>
      </v-row>
      <v-row align="center" justify="center">
        <v-text-field
          v-model="department_name"
          label="Afdeling naam"
          required
        ></v-text-field>
        <v-col style="margin-top: 0px; padding-top: 0px" align="end">
          <v-btn
            style="margin-top: 0px; padding-top: 0px"
            :disabled="!valid && !adding"
            color="success"
            class="mr-4"
            @click="createDepartment"
          >
            Afdeling toevoegen
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    adding: false,
    valid: true,
    department_name: "",
  }),

  methods: {
    ...mapActions("interests", ["getDepartments"]),
    async createDepartment() {
      const { dispatch } = this.$store;
      if (this.department_name != "") {
        this.adding = true;
        let department = {
          name: this.department_name,
        };
        await dispatch("interests/createDepartment", department).then((res) => {
          dispatch("alerts/success", "Afdeling toegevoegd");
          this.getDepartments();
          this.adding = false;
          this.clear();
          this.$emit("close-dialog");
        });
      } else {
        this.adding = false;
        dispatch("alerts/warn", "Geef de afdeling een naam");
      }
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>