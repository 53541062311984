import { utilityService } from "../../services/utility.service";
import axios from "axios";
import config from "../../../config";
import { authHeader } from "../../utils/auth-header";

export const utility = {
  namespaced: true,
  state: {
    document: null,
  },
  getters: {
    getDocument: (state) => state.document,
  },

  actions: {
    requestFeature({ dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        utilityService.requestFeature(data).then((response) => {
          resolve(response);
        });
      });
    },
    requestBug({ dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        utilityService.requestBug(data).then((response) => {
          resolve(response);
        });
      });
    },
    async parseResume({ dispatch, commit }, payload) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(payload),
        };
        await fetch(`${config.apiUrl}/utility/parseRes`, request).then(
          handleResponse
        );
        // axios.defaults.headers.common["Authorization"] = `${
        //   authHeader().Authorization
        // }`;
        // const { data } = await axios.post(
        //   `${config.apiUrl}/utility/parseRes`,
        //   payload
        // );
        dispatch("alerts/success", "CV Parsed", { root: true });
        // console.log(data);
      } catch (error) {
        dispatch("alerts/error", "The file is invalid", { root: true });
        console.log("error");
      }
    },
    async getDocumentById({ commit }, docId) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(`${config.apiUrl}/utility/${docId}`, request)
          .then(handleResponse)
          .then((data) => {
            commit("saveDocument", data);
          });
        // axios.defaults.headers.common["Authorization"] = `${
        //   authHeader().Authorization
        // }`;
        // const { data } = await axios.get(`${config.apiUrl}/utility/${docId}`);
        // commit("saveDocument", data);
      } catch (error) {
        console.log("error");
      }
    },
  },
  mutations: {
    saveDocument(state, payload) {
      state.document = payload;
    },
  },
};
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
