<template>
  <v-container class="pa-5">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col>
          <v-text-field
            v-model="formDataFields.first_name"
            :rules="[(v) => !!v || 'Voornaam is vereist']"
            label="Voornaam"
            required
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="formDataFields.last_name"
            :rules="[(v) => !!v || 'Achternaam is vereist']"
            label="Achternaam"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-text-field
        v-model="formDataFields.mail"
        :rules="[(v) => !!v || 'Email is vereist']"
        label="Email"
        required
        :loading="adding"
        v-if="type == 0"
      ></v-text-field>
      <v-select
        :items="this.getSubCalendars"
        v-model="formDataFields.cal_id"
        label="Calendar Name"
        required
        item-text="name"
        item-value="id"
        solo
      ></v-select>
      <v-autocomplete
        dense
        class="pt-1"
        :items="notifGroups"
        v-model="data_fields.Notification_Groups"
        chips
        deletable-chips
        multiple
        item-text="name"
        return-object
        label="Notificatie groepen"
        ><template v-slot:selection="{ attr, on, item, selected }">
          <v-chip
            v-bind="attr"
            :input-value="selected"
            v-on="on"
            close
            @click:close="remove(item)"
            ><div style="color: black">
              <strong>{{ item.name }}</strong>
            </div></v-chip
          >
        </template></v-autocomplete
      >
      <v-select
        :items="roles"
        v-model="data_fields.role"
        required
        item-text="name"
        item-value="id"
        label="Role"
        solo
      ></v-select>
      <v-row justify="center">
        <v-btn
          :disabled="!valid || adding"
          color="success"
          class="mr-4"
          @click="buttonClick"
        >
          {{ types[type].buttonText }}
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    type: String,
    formData: Object,
    parentID: Number,
  },
  data: () => ({
    adding: false,
    valid: false,
    verified: false,
    data_fields: {
      role: 5,
      first_name: "",
      last_name: "",
      cal_id: 0,
      Notification_Groups: [],
      mail: "",
    },
    loaded: false,
    roles: [
      { id: 0, name: "Admin" },
      { id: 1, name: "Manager" },
      { id: 2, name: "Community Manager" },
      { id: 3, name: "Recruiter" },
      { id: 4, name: "Recruiter Assistant" },
      { id: 5, name: "Geen" },
    ],
    types: [
      {
        buttonText: "Werknemer Aanmaken",
      },
      {
        buttonText: "Werknemer Aanpassen",
      },
    ],
  }),

  computed: {
    ...mapGetters("teamup", ["getSubCalendars"]),
    ...mapGetters("notifications", ["notifGroups"]),
    formDataFields() {
      this.updateFormFields();
      return this.data_fields;
    },
  },

  created() {
    if (this.type == 1) {
      this.data_fields = this.formData;
      this.callgetCall();
    }
  },

  methods: {
    ...mapActions("teamup", [
      "getAllSubcalendars",
      "createEvent"
    ]),
    ...mapActions("notifications", ["getAllNotificationGroups", "updateEmployeeGroups"]),
    async callgetCall() {
      await this.getAllSubcalendars();
      await this.getAllNotificationGroups();
      this.loaded = true;
    },
    updateFormFields() {
      if (this.type == 1) {
        this.data_fields = this.formData;
      }
    },

    buttonClick() {
      if (this.type == 0) {
        //Create employee
        this.createEmployee();
      } else if (this.type == 1) {
        //Edit employee
        this.editEmployee();
      }
    },

    remove(item) {
      let index = -1;
      this.data_fields.Notification_Groups.find(function(status, i) {
        if (status.name === item.name) {
          index = i;
        }
      });
      if (index >= 0) {
        this.data_fields.Notification_Groups.splice(index, 1);
      }
    },

    async editEmployee() {
      const { dispatch } = this.$store; //Const fot later use
      if (this.$refs.form.validate()) {
        //Validate form fields
        this.adding = true; //Prevent add button from being used
        let employee = {
          //Map data_fiels to json object for employee creation, this object needs to have the same names as what the API expects
          first_name: this.data_fields.first_name,
          last_name: this.data_fields.last_name,
          cal_id: this.data_fields.cal_id,
          role: this.data_fields.role
        };
        var empId = this.formData.id;
        if (this.$store.state.users.user.role == 0) {
          //Only admins can add employees
          await dispatch("users/updateEmployee", { employee, empId }).then(
            async (employee) => {
              //Dispatch and await user creation
              const groups = this.data_fields.Notification_Groups
              await this.updateEmployeeGroups({empId, groups});
              dispatch("alerts/success", "Werknemer aangepast"); //Success notification
              dispatch("users/getAll"); //Get all users, this should update the employees once user is created
              this.$emit("close-dialog");
            }
          );
        } else {
          dispatch("alerts/warn", "Je bent niet bevoegd om dit te doen"); //Notification to say that user does not have right role (user should never get to this page but incase they do somehow)
        }
      } else {
        dispatch("alerts/warn", "Niet alle velden zijn ingevuld"); //Notification that not all fields are filled in
      }
      this.adding = false;
    },

    async createEmployee() {
      const { dispatch } = this.$store; //Const fot later use
      if (this.$refs.form.validate()) {
        //Validate form fields
        this.adding = true; //Prevent add button from being used
        let newEmployee = {
          //Map data_fiels to json object for employee creation, this object needs to have the same names as what the API expects
          first_name: this.data_fields.first_name,
          last_name: this.data_fields.last_name,
          cal_id: this.data_fields.cal_id,
          mail: this.data_fields.mail,
          role: this.data_fields.role,
        };
        if (this.$store.state.users.user.role == 0) {
          //Only admins can add employees
          await dispatch("users/createEmployee", newEmployee).then(
            (employee) => {
              dispatch("alerts/success", "Werknemer aangemaakt"); //Success notification
              dispatch("users/getAll"); //Get all users, this should update the employees once user is created
              this.clear(); //Clear the input fields
              this.$emit("close-dialog");
            }
          );
        } else {
          dispatch("alerts/warn", "Je bent niet bevoegd om dit te doen"); //Notification to say that user does not have right role (user should never get to this page but incase they do somehow)
        }
      } else {
        dispatch("alerts/warn", "Niet alle velden zijn ingevuld"); //Notification that not all fields are filled in
      }
      this.adding = false;
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped></style>
