import config from "../../config";
import { authHeader } from "../utils/auth-header";
export const utilityService = {
  requestFeature,
  requestBug,
};

async function requestFeature(data) {
  const request = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${config.apiUrl}/utility/featurereq`, request)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

async function requestBug(data) {
  const request = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${config.apiUrl}/utility/bugreq`, request)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
