<template>
  <v-card :loading="!loaded">
    <v-container class="pa-4">
      <v-form ref="form" v-if="loaded">
        <v-card-title>
          <span class="text-h5">Delete Lead?</span>
        </v-card-title>
        <v-card-text>
          <p>The lead will be deleted forever!</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="error" @click="deleteLead">
            Delete
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  props: {
    type: String,
    formData: Object,
    parentID: Number,
  },
  data: () => ({
    loaded: false,
    data_fields_user: {
      first_name: "",
      last_name: "",
      email: "",
      tel_number: "",
    },
    data_fields: {
      lead_user: 0,
    },
  }),

  computed: {
    ...mapState({
      vacancies: (state) =>
        state.leads.vacancies.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }),

      channels: (state) => state.leads.channels,

      lead_users: (state) =>
        state.lead_users.lead_users.sort((a, b) => {
          if (a.first_name < b.first_name) {
            return -1;
          }
          if (a.first_name > b.first_name) {
            return 1;
          }
          return 0;
        }),
    }),
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.loaded = true;
    },

    clear() {
      this.$refs.form.reset();
    },

    async deleteLead() {
      const { dispatch } = this.$store;
      if (this.$store.state.users.user.role === 0) {
        await dispatch("leads/DeleteLeadById", this.formData.id).then(() => {
          dispatch("lead_users/getLeadUser", this.$route.params.id);
        });
      } else {
        dispatch("alerts/warn", "Only admins can delete leads!");
      }

      // this.clear();
      this.$emit("close-dialog");
    },
  },
};
</script>

<style scoped></style>
