import config from "../../../config";
import { authHeader } from "../../utils/auth-header";

export const filters = {
  namespaced: true,
  state: {
    vacancy_filter: {},
    lead_filter: {},
    candidate_filter: {},
    leadSortBy: "applied_date",
    leadDesc: true,
    lists: {
      sync: null,
      vacancies: [],
      employees: [],
      companies: [],
      roles: [],
    },
  },
  actions: {
    storeVacancyFilter({ commit }, filter) {
      commit("storeVacancyFilter", filter);
    },
    storeLeadFilter({ commit }, filter) {
      commit("storeLeadFilter", filter);
    },
    storeCandidateFilter({ commit }, filter) {
      commit("storeCandidateFilter", filter);
    },

    updateLeadSortBy({ commit }, sortBy) {
      commit("updateLeadSortBy", sortBy);
    },

    updateLeadSortDesc({ commit }, sortDesc) {
      commit("updateLeadSortDesc", sortDesc);
    },

    syncLists({ state, dispatch }, forceSynce) {
      const now = Date.now();
      const localSync = localStorage.getItem("listsSync");
      var hours = 0;
      if (localSync != null) {
        hours = Math.abs(now - localSync) / 36e5;
      }
      if (localSync == null || hours > 1 || forceSynce) {
        state.lists.sync = now;
        localStorage.setItem("listsSync", now);
        dispatch("fetchVacancies");
        dispatch("fetchEmployees");
        dispatch("fetchCompanies");
        dispatch("fetchRoles");
      } else {
        state.lists.vacancies = JSON.parse(
          localStorage.getItem("short-vacancies")
        );
        state.lists.employees = JSON.parse(
          localStorage.getItem("short-employees")
        );
        state.lists.companies = JSON.parse(
          localStorage.getItem("short-companies")
        );
        state.lists.roles = JSON.parse(localStorage.getItem("short-roles"));
      }
    },
    async fetchVacancies({ state }) {
      const request = {
        method: "GET",
        headers: authHeader(),
      };
      await fetch(`${config.apiUrl}/vacancies-short`, request)
        .then(handleResponse)
        .then((data) => {
          state.lists.vacancies = data.vacancies;
          localStorage.setItem(
            "short-vacancies",
            JSON.stringify(data.vacancies)
          );
        });
    },
    async fetchEmployees({ state }) {
      const request = {
        method: "GET",
        headers: authHeader(),
      };
      await fetch(`${config.apiUrl}/employees-short`, request)
        .then(handleResponse)
        .then((data) => {
          state.lists.employees = data.employees;
          localStorage.setItem(
            "short-employees",
            JSON.stringify(data.employees)
          );
        });
    },
    async fetchCompanies({ state }) {
      const request = {
        method: "GET",
        headers: authHeader(),
      };
      await fetch(`${config.apiUrl}/companies-short`, request)
        .then(handleResponse)
        .then((data) => {
          state.lists.companies = data.companies;
          localStorage.setItem(
            "short-companies",
            JSON.stringify(data.companies)
          );
        });
    },
    async fetchRoles({ state }) {
      const request = {
        method: "GET",
        headers: authHeader(),
      };
      await fetch(`${config.apiUrl}/roles-short`, request)
        .then(handleResponse)
        .then((data) => {
          state.lists.roles = data.roles;
          localStorage.setItem("short-roles", JSON.stringify(data.roles));
        });
    },
  },
  mutations: {
    storeVacancyFilter(state, filter) {
      state.vacancy_filter = filter;
    },
    storeLeadFilter(state, filter) {
      state.lead_filter = filter;
    },
    storeCandidateFilter(state, filter) {
      state.candidate_filter = filter;
    },
    updateLeadSortBy(state, sortBy) {
      state.leadSortBy = sortBy;
    },
    updateLeadSortDesc(state, sortDesc) {
      state.leadSortDesc = sortDesc;
    },
  },
};

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
