<template>
  <v-container class="pa-5">
    <v-row>
      <v-col>
        <h2>Bug / Idee melden</h2>
      </v-col>
      <v-col class="pr-0 mr-0" align="end">
        <v-btn
          :disabled="!valid"
          color="primary"
          class="mr-4"
          @click="sendToTrello"
        >
          Trello
        </v-btn>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        v-model="data_fields.title"
        label="Title"
        required
        :rules="[(v) => !!v || 'Title is vereist']"
      ></v-text-field>
      <v-textarea
        v-model="data_fields.description"
        filled
        label="Omschrijving"
        required
        :rules="[(v) => !!v || 'Omschrijving is vereist']"
      ></v-textarea>
      <v-select
        :items="reportTypes"
        v-model="data_fields.reportType"
        required
        :rules="[(v) => !!v || 'Type is vereist']"
        label="Type"
        solo
        style="padding-top: 15px"
      ></v-select>
      <v-row justify="center">
        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="buttonClick"
        >
          Verzenden
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  props: {},
  data: () => ({
    valid: false,
    data_fields: {
      reportType: "",
      title: "",
      description: "",
    },
    reportTypes: ["Bug", "Feature"],
  }),

  methods: {
    async buttonClick() {
      if (this.data_fields.reportType == "") {
        const { dispatch } = this.$store;
        dispatch("alerts/error", "Kies een Type");
      } else if (this.data_fields.reportType == "Bug") {
        this.createJiraReport();
      } else if (this.data_fields.reportType == "Feature") {
        this.createTrelloReport();
      }
    },

    async createTrelloReport() {
      var reportCreator =
        this.$store.state.users.user.first_name +
        " " +
        this.$store.state.users.user.last_name;
      let data = {
        title: this.data_fields.title,
        reportCreator: reportCreator,
        description: this.data_fields.description,
      };
      const { dispatch } = this.$store;
      await dispatch("utility/requestFeature", data).then((res) => {
        dispatch("alerts/success", "Melding aangemaakt");
        this.$emit("close-dialog");
        this.clear();
      });
    },

    async createJiraReport() {
      var reportCreator =
        this.$store.state.users.user.first_name +
        " " +
        this.$store.state.users.user.last_name;
      try {
        const { dispatch } = this.$store;
        await dispatch("utility/requestBug", {
          data: {
            title: this.data_fields.title,
            description: this.data_fields.description,
            type: this.data_fields.reportType,
            submitter: reportCreator,
          },
        }).then((res) => {
          dispatch("alerts/success", "Melding aangemaakt");
          this.$emit("close-dialog");
          this.clear();
        });
      } catch (err) {
        //Hey cors errors <3 you
      }
    },
    clear() {
      this.$refs.form.reset();
    },
    sendToTrello() {
      window.open("https://trello.com/b/FGZIWUtD", "_blank").focus();
    },
  },
};
</script>

<style scoped></style>
