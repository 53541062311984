import config from '../../../config';
import { authHeader } from '../../utils/auth-header';
export const clientService = {
    getAll,
    getData
};

function getAll() {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.analyticApi}/clients`, request)
        .then(handleResponse)
        .then(companies => {
            return companies;
        });
}

function getData(clientId, startDate, endDate) {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.analyticApi}/clients/${clientId}/${startDate}/${endDate}`, request)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}