import { companyService } from '../../services/company.service'

export const company = {
    namespaced: true,
    state: {
        status: {},
        activeCompany: null,
        companies: {
            data: {}
        },
        pagination: {},
    },
    actions: {
        createCompany({ dispatch, commit }, company) {
            return new Promise((resolve, reject) => {
                companyService.createCompany(company)
                    .then(resolve(company))
            });
        },
        updateCompany({ dispatch, commit }, { company, compId }) {
            return new Promise((resolve, reject) => {
                companyService.updateCompany(company, compId)
                    .then(resolve(company))
            })
        },
        getAllCompanies({ commit }) {
            commit('getAllRequest');
            return new Promise((resolve, reject) => {
                companyService.getAll()
                    .then(
                        companies => {
                            commit('getAllSuccess', companies)
                            resolve(companies)
                        },
                        error => commit('getAllFailure', error)
                    );
            })
        },
        /* Alternative way to do this
        getAllCompanies({commit}, accessToken) {
            return axios.get('http://localhost:6060/api/companies', {
                headers: { 'Authorization': 'Bearer ' + accessToken, 'Content-Type': 'application/json'},
            })
                .then(res => {
                    let companies = res.data;
                    commit('getAllSuccess', companies)
                    return companies
                }).catch(err => {
            })
        },
        */

        getAllCompaniesWithAgencyName({ commit }, platform_name) {
            commit('getAllRequest');
            return new Promise((resolve, reject) => {
                companyService.getAllWithPlatformName(platform_name)
                    .then(
                        companies => {
                            commit('getAllSuccess', companies)
                            resolve(companies)
                        },
                        error => commit('getAllFailure', error)
                    );
            })
        },

        getCompany({ commit }, id) {
            commit('getCompanyRequest');
            return new Promise((resolve, reject) => {
                companyService.getCompany(id)
                    .then(
                        company => {
                            company.company.address = JSON.parse(company.company.address)
                            commit('getCompanySuccess', company)
                            resolve(company)
                        },
                        error => commit('getAllFailure', error)
                    );
            })
        },

        updatePagination({ commit }, pagination) {
            commit('setPaginationState', pagination);
        },
    },
    mutations: {
        getAllRequest(state) {
            state.status = { loading: true };
        },
        getAllSuccess(state, companies) {
            state.status = { loading: false };
            state.companies = { companies };
        },
        getAllFailure(state, error) {
            state.status = { loading: false };
            state.all = { error };
        },
        getCompanyRequest(state) {
            state.status = { loading: true };
        },
        getCompanySuccess(state, company) {
            state.status = { loading: false };
            state.activeCompany = company;
        },
        setPaginationState(state, pagination) {
            state.pagination = pagination;
        },
    },
}