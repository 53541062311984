import Vue from "vue";
import VueRouter from "vue-router";
import { authGuard } from "../auth/authGuard";

import Home from '../views/Home.vue'
import Vacancies from '../views/Vacancies.vue'
import Leads from '../views/Leads.vue'
import People from '../views/Candidates.vue'
import Companies from '../views/Companies.vue'
import Admin from '../views/Admin.vue'
import Company from '../views/Company.vue'
import Profile from '../views/Profile.vue'
import Vacancy from '../views/Vacancy.vue'
import Analytic from '../views/Analytic.vue'
import Dashboard from '../views/DashboardFlow.vue'
import LeadsFlow from '../views/LeadsFlow.vue'
import Search from "../views/Search.vue";
import Marketing from "../views/Marketing.vue";

Vue.use(VueRouter);
//beforeEnter: authGuard
const routes = [
  { path: '/', component: Home },
  { path: '/vacatures', component: Vacancies, beforeEnter: authGuard },
  { path: '/leads-oud', component: Leads, beforeEnter: authGuard },
  { path: '/profiel/:id', component: Profile, beforeEnter: authGuard },
  { path: '/kandidaten', component: People, beforeEnter: authGuard },
  { path: '/bedrijven', component: Companies, beforeEnter: authGuard },
  { path: '/bedrijf/:id', component: Company, beforeEnter: authGuard },
  { path: '/vacature/:id', component: Vacancy, beforeEnter: authGuard },
  { path: '/admin', component: Admin, beforeEnter: authGuard },
  { path: '/analytiek', component: Analytic, beforeEnter: authGuard },
  { path: '/dashboard', component: Dashboard, beforeEnter: authGuard },
  { path: '/leads', component: LeadsFlow, beforeEnter: authGuard },
  { path: "/search", component: Search, beforeEnter: authGuard },
  { path: "/marketing", component: Marketing, beforeEnter: authGuard },
  // otherwise redirect to home
  { path: "*", redirect: "/" },
];

const router = new VueRouter({
  mode: "history",
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "active",
});
/*
router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = Store.state.authentication.user;
  if (authRequired && !loggedIn) {
    return next('/login');
  }

  next();
})*/

export default router;
