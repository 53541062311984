import { fileUploadService } from '../../services/fileUpload.service'

export const file_upload = {
    namespaced: true,
    state: {
    },
    actions: {
        uploadDocument({ dispatch, commit }, {fileToUpload, uploadData}) {
            return new Promise((resolve, reject) => {
                fileUploadService.uploadDocument(fileToUpload, uploadData)
                .then(
                    response => {
                        resolve(response)
                    },
                );
                });
        },

        downloadDocument({ commit }, docID) {
            return new Promise((resolve, reject) => {
                fileUploadService.downloadDocument(docID)
                    .then(
                        document => {
                            resolve(document)
                        },
                    );
            })
        },
    },
    mutations: {
        
    },
}