import { utilityService } from "../../services/utility.service";
import axios from "axios";
import config from "../../../config";
import { authHeader } from "../../utils/auth-header";

export const marketing_spending = {
  namespaced: true,
  state: {
    spending: {},
    allSpendings: {},
  },
  getters: {
    getSpending: (state) => state.spending,
    allSpendings: (state) => state.allSpendings,
  },

  actions: {
    async createSpending({ dispatch, commit }, payload) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(payload),
        };
        await fetch(`${config.apiUrl}/spendings`, request)
          .then(handleResponse)
          .then((data) => {
            commit("saveSpending", data);
          });
        // dispatch("alerts/success", "Spending Created", { root: true });
      } catch (error) {
        console.log("error");
      }
    },
    async getSpendingByMonth({ commit }, date) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(`${config.apiUrl}/spendings/${date}`, request)
          .then(handleResponse)
          .then((data) => {
            commit("saveSpending", data.spending);
          });
      } catch (error) {
        console.log("error");
      }
    },
    async updateSpending({ commit }, data) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(data.spendingData),
        };
        await fetch(`${config.apiUrl}/spendings/${data.tempDate}`, request)
          .then(handleResponse)
          .then((data) => {
            commit("saveSpending", data.updatedSpending);
          });
      } catch (error) {
        console.log("error");
      }
    },
    async getAllSpendings({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(`${config.apiUrl}/spendings`, request)
          .then(handleResponse)
          .then((data) => {
            commit("saveSpendings", data);
          });
      } catch (error) {
        console.log("error");
      }
    },
  },
  mutations: {
    saveSpending(state, payload) {
      state.spending = payload;
    },
    saveSpendings(state, payload) {
      state.allSpendings = payload;
    },
  },
};
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
