<template>
  <v-container fluid>
    <v-dialog v-model="dialogCreate" max-width="600px">
      <v-card>
        <CreateLeadUser
          v-on:close-dialog="dialogCreate = false"
          type="0"
        ></CreateLeadUser>
      </v-card>
    </v-dialog>
    <v-card class="pa-4" :loading="!leadUsersLoaded">
      <v-row>
        <v-col md="8">
          <v-row class="pa-3">
            <h2>Kandidaten</h2>
            <v-divider
              vertical
              inset
              style="padding-left: 10px; padding-left: 10px"
            ></v-divider>
            <v-text-field
              style="padding-top: 0px; padding-left: 10px"
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-row>
        </v-col>
        <v-col align="end" md="4">
          <v-btn icon color="primary" class="mb-2" @click="reload()">
            <v-icon dark>mdi-reload</v-icon>
          </v-btn>
          <v-btn icon color="primary" class="mb-2" @click="dialogCreate = true">
            <v-icon dark> mdi-plus-box </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-menu
        v-model="showMenu"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
      >
        <v-list dense>
          <v-list-item @click="open" dense>
            <v-list-item-title>Open</v-list-item-title>
          </v-list-item>
          <v-list-item @click="openNewTab" dense>
            <v-list-item-title>Nieuw tabblad</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-data-table
        v-if="leadUsersLoaded"
        :page.sync="pagination.page"
        :items-per-page="pagination.itemsPerPage"
        :footer-props="{ itemsPerPageOptions: [20, 35, 50, -1] }"
        :headers="headers"
        :items="leadsuserAdjustedList"
        :search="search"
        sort-by="name"
        class="elevation-1 pointerOnHover"
        @click:row="editItem"
        @contextmenu:row="show"
        @pagination="storePaginationToState"
      >
        <template v-slot:top>
          <v-expansion-panels focusable flat :value="collapsed">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-4 pt-4"
                ><h2>Filter</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container fluid>
                  <v-row dense>
                    <v-col align="end" class="mr-2 mt-2 mb-0">
                      <v-btn icon color="red" @click="clearFilters()"
                        ><span class="mr-1">Clear</span
                        ><v-icon dark>mdi-backspace</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-select
                        :items="genders"
                        v-model="filters.gender"
                        clearable
                        label="Geslacht"
                        @change="storeFilterToState"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="filters.region"
                        append-icon="mdi-magnify"
                        label="Woonplaats"
                        single-line
                        hide-details
                        @change="storeFilterToState"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="car"
                        v-model="filters.has_car"
                        clearable
                        label="Auto"
                        @change="storeFilterToState"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="contract_terms"
                        v-model="filters.contract_term"
                        clearable
                        label="Contracttermijn"
                        @change="storeFilterToState"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="contract_types"
                        v-model="filters.contract_type"
                        clearable
                        label="Betrekkingsomvang"
                        @change="storeFilterToState"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
        <template v-slot:[`item.has_car`]="{ item }">
          <v-checkbox :input-value="item.has_car" value disabled></v-checkbox>
        </template>
        <template v-slot:[`item.cv`]="{ item }">
          <v-icon :color="item.cv">mdi-file-document</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import CreateLeadUser from "../components/dialogs/CreateLeadUser.vue";
import { mapState } from "vuex";
export default {
  components: { CreateLeadUser },
  data: () => ({
    leadUsersLoaded: false,
    dialogCreate: false,
    search: "",
    collapsed: 0,
    car: ["Ja", "Nee"],
    genders: ["Man", "Vrouw", "Transgender", "Non-binair"],
    contract_terms: ["Flexibel", "Vast"],
    contract_types: ["Fulltime", "Parttime", "Fulltime/Parttime", "Bijbaan"],
    filters: {},
    selectedRow: null,
    showMenu: false,
    x: 0,
    y: 0,
  }),
  computed: {
    headers() {
      return [
        {
          text: "Naam",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Geslacht",
          sortable: true,
          value: "gender",
          filter: (value) => {
            if (!this.filters.gender) return true;
            return value === this.filters.gender;
          },
        },
        { text: "Telefoonnummer", sortable: true, value: "tel_number" },
        {
          text: "Woonplaats",
          sortable: true,
          value: "region",
          filter: (value) => {
            if (!this.filters.region) return true;
            return value
              .toLowerCase()
              .includes(this.filters.region.toLowerCase());
          },
        },
        {
          text: "Auto",
          sortable: true,
          value: "has_car",
          filter: (value) => {
            if (!this.filters.has_car) return true;
            const has_car = this.filters.has_car === "Ja" ? true : false;
            return value === has_car;
          },
        },
        { text: "CV Parsed", sortable: true, value: "cv" },
        {
          text: "Contracttermijn",
          sortable: true,
          value: "contract_term",
          filter: (value) => {
            if (!this.filters.contract_term) return true;
            return value === this.filters.contract_term;
          },
        },
        {
          text: "Betrekkingsomvang",
          sortable: true,
          value: "contract_type",
          filter: (value) => {
            if (!this.filters.contract_type) return true;
            return value === this.filters.contract_type;
          },
        },
        { text: "Salarisindicatie", sortable: true, value: "sal_indication" },
      ];
    },
    leadsuserAdjustedList() {
      var leads =  this.lead_users.map((e) => {
        return {
          id: e.id,
          name: `${e.first_name.trim()} ${e.last_name.trim()}`,
          gender: this.itemExists(e.gender),
          tel_number: this.itemExists(e.tel_number),
          region: this.itemExists(e.region),
          has_car: e.car,
          contract_term: this.itemExists(e.contract_term),
          contract_type: this.itemExists(e.contract_type),
          sal_indication: this.itemExists(e.sal_indication),
          cv: this.cvParsed(e),
        };
      });
      return leads;
    },
    ...mapState({
      lead_users: (state) => state.lead_users.lead_users,

      stored_filters: (state) => state.filters.candidate_filter,

      pagination: (state) => state.lead_users.pagination,
    }),
  },
  created() {
    this.initialize();
    this.filters = this.stored_filters;
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    itemExists(item) {
      if (item == null || item == "") return "Onbekend";
      return item;
    },

    cvParsed(item){
      if (item.parsedCv == null || item.parsedCv == "") return "red";
      if (item.textCv == null || item.textCv == "") return "yellow";
      return "green";
    },

    clearFilters() {
      this.filters = {};
      this.storeFilterToState();
    },

    async initialize() {
      const { dispatch } = this.$store;
      if (Object.keys(this.lead_users).length === 0) {
        await dispatch("lead_users/getAllLeadUsers");
      }
      this.leadUsersLoaded = true;
    },

    async reload() {
      this.leadUsersLoaded = false;
      const { dispatch } = this.$store;
      await dispatch("lead_users/getAllLeadUsers");
      this.leadUsersLoaded = true;
    },

    async storeFilterToState() {
      const { dispatch } = this.$store;
      await dispatch("filters/storeCandidateFilter", this.filters);
    },

    async storePaginationToState(val) {
      const { dispatch } = this.$store;
      await dispatch("lead_users/updatePagination", val);
    },

    async editItem(item) {
      this.$router.push(`/profiel/${item.id}`);
    },

    open() {
      this.$router.push(`/profiel/${this.selectedRow.id}`);
    },

    openNewTab() {
      let routeData = this.$router.resolve({
        path: `/profiel/${this.selectedRow.id}`,
      });
      window.open(routeData.href, "_blank");
    },

    show(event, row) {
      event.preventDefault();
      this.showMenu = false;
      this.x = event.clientX;
      this.y = event.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
      this.selectedRow = row.item;
    },
  },
};
</script>
