<template>
  <div class="google-map" v-if="!incompleteAddress">
    <v-container fluid fill-height class="pa-0 ma-0" ref="googleMap">
    </v-container>
  </div>
</template>

<script>
import { incompleteAddress, calculateLocation } from "../helpers/google";
import gmapsInit from "../utils/gmaps";

export default {
  props: {
    address: Object,
  },
  data: () => ({
    latlng: { lat: 0, lng: 0 },
  }),
  computed: {
    incompleteAddress() {
      return incompleteAddress(this.address);
    },
  },
  async mounted() {
    try {
      const google = await gmapsInit();
      const geocoder = new google.maps.Geocoder();
      const map = new google.maps.Map(this.$refs.googleMap, {
        zoom: 10,
        center: this.latlng,
      });
      const marker = new google.maps.Marker({
        position: this.latlng,
        map: map,
      });
    } catch (error) {
      console.error(error);
    }
  },

  watch: {
    address() {
      if (!this.incompleteAddress) {
        this.latlng.lat = this.address.lat;
        this.latlng.lng = this.address.lng;
      }
    },
  },
  created() {
    if (!this.incompleteAddress) {
      if (!(this.address.lat && this.address.lng)) {
        calculateLocation(this.address).then((res) => {
          this.latlng.lat = res.lat;
          this.latlng.lng = res.lng;
          this.$emit("latlng-calculated", this.latlng);
        });
      } else {
        this.latlng.lat = this.address.lat;
        this.latlng.lng = this.address.lng;
      }
    }
  },
};
</script>
<style scoped>
.google-map {
  width: 100%;
  height: 100%;
}
</style>