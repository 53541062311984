<template>
  <v-container>
    <v-form ref="form" class="pa-3">
      <v-row>
        <h3>Bestand uploaden</h3>
      </v-row>

      <v-row align="center" justify="center">
        <v-text-field
          v-model="title"
          label="Titel"
          :rules="[(v) => !!v || 'Document titel is vereist']"
          required
        ></v-text-field>
      </v-row>
      <v-row align="center" justify="center">
        <v-file-input
          show-size
          label="Kies een bestand"
          @change="selectFile"
        ></v-file-input>
      </v-row>
      <v-row align="center" justify="center">
        <v-checkbox v-model="parseFile" label="Parseer"></v-checkbox>
      </v-row>
      <v-row align="center" justify="center">
        <v-btn
          style="margin-top: 0px; padding-top: 0px"
          color="success"
          class="mr-4"
          @click="upload"
          :disabled="disableButton"
        >
          Upload
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    parentID: Number,
    type: Number, //1 = Lead User Upload | 2 = Company Upload
  },
  data: () => ({
    currentFile: undefined,
    message: "",
    fileInfos: [],
    title: "",
    disableButton: false,
    parseFile: false,
  }),

  computed: {},

  created() {},

  methods: {
    ...mapActions("utility", ["parseResume"]),
    selectFile(file) {
      this.currentFile = file;
    },
    async upload() {
      this.disableButton = true;
      const { dispatch } = this.$store;
      if (!this.currentFile) {
        dispatch("alerts/warn", "Kies een bestand om te uploaden");
        return;
      }
      var fileToUpload = this.currentFile;

      let fileId = null;

      let uploadData = {
        lead_user_id: null,
        comp_id: null,
        title: this.title,
      };
      if (this.type == 1) {
        uploadData.lead_user_id = this.parentID;
      } else {
        uploadData.comp_id = this.parentID;
      }
      const formData = new FormData();
      formData.append("file", fileToUpload);
      await dispatch("file_upload/uploadDocument", {
        fileToUpload,
        uploadData,
      }).then((file) => {
        dispatch("alerts/success", "Document uploaded");
        if (this.parseFile) {
          this.parseResume({
            docId: file.document.id,
            docUserId: this.parentID,
          }).then(() => {
            dispatch("lead_users/getLeadUser", this.parentID);
          });
        }
        if (this.type == 1) {
          dispatch("lead_users/getLeadUser", this.parentID).then(
            this.$emit("close-dialog")
          );
        } else {
          dispatch("company/getCompany", this.parentID).then(
            this.$emit("close-dialog")
          );
        }
        this.disableButton = false;
        this.clear();
      });
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped>
</style>