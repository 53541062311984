<template>
  <v-container fluid v-if="currentTable.title">
    <v-dialog v-model="dialogCreate" max-width="600px">
      <v-card>
        <CreateContact
          v-on:close-dialog="dialogCreate = false"
          v-if="type === '0'"
          type="0"
          :parentID="parentID"
        ></CreateContact>
        <CreatePosition
          v-on:close-dialog="dialogCreate = false"
          :parentID="parentID"
          v-if="type == '1'"
        ></CreatePosition>
        <CreateEmployee
          v-on:close-dialog="dialogCreate = false"
          v-if="type === '2'"
          type="0"
        ></CreateEmployee>
        <CreateLeadStatus
          v-on:close-dialog="dialogCreate = false"
          v-if="type === '3'"
          type="0"
        ></CreateLeadStatus>
        <CreateContactMomentStatus
          v-on:close-dialog="dialogCreate = false"
          v-if="type === '4'"
          type="0"
        ></CreateContactMomentStatus>
        <CreateLead
          v-on:close-dialog="dialogCreate = false"
          v-if="type === '5'"
          type="0"
          :parentID="parentID"
        ></CreateLead>
        <CreateVacancy
          v-on:close-dialog="dialogCreate = false"
          v-if="type === '6'"
          type="0"
        ></CreateVacancy>
        <CreateRole
          v-on:close-dialog="dialogCreate = false"
          v-if="type == '7'"
        ></CreateRole>
        <CreateDepartment
          v-on:close-dialog="dialogCreate = false"
          v-if="type == '8'"
        ></CreateDepartment>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEdit" max-width="600px">
      <v-card>
        <CreateContact
          v-on:close-dialog="dialogEdit = false"
          v-if="type === '0'"
          type="1"
          v-bind:formData="editItem"
        ></CreateContact>
        <CreatePosition
          v-on:close-dialog="dialogEdit = false"
          v-if="type === '1'"
        ></CreatePosition>
        <CreateEmployee
          v-on:close-dialog="dialogEdit = false"
          v-if="type === '2'"
          type="1"
          v-bind:formData="editItem"
        ></CreateEmployee>
        <CreateLeadStatus
          v-on:close-dialog="dialogEdit = false"
          v-if="type === '3'"
          type="1"
          v-bind:formData="editItem"
        ></CreateLeadStatus>
        <CreateContactMomentStatus
          v-on:close-dialog="dialogEdit = false"
          v-if="type === '4'"
          type="1"
          v-bind:formData="editItem"
        ></CreateContactMomentStatus>
        <CreateRole
          v-on:close-dialog="dialogEdit = false"
          v-if="type == '7'"
        ></CreateRole>
        <CreateDepartment
          v-on:close-dialog="dialogEdit = false"
          v-if="type == '8'"
        ></CreateDepartment>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCopyVacancy" max-width="600px">
      <v-card>
        <CreateVacancy
          v-if="selectedRow"
          v-on:close-dialog="dialogCopyVacancy = false"
          type="2"
          :formData="selectedRow"
        ></CreateVacancy>
      </v-card>
    </v-dialog>
    <v-bottom-sheet v-model="flowSpeak.dialogSpeak" inset>
      <v-sheet class="text-center">
        <SpokenDatePicker
          v-on:close-dialog="flowSpeak.dialogSpeak = false"
          :type="1"
          :leadID="flowSpeak.activeLeadId"
          :leadUserID="flowSpeak.activeLeadUserId"
          :leadName="flowSpeak.activeLeadName"
          :leadContactNo="flowSpeak.activeLeadContactNo"
          :vmCount="flowSpeak.activeLeadVmCount"
          :additional="flowSpeak.activeVacancyId"
        />
      </v-sheet>
    </v-bottom-sheet>
    <v-card class="pa-4" elevation="4">
      <v-row>
        <v-col md="8">
          <v-row class="pa-3">
            <h2>{{ currentTable.title }}</h2>
            <v-divider
              vertical
              inset
              style="padding-left: 10px; padding-left: 10px"
            ></v-divider>
            <v-text-field
              style="padding-top: 0px; padding-left: 10px"
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-row>
        </v-col>
        <v-col align="end" md="4">
          <v-btn icon color="primary" class="mb-2" @click="dialogCreate = true">
            <v-icon dark> mdi-plus-box </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-menu
        v-model="showMenu"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
      >
        <v-list dense>
          <v-list-item @click="open" dense>
            <v-list-item-title>Open</v-list-item-title>
          </v-list-item>
          <v-list-item @click="openNewTab" dense>
            <v-list-item-title>Nieuw tabblad</v-list-item-title>
          </v-list-item>
          <v-list-item @click="dialogCopyVacancy = true" dense>
            <v-list-item-title>Kopiëer</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-data-table
        :headers="currentTable.headers"
        :items="data"
        :search="search"
        :items-per-page="5"
        @click:row="itemAction"
        @contextmenu:row="show"
        sort-by="name"
        :class="currentTable.class"
      >
        <template v-if="type === '2'" v-slot:[`item.role`]="{ item }">
          {{ currentTable.roleStrings[item.role] }}
        </template>

        <template v-if="type == '3'" v-slot:[`item.color`]="{ item }">
          <v-chip :color="item.color"
            ><div style="color: black">{{ item.color }}</div></v-chip
          >
        </template>
        <template v-if="type === '5'" v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="columnSelectOpen(item)">
            mdi-open-in-new
          </v-icon>
        </template>
        <template
          v-if="type === '5'"
          v-slot:[`item.lead_status_name`]="{ item }"
        >
          <v-chip :color="item.lead_status_color"
            ><div style="color: black">
              <strong>{{ item.lead_status_name }}</strong>
            </div></v-chip
          >
        </template>
        <template
          v-if="type === '5'"
          v-slot:[`item.drivers_licence`]="{ item }"
        >
          <v-checkbox
            :input-value="item.has_driverslicense"
            value
            disabled
          ></v-checkbox>
        </template>
        <template v-if="type === '5'" v-slot:[`item.car`]="{ item }">
          <v-checkbox :input-value="item.has_car" value disabled></v-checkbox>
        </template>

        <template v-if="type === '5'" v-slot:[`item.first_date`]="{ item }">
          <span v-if="item.first_date != null">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="green" dark v-bind="attrs" v-on="on">
                  mdi-phone
                </v-icon>
              </template>
              <span>{{ item.first_date }}</span>
            </v-tooltip>
          </span>
          <v-badge
            v-else-if="item.vm_date != null"
            bordered
            color="error"
            :content="item.vm_count"
            overlap
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="0"
                  color="error"
                  small
                  @click="openFlowSpoken(item)"
                  class="pl-1 pr-3"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-phone-missed</v-icon>
                </v-btn>
              </template>
              <span>{{ item.vm_date }}</span>
            </v-tooltip>
          </v-badge>
          <v-btn
            v-else
            elevation="0"
            color="primary"
            small
            @click="openFlowSpoken(item)"
            icon
          >
            <v-icon color="red" dark>
              mdi-phone
            </v-icon>
          </v-btn>
        </template>

        <template v-if="type === '6'" v-slot:[`item.status_name`]="{ item }">
          <v-chip :color="item.status_color"
            ><div style="color: black">
              <strong>{{ item.status_name }}</strong>
            </div></v-chip
          >
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import CreateContact from "../components/dialogs/CreateContact";
import CreatePosition from "../components/dialogs/CreatePosition";
import CreateEmployee from "../components/dialogs/CreateEmployee";
import CreateLeadStatus from "../components/dialogs/CreateLeadStatus";
import CreateContactMomentStatus from "../components/dialogs/CreateContactMomentStatus";
import CreateLead from "../components/dialogs/CreateLead";
import CreateVacancy from "../components/dialogs/CreateVacancy";
import CreateDepartment from "../components/dialogs/CreateDepartment";
import CreateRole from "../components/dialogs/CreateRole";
import SpokenDatePicker from "../components/dialogs/SpokenDatePicker";
export default {
  components: {
    CreateContact,
    CreatePosition,
    CreateEmployee,
    CreateLeadStatus,
    CreateContactMomentStatus,
    CreateLead,
    CreateVacancy,
    CreateRole,
    CreateDepartment,
    SpokenDatePicker,
  },
  props: {
    type: String,
    data: Array,
    parentID: Number,
  },
  data: () => ({
    hover: false,
    dialogCreate: false,
    dialogEdit: false,
    dialogCopyVacancy: false,
    showMenu: false,
    selectedRow: null,
    x: 0,
    y: 0,
    search: "",
    currentTable: {},
    editItem: {},
    flowSpeak: {
      activeLeadId: null,
      activeLeadUserId: null,
      activeLeadName: null,
      activeLeadContactNo: null,
      activeLeadVmCount: null,
      activeVacancyId: null,
      dialogSpeak: false,
    },
    tables: [
      {
        // 0
        title: "Contactpersonen",
        actionIcon: "mdi-pencil",
        class: "elevation-1 pointerOnHover",
        headers: [
          {
            text: "Afdeling",
            align: "start",
            sortable: false,
            value: "department",
          },
          { text: "Voornaam", value: "first_name" },
          { text: "Achternaam", value: "last_name" },
          { text: "Mail", value: "mail" },
          { text: "Telefoon", value: "phone" },
        ],
      },
      {
        // 1
        title: "Posities",
        actionIcon: "mdi-delete",
        class: "elevation-1 pointerOnHover",
        headers: [
          {
            text: "Positie",
            align: "start",
            sortable: true,
            value: "name",
          },
        ],
      },
      {
        // 2
        title: "Werknemers",
        actionIcon: "mdi-pencil",
        roleStrings: [
          "Admin",
          "Manager",
          "Community Manager",
          "Recruiter",
          "Recruiter Assistant",
          "Geen",
        ],
        class: "elevation-1 pointerOnHover",
        headers: [
          {
            text: "ID",
            align: "start",
            sortable: false,
            value: "id",
          },
          { text: "Voornaam", value: "first_name" },
          { text: "Achternaam", value: "last_name" },
          { text: "Role", value: "role" },
          { text: "Calendar ID", value: "cal_id" },
        ],
      },
      {
        // 3
        title: "Persoon Statuses",
        actionIcon: "mdi-delete",
        class: "elevation-1 pointerOnHover",
        headers: [
          {
            text: "Persoon Status",
            align: "start",
            sortable: true,
            value: "name",
          },
          { text: "Tag Kleur", value: "color" },
        ],
      },
      {
        // 4
        title: "Contact Moment Statuses",
        actionIcon: "mdi-delete",
        class: "elevation-1 pointerOnHover",
        headers: [
          {
            text: "Contact Moment Status",
            align: "start",
            sortable: true,
            value: "name",
          },
        ],
      },
      {
        // 5
        title: "Leads",
        actionIcon: "mdi-delete",
        class: "elevation-1",
        headers: [
          { text: "Actie", value: "actions", sortable: false, align: "start" },
          {
            text: "Status",
            sortable: true,
            value: "lead_status_name",
          },
          { text: "Naam", sortable: true, value: "user_name" },
          { text: "Geboortedatum", sortable: true, value: "user_dob" },
          { text: "Geslacht", sortable: true, value: "gender" },
          { text: "Rijbewijs", sortable: true, value: "drivers_licence" },
          { text: "Auto", sortable: true, value: "car" },
          { text: "Solliciatiedatum", sortable: true, value: "applied_date" },
          { text: "Regio", sortable: true, value: "user_region" },
          { text: "Recruiter", sortable: true, value: "recruiter_name" },
          {
            text: "Recruiter-assistent",
            sortable: true,
            value: "recruitment_assistant_name",
          },
          {
            text: "Bellen",
            value: "first_date",
            sortable: false,
          },
        ],
      },
      {
        // 6
        title: "Vacatures",
        actionIcon: "mdi-delete",
        class: "elevation-1 pointerOnHover",
        headers: [
          { text: "Status", value: "status_name" },
          {
            text: "Naam",
            value: "name",
          },
          { text: "Regio", value: "region" },
          { text: "Community Manager", value: "cm" },
          { text: "Betrekkingsomvang", value: "employment_type" },
          { text: "Betrekkingsduur", value: "employment_length" },
          { text: "Mensen nodig", value: "people_needed" },
          { text: "Aantal sollicitanten", value: "applicants" },
          { text: "Startdatum", value: "start_date" },
        ],
      },
      {
        // 7
        title: "Rollen",
        actionIcon: "mdi-delete",
        class: "elevation-1 pointerOnHover",
        headers: [
          {
            text: "Rol",
            value: "name",
          },
        ],
      },
      {
        // 8
        title: "Afdelingen",
        actionIcon: "mdi-delete",
        class: "elevation-1 pointerOnHover",
        headers: [
          {
            text: "Afdeling",
            value: "name",
          },
        ],
      },
    ],
  }),

  created() {
    this.currentTable = this.tables[parseInt(this.type)];
  },

  methods: {
    async itemAction(item) {
      switch (this.type) {
        case "0":
          // Edit dialog for contact person
          this.editItem = item;
          this.dialogEdit = true;
          break;
        case "1":
          // Edit dialog for employees
          this.editItem = item;
          this.dialogEdit = true;
          break;
        case "2":
          // Edit dialog for lead statuses
          this.editItem = item;
          this.dialogEdit = true;
          break;
        case "3":
          // Edit dialog for lead statuses
          this.editItem = item;
          this.dialogEdit = true;
          break;
        case "4":
          // Edit dialog for Contact moment statuses
          this.editItem = item;
          this.dialogEdit = true;
          break;
        case "5": {
          // Route to Lead
          //this.$router.push(`/profiel/${item.lead_user}`);
          break;
        }
        case "6": {
          // Route to Vacancy
          this.$router.push(`/vacature/${item.id}`);
          break;
        }
        case "7": {
          // Edit dialog for role
          this.editItem = item;
          this.dialogEdit = true;
          break;
        }
        case "8": {
          // Edit dialog for role
          this.editItem = item;
          this.dialogEdit = true;
          break;
        }
      }
    },
    open() {
      switch (this.type) {
        case "5": {
          // Route to Lead
          this.$router.push(`/profiel/${this.selectedRow.id}`);
          break;
        }
        case "6": {
          // Route to Vacancy
          this.$router.push(`/vacature/${this.selectedRow.id}`);
          break;
        }
      }
    },

    openNewTab() {
      switch (this.type) {
        case "5": {
          // Route to Lead
          let routeData = this.$router.resolve({
            path: `/profiel/${this.selectedRow.id}`,
          });
          window.open(routeData.href, "_blank");
          break;
        }
        case "6": {
          // Route to Vacancy
          let routeData = this.$router.resolve({
            path: `/vacature/${this.selectedRow.id}`,
          });
          window.open(routeData.href, "_blank");
          break;
        }
      }
    },

    async show(event, row) {
      if (this.type === "5" || this.type === "6") {
        event.preventDefault();
        this.showMenu = false;
        this.x = event.clientX;
        this.y = event.clientY;
        this.$nextTick(() => {
          this.showMenu = true;
        });
        if (this.type === "6") {
          const { dispatch } = this.$store;
          await dispatch("vacancy/getVacancy", row.item.id).then((res) => {
            this.selectedRow = res.vacancy;
          });
        } else {
          this.selectedRow = row.item;
        }
      }
    },

    openFlowSpoken(item) {
      this.flowSpeak.activeLeadId = item.id;
      this.flowSpeak.activeLeadUserId = item.lead_user;
      this.flowSpeak.activeLeadName = item.user_name;
      this.flowSpeak.activeLeadContactNo = item.tel_number;
      this.flowSpeak.activeLeadVmCount = item.vm_count;
      this.flowSpeak.activeVacancyId = item.vacancy_id.toString();
      this.flowSpeak.dialogSpeak = true;
    },

    columnSelectOpen(item) {
      this.$router.push(`/profiel/${item.lead_user}`);
    },
  },
};
</script>
<style>
.pointerOnHover tr:hover {
  cursor: pointer;
}
</style>
