import { statusService } from "../../services/status.service";

export const statuses = {
  namespaced: true,
  state: {
    lead_statuses: {},
    contact_moment_statuses: {},
  },
  getters: {
    getLeadStatuses: (state) => state.lead_statuses,
  },
  actions: {
    createContactMomentStatus({ dispatch, commit }, contact_moment_status) {
      return new Promise((resolve, reject) => {
        statusService
          .createContactMomentStatus(contact_moment_status)
          .then((contact_moment_status) => resolve(contact_moment_status));
      });
    },
    updateContactMomentStatus(
      { dispatch, commit },
      { contact_moment_status, contact_moment_status_id }
    ) {
      return new Promise((resolve, reject) => {
        statusService
          .updateContactMomentStatus(
            contact_moment_status,
            contact_moment_status_id
          )
          .then((contact_moment_status) => resolve(contact_moment_status));
      });
    },
    getAllContactMomentStatus({ commit }) {
      return new Promise((resolve, reject) => {
        statusService
          .getAllContactMomentStatus()
          .then((contact_moment_statuses) => {
            commit("getAllContactMomentStatusSuccess", contact_moment_statuses);
            resolve(contact_moment_statuses);
          });
      });
    },

    // --------------------------------------------------------------------------------

    createLeadStatus({ dispatch, commit }, lead_status) {
      return new Promise((resolve, reject) => {
        statusService
          .createLeadStatus(lead_status)
          .then((lead_status) => resolve(lead_status));
      });
    },
    updateLeadStatus({ dispatch, commit }, { lead_status, statusId }) {
      return new Promise((resolve, reject) => {
        statusService
          .updateLeadStatus(lead_status, statusId)
          .then((lead_status) => resolve(lead_status));
      });
    },
    getAllLeadStatus({ commit }) {
      return new Promise((resolve, reject) => {
        statusService.getAllLeadStatus().then((lead_statuses) => {
          commit("getAllLeadStatusSuccess", lead_statuses);
          resolve(lead_statuses);
        });
      });
    },

    // --------------------------------------------------------------------------------

    createVacancyStatus({ dispatch, commit }, vacancy_status) {
      return new Promise((resolve, reject) => {
        statusService
          .createVacancyStatus(vacancy_status)
          .then((vacancy_status) => resolve(vacancy_status));
      });
    },
    updateVacancyStatus({ dispatch, commit }, { vacancy_status, statusId }) {
      return new Promise((resolve, reject) => {
        statusService
          .updateVacancyStatus(vacancy_status, statusId)
          .then((vacancy_status) => resolve(vacancy_status));
      });
    },
    getAllVacancyStatus({ commit }) {
      return new Promise((resolve, reject) => {
        statusService.getAllVacancyStatus().then((vacancy_statuses) => {
          commit("getAllVacancyStatusSuccess", vacancy_statuses);
          resolve(vacancy_statuses);
        });
      });
    },
  },
  mutations: {
    getAllContactMomentStatusSuccess(state, contact_moment_statuses) {
      state.contact_moment_statuses =
        contact_moment_statuses.contact_moment_statuses;
    },
    getAllLeadStatusSuccess(state, lead_statuses) {
      state.lead_statuses = lead_statuses.lead_statuses;
    },
    getAllVacancyStatusSuccess(state, vacancy_statuses) {
      state.vacancy_statuses = vacancy_statuses.vacancy_statuses;
    },
  },
};
