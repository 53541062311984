import config from '../../config';
import { authHeader } from '../utils/auth-header';

export const industriesService = {
    getIndustries,
    getIndustryById,
    createIndustry,
    updateIndustry
}

function getIndustries() {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/industries`, request)
        .then(handleResponse)
        .then(industries => {
            return industries;
        });
}

function getIndustryById(industryId) {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/industries/${industryId}`, request)
        .then(handleResponse)
        .then(industry => {
            return industry;
        });
}

function createIndustry(industry) {
    const request = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(industry)
    }
    return fetch(`${config.apiUrl}/industries`, request)
        .then(handleResponse)
        .then(industry => {
            return industry;
        });
}

function updateIndustry(industry, industryId) {
    const request = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(industry)
    }
    return fetch(`${config.apiUrl}/interests/${industryId}`, request)
        .then(handleResponse)
        .then(industry => {
            return industry;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}