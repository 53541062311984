import {
  interestsService,
  jobsService,
  industriesService,
  rolesService,
  departmentsService,
} from "../../services";

export const interests = {
  namespaced: true,
  state: {
    status: {
      interests: false,
      industries: false,
      roles: false,
      jobs: false,
      departments: false,
    },
    interests: {},
    industries: {},
    roles: {},
    jobs: {},
    departments: {},
  },
  getters: {
    getLoadingStates: (state) => state.status,
    interests: (state) => state.interests,
    industries: (state) => state.industries,
    roles: (state) => state.roles,
    jobs: (state) => state.jobs,
    departments: (state) => state.departments,
  },
  actions: {
    // getInterests({ commit, state }) {
    //     return new Promise((resolve, reject) => {
    //         interestsService.getInterests().then(
    //             interests => {
    //                 state.status.interests = true
    //                 state.interests = interests.interests
    //                 resolve(interests.interests)
    //             }
    //         );
    //     })
    // },
    getInterestsForLeadUser({ commit, state }, leadUserId) {
      return new Promise((resolve, reject) => {
        interestsService
          .getInterestsForLeadUser(leadUserId)
          .then((interests) => {
            state.status.interests = true;
            state.interests = interests.interests;
            resolve(interests);
          });
      });
    },
    createInterest({ commit }, interest) {
      return new Promise((resolve, reject) => {
        interestsService.createInterest(interest).then((interest) => {
          resolve(interest);
        });
      });
    },
    updateInterest({ commit }, { interest, interestId }) {
      console.log(interestId);
      return new Promise((resolve, reject) => {
        interestsService
          .updateInterest(interest, interestId)
          .then((interest) => {
            resolve(interest);
          });
      });
    },
    // Departments
    getDepartments({ commit, state }) {
      return new Promise((resolve, reject) => {
        departmentsService.getDepartments().then((departments) => {
          state.status.departments = true;
          state.departments = departments.departments;
          resolve(departments.departments);
        });
      });
    },
    createDepartment({ commit }, department) {
      return new Promise((resolve, reject) => {
        departmentsService.createDepartment(department).then((department) => {
          resolve(department);
        });
      });
    },
    updateDepartment({ commit }, { department, departmentId }) {
      return new Promise((resolve, reject) => {
        departmentsService
          .updateDepartment(department, departmentId)
          .then((department) => {
            resolve(department);
          });
      });
    },
    // Roles
    getRoles({ commit, state }) {
      return new Promise((resolve, reject) => {
        rolesService.getRoles().then((roles) => {
          state.roles = roles.roles;
          state.status.roles = true;
          resolve(roles.roles);
        });
      });
    },
    createRole({ commit }, role) {
      return new Promise((resolve, reject) => {
        rolesService.createRole(role).then((role) => {
          resolve(role);
        });
      });
    },
    updateRole({ commit }, role, roleId) {
      return new Promise((resolve, reject) => {
        rolesService.updateRole(role, roleId).then((role) => {
          resolve(role);
        });
      });
    },
    //Industries
    getIndustries({ commit, state }) {
      return new Promise((resolve, reject) => {
        industriesService.getIndustries().then((industries) => {
          state.status.industries = true;
          state.industries = industries.industries;
          resolve(industries.industries);
        });
      });
    },
    createIndustry({ commit }, industry) {
      return new Promise((resolve, reject) => {
        industriesService.createIndustry(industry).then((industry) => {
          resolve(industry);
        });
      });
    },
    updateIndustry({ commit }, { industry, industryId }) {
      return new Promise((resolve, reject) => {
        industriesService
          .updateIndustry(industry, industryId)
          .then((industry) => {
            resolve(industry);
          });
      });
    },
    //Jobs
    getJobs({ commit, state }) {
      return new Promise((resolve, reject) => {
        jobsService.getJobs().then((jobs) => {
          state.status.jobs = true;
          state.jobs = jobs.jobs;
          resolve(jobs.jobs);
        });
      });
    },
    createJob({ commit }, job) {
      return new Promise((resolve, reject) => {
        jobsService.createJob(job).then((job) => {
          resolve(job);
        });
      });
    },
    updateJob({ commit }, { job, jobId }) {
      return new Promise((resolve, reject) => {
        jobsService.updateJob(job, jobId).then((job) => {
          resolve(job);
        });
      });
    },

    // Actions that are being ignored for the time being

    // getInterestsForJob({ commit }, jobId) {
    //     commit('getAllRequest');
    //     return new Promise((resolve, reject) => {
    //         interestsService.getInterestsForJob(jobId).then(
    //             interest => {
    //                 commit('getAllSuccess', interest);
    //                 resolve(interest)
    //             },
    //             error => commit('getAllFailure', error)
    //         );
    //     })
    // },
    // getJobById({ commit }, jobId) {
    //     commit('getJobRequest');
    //     return new Promise((resolve, reject) => {
    //         jobsService.getJobById(jobId).then(
    //             job => {
    //                 commit('getJobSuccess', job);
    //                 resolve(job)
    //             },
    //             error => commit('getAllFailure', error)
    //         );
    //     })
    // },
    // getDepartmentById({ commit }, departmentId) {
    //     commit('getDepartmentRequest');
    //     return new Promise((resolve, reject) => {
    //         departmentsService.getDepartments().then(
    //             department => {
    //                 commit('getDepartmentSuccess', department);
    //                 resolve(department)
    //             },
    //             error => commit('getAllFailure', error)
    //         );
    //     })
    // },
    // getIndustryById({ commit }, industryId) {
    //     commit('getIndustryRequest');
    //     return new Promise((resolve, reject) => {
    //         industriesService.getIndustryById(industryId).then(
    //             industry => {
    //                 commit('getIndustrySuccess', industry);
    //                 resolve(industry)
    //             },
    //             error => commit('getAllFailure', error)
    //         );
    //     })
    // },
    // getRoleById({ commit }, roleId) {
    //     commit('getRoleRequest');
    //     return new Promise((resolve, reject) => {
    //         rolesService.getRoleById(roleId).then(
    //             role => {
    //                 commit('getRoleSuccess', role);
    //                 resolve(role)
    //             },
    //             error => commit('getAllFailure', error)
    //         );
    //     })
    // },
  },
  mutations: {},
};
