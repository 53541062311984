<template>
  <v-container class="pa-5">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        v-model="formDataFields.department"
        :rules="[(v) => !!v || 'Afdeling is vereist']"
        label="Afdeling"
        required
      ></v-text-field>
      <v-row>
        <v-col>
          <v-text-field
            v-model="formDataFields.first_name"
            :rules="[(v) => !!v || 'Voornaam is vereist']"
            label="Voornaam"
            required
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
            v-model="formDataFields.last_name"
            :rules="[(v) => !!v || 'Achternaam is vereist']"
            label="Achternaam"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-text-field
        v-model="formDataFields.mail"
        :rules="[(v) => !!v || 'Email is vereist']"
        label="Email"
        required
      ></v-text-field>

      <v-text-field
        v-model="formDataFields.phone"
        :rules="[(v) => !!v || 'Telefoonnummer is vereist']"
        label="Telefoonnummer"
        required
      ></v-text-field>
      <v-row justify="center">
        <v-btn
          :disabled="!valid && !adding"
          color="success"
          class="mr-4"
          @click="buttonClick"
        >
          {{ types[type].buttonText }}
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  props: {
    type: String,
    formData: Object,
    parentID: Number,
  },
  data: () => ({
    adding: false,
    valid: true,
    data_fields: {
      department: "",
      first_name: "",
      last_name: "",
      mail: "",
      phone: "",
    },
    types: [
      {
        buttonText: "Contactpersoon Aanmaken",
      },
      {
        buttonText: "Contactpersoon Aanpassen",
      },
    ],
  }),

  computed: {
    formDataFields() {
      this.updateFormFields();
      return this.data_fields;
    },
  },

  created() {
    if (this.type == 1) {
      this.data_fields = this.formData;
    }
  },

  methods: {
    updateFormFields() {
      if (this.type == 1) {
        this.data_fields = this.formData;
      }
    },

    buttonClick() {
      if (this.type == 0) {
        this.addContact();
      } else if (this.type == 1) {
        this.editContact();
      }
    },

    async editContact() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        if (this.$store.state.users.user.role <= 2) {
          this.adding = true;

          let contact = {
            department: this.data_fields.department,
            first_name: this.data_fields.first_name,
            last_name: this.data_fields.last_name,
            mail: this.data_fields.mail,
            phone: this.data_fields.phone,
            comp_id: this.parentID,
          };
          const id = this.data_fields.id;
          await dispatch("contact/updateContact", { contact, id }).then(
            (contact) => {
              dispatch("alerts/success", "Contactpersoon aangepast");
            }
          );
          dispatch(
            "company/getCompany",
            this.$store.state.company.activeCompany.company.id
          );
          this.adding = false;
          this.$emit('close-dialog');
        } else {
          dispatch("alerts/warn", "Je bent niet bevoegd om dit te doen");
        }
      } else {
        dispatch("alerts/warn", "Niet alle benodigde velden zijn ingevuld");
      }
    },

    async addContact() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        if (this.$store.state.users.user.role <= 2) {
          this.adding = true;

          let contact = {
            department: this.data_fields.department,
            first_name: this.data_fields.first_name,
            last_name: this.data_fields.last_name,
            mail: this.data_fields.mail,
            phone: this.data_fields.phone,
            comp_id: this.parentID,
          };
          const { dispatch } = this.$store;
          await dispatch("contact/createContact", contact).then((contact) => {
            dispatch("alerts/success", "Contactpersoon aangemaakt");
          });
          dispatch("company/getCompany", contact.comp_id);
          this.adding = false;
          this.clear();
          this.$emit('close-dialog');
        } else {
          dispatch("alerts/warn", "Je bent niet bevoegd om dit te doen");
        }
      } else {
        dispatch("alerts/warn", "Niet alle benodigde velden zijn ingevuld");
      }
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped>
</style>