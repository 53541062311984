<template>
  <v-container fluid>
    <v-card class="pa-4">
      <v-row>
        <h2 class="pa-6">Month</h2>
        <v-col cols="11" sm="5">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Select month"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              type="month"
              no-title
              scrollable
              @change="getWebsitesEuros"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col align="right">
          <v-btn icon color="primary" class="mb-2" @click="excelExport()">
            <v-icon dark>mdi-table-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        v-if="websiteEuros"
        :headers="headers"
        :items="websiteEuros"
        hide-default-footer
        :items-per-page="-1"
        class="elevation-3"
      >
        <template v-slot:[`item.facebookEuros`]="{ item }">
          <v-edit-dialog
            :return-value.sync="item.facebookEuros"
            @save="save"
            @cancel="cancel"
            @open="open"
            @close="close"
          >
            {{ roundToTwoDecimals(item.facebookEuros).toFixed(2) }} €
            <template v-slot:input>
              <v-text-field
                v-model="item.facebookEuros"
                :rules="[max25chars]"
                label="Edit"
                single-line
                counter
                type="number"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:[`item.indeedEuros`]="{ item }">
          <v-edit-dialog
            :return-value.sync="item.indeedEuros"
            @save="save"
            @cancel="cancel"
            @open="open"
            @close="close"
          >
            {{ roundToTwoDecimals(item.indeedEuros).toFixed(2) }} €
            <template v-slot:input>
              <v-text-field
                v-model="item.indeedEuros"
                :rules="[max25chars]"
                label="Edit"
                single-line
                counter
                type="number"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:[`item.linkedInEuros`]="{ item }">
          <v-edit-dialog
            :return-value.sync="item.linkedInEuros"
            @save="save"
            @cancel="cancel"
            @open="open"
            @close="close"
          >
            {{ roundToTwoDecimals(item.linkedInEuros).toFixed(2) }} €
            <template v-slot:input>
              <v-text-field
                v-model="item.linkedInEuros"
                :rules="[max25chars]"
                label="Edit"
                single-line
                counter
                type="number"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:[`item.werkezoekenEuros`]="{ item }">
          <v-edit-dialog
            :return-value.sync="item.werkezoekenEuros"
            @save="save"
            @cancel="cancel"
            @open="open"
            @close="close"
          >
            {{ roundToTwoDecimals(item.werkezoekenEuros).toFixed(2) }} €
            <template v-slot:input>
              <v-text-field
                v-model="item.werkezoekenEuros"
                :rules="[max25chars]"
                label="Edit"
                single-line
                counter
                type="number"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:[`item.werkEuros`]="{ item }">
          <v-edit-dialog
            :return-value.sync="item.werkEuros"
            @save="save"
            @cancel="cancel"
            @open="open"
            @close="close"
          >
            {{ roundToTwoDecimals(item.werkEuros).toFixed(2) }} €
            <template v-slot:input>
              <v-text-field
                v-model="item.werkEuros"
                :rules="[max25chars]"
                label="Edit"
                single-line
                counter
                type="number"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:[`item.doenSiteEuros`]="{ item }">
          <v-edit-dialog
            :return-value.sync="item.doenSiteEuros"
            @save="save"
            @cancel="cancel"
            @open="open"
            @close="close"
          >
            {{ roundToTwoDecimals(item.doenSiteEuros).toFixed(2) }} €
            <template v-slot:input>
              <v-text-field
                v-model="item.doenSiteEuros"
                :rules="[max25chars]"
                label="Edit"
                single-line
                counter
                type="number"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:[`item.zoomerSiteEuros`]="{ item }">
          <v-edit-dialog
            :return-value.sync="item.zoomerSiteEuros"
            @save="save"
            @cancel="cancel"
            @open="open"
            @close="close"
          >
            {{ roundToTwoDecimals(item.zoomerSiteEuros).toFixed(2) }} €
            <template v-slot:input>
              <v-text-field
                v-model="item.zoomerSiteEuros"
                :rules="[max25chars]"
                required
                label="Edit"
                single-line
                counter
                type="number"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
      </v-data-table>
      <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
        {{ snackText }}
        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
        </template>
      </v-snackbar>
      <v-data-table
        v-if="websiteEurosTotal"
        :headers="headersTotal"
        :items="websiteEurosTotal"
        hide-default-footer
        class="elevation-1 mt-8 mb-6"
      ></v-data-table>

      <v-data-table
        v-if="websiteEurosAverage"
        :headers="headersAverage"
        :items="websiteEurosAverage"
        hide-default-footer
        class="elevation-1"
      ></v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      excelExporting: false,
      snack: false,
      snackColor: "",
      snackText: 0,
      max25chars: (v) => v.length <= 25 || "Input too long!",

      //date by month picker script
      date: new Date().toISOString().substr(0, 7),
      menu: false,
      modal: false,

      leadsCount: {
        facebookLeads: 0,
        indeedLeads: 0,
        linkedInLeads: 0,
        werkezoekenLeads: 0,
        werkLeads: 0,
        doenSiteLeads: 0,
        zoomerSiteLeads: 0,
      },

      headers: [
        {
          text: "Day",
          align: "start",
          value: "day",
        },
        { text: "Facebook €", value: "facebookEuros" },
        { text: "Indeed €", value: "indeedEuros" },
        { text: "Linkedin €", value: "linkedInEuros" },
        { text: "Werkezoeken €", value: "werkezoekenEuros" },
        { text: "Werk.nl €", value: "werkEuros" },
        { text: "Doen Site €", value: "doenSiteEuros" },
        { text: "Zoomer Site €", value: "zoomerSiteEuros" },
      ],
      websiteEuros: null,

      // Total table
      headersTotal: [
        {
          text: "",
          align: "start",
          value: "total",
        },
        { text: "Total Facebook €", value: "facebookEurosTotal" },
        { text: "Total Indeed €", value: "indeedEurosTotal" },
        { text: "Total Linkedin €", value: "linkedInEurosTotal" },
        { text: "Total Werkezoeken €", value: "werkezoekenEurosTotal" },
        { text: "Total Werk.nl €", value: "werkEurosTotal" },
        { text: "Total Doen Site €", value: "doenSiteEurosTotal" },
        { text: "Total Zoomer Site €", value: "zoomerSiteEurosTotal" },
      ],
      websiteEurosTotal: [
        {
          total: "Total",
          facebookEurosTotal: 0.0,
          indeedEurosTotal: 0.0,
          linkedInEurosTotal: 0.0,
          werkezoekenEurosTotal: 0.0,
          werkEurosTotal: 0.0,
          doenSiteEurosTotal: 0.0,
          zoomerSiteEurosTotal: 0.0,
        },
      ],

      // Average table
      headersAverage: [
        {
          text: "",
          align: "start",
          value: "average",
        },
        {
          text: "Average Facebook €",
          value: "facebookEurosAverage",
        },
        { text: "Average Indeed €", value: "indeedEurosAverage" },
        { text: "Average Linkedin €", value: "linkedInEurosAverage" },
        { text: "Average Werkezoeken €", value: "werkezoekenEurosAverage" },
        { text: "Average Werk.nl €", value: "werkEurosAverage" },
        { text: "Average Doen Site €", value: "doenSiteEurosAverage" },
        { text: "Average Zoomer Site €", value: "zoomerSiteEurosAverage" },
      ],
      websiteEurosAverage: [
        {
          average: "Average",
          facebookEurosAverage: 0.0,
          indeedEurosAverage: 0.0,
          linkedInEurosAverage: 0.0,
          werkezoekenEurosAverage: 0.0,
          werkEurosAverage: 0.0,
          doenSiteEurosAverage: 0.0,
          zoomerSiteEurosAverage: 0.0,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("marketing_spending", ["getSpending", "allSpendings"]),
    ...mapGetters("leads", ["getleadsByMonth"]),
  },
  created() {
    this.getWebsitesEuros();
  },
  methods: {
    ...mapActions("marketing_spending", [
      "createSpending",
      "getSpendingByMonth",
      "updateSpending",
      "getAllSpendings",
    ]),
    ...mapActions("leads", ["getLeadsByMonth"]),
    async getWebsitesEuros() {
      //Fetching spendings for month
      let temp = this.date + "-01";
      await this.getSpendingByMonth(temp);
      await this.getLeadsByMonth(this.date);
      if (this.getSpending === null) {
        const [year, month] = this.date.split("-");
        const days = this.daysInMonth(month, year);
        let arrayOfwebsitesEuros = [];
        for (let i = 1; i < days + 1; i++) {
          const websiteEuro = {
            day: i,
            facebookEuros: 0,
            indeedEuros: 0,
            linkedInEuros: 0,
            werkezoekenEuros: 0,
            werkEuros: 0,
            doenSiteEuros: 0,
            zoomerSiteEuros: 0,
          };
          arrayOfwebsitesEuros.push(websiteEuro);
        }
        this.resetTotals();
        let array = JSON.stringify(arrayOfwebsitesEuros);
        let total = JSON.stringify(this.websiteEurosTotal);
        await this.createSpending({
          month_year: temp,
          spendings: array,
          total_spendings: total,
        });
      }
      this.websiteEuros = JSON.parse(this.getSpending.spendings);
      this.websiteEurosTotal = JSON.parse(this.getSpending.total_spendings);
      this.calculateAverage();
    },
    daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    },

    // round number to max two decimals after comma
    roundToTwoDecimals(value) {
      return Math.round(value * 100) / 100;
    },

    // edit data methods
    async save() {
      let tempDate = this.date + "-01";

      let spendingData = {
        spendings: this.websiteEuros,
      };
      spendingData.spendings = JSON.stringify(spendingData.spendings);
      await this.updateSpending({
        tempDate: tempDate,
        spendingData: spendingData,
      }).then(() => {
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Data saved";
      });
      this.websiteEuros = JSON.parse(this.getSpending.spendings);
      this.calculateTotals();
      this.calculateAverage();
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
    open() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },
    close() {},
    async calculateTotals() {
      this.resetTotals();
      for (let i = 0; i < this.websiteEuros.length; i++) {
        this.websiteEurosTotal[0].facebookEurosTotal = (
          this.roundToTwoDecimals(
            this.websiteEurosTotal[0].facebookEurosTotal
          ) + this.roundToTwoDecimals(this.websiteEuros[i].facebookEuros)
        ).toFixed(2);

        this.websiteEurosTotal[0].indeedEurosTotal = (
          this.roundToTwoDecimals(this.websiteEurosTotal[0].indeedEurosTotal) +
          this.roundToTwoDecimals(this.websiteEuros[i].indeedEuros)
        ).toFixed(2);

        this.websiteEurosTotal[0].linkedInEurosTotal = (
          this.roundToTwoDecimals(
            this.websiteEurosTotal[0].linkedInEurosTotal
          ) + this.roundToTwoDecimals(this.websiteEuros[i].linkedInEuros)
        ).toFixed(2);

        this.websiteEurosTotal[0].werkezoekenEurosTotal = (
          this.roundToTwoDecimals(
            this.websiteEurosTotal[0].werkezoekenEurosTotal
          ) + this.roundToTwoDecimals(this.websiteEuros[i].werkezoekenEuros)
        ).toFixed(2);

        this.websiteEurosTotal[0].werkEurosTotal = (
          this.roundToTwoDecimals(this.websiteEurosTotal[0].werkEurosTotal) +
          this.roundToTwoDecimals(this.websiteEuros[i].werkEuros)
        ).toFixed(2);

        this.websiteEurosTotal[0].doenSiteEurosTotal = (
          this.roundToTwoDecimals(
            this.websiteEurosTotal[0].doenSiteEurosTotal
          ) + this.roundToTwoDecimals(this.websiteEuros[i].doenSiteEuros)
        ).toFixed(2);

        this.websiteEurosTotal[0].zoomerSiteEurosTotal = (
          this.roundToTwoDecimals(
            this.websiteEurosTotal[0].zoomerSiteEurosTotal
          ) + this.roundToTwoDecimals(this.websiteEuros[i].zoomerSiteEuros)
        ).toFixed(2);
      }
      let tempDate = this.date + "-01";
      let spendingData = {
        total_spendings: this.websiteEurosTotal,
      };
      spendingData.total_spendings = JSON.stringify(
        spendingData.total_spendings
      );
      await this.updateSpending({
        tempDate: tempDate,
        spendingData: spendingData,
      });
      this.websiteEurosTotal = JSON.parse(this.getSpending.total_spendings);
    },
    resetTotals() {
      this.websiteEurosTotal = [
        {
          total: "Total",
          facebookEurosTotal: 0.0,
          indeedEurosTotal: 0.0,
          linkedInEurosTotal: 0.0,
          werkezoekenEurosTotal: 0.0,
          werkEurosTotal: 0.0,
          doenSiteEurosTotal: 0.0,
          zoomerSiteEurosTotal: 0.0,
        },
      ];
    },
    calculateAverage() {
      this.resetAverage();
      //Saves number of leads per channel for this month
      for (let i = 0; i < this.getleadsByMonth.length; i++) {
        switch (this.getleadsByMonth[i].channel_id) {
          case 1:
            this.leadsCount.facebookLeads++;
            break;
          case 5:
            this.leadsCount.indeedLeads++;
            break;
          case 10:
            this.leadsCount.linkedInLeads++;
            break;
          case 4:
            this.leadsCount.werkezoekenLeads++;
            break;
          case 7:
            this.leadsCount.werkLeads++;
            break;
          case 2:
            this.leadsCount.doenSiteLeads++;
            break;
          case 3:
            this.leadsCount.zoomerSiteLeads++;
            break;
          default:
            break;
        }
      }
      if (this.getleadsByMonth.length > 0) {
        if (
          this.websiteEurosTotal[0].facebookEurosTotal !== 0 &&
          this.leadsCount.facebookLeads !== 0
        ) {
          this.websiteEurosAverage[0].facebookEurosAverage =
            this.roundToTwoDecimals(
              Math.round(
                this.websiteEurosTotal[0].facebookEurosTotal /
                  this.leadsCount.facebookLeads
              )
            ).toFixed(2);
        }
        if (
          this.websiteEurosTotal[0].indeedEurosTotal !== 0 &&
          this.leadsCount.indeedLeads !== 0
        ) {
          this.websiteEurosAverage[0].indeedEurosAverage =
            this.roundToTwoDecimals(
              Math.round(
                this.websiteEurosTotal[0].indeedEurosTotal /
                  this.leadsCount.indeedLeads
              )
            ).toFixed(2);
        }
        if (
          this.websiteEurosTotal[0].linkedInEurosTotal !== 0 &&
          this.leadsCount.linkedInLeads !== 0
        ) {
          this.websiteEurosAverage[0].linkedInEurosAverage =
            this.roundToTwoDecimals(
              Math.round(
                this.websiteEurosTotal[0].linkedInEurosTotal /
                  this.leadsCount.linkedInLeads
              )
            ).toFixed(2);
        }
        if (
          this.websiteEurosTotal[0].werkezoekenEurosTotal !== 0 &&
          this.leadsCount.werkezoekenLeads !== 0
        ) {
          this.websiteEurosAverage[0].werkezoekenEurosAverage =
            this.roundToTwoDecimals(
              Math.round(
                this.websiteEurosTotal[0].werkezoekenEurosTotal /
                  this.leadsCount.werkezoekenLeads
              )
            ).toFixed(2);
        }
        if (
          this.websiteEurosTotal[0].werkEurosTotal !== 0 &&
          this.leadsCount.werkLeads !== 0
        ) {
          this.websiteEurosAverage[0].werkEurosAverage =
            this.roundToTwoDecimals(
              Math.round(
                this.websiteEurosTotal[0].werkEurosTotal /
                  this.leadsCount.werkLeads
              )
            ).toFixed(2);
        }
        if (
          this.websiteEurosTotal[0].doenSiteEurosTotal !== 0 &&
          this.leadsCount.doenSiteLeads !== 0
        ) {
          this.websiteEurosAverage[0].doenSiteEurosAverage =
            this.roundToTwoDecimals(
              Math.round(
                this.websiteEurosTotal[0].doenSiteEurosTotal /
                  this.leadsCount.doenSiteLeads
              )
            ).toFixed(2);
        }
        if (
          this.websiteEurosTotal[0].zoomerSiteEurosTotal !== 0 &&
          this.leadsCount.zoomerSiteLeads !== 0
        ) {
          this.websiteEurosAverage[0].zoomerSiteEurosAverage =
            this.roundToTwoDecimals(
              Math.round(
                this.websiteEurosTotal[0].zoomerSiteEurosTotal /
                  this.leadsCount.zoomerSiteLeads
              )
            ).toFixed(2);
        }
      }
    },
    resetAverage() {
      this.websiteEurosAverage = [
        {
          average: "Average",
          facebookEurosAverage: 0.0,
          indeedEurosAverage: 0.0,
          linkedInEurosAverage: 0.0,
          werkezoekenEurosAverage: 0.0,
          werkEurosAverage: 0.0,
          doenSiteEurosAverage: 0.0,
          zoomerSiteEurosAverage: 0.0,
        },
      ];
      this.leadsCount = {
        facebookLeads: 0,
        indeedLeads: 0,
        linkedInLeads: 0,
        werkezoekenLeads: 0,
        werkLeads: 0,
        doenSiteLeads: 0,
        zoomerSiteLeads: 0,
      };
    },
    excelExport() {
      this.excelExporting = true;
      let csvContent = "data:text/csv;charset=utf-8,";
      let headings = `Days;Facebook €;Indeed €;Linkedin €;Werkezoeken €;Werk.nl €;Doen Site €;Zoomer Site €`;
      csvContent += headings + "\r\n";
      this.websiteEuros.forEach((webEuros, day) => {
        let row = `${day + 1};${webEuros.facebookEuros};${
          webEuros.indeedEuros
        };${webEuros.linkedInEuros};${webEuros.werkezoekenEuros};${
          webEuros.werkEuros
        };${webEuros.doenSiteEuros};${webEuros.zoomerSiteEuros}`;
        csvContent += row + "\r\n";
      });
      let modifiedCsv = csvContent.split(".").join(",");
      console.log(modifiedCsv);
      var encodedUri = encodeURI(modifiedCsv);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "marketing_export.csv");
      document.body.appendChild(link);
      link.click();
      this.excelExporting = false;
    },
  },
};
</script>

<style></style>
