<template>
  <v-card :loading="!loaded">
    <v-container class="pa-5">
      <v-form ref="form" v-model="valid" lazy-validation v-if="loaded">
        <v-row>
          <v-col md="6">
            <v-autocomplete
              :items="contactMomentStatusesList"
              item-text="name"
              item-value="id"
              required
              :rules="[(v) => !!v || 'Type is vereist']"
              v-model="formDataFields.type"
              label="Contact Moment Type"
              persistent-hint
              hint="Wat voor soort contact moment"
            ></v-autocomplete>
          </v-col>
          <v-col md="6">
            <v-autocomplete
              :items="vacanciesList"
              item-text="name"
              item-value="id"
              v-model="formDataFields.vacancy_id"
              label="Vacature"
              persistent-hint
              hint="Ging dit moment over een vacature?"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <v-select
              v-model="formDataFields.contact_people"
              :items="contactPeopleList"
              :item-text="fullName"
              item-value="id"
              required
              :rules="
                [(v) => !!v || 'Type is vereist'] && [
                  (v) => v.length > 0 || 'Type is vereist',
                ]
              "
              label="Contactpersonen"
              persistent-hint
              hint="Bedrijf contact persoon toevoegen"
              multiple
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ fullName(item) }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text caption">
                  (+{{ formDataFields.contact_people.length - 1 }} others)
                </span>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <v-select
              v-model="formDataFields.employees"
              :items="employeesList"
              :item-text="fullName"
              item-value="id"
              label="Personeel"
              persistent-hint
              hint="Personeel dat meedoet in het contact moment"
              multiple
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ fullName(item) }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text caption">
                  (+{{ formDataFields.employees.length - 1 }} others)
                </span>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row class="ml-1 mr-1">
          <DateTimePicker v-model="data_fields.createdAt"></DateTimePicker>
        </v-row>
        <v-row class="pb-0 mb-n8">
          <v-col md="12">
            <h5>Notities</h5>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <quillEditor v-model="data_fields.notes" :options="editorOption" />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-btn
            :disabled="!valid || adding"
            color="success"
            class="mr-4"
            @click="buttonClick"
          >
            {{ types[type].buttonText }}
          </v-btn>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import DateTimePicker from "./DateTimePicker.vue";

export default {
  components: {
    quillEditor,
    DateTimePicker,
  },
  props: {
    type: String,
    formData: Object,
    parentID: Number,
  },
  data: () => ({
    loaded: false,
    contactMomentStatusesList: [],
    contactPeopleList: [],
    something: {},
    employeesList: [],
    vacanciesList: [],
    adding: false,
    valid: false,
    data_fields_status: {
      lead_status_id: null,
    },
    data_fields: {
      contact_people: [],
      employees: [],
      company_id: null,
      type: null,
      comunicator_id: null,
      user_status_id: null,
      notes: null,
      vacancy_id: null,
      createdAt: null,
    },
    content: "",
    editorOption: {
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          [{ header: 1 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ color: [] }, { background: [] }],
        ],
      },
    },
    types: [
      {
        buttonText: "Contact Moment Aanmaken",
      },
      {
        buttonText: "Contact Moment Aanpassen",
      },
    ],
  }),

  computed: {
    formDataFields() {
      this.updateFormFields();
      return this.data_fields;
    },
  },

  created() {
    if (this.type == 1) {
      this.data_fields = this.formData;
      this.userSwitch = true;
      this.editMode = true;
    }
    this.initialize();
  },

  methods: {
    fullName(item) {
      return item.first_name + " " + item.last_name;
    },

    async initialize() {
      const { dispatch } = this.$store;
      await dispatch("vacancy/getVacanciesByCompanyId", this.parentID).then(
        (res) => {
          this.vacanciesList = res.vacancies.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        }
      );
      await dispatch("contact/getContacts", this.parentID).then((res) => {
        this.contactPeopleList = res.contact_persons.sort((a, b) => {
          if (a.first_name < b.first_name) {
            return -1;
          }
          if (a.first_name > b.first_name) {
            return 1;
          }
          return 0;
        });
      });
      await dispatch("users/getAll").then((res) => {
        this.employeesList = res.employees.sort((a, b) => {
          if (a.first_name < b.first_name) {
            return -1;
          }
          if (a.first_name > b.first_name) {
            return 1;
          }
          return 0;
        });
      });
      await dispatch("statuses/getAllContactMomentStatus").then((res) => {
        this.contactMomentStatusesList = res.contact_moment_statuses;
      });
      this.loaded = true;
    },

    updateFormFields() {
      if (this.type == "1") {
        this.data_fields = this.formData;
        let employees = JSON.parse(JSON.stringify(this.formData.employees)); // Make copy of object
        this.data_fields.employees = [];
        for (let i = 0; i < employees.length; i++) {
          this.data_fields.employees.push(employees[i].id);
        }
      } else {
        this.data_fields = {
          contact_people: [],
          employees: [],
          company_id: null,
          type: null,
          comunicator_id: null,
          user_status_id: null,
          notes: null,
          vacancy_id: null,
          createdAt: null,
        };
      }
    },

    buttonClick() {
      if (this.type == 0) {
        this.createNewCompanyContactMoment();
      } else if (this.type == 1) {
        this.editCompanyContactMoment();
      }
    },

    async editCompanyContactMoment() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        this.adding = true;
        let contact_moment = {
          type: this.data_fields.type,
          notes: this.data_fields.notes,
          vacancy_id: this.data_fields.vacancy_id,
          contact_people: this.data_fields.contact_people,
          employees: this.data_fields.employees,
          createdAt: this.data_fields.createdAt,
        };
        let contact_momentId = this.formData.id;
        if (this.$store.state.users.user.role <= 2) {
          await dispatch("contact_moments/updateContactMoment", {
            contact_moment,
            contact_momentId,
          }).then((contact_moment) => {
            dispatch("alerts/success", "Contact moment aangepast");
            dispatch("company/getCompany", this.parentID);
          });
          this.$emit("close-dialog");
        } else {
          dispatch("alerts/warn", "Je bent niet bevoegd om dit te doen");
        }
      } else {
        dispatch("alerts/warn", "Niet alle velden zijn ingevuld");
      }
      this.adding = false;
    },

    async createNewCompanyContactMoment() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        if (this.$store.state.users.user.role <= 4) {
          this.adding = true;
          let newContactMoment = {
            type: this.data_fields.type,
            company_id: this.parentID,
            comunicator_id: this.$store.state.users.user.id,
            user_status_id: null,
            notes: this.data_fields.notes,
            vacancy_id: this.data_fields.vacancy_id,
            contact_people: this.data_fields.contact_people,
            employees: this.data_fields.employees,
            createdAt: this.data_fields.createdAt,
          };

          await dispatch(
            "contact_moments/createContactMoment",
            newContactMoment
          ).then((contact_moment) => {
            dispatch("alerts/success", "Contact moment aangemaakt");
            dispatch("company/getCompany", newContactMoment.company_id);
            this.clear();
            this.$emit("close-dialog");
          });
        } else {
          dispatch("alerts/warn", "Je bent niet bevoegd om dit te doen");
        }
      } else {
        dispatch("alerts/warn", "Niet alle verplichte velden zijn ingevuld");
      }
      this.adding = false;
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped></style>
