<template>
  <v-container fill-height class="ma-0">
    <v-dialog v-model="dialogParsedCv" width="700" min-height="700">
      <v-tabs>
        <v-tab>
          Parsed CV
        </v-tab>
        <v-tab>
          Text
        </v-tab>
        <v-tab-item>
          <v-card style="width:700px; min-height:700px;">
            <ParsedCvViewer
              v-on:close-dialog="dialogParsedCv = false"
              :parsedCv="parsedCv"
            ></ParsedCvViewer>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card style="width:700px; min-height:700px;">
            <v-textarea
              class="pa-4"
              rows="25"
              filled
              name="input-7-1"
              :value="textCv"
              readonly
            ></v-textarea>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Parse Resume?
        </v-card-title>
        <v-card-text
          >Parsing is only for resumes and it will overwrite previously parsed
          ones</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            No
          </v-btn>
          <v-btn color="green darken-1" text @click="parseDocument()">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogFileUploader" max-width="600px">
      <v-card>
        <FileUploader
          v-on:close-dialog="dialogFileUploader = false"
          :parentID="parentID"
          :type="type"
        ></FileUploader>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col class="pa-0">
        <v-card min-height="380">
          <v-row :style="cardHeaders" class="ma-0">
            <v-row class="pa-5">
              <v-col md="7">
                <v-row>
                  <v-icon medium>mdi-file-document</v-icon>
                  <h3><strong>Documenten</strong></h3>
                </v-row>
              </v-col>
              <v-col md="3" class="pa-0">
                <v-btn
                  depressed
                  small
                  color="primary"
                  @click="previewCv"
                  :disabled="parsedCv === null"
                >
                  Preview CV
                </v-btn>
              </v-col>
              <v-col md="2" align="end" class="pa-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      medium
                      @click="uploadDocument()"
                      >mdi-plus-box</v-icon
                    >
                  </template>
                  <span>Document uploaden</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-row>
          <v-card class="pa-2 overflow-y-auto" max-height="335" elevation="0">
            <v-container class="pl-6 pt-6">
              <v-row
                class="pa-0"
                v-for="document in documents"
                :key="document.id"
              >
                <v-container class="mb-2 pb-0">
                  <v-row class="mb-5">
                    <v-col md="2" class="pa-0">
                      <p style="margin-bottom: 0">
                        {{ shortDate(document.createdAt) }}
                      </p>
                    </v-col>
                    <v-col md="5" class="pa-0 pr-2">
                      <h4>{{ document.title }}</h4>
                    </v-col>
                    <v-col md="3" class="pa-0">
                      <h4>{{ shortFileName(document.file) }}</h4>
                    </v-col>
                    <v-col md="2" class="pr-6 pb-0">
                      <v-row justify="end">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              medium
                              color="green"
                              @click="downloadDocument(document)"
                              >mdi-arrow-down-bold-box</v-icon
                            >
                          </template>
                          <span>Download</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              medium
                              color="red"
                              @click="deleteDocument(document)"
                              >mdi-trash-can</v-icon
                            >
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                      </v-row>
                      <v-row justify="end">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              medium
                              :color="parsedCv !== null ? 'red' : 'blue'"
                              @click="openDialog(document)"
                              >mdi-file-replace</v-icon
                            >
                          </template>
                          <span>Parse</span>
                        </v-tooltip>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-divider v-if="lastItem(document.id)"></v-divider>
                </v-container>
              </v-row>
            </v-container>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FileUploader from "../components/dialogs/FileUploader.vue";
import ParsedCvViewer from "../components/dialogs/ParsedCvViewer.vue";
import { mapActions } from "vuex";
export default {
  components: {
    FileUploader,
    ParsedCvViewer,
  },
  props: {
    parentID: Number,
    type: Number, //1 = Lead User Upload | 2 = Company Upload
    documents: {},
    parsedCv: {},
    textCv: String,
  },

  data() {
    return {
      dialogFileUploader: false,
      dialogParsedCv: false,
      dialog: false,
      selectedDocument: null,
      leadUser: null,
    };
  },
  computed: {
    cardHeaders() {
      if (this.$vuetify.theme.dark) {
        return "background: #2a2a2a";
      } else {
        return "background: #b3b3b3";
      }
    },
  },
  created() {},
  methods: {
    ...mapActions("utility", ["parseResume"]),
    shortDate(document) {
      return document.substring(0, 10);
    },
    shortFileName(document) {
      return document.substring(14);
    },
    lastItem(document) {
      if (this.documents[this.documents.length - 1].id == document) {
        return false;
      }
      return true;
    },
    uploadDocument() {
      this.dialogFileUploader = true;
    },
    previewCv() {
      this.dialogParsedCv = true;
    },
    async downloadDocument(document) {
      const { dispatch } = this.$store;
      await dispatch("file_upload/downloadDocument", document.id);
    },
    async parseDocument() {
      const { dispatch } = this.$store;
      this.parseResume({
        docId: this.selectedDocument.id,
        docUserId: this.selectedDocument.lead_user_id,
      }).then(() => {
        dispatch("lead_users/getLeadUser", this.$route.params.id);
      });
      this.dialog = false;
    },
    openDialog(document) {
      this.selectedDocument = document;
      this.dialog = true;
    },
    deleteDocument(document) {
      const { dispatch } = this.$store;
      dispatch(
        "alerts/warn",
        "Het is nog niet mogelijk om documenten te verwijderen"
      );
    },
  },
};
</script>
