var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-autocomplete',{attrs:{"items":_vm.languages,"chips":"","hide-details":"","hide-selected":"","item-text":"name","item-value":"symbol","label":"Talen","multiple":""},on:{"change":_vm.newLanguages},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"input-value":selected,"close":""},on:{"click:close":function($event){return _vm.remove(item)}}},'v-chip',attr,false),on),[_c('CountryFlag',{attrs:{"country":item.flag}}),_c('span',{staticStyle:{"padding-left":"5px"},domProps:{"textContent":_vm._s(item.name)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',[_c('CountryFlag',{attrs:{"country":item.flag}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.code)}})],1)]}}]),model:{value:(_vm.computedLanguages),callback:function ($$v) {_vm.computedLanguages=$$v},expression:"computedLanguages"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }