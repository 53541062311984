import { leadUserService } from "../../services/lead_users.service";
import axios from "axios";
import config from "../../../config";
import { authHeader } from "../../utils/auth-header";

export const lead_users /* candidates */ = {
  namespaced: true,
  state: {
    lead_users: {},
    filtered_lead_user: {},
    active_lead_user: {},
    lead_users_with_lead: {},
    filter: {},
    pagination: {},
    loading: false,
    displayData: true,
  },
  getters: {
    getLoading: (state) => state.loading,
    getDisplayData: (state) => state.displayData,
    getFilteredLeadUser: (state) => state.filtered_lead_user,
  },
  actions: {
    createLeadUser({ dispatch, commit }, lead_user) {
      return new Promise((resolve, reject) => {
        leadUserService
          .createLeadUser(lead_user)
          .then((lead_user) => resolve(lead_user));
      });
    },
    updateLeadUser({ dispatch, commit }, { lead_user, userId }) {
      return new Promise((resolve, reject) => {
        leadUserService
          .updateLeadUser(lead_user, userId)
          .then((lead_user) => resolve(lead_user));
      });
    },
    getAllLeadUsers({ commit }) {
      return new Promise((resolve, reject) => {
        leadUserService.getAllLeadUsers().then((lead_users) => {
          commit("getAllLeadUsersSuccess", lead_users);
          resolve(lead_users);
        });
      });
    },

    getAllLeadUsersWithLead({ commit }) {
      return new Promise((resolve, reject) => {
        leadUserService.getAllLeadUsersWithLead().then((lead_users) => {
          commit("getAllLeadUsersWithLeadSuccess", lead_users);
          resolve(lead_users);
        });
      });
    },

    async searchLeadUsersWithLeads({ dispatch, commit }, searchData) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(`${config.apiUrl}/lead-users/search/${searchData}`, request)
          .then(handleResponse)
          .then((data) => {
            commit("getFoundLeadUser", data);
          });
      } catch (error) {
        console.log("error");
      }
    },

    async setLoadingState({ commit }, bool) {
      try {
        commit("setLoading", bool);
      } catch (error) {
        console.log("error");
      }
    },

    async setDisplayDataState({ commit }, bool) {
      try {
        commit("setDisplayData", bool);
      } catch (error) {
        console.log("error");
      }
    },

    async getLeadUsers({ dispatch, commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(`${config.apiUrl}/lead-users/getAllLeadUsers`, request)
          .then(handleResponse)
          .then((data) => {
            commit("getFoundLeadUser", data);
          });
      } catch (error) {
        console.log("error");
      }
    },

    getLeadUser({ commit }, userId) {
      return new Promise((resolve, reject) => {
        leadUserService.getLeadUser(userId).then((lead_user) => {
          lead_user.lead_user.languages = JSON.parse(
            lead_user.lead_user.languages
          );
          lead_user.lead_user.address = JSON.parse(lead_user.lead_user.address);
          lead_user.lead_user.parsedCv = JSON.parse(
            lead_user.lead_user.parsedCv
          );
          commit("getLeadUserSuccess", lead_user);
          resolve(lead_user);
        });
      });
    },

    addStatusToLeadUser({ dispatch, commit }, leadUserStatus) {
      return new Promise((resolve, reject) => {
        leadUserService
          .addStatusToLeadUser(leadUserStatus)
          .then((leadUserStatus) => resolve(leadUserStatus));
      });
    },

    updatePagination({ commit }, pagination) {
      commit("setPaginationState", pagination);
    },
  },
  mutations: {
    getAllLeadUsersSuccess(state, items) {
      state.lead_users = items.lead_users;
    },
    getLeadUserSuccess(state, items) {
      state.active_lead_user = items.lead_user;
    },
    getAllLeadUsersWithLeadSuccess(state, items) {
      state.lead_users_with_lead = items.lead_users;
    },
    getFoundLeadUser(state, data) {
      state.filtered_lead_user = data.lead_users;
    },
    setPaginationState(state, pagination) {
      state.pagination = pagination;
    },
    setLoading(state, bool) {
      state.loading = bool;
    },
    setDisplayData(state, bool) {
      state.displayData = bool;
    },
  },
};

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
