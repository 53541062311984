<template>
  <v-container class="pa-5">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-select
        :items="platforms"
        label="DOEN / Zoomer"
        v-model="data_fields.platform_name"
        :rules="[(v) => !!v || 'Veld is vereist']"
        required
      ></v-select>
      <v-row>
        <v-col>
          <v-text-field
            v-model="data_fields.name"
            :rules="[(v) => !!v || 'Naam is vereist']"
            label="Naam"
            required
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
            v-model="data_fields.kvk"
            :rules="[(v) => !!v || 'KVK-nummer is vereist']"
            label="KVK-nummer"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-text-field
        v-model="data_fields.phone"
        :rules="[(v) => !!v || 'Telefoonnummer is vereist']"
        label="Telefoonnummer"
        required
      ></v-text-field>

      <v-text-field
        v-model="data_fields.street"
        :rules="[(v) => !!v || 'Straatnaam is vereist']"
        label="Straatnaam"
        required
      ></v-text-field>

      <v-row>
        <v-col>
          <v-text-field
            v-model="data_fields.postalcode"
            :rules="[(v) => !!v || 'Postcode is vereist']"
            label="Postcode"
            required
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
            v-model="data_fields.houseno"
            :rules="[(v) => !!v || 'Huisnummer is vereist']"
            label="Huisnummer"
            required
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
            v-model="data_fields.addition"
            label="Toevoeging"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-text-field
        v-model="data_fields.region"
        :rules="[(v) => !!v || 'Plaats is vereist']"
        label="Plaats"
        required
      ></v-text-field>

      <v-text-field
        v-model="data_fields.site_url"
        label="Website"
      ></v-text-field>

      <v-textarea
        outlined
        v-model="data_fields.description"
        label="Omschrijving"
        auto-grow
      ></v-textarea>
      <v-row>
        <h3 style="padding-left: 10px">
          Mogen de volgende zaken gebruikt worden voor werving?
        </h3>
      </v-row>
      <v-row>
        <v-switch
          v-model="data_fields.name_use"
          style="padding-left: 10px"
          label="Bedrijfsnaam"
        ></v-switch>
        <v-switch
          v-model="data_fields.logo_use"
          style="padding-left: 10px"
          label="Logo"
        ></v-switch>
        <v-switch
          v-model="data_fields.photo_use"
          style="padding-left: 10px"
          label="Foto's"
        ></v-switch>
      </v-row>
      <v-row justify="center">
        <v-btn
          :disabled="!valid && !adding"
          color="success"
          class="mr-4"
          @click="buttonClick"
        >
          {{ types[type].buttonText }}
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { calculateLocation } from "../../helpers/google";
export default {
  props: {
    type: String,
    formData: Object,
  },
  data: () => ({
    platforms: ["DOEN", "Zoomer"],
    adding: false,
    valid: true,
    data_fields: {
      platform_name: "",
      name: "",
      kvk: "",
      phone: "",
      street: "",
      postalcode: "",
      houseno: "",
      addition: "",
      region: "",
      site_url: "",
      description: "",
      name_use: false,
      logo_use: false,
      photo_use: false,
    },
    types: [
      {
        buttonText: "Bedrijf Aanmaken",
      },
      {
        buttonText: "Bedrijf Aanpassen",
      },
    ],
  }),

  created() {
    if (this.type == 1) {
      this.data_fields = this.formData;
      this.data_fields.street = this.formData.address.street;
      this.data_fields.postalcode = this.formData.address.postalcode;
      this.data_fields.houseno = this.formData.address.houseno;
      this.data_fields.addition = this.formData.address.addition;
    }
  },

  methods: {
    buttonClick() {
      if (this.type == 0) {
        this.addCompany();
      } else if (this.type == 1) {
        this.editCompany();
      }
    },

    async editCompany() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        if (this.$store.state.users.user.role <= 2) {
          this.adding = true;

          let company = {
            platform_name: this.data_fields.platform_name,
            kvk: this.data_fields.kvk,
            name: this.data_fields.name,
            phone: this.data_fields.phone,
            address: {
              street: this.data_fields.street,
              postalcode: this.data_fields.postalcode,
              houseno: this.data_fields.houseno,
              addition: this.data_fields.addition,
              region: this.data_fields.region,
            },
            region: this.data_fields.region,
            site_url: this.data_fields.site_url,
            name_use: this.data_fields.name_use,
            logo_use: this.data_fields.logo_use,
            photo_use: this.data_fields.photo_use,
            description: this.data_fields.description,
          };

          await calculateLocation(company.address).then((res) => {
            company.address.lat = res.lat;
            company.address.lng = res.lng;
          });

          const compId = this.data_fields.id;

          company.address = JSON.stringify(company.address);
          await dispatch("company/updateCompany", { company, compId }).then(
            (company) => {
              dispatch("alerts/success", "Bedrijf aangepast");
              dispatch("company/getCompany", compId);
              this.adding = false;
              this.$emit("close-dialog");
            }
          );
        } else {
          dispatch("alerts/warn", "Je bent niet bevoegd om dit te doen");
        }
      } else {
        dispatch("alerts/warn", "Niet alle benodigde velden zijn ingevuld");
      }
    },

    async addCompany() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        if (this.$store.state.users.user.role <= 2) {
          this.adding = true;

          let company = {
            platform_name: this.data_fields.platform_name,
            kvk: this.data_fields.kvk,
            name: this.data_fields.name,
            phone: this.data_fields.phone,
            address: {
              street: this.data_fields.street,
              postalcode: this.data_fields.postalcode,
              houseno: this.data_fields.houseno,
              addition: this.data_fields.addition,
              region: this.data_fields.region,
            },
            region: this.data_fields.region,
            site_url: this.data_fields.site_url,
            name_use: this.data_fields.name_use,
            logo_use: this.data_fields.logo_use,
            photo_use: this.data_fields.photo_use,
            description: this.data_fields.description,
          };

          await calculateLocation(company.address).then((res) => {
            company.address.lat = res.lat;
            company.address.lng = res.lng;
          });

          company.address = JSON.stringify(company.address);
          await dispatch("company/createCompany", company).then((company) => {
            dispatch("company/getAllCompanies");
            dispatch("alerts/success", "Bedrijf aangemaakt");
          });
          this.adding = false;
          this.clear();
          this.$emit("close-dialog");
        } else {
          dispatch("alerts/warn", "Je bent niet bevoegd om dit te doen");
        }
      } else {
        dispatch("alerts/warn", "Niet alle benodigde velden zijn ingevuld");
      }
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>
