import Vue from 'vue'
import { authHeader } from '../utils/auth-header';
import config from '../../config';

export const userService = {
    login,
    logout,
    getAll,
    getSelf,
    createEmployee,
    updateEmployee
};

function login(email, password, twofa) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, twofa })
    };
    return fetch(`${config.apiUrl}/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('token', JSON.stringify(user.token));
            }

            return user;
        });
}

function getSelf(oAuthID) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}/employees/self/` + oAuthID, requestOptions).then(handleResponse).then(user => {
        addGroupsToStorage(user.employee.Notification_Groups)
        return user.employee;
    });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
    localStorage.removeItem('user');
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}/employees`, requestOptions).then(handleResponse);
}

async function createEmployee(employee) {
    const request = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(employee)
    }
    return fetch(`${config.apiUrl}/employees`, request)
        .then(handleResponse)
        .then(employee => {
            return employee;
        });
}

function updateEmployee(employee, empId) {
    const request = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(employee)
    }
    return fetch(`${config.apiUrl}/employees/${empId}`, request)
        .then(handleResponse)
        .then(employee => {
            return employee;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }
            const error = (data) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function addGroupsToStorage(groups){
    var groupArr = []
    if(groups){
        for (const group of groups){
            groupArr.push(group.name)
        }
    }
    localStorage.setItem('groups', JSON.stringify(groupArr));
}