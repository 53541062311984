<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <SubTable
          v-if="positionsLoaded"
          type="1"
          v-bind:data="positionsItems.positions.positions"
        ></SubTable>
        <SubTable
          v-if="leadStatusesLoaded && authorized"
          type="3"
          v-bind:data="statusItems.lead_statuses"
        ></SubTable>
        <SubTable
          v-if="getLoadingStates.roles && authorized"
          type="7"
          v-bind:data="roles"
        ></SubTable>
      </v-col>
      <v-col>
        <SubTable
          v-if="employeesLoaded && authorized"
          type="2"
          v-bind:data="employeeItems.all.items.employees"
        ></SubTable>
        <SubTable
          v-if="contactMomentStatusesLoaded && authorized"
          type="4"
          v-bind:data="statusItems.contact_moment_statuses"
        ></SubTable>
        <SubTable
          v-if="getLoadingStates.departments && authorized"
          type="8"
          v-bind:data="departments"
        ></SubTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import SubTable from "../components/SubTables.vue";
export default {
  components: { SubTable },
  data: () => ({
    adding: false,
    positionsLoaded: false,
    employeesLoaded: false,
    contactMomentStatusesLoaded: false,
    leadStatusesLoaded: false,
    interestsLoaded: false,
    valid: true,
    positionSelect: "",
  }),

  created() {
    this.initialize();
  },

  computed: {
    ...mapState({
      positionsItems: (state) => state.positions,

      employeeItems: (state) => state.users,

      statusItems: (state) => state.statuses,
    }),
    ...mapGetters("interests", ["getLoadingStates", "departments", "roles"]),
    authorized() {
      if (this.$store.state.users.user.role == 0) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    ...mapActions("positions", ["getAllPositions"]),
    ...mapActions("users", ["getAll"]),
    ...mapActions("statuses", [
      "getAllContactMomentStatus",
      "getAllLeadStatus",
    ]),
    ...mapActions("interests", ["getRoles", "getDepartments"]),
    async initialize() {
      await this.getAllPositions().then((res) => {
        this.positionsLoaded = true;
      });
      await this.getAll().then((res) => {
        this.employeesLoaded = true;
      });
      await this.getAllContactMomentStatus().then((res) => {
        this.contactMomentStatusesLoaded = true;
      });
      await this.getAllLeadStatus().then((res) => {
        this.leadStatusesLoaded = true;
      });
      await this.getRoles();
      await this.getDepartments();
    },
  },
};
</script>
