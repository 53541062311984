import config from '../../config';
import { authHeader } from '../utils/auth-header';

export const interestsService = {
    getInterests,
    getInterestsForLeadUser,
    getInterestsForJob,
    createInterest,
    updateInterest
}

function getInterests() {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/interests`, request)
    .then(handleResponse)
    .then(interests => {
        return interests;
    });
}

function getInterestsForLeadUser(leadUserId) {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/interests/leaduser/${leadUserId}`, request)
        .then(handleResponse)
        .then(interests => {
            return interests;
        });
}

function getInterestsForJob(jobId) {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/interests/job/${jobId}`, request)
        .then(handleResponse)
        .then(interests => {
            return interests;
        });
}

function createInterest(interest) {
    const request = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(interest)
    }
    return fetch(`${config.apiUrl}/interests`, request)
        .then(handleResponse)
        .then(interest => {
            return interest;
        });
}

function updateInterest(interest, interestId) {
    const request = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(interest)
    }
    return fetch(`${config.apiUrl}/interests/${interestId}`, request)
        .then(handleResponse)
        .then(interest => {
            return interest;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}