<template>
  <v-container fluid class="pa-5">
    <v-card class="pa-1" elevation="0">
      <v-row>
        <v-col md="8">
          <v-row class="pa-3">
            <h2>Recommended</h2>
            <v-divider
              vertical
              inset
              style="padding-left: 10px; padding-left: 10px"
            ></v-divider>
            <v-text-field
              style="padding-top: 0px; padding-left: 10px"
              v-model="filters.search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @keyup.enter="deepSearch(filters.search)"
              :loading="disableSearchBtn"
            ></v-text-field>
            <v-btn
              :disabled="disableSearchBtn"
              @click="deepSearch(filters.search)"
              color="primary"
              class="ml-4"
            >
              Zoek
            </v-btn>
          </v-row>
        </v-col>
        <v-col align="end" md="4">
          <v-btn icon color="primary" class="mb-2" @click="clear()">
            <v-icon dark>mdi-autorenew</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="pb-0 px-2">
        <v-col class="d-flex" cols="12" sm="6">
          <v-autocomplete
            dense
            class="pt-1"
            :items="this.lead_statuses"
            v-model="filters.statuses"
            chips
            deletable-chips
            multiple
            item-text="name"
            item-value="name"
            return-object
            label="Status"
            ><template v-slot:selection="{ attr, on, item, selected }">
              <v-chip
                v-bind="attr"
                :input-value="selected"
                v-on="on"
                close
                :color="item.color"
                @click:close="remove(item)"
                ><div style="color: black">
                  <strong>{{ item.name }}</strong>
                </div></v-chip
              >
            </template></v-autocomplete
          >
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-autocomplete
            :items="this.booleans"
            v-model="filters.has_car"
            clearable
            item-text="text"
            item-value="value"
            label="Car"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row class="pb-0 px-2">
        <v-col class="d-flex" cols="12" sm="6">
          <v-autocomplete
            :items="this.booleans"
            v-model="filters.has_license"
            clearable
            item-text="text"
            item-value="value"
            label="Rijbewijs"
          ></v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-autocomplete
            dense
            class="pt-1"
            :items="this.genderSelect"
            v-model="filters.gender"
            chips
            deletable-chips
            multiple
            return-object
            label="Gender"
            ><template v-slot:selection="{ attr, on, item, selected }">
              <v-chip
                v-bind="attr"
                :input-value="selected"
                v-on="on"
                close
                @click:close="removeGender(item)"
                ><div style="color: black">
                  <strong>{{ item }}</strong>
                </div></v-chip
              >
            </template></v-autocomplete
          >
        </v-col>
      </v-row>

      <v-row class="pb-0 px-2">
        <v-col class="d-flex" cols="12" sm="2">
          <v-subheader style="">Age Range:</v-subheader>
        </v-col>
        <v-col class="d-flex" cols="12" sm="10">
          <v-range-slider
            v-model="filters.range"
            thumb-label
            style="margin-bottom: 10px !important"
            :max="max"
            :min="min"
            hide-details
          >
            <template v-slot:prepend>
              <v-text-field
                :value="filters.range[0]"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
                @change="$set(filters.range, 0, $event)"
              ></v-text-field>
            </template>
            <template v-slot:append>
              <v-text-field
                :value="filters.range[1]"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
                @change="$set(filters.range, 1, $event)"
              ></v-text-field>
              <v-btn
                class="ml-3"
                style="margin-top: -5px !important;"
                depressed
                color="primary"
                @click="save()"
                >Save</v-btn
              >
            </template>
          </v-range-slider>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { calculateDistance } from "../../helpers/google";
export default {
  components: {},
  props: {
    parentID: Number,
    searchFilters: Object,
    leadStatuses: Array,
  },
  data: () => ({
    disableSearchBtn: false,
    min: 10,
    max: 70,

    filters: {
      statuses: [],
      // channels: [],
      has_car: null,
      has_license: null,
      gender: [],
      range: [10, 70],
      search: "",
      // recruiter: "",
      // recruiter_ass: "",
    },
    lead_statuses: [],
    booleans: [
      {
        text: "Yes",
        value: true,
      },
      {
        text: "No",
        value: false,
      },
    ],
    radius: [
      {
        distance: "2 km",
        value: 2000,
      },
      {
        distance: "5 km",
        value: 5000,
      },
      {
        distance: "10 km",
        value: 10000,
      },
      {
        distance: "20 km",
        value: 20000,
      },
      {
        distance: "50 km",
        value: 50000,
      },
      {
        distance: "100 km",
        value: 100000,
      },
      {
        distance: "200 km",
        value: 200000,
      },
    ],

    genderSelect: ["Man", "Vrouw", "Transgender", "Non-binair"],
  }),

  computed: {
    ...mapGetters("lead_users", [
      "getLoading",
      "getDisplayData",
      "getFilteredLeadUser",
    ]),
    ...mapGetters("statuses", ["getLeadStatuses"]),
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.filters = this.searchFilters;
      this.lead_statuses = this.leadStatuses;
    },
    async setLoading(bool) {
      const { dispatch } = this.$store;
      await dispatch("lead_users/setLoadingState", bool);
    },
    async setDisplayData(bool) {
      const { dispatch } = this.$store;
      await dispatch("lead_users/setDisplayDataState", bool);
    },
    remove(item) {
      let index = -1;
      this.filters.statuses.find(function(status, i) {
        if (status.name === item.name) {
          index = i;
        }
      });
      if (index >= 0) {
        this.filters.statuses.splice(index, 1);
      }
    },
    removeGender(item) {
      let index = -1;
      this.filters.gender.find(function(gender, i) {
        if (gender === item) {
          index = i;
        }
      });
      if (index >= 0) {
        this.filters.gender.splice(index, 1);
      }
    },
    save() {
      this.saveFilter();
      this.setLoading(true);
      this.setDisplayData(true);
    },
    async saveFilter() {
      const { dispatch } = this.$store;

      let id = this.$route.params.id;
      let searchTerms = JSON.parse(JSON.stringify(this.filters));
      await dispatch("vacancy/updateVacancySearchTerms", { searchTerms, id });
    },
    async deepSearch(value) {
      this.disableSearchBtn = true;
      this.setLoading(false);
      if (value !== "") {
        await this.$store.dispatch(
          "lead_users/searchLeadUsersWithLeads",
          value
        );
        this.setLoading(true);
      } else {
        if (Object.keys(this.getFilteredLeadUser).length === 0) {
          await this.$store.dispatch("lead_users/getLeadUsers");
        }

        this.setLoading(false);
      }
      this.saveFilter();
      this.setDisplayData(true);
      this.disableSearchBtn = false;
    },
    async clear() {
      const { dispatch } = this.$store;

      this.filters.statuses = [];
      this.filters.has_car = null;
      this.filters.has_license = null;
      this.filters.gender = [];
      this.filters.range = [10, 70];
      this.filters.search = "";
      if (Object.keys(this.getFilteredLeadUser).length === 0) {
        await dispatch("lead_users/getLeadUsers");
      }
      this.saveFilter();
      this.setLoading(true);
      this.setDisplayData(false);
    },
  },
};
</script>
