import config from '../../config';
import { authHeader } from '../utils/auth-header';
export const contactService = {
    getContactsWithCompanyId,
    createContact,
    updateContact,
};

function getContactsWithCompanyId(id) {
    const request = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}/contact-people/company/${id}`, request)
        .then(handleResponse)
        .then(contacts => {
            return contacts;
        });
}

function createContact(contact) {
    const request = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(contact)
    }
    return fetch(`${config.apiUrl}/contact-people`, request)
        .then(handleResponse)
        .then(contact => {
            return contact;
        });
}

function updateContact(contact, id) {
    const request = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(contact)
    }
    return fetch(`${config.apiUrl}/contact-people/${id}`, request)
        .then(handleResponse)
        .then(contact => {
            return contact;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}