import config from '../../config';
import { authHeader } from '../utils/auth-header';

export const rolesService = {
    getRoles,
    getRoleById,
    createRole,
    updateRole
}

function getRoles() {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/roles`, request)
        .then(handleResponse)
        .then(roles => {
            return roles;
        });
}

function getRoleById(roleId) {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/roles/${roleId}`, request)
        .then(handleResponse)
        .then(role => {
            return role;
        });
}

function createRole(role) {
    const request = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(role)
    }
    return fetch(`${config.apiUrl}/roles`, request)
        .then(handleResponse)
        .then(role => {
            return role;
        });
}

function updateRole(role, roleId) {
    const request = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(role)
    }
    return fetch(`${config.apiUrl}/roles/${roleId}`, request)
        .then(handleResponse)
        .then(role => {
            return role;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}