import config from "../../config";
import axios from "axios";
import gmapsInit from '../utils/gmaps';

export function incompleteAddress(address) {
  const { addition, ...rest } = address;
  const location = Object.values(rest);
  return location.some((e) => e === "");
}

export async function calculateLocation(address) {
  var useAddress = "";
  if (
    address.addition &&
    address.street &&
    address.houseno &&
    address.postalcode &&
    address.region
  ) {
    useAddress = `${address.street}+${address.houseno}${address.addition}+${address.postalcode}+${address.region}`;
  }

  if (
    address.street &&
    address.houseno &&
    address.postalcode &&
    address.region
  ) {
    useAddress = `${address.street}+${address.houseno}+${address.postalcode}+${address.region}`;
  }

  if (useAddress) {
    const { data } = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${useAddress}&key=${config.mapsKey}`
    );
    return {
      lat: data.results[0].geometry.location.lat,
      lng: data.results[0].geometry.location.lng,
    };
  }
  return { lat: 0, lng: 0 };
}

export async function calculateDistance(userAdress, cityLat, cityLng) {
  try {
    if(!userAdress){
      return false
    }
    const google = await gmapsInit();
    var destination = new google.maps.LatLng(cityLat, cityLng)
    let lat = 0;
    let lng = 0;
    if (!userAdress.lat) {
      await calculateLocation(userAdress).then((res) => {
        lat = res.lat;
        lng = res.lng;
      });
    } else {
      lat = userAdress.lat;
      lng = userAdress.lng;
    }
    var origin
    if(lat == 0){
      if(userAdress.region != null && userAdress.region != "" ){
        origin = userAdress.region
      }else{
        return false
      }
    }else{
      origin = new google.maps.LatLng(lat, lng)
    }
    var service = new google.maps.DistanceMatrixService();
    var res = {}
    await service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: 'DRIVING',
      },
      (results, status) => {
        if (status !== 'OK') {
          throw new Error(status);
        }
        res = results
      }
    );
    return res
  } catch (error) {
    console.error(error);
  }
}