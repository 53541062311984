<template>
  <v-container fluid>
    <v-dialog v-model="dialogColumns" max-width="600px">
      <SelectColumns
        :headers="headers"
        type="Search"
        v-on:headers-changed="setHeaders($event)"
        v-on:close-dialog="dialogColumns = false"
      />
    </v-dialog>
    <v-navigation-drawer :width="550" v-model="drawer" absolute temporary right>
      <v-list-item class="ma-2">
        <v-list-item-content>
          <v-list-item-title style="font-size: 1.5em">
            {{
              "ID: " +
              this.selectedLeadUser.id +
              " " +
              this.selectedLeadUser.first_name +
              " " +
              this.selectedLeadUser.last_name
            }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="mb-5"></v-divider>
      <v-tabs>
        <v-tab> Text </v-tab>
        <v-tab> Parsed CV </v-tab>

        <v-tab-item>
          <div
            style="background-color: #d64550; color: white; text-align: center"
            v-if="this.selectedLeadUser.textCv == null"
          >
            No Parsed CV Found
          </div>
          <v-card v-else class="pa-4 ma-2 elevation-0" rounded>
            <TextHighlight :queries="this.search">{{
              this.selectedLeadUser.textCv
            }}</TextHighlight>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <div
            style="background-color: #d64550; color: white; text-align: center"
            v-if="this.selectedLeadUser.parsedCv == null"
          >
            No Parsed CV Found
          </div>
          <v-card v-else style="width: 700px; min-height: 700px">
            <ParsedCvViewer
              :parsedCv="this.selectedLeadUser.parsedCv"
            ></ParsedCvViewer>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-navigation-drawer>

    <v-card class="pa-4">
      <v-row class="" style="justify-content: space-between">
        <v-col md="6">
          <v-row class="pa-3">
            <h2>Search</h2>
            <v-divider
              vertical
              inset
              style="padding-left: 10px; padding-left: 10px"
            ></v-divider>
            <v-text-field
              style="padding-top: 0px; padding-left: 10px"
              v-model="search"
              label="Search"
              single-line
              hide-details
              @keyup.enter="deepSearch(search)"
              :loading="disableSearchBtn"
            ></v-text-field>
            <v-btn
              :disabled="disableSearchBtn"
              @click="deepSearch(search)"
              color="primary"
              class="ml-4"
            >
              Zoek
            </v-btn>
            <v-btn
              :disabled="!filteredLeadUsers.length != 0 || excelExporting"
              @click="excelExport()"
              color="primary"
              class="ml-4"
            >
              Export Excel
            </v-btn>
          </v-row>
        </v-col>
        <v-col align="end" md="4">
          <v-btn
            icon
            color="primary"
            class="mb-2"
            @click="dialogColumns = true"
          >
            <v-icon dark>mdi-bookmark</v-icon>
          </v-btn>
          <v-btn icon color="primary" class="mb-2" @click="clear()">
            <v-icon dark>mdi-autorenew</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="pb-5" style="justify-content: start">
        <v-checkbox
          class="ml-3"
          v-model="filterDistance"
          @change="deepSearch(search)"
        ></v-checkbox>
        <v-col class="d-flex" cols="6" sm="3">
          <gmap-autocomplete
            disable
            :class="
              this.$vuetify.theme.dark ? 'gmapField-dark' : 'gmapField-light'
            "
            :v-model="description"
            placeholder="Input address"
            @place_changed="setPlace"
            :select-first-on-enter="true"
            :disabled="!filterDistance"
          >
          </gmap-autocomplete>

          <!-- <v-select
            dense
            :items="cities"
            v-model="selectedCity"
            item-text="name"
            item-value="coordinates"
            label="City"
            :hint="this.selectedCity != null ? 'City' : ''"
            persistent-hint
            :disabled="!filterDistance"
            solo
            @change="deepSearch(search)"
          ></v-select> -->
        </v-col>
        <v-col class="d-flex" cols="6" sm="2">
          <v-select
            dense
            v-model="selectedRadius"
            :items="radius"
            item-text="distance"
            item-value="value"
            label="Radius"
            :hint="this.selectedRadius != null ? 'Radius' : ''"
            persistent-hint
            :disabled="!filterDistance"
            solo
          ></v-select>
        </v-col>
      </v-row>
      <div class="pb-0" style="justify-content: start; margin-top: -35px">
        <div class="mb-3" v-for="(component, i) in components" :key="i">
          <v-row class="mt-0" style="width: 37%" justify="start">
            <v-btn class="mr-3 ml-2" icon color="red" @click="remove(i)">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-select
              dense
              v-model="component.selectedFilter"
              :items="component.filterBy"
              item-text="desc"
              item-value="id"
              label="Choose Filter"
              :hint="component.selectedFilter != null ? 'Filter By' : ''"
              persistent-hint
              solo
              class="mr-7"
              style="width: 20px"
              @change="filterSelect(i)"
            ></v-select>

            <v-select
              v-if="component.selectedFilter === 2"
              dense
              v-model="genderFieldValue"
              :items="genderSelect"
              solo
              class="ml-0"
              style="width: 20px"
              @input="inputChanged"
            ></v-select>

            <v-select
              v-if="component.selectedFilter === 3"
              dense
              v-model="recruiterFieldValue"
              :items="employees"
              item-text="full_name"
              item-value="id"
              solo
              class="ml-0"
              style="width: 20px"
              @input="inputChanged"
            ></v-select>

            <v-select
              v-if="component.selectedFilter === 4"
              dense
              v-model="recAssFieldValue"
              :items="employees"
              item-text="full_name"
              item-value="id"
              solo
              class="ml-0"
              style="width: 20px"
              @input="inputChanged"
            ></v-select>

            <v-range-slider
              v-if="component.selectedFilter === 1"
              v-model="range"
              :max="max"
              :min="min"
              hide-details
            >
              <template v-slot:prepend>
                <v-text-field
                  :value="range[0]"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  type="number"
                  style="width: 60px"
                  @change="$set(range, 0, $event)"
                ></v-text-field>
              </template>
              <template v-slot:append>
                <v-text-field
                  :value="range[1]"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  type="number"
                  style="width: 60px"
                  @change="$set(range, 1, $event)"
                ></v-text-field>
              </template>
            </v-range-slider>
          </v-row>
        </div>
      </div>

      <v-row class="pb-6 mt-2" style="justify-content: start">
        <v-btn
          class="mx-2 elevation-0"
          @click="add"
          small
          fab
          dark
          color="blue"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </v-row>
      <v-data-table
        v-if="leadUsersLoaded"
        :page.sync="pagination.page"
        :items-per-page="pagination.itemsPerPage"
        :footer-props="{ itemsPerPageOptions: [20, 35, 50, -1] }"
        :headers="headers"
        :items="leadsuserAdjustedList"
        item-key="id"
        sort-by="name"
        class="elevation-1 pointerOnHover"
        @click:row="editItem"
        @pagination="storePaginationToState"
        @current-items="getFiltered"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" color="blue" v-on:click.stop="openNewTab(item)">
            mdi-open-in-new
          </v-icon>
          <v-icon class="mr-2" color="blue" v-on:click.stop="openCv(item)">
            mdi-binoculars
          </v-icon>
        </template>
        <template v-slot:[`item.status_name`]="{ item }">
          <v-chip :color="item.status_color"
            ><div :style="`color: ${calculateTextColor(item.status_color)}`">
              <strong>{{ item.status_name }}</strong>
            </div></v-chip
          >
        </template>
        <template v-slot:[`item.has_car`]="{ item }">
          <v-icon :color="item.has_car ? 'green' : 'red'" medium
            >mdi-car</v-icon
          >
        </template>
        <template v-slot:[`item.rec_ass_id`]="{ item }">
          {{ employeeName(item.rec_ass_id) }}
        </template>
        <template v-slot:[`item.rec_id`]="{ item }">
          {{ employeeName(item.rec_id) }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { calculateDistance } from "../helpers/google";
import ParsedCvViewer from "../components/dialogs/ParsedCvViewer.vue";
import TextHighlight from "vue-text-highlight";
import SelectColumns from "../components/dialogs/SelectColumns.vue";
export default {
  components: {
    TextHighlight,
    ParsedCvViewer,
    SelectColumns,
  },
  data: () => ({
    leadUsersLoaded: false,
    filteredLeadUsers: [],
    excelExporting: false,
    search: "",
    disableSearchBtn: false,
    description: "",
    latLng: {
      lat: "",
      lng: "",
    },
    components: [],
    ageSelect: [...Array(100).keys()],
    genderSelect: ["Man", "Vrouw", "Transgender", "Non-binair"],

    genderFieldValue: "",
    recruiterFieldValue: "",
    recAssFieldValue: "",
    min: 10,
    max: 70,
    range: [10, 70],

    filterDistance: false,

    selectedRadius: null,
    radius: [
      {
        distance: "2 km",
        value: 2000,
      },
      {
        distance: "5 km",
        value: 5000,
      },
      {
        distance: "10 km",
        value: 10000,
      },
      {
        distance: "20 km",
        value: 20000,
      },
      {
        distance: "50 km",
        value: 50000,
      },
      {
        distance: "100 km",
        value: 100000,
      },
      {
        distance: "200 km",
        value: 200000,
      },
    ],

    selectedUserId: null,
    selectedLeadUser: {},
    drawer: null,
    dialogColumns: false,
    selectedHeaders: [],
  }),

  computed: {
    headers() {
      let headers = [
        {
          text: "Actie",
          value: "actions",
          perma: true,
        },
        {
          text: "Status",
          value: "status_name",
          perma: true,
        },
        {
          text: "Naam",
          sortable: true,
          value: "name",
          perma: true,
        },
        {
          text: "Distance",
          value: "distanceFromCity",
          align: " d-none",
          filter: (value) => {
            if (!this.filterDistance) return true;
            return value < this.selectedRadius;
          },
        },
        {
          text: "Age",
          value: "age",
          align: " d-none",
          filter: this.ageFilter,
        },
        {
          text: "Recruiter",
          value: "rec_id",
          align: " d-none",
          filter: this.recruiterFilter,
        },
        {
          text: "Recruiter Ass",
          value: "rec_ass_id",
          align: " d-none",
          filter: this.recAssFilter,
        },
        {
          text: "Geslacht",
          sortable: true,
          value: "gender",
          align: " d-none",
          filter: this.genderFilter,
        },
        {
          text: "Telefoonnummer",
          sortable: true,
          value: "tel_number",
          align: " d-none",
        },
        {
          text: "Woonplaats",
          sortable: true,
          value: "region",
          align: " d-none",
        },
        {
          text: "Auto",
          sortable: true,
          value: "has_car",
          align: " d-none",
        },
        {
          text: "Travel Time",
          value: "travelDuration",
          sortable: true,
          align: " d-none",
        },
        {
          text: "Contracttermijn",
          sortable: true,
          value: "contract_term",
          align: " d-none",
        },
        {
          text: "Betrekkingsomvang",
          sortable: true,
          value: "contract_type",
          align: " d-none",
        },
        {
          text: "Salarisindicatie",
          sortable: true,
          value: "sal_indication",
          align: " d-none",
        },
        {
          text: "Contact Momenten",
          sortable: true,
          value: "ammount_contacts",
          align: " d-none",
        },
        {
          text: "Laatste Contact",
          sortable: true,
          value: "latest_contact",
          align: " d-none",
        },
        {
          text: "Soli. Datum",
          sortable: true,
          value: "applied_date",
          align: " d-none",
        },
        {
          text: "Soli. Vacature",
          sortable: true,
          value: "applied_vac",
          align: " d-none",
        },
        {
          text: "Bron",
          sortable: true,
          value: "applied_channel",
          align: " d-none",
        },
      ];
      for (let i = 0; i < headers.length; i++) {
        if (this.selectedHeaders.some((elem) => headers[i].text === elem)) {
          headers[i].align = "";
        }
      }
      return headers;
    },
    leadsuserAdjustedList() {
      return this.lead_users.map((e) => {
        return {
          id: e.id,
          address: e.address,
          distanceFromCity: 200001,
          travelDuration: "-",
          status_name: this.latestStatus(e).name,
          status_color: this.latestStatus(e).color,
          name: `${e.first_name} ${e.last_name}`,
          age: this.getAge(this.itemExists(e.dob)),
          gender: this.itemExists(e.gender),
          tel_number: this.itemExists(e.tel_number),
          region: this.itemExists(e.region),
          has_car: e.car,
          rec_id: this.itemExists(e.leads[e.leads.length - 1].recruiter).id,
          rec_ass_id: this.itemExists(
            e.leads[e.leads.length - 1].recruitment_assistant
          ).id,
          contract_term: this.itemExists(e.contract_term),
          contract_type: this.itemExists(e.contract_type),
          sal_indication: this.itemExists(e.sal_indication),
          email: this.itemExists(e.email),
          ammount_contacts: e.contact_moments.length,
          latest_contact: this.latestContactMomentDate(e.contact_moments),
          applied_date: this.latestLead(e.leads).applied_date,
          applied_vac: this.latestLead(e.leads).vacancy.name,
          applied_channel: this.latestLead(e.leads).channel.name,
        };
      });
    },
    ...mapState({
      lead_users: (state) => state.lead_users.filtered_lead_user,
      pagination: (state) => state.lead_users.pagination,

      employees: (state) =>
        state.users.all.items.employees
          .filter((e) => e.role <= 4)
          .sort((a, b) => {
            if (a.first_name < b.first_name) {
              return -1;
            }
            if (a.first_name > b.first_name) {
              return 1;
            }
            return 0;
          })
          .map((e) => {
            return {
              id: e.id,
              full_name: e.first_name + " " + e.last_name,
            };
          }),

      currentUser: (state) => state.users.user,
    }),
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions("lead_users", ["searchLeadUsersWithLeads"]),
    async initialize() {
      const { dispatch } = this.$store;
      await dispatch("users/getAll");
      setTimeout(() => {
        this.selectedHeaders = this.currentUser.search_columns.split(",");
      }, 100);
    },

    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },

    inputChanged() {
      this.leadUsersLoaded = false;
      if (this.search !== "") {
        this.leadUsersLoaded = true;
      }
    },

    ageFilter(value) {
      const foundAge = this.components.some((el) => el.selectedFilter === 1);

      if (this.components.length === 0) return true;
      if (foundAge) {
        if (value >= this.range[0] && value <= this.range[1]) {
          return value;
        }
      } else {
        return true;
      }
    },

    latestContactMomentDate(contactMoments) {
      if (contactMoments) {
        var date = contactMoments[contactMoments.length - 1].createdAt;
        return date.substring(0, 10);
      }
      return "Onbekend";
    },

    latestLead(leads) {
      if (leads) {
        return leads[leads.length - 1];
      }
      return {
        applied_date: "Onbekend",
        vacancy: { name: "Onbekend" },
        channel: { name: "Onbekend" },
      };
    },

    employeeName(employeeId) {
      for (var key in this.employees) {
        var obj = this.employees[key];
        if (obj.id == employeeId) {
          return obj.full_name;
        }
      }
      return "Onbekend";
    },

    recruiterFilter(value) {
      const foundRecruiter = this.components.some(
        (el) => el.selectedFilter === 3
      );

      if (this.components.length === 0) return true;
      if (foundRecruiter) {
        return value === parseInt(this.recruiterFieldValue);
      } else {
        return true;
      }
    },
    recAssFilter(value) {
      const foundRecruiterAss = this.components.some(
        (el) => el.selectedFilter === 4
      );

      if (this.components.length === 0) return true;
      if (foundRecruiterAss) {
        return value === parseInt(this.recAssFieldValue);
      } else {
        return true;
      }
    },

    genderFilter(value) {
      const found = this.components.some((el) => el.selectedFilter === 2);

      if (this.components.length === 0) return true;
      if (found) {
        return value.toLowerCase() === this.genderFieldValue.toLowerCase();
      } else {
        return true;
      }
    },

    setDescription(description) {
      this.description = description;
    },

    setPlace(place) {
      if (!place) return;

      this.latLng = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.deepSearch(this.search);
    },

    setHeaders(e) {
      this.selectedHeaders = e;
    },

    clear() {
      this.leadUsersLoaded = false;
      // const { dispatch } = this.$store;
      // await dispatch("lead_users/searchLeadUsersWithLeads");
      this.search = "";
      this.latLng.lat = "";
      this.latLng.lng = "";
      this.selectedRadius = null;
      this.filterDistance = false;
      this.components = [];
      this.genderFieldValue = "";
      this.recruiterFieldValue = "";
      this.recAssFieldValue = "";
      // this.leadUsersLoaded = true;
    },
    change() {
      this.leadUsersLoaded = false;
      this.search = "";
    },
    add() {
      this.components.push({
        selectedFilter: null,
        filterBy: [
          {
            id: 1,
            desc: "Age",
          },
          {
            id: 2,
            desc: "Gender",
          },
          {
            id: 3,
            desc: "Recruiter",
          },
          {
            id: 4,
            desc: "Recruiter Assistant",
          },
        ],
      });
    },

    remove(index) {
      this.components.splice(index, 1);
    },

    filterSelect(index) {
      this.leadUsersLoaded = false;
      const { dispatch } = this.$store;
      for (let i = 0; i < this.components.length; i++) {
        if (this.components[i] !== this.components[index]) {
          if (
            this.components[i].selectedFilter ===
            this.components[index].selectedFilter
          ) {
            this.remove(index);
            dispatch("alerts/warn", "This filter is already selected");
          }
        }
      }
      if (this.search !== "") {
        this.leadUsersLoaded = true;
      }
    },

    async storePaginationToState(val) {
      const { dispatch } = this.$store;
      await dispatch("lead_users/updatePagination", val);
    },

    itemExists(item) {
      if (item == null || item == "") return "Onbekend";
      return item;
    },

    openCv(item) {
      this.selectedUserId = item.id;
      const { dispatch } = this.$store;
      dispatch("lead_users/getLeadUser", item.id).then((res) => {
        this.selectedLeadUser = res.lead_user;
      });
      this.drawer = true;
    },

    async editItem(item) {
      this.$router.push(`/profiel/${item.id}`);
    },

    async deepSearch(value) {
      this.disableSearchBtn = true;
      this.leadUsersLoaded = false;
      if (this.search !== "") {
        await this.$store.dispatch(
          "lead_users/searchLeadUsersWithLeads",
          value
        );
        //Calculating distance
        if (this.filterDistance) {
          for (let i = 0; i < this.leadsuserAdjustedList.length; i++) {
            let address = JSON.parse(this.leadsuserAdjustedList[i].address);
            if (address) {
              if (address.region == "" || address.region == null) {
                if (this.leadsuserAdjustedList[i].region != "Onbekend") {
                  address.region = this.leadsuserAdjustedList[i].region;
                }
              }
            }
            if (this.latLng.lat !== "" && this.latLng.lng !== "") {
              await calculateDistance(
                address,
                this.latLng.lat,
                this.latLng.lng
              ).then((res) => {
                if (res != false) {
                  if (res.rows[0].elements[0].status != "ZERO_RESULTS") {
                    this.leadsuserAdjustedList[i].distanceFromCity =
                      res.rows[0].elements[0].distance.value;
                    if (this.leadsuserAdjustedList[i].has_car) {
                      this.leadsuserAdjustedList[i].travelDuration =
                        res.rows[0].elements[0].duration.text;
                    }
                  } else {
                    this.leadsuserAdjustedList[i].distanceFromCity = 200001;
                  }
                } else {
                  this.leadsuserAdjustedList[i].distanceFromCity = 200001;
                }
              });
            }
          }
        }
        this.leadUsersLoaded = true;
      }
      this.disableSearchBtn = false;
    },

    excelExport() {
      this.excelExporting = true;
      let csvContent = "data:text/csv;charset=utf-8,";
      let headings = `id,name,tel_number,email,gender`;
      csvContent += headings + "\r\n";
      this.filteredLeadUsers.forEach((leadUser) => {
        let row = `${leadUser.id},${leadUser.name},tel:${leadUser.tel_number},${leadUser.email},${leadUser.gender}`;
        csvContent += row + "\r\n";
      });
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "cllbr_export.csv");
      document.body.appendChild(link);
      link.click();
      this.excelExporting = false;
    },

    getFiltered(e) {
      this.filteredLeadUsers = e;
    },

    latestStatus(item) {
      if (item.lead_statuses.length > 0) {
        return item.lead_statuses[item.lead_statuses.length - 1].status;
      } else {
        return { name: "Geen", color: "#FFD700" };
      }
    },

    calculateTextColor(bgColor) {
      var color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
      var r = parseInt(color.substring(0, 2), 16); // hexToR
      var g = parseInt(color.substring(2, 4), 16); // hexToG
      var b = parseInt(color.substring(4, 6), 16); // hexToB
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#ffffff";
    },

    openNewTab(item) {
      let routeData = this.$router.resolve({
        path: `/profiel/${item.id}`,
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss">
.gmapField-light {
  width: 100%;
  border: 2px solid #aaa;
  border-radius: 5px;
  height: 38px;
  margin-top: 1px;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
}

.gmapField-light:focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 2px 0 dodgerBlue;
}

.gmapField-dark {
  width: 100%;
  border: 2px solid rgb(245, 245, 245);
  color: white;
  border-radius: 5px;
  height: 38px;
  margin-top: 1px;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
}

.gmapField-dark:focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 2px 0 dodgerBlue;
}

.pac-container {
  color: var(--v-text-base);
  background-color: var(--v-background-base);
  opacity: 100;
}
.pac-item {
  background: var(--v-background-base);
  color: var(--v-text-darken1);
  opacity: 100;
}

.pac-item:hover {
  background: var(--v-background-darken1);
}

.pac-item-query {
  color: var(--v-text-base);
}
</style>
