import config from "../../config";
import { authHeader } from "../utils/auth-header";

export const vacancyService = {
  getAll,
  getVacancy,
  getVacanciesByCompanyId,
  getVacancyStatuses,
  createVacancy,
  updateVacancy,
  updateVacancySearchTerms,
};
function getAll() {
  const request = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/vacancies`, request)
    .then(handleResponse)
    .then((vacancies) => {
      return vacancies;
    });
}

function getVacancy(id) {
  const request = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/vacancies/${id}`, request)
    .then(handleResponse)
    .then((vacancy) => {
      return vacancy;
    });
}

function getVacanciesByCompanyId(id) {
  const request = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/vacancies/company/${id}`, request)
    .then(handleResponse)
    .then((vacancies) => {
      return vacancies;
    });
}

function getVacancyStatuses() {
  const request = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/vacancy-status`, request)
    .then(handleResponse)
    .then((statuses) => {
      return statuses;
    });
}

function createVacancy(vacancy) {
  const request = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(vacancy),
  };
  return fetch(`${config.apiUrl}/vacancies`, request)
    .then(handleResponse)
    .then((vacancy) => {
      return vacancy;
    });
}

function updateVacancy(vacancy, id) {
  const request = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(vacancy),
  };
  return fetch(`${config.apiUrl}/vacancies/${id}`, request)
    .then(handleResponse)
    .then((vacancy) => { 
      return vacancy;
    });
}

function updateVacancySearchTerms(searchTerms, id) {
  const request = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(searchTerms),
  };
  return fetch(`${config.apiUrl}/vacancies/searchTerms/${id}`, request)
    .then(handleResponse)
    .then((vacancy) => {
      return vacancy;
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
