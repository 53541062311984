export function authHeader() {
    // return authorization header with jwt token
    let token = JSON.parse(localStorage.getItem('token'));
    let user = (localStorage.getItem('user'));
    if (token) {
        return { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', 'request_user': "" + user};
    } else {
        return {};
    }
}

export function socketAuth(){
    let token = JSON.parse(localStorage.getItem('token'));
    let user = (localStorage.getItem('user'));
    let groups = JSON.parse(localStorage.getItem('groups'))
    if(token && groups){
      return {
        token: token,
        user: user,
        groups: groups
      }
    }
    return {
      token: null,
      user: null,
      groups: null
    }
  }