<template>
  <v-container class="pa-5">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        v-model="data_fields.name"
        :rules="[(v) => !!v || 'Vacature titel is vereist']"
        label="Vacaturetitel"
        required
      ></v-text-field>

      <v-row>
        <v-col>
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedStartDate"
                label="Startdatum"
                hint="Per wanneer kan de kandidaat beginnen"
                persistent-hint
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                :rules="[(v) => !!v || 'Startdatum is vereist']"
                required
              ></v-text-field>
            </template>
            <v-date-picker
              locale="nl-NL"
              v-model="data_fields.start_date"
              no-title
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedVacancyDate"
                label="Einddatum"
                hint="Wanneer is de vacature gesloten"
                persistent-hint
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="nl-NL"
              v-model="data_fields.vacancy_close_date"
              no-title
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col>
          <v-autocomplete
            :items="companies_list"
            item-text="name"
            item-value="id"
            v-model="data_fields.company_id"
            @change="setContacts"
            label="Bedrijfsnaam"
            :rules="[(v) => !!v || 'Bedrijfsnaam is vereist']"
            required
          ></v-autocomplete>
        </v-col>

        <v-col>
          <v-autocomplete
            :items="positions_list"
            item-text="name"
            item-value="id"
            v-model="data_fields.position_id"
            label="Functie"
            :rules="[(v) => !!v || 'Functie is vereist']"
            required
          ></v-autocomplete>
        </v-col>
      </v-row>

      <div v-if="type === '0'">
        <v-switch
          v-model="use_existing_contact"
          label="Gebruik bestaand contact"
        ></v-switch>
      </div>
      <v-autocomplete
        v-if="use_existing_contact || type === '1'"
        :items="contacts_list"
        :item-text="fullName"
        item-value="id"
        v-model="data_fields.cp"
        label="Contactpersoon"
      ></v-autocomplete>

      <v-divider v-if="!use_existing_contact"></v-divider>
      <div v-if="type === '0' && !use_existing_contact">
        <h2>Nieuw contactpersoon</h2>
        <v-text-field
          :rules="[(v) => !!v || 'Afdeling is vereist']"
          v-model="contact_fields.department"
          label="Afdeling"
          required
        ></v-text-field>
        <v-row>
          <v-col>
            <v-text-field
              :rules="[(v) => !!v || 'Voornaam is vereist']"
              v-model="contact_fields.first_name"
              label="Voornaam"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :rules="[(v) => !!v || 'Achternaam is vereist']"
              v-model="contact_fields.last_name"
              label="Achternaam"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-text-field
          :rules="[(v) => !!v || 'Email is vereist']"
          v-model="contact_fields.mail"
          label="Email"
          required
        ></v-text-field>

        <v-text-field
          :rules="[(v) => !!v || 'Telefoonnummer is vereist']"
          v-model="contact_fields.phone"
          label="Telefoonnummer"
          required
        ></v-text-field>
      </div>
      <v-divider v-if="!use_existing_contact"></v-divider>

      <v-row>
        <v-col>
          <v-select
            :items="emp_type"
            v-model="data_fields.employment_type"
            label="Betrekkingsomvang"
            :rules="[(v) => !!v || 'Betrekkingsomvang is vereist']"
            required
          ></v-select>
        </v-col>
        <v-col>
          <v-text-field
            v-model="data_fields.employment_length"
            label="Betrekkingsduur"
            :rules="[(v) => !!v || 'Betrekkingsduur is vereist']"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-autocomplete
        :items="getManagers"
        :item-text="fullName"
        item-value="id"
        v-model="data_fields.cm_id"
        label="Community Manager "
        :rules="[(v) => !!v || 'Community Manager is vereist']"
        required
      ></v-autocomplete>

      <v-autocomplete
        :items="getRecruiters"
        :item-text="fullName"
        item-value="id"
        v-model="data_fields.rec_id"
        label="Recruiter"
      ></v-autocomplete>

      <v-row class="mt-0">
        <v-col>
          <v-text-field
            v-model="data_fields.people_needed"
            label="Mensen nodig"
            :rules="[(v) => !!v || 'Mensen nodig is vereist']"
            required
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="data_fields.sal_indication"
            label="Salarisindicatie"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="data_fields.tariff"
            label="Tarief"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-select
        :items="vacancy_statuses"
        item-text="name"
        item-value="id"
        v-model="data_fields.status"
        label="Status"
        :rules="[(v) => !!v || 'Status is vereist']"
        required
      ></v-select>

      <v-switch
        v-model="use_existing_location"
        v-if="type === '0'"
        label="Gebruik bestaand adres"
      ></v-switch>
      <div v-if="!use_existing_location">
        <v-text-field
          v-model="data_fields.street"
          :rules="[(v) => !!v || 'Straatnaam is vereist']"
          label="Straatnaam"
          required
        ></v-text-field>
        <v-row>
          <v-col>
            <v-text-field
              v-model="data_fields.postalcode"
              :rules="[(v) => !!v || 'Postcode is vereist']"
              label="Postcode"
              required
            ></v-text-field>
          </v-col>

          <v-col>
            <v-text-field
              v-model="data_fields.houseno"
              :rules="[(v) => !!v || 'Huisnummer is vereist']"
              label="Huisnummer"
              required
            ></v-text-field>
          </v-col>

          <v-col>
            <v-text-field
              v-model="data_fields.addition"
              label="Toevoeging"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-text-field
          v-model="data_fields.region"
          :rules="[(v) => !!v || 'Plaats is vereist']"
          label="Plaats"
          required
        ></v-text-field>
      </div>

      <v-textarea
        outlined
        v-model="data_fields.desc"
        label="Omschrijving"
        auto-grow
        :rules="[(v) => !!v || 'Omschrijving is vereist']"
        required
      ></v-textarea>

      <v-card color="#385F73" dark class="pa-2 mb-6">
        <v-row class="pa-2" justify="center">
          <div>CREATE JOB</div>
        </v-row>
        <v-autocomplete
          required
          :rules="[(v) => !!v || 'Rol is vereist']"
          label="Rol"
          v-model="data_fields.role"
          v-if="getLoadingStates.roles"
          :items="roles"
          item-text="name"
          item-value="id"
        ></v-autocomplete>
        <v-autocomplete
          required
          :rules="[(v) => !!v || 'Afdeling is vereist']"
          label="Afdeling"
          v-model="data_fields.department"
          v-if="getLoadingStates.departments"
          :items="departments"
          item-text="name"
          item-value="id"
        ></v-autocomplete>
        <v-autocomplete
          required
          :rules="[(v) => !!v || 'Industrie is vereist']"
          label="Industrie"
          v-model="data_fields.industry"
          v-if="getLoadingStates.industries"
          :items="industries"
          item-text="name"
          item-value="id"
        ></v-autocomplete>
      </v-card>

      <h2>Optioneel</h2>

      <v-text-field
        v-model="data_fields.vacancy_link"
        label="Vacature link"
      ></v-text-field>

      <v-row>
        <v-col>
          <v-text-field
            v-model="data_fields.min_days"
            label="Minimale dagen / uren"
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field v-model="data_fields.cao" label="CAO"></v-text-field>
        </v-col>
      </v-row>

      <v-text-field
        v-model="data_fields.dresscode"
        label="Kledingvoorschrift"
      ></v-text-field>

      <v-row
        ><v-col>
          <v-text-field
            v-model="data_fields.initial_scale"
            label="Aanvangsschaal"
          ></v-text-field>
        </v-col>
        <v-col
          ><v-text-field
            v-model="data_fields.surcharches"
            label="Toeslagen"
          ></v-text-field> </v-col
      ></v-row>

      <v-text-field
        v-model="data_fields.extra_remarks"
        label="Overige opmerkingen"
      ></v-text-field>

      <v-text-field
        class="mb-6"
        v-model="data_fields.extra_requirements"
        label="Overige vereisten"
      ></v-text-field>

      <v-textarea
        outlined
        v-model="data_fields.other_info"
        label="Aanvullende informatie"
        auto-grow
      ></v-textarea>

      <v-row justify="center">
        <v-btn
          :disabled="!valid && !adding"
          color="success"
          class="mr-4"
          @click="buttonClick"
        >
          {{ types[type].buttonText }}
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { calculateLocation } from "../../helpers/google";
export default {
  props: {
    type: String,
    formData: Object,
  },
  data: () => ({
    menu1: false,
    menu2: false,
    adding: false,
    valid: true,
    use_existing_contact: false,
    use_existing_location: false,
    companies_list: [],
    positions_list: [],
    users_list: [],
    contacts_list: [],
    emp_type: ["Fulltime", "Parttime", "Fulltime/Parttime", "Bijbaan"],
    vacancy_statuses: [],
    contact_fields: {
      first_name: "",
      last_name: "",
      department: "",
      mail: "",
      phone: "",
    },
    data_fields: {
      name: "",
      position_id: "",
      company_id: "",
      start_date: "",
      vacancy_close_date: null,
      desc: "",
      people_needed: "",
      sal_indication: "",
      tariff: "",
      employment_type: "",
      employment_length: "",

      //Job
      job_id: null,
      role: null,
      department: null,
      industry: null,

      // Terms
      min_days: "",
      extra_remarks: "",
      dresscode: "",
      extra_requirements: "",
      cao: "",
      initial_scale: "",
      surcharches: "",

      other_info: "",
      cm_id: "",
      rec_id: null,
      street: "",
      postalcode: "",
      houseno: "",
      addition: "",
      region: "",

      status: "",
      vacancy_link: "",
      cp: "",
    },
    types: [
      {
        buttonText: "Vacature Aanmaken",
      },
      {
        buttonText: "Vacature Aanpassen",
      },
      {
        buttonText: "Vacature kopiëren",
      },
    ],
  }),
  computed: {
    ...mapGetters("interests", [
      "getLoadingStates",
      "departments",
      "roles",
      "industries",
    ]),
    computedStartDate: {
      get() {
        return this.formatDate(this.data_fields.start_date);
      },
      set() {
        return null;
      },
    },
    computedVacancyDate: {
      get() {
        return this.formatDate(this.data_fields.vacancy_close_date);
      },
      set() {
        return null;
      },
    },
    activeCompany() {
      return this.$store.state.company.activeCompany;
    },
    getManagers() {
      let managers = [];
      for (let i = 0; i < this.users_list.length; i++) {
        if (this.users_list[i].role <= 2) {
          managers.push(this.users_list[i]);
        }
      }
      return managers;
    },
    getRecruiters() {
      return [...this.users_list];
    },
    computedLanguages: {
      get() {
        return this.chosenLanguages;
      },
      set(updatedChosenLanguages) {
        this.updateLanguages(updatedChosenLanguages);
        return this.chosenLanguages;
      },
    },
  },

  methods: {
    ...mapActions("interests", [
      "getDepartments",
      "getRoles",
      "getIndustries",
      "createJob",
    ]),
    buttonClick() {
      if (this.type === "0" || this.type === "2") {
        if (this.use_existing_location) {
          this.setExistingLocation();
        }
        this.addVacancy();
      } else if (this.type === "1") {
        this.editVacancy();
      }
    },
    async initialize() {
      const { dispatch } = this.$store;
      const compareNames = (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      };
      await dispatch("company/getAllCompanies").then((res) => {
        this.companies_list = res.companies.sort(compareNames);
      });
      await dispatch("positions/getAllPositions").then((res) => {
        this.positions_list = res.positions.sort(compareNames);
      });
      await dispatch("users/getAll").then((res) => {
        this.users_list = res.employees.sort((a, b) => {
          if (a.first_name < b.first_name) {
            return -1;
          }
          if (a.first_name > b.first_name) {
            return 1;
          }
          return 0;
        });
      });
      await dispatch("statuses/getAllVacancyStatus").then((res) => {
        this.vacancy_statuses = res.vacancy_statuses.sort(compareNames);
      });
      await this.getDepartments();
      await this.getRoles();
      await this.getIndustries();
    },
    async setContacts() {
      let id = this.data_fields.company_id;
      if (id === "") return;

      const { dispatch } = this.$store;
      await dispatch("contact/getContacts", id).then((contacts) => {
        this.contacts_list = contacts.contact_persons.sort((a, b) => {
          if (a.first_name < b.first_name) {
            return -1;
          }
          if (a.first_name > b.first_name) {
            return 1;
          }
          return 0;
        });
      });
    },
    fullName(item) {
      return item.first_name + " " + item.last_name;
    },
    setExistingLocation() {
      if (this.companies_list != null) {
        for (let i = 0; i < this.companies_list.length; i++) {
          if (this.companies_list[i].id == this.data_fields.company_id) {
            const address = JSON.parse(this.companies_list[i].address);
            this.data_fields.street = address.street;
            this.data_fields.postalcode = address.postalcode;
            this.data_fields.houseno = address.houseno;
            this.data_fields.region = address.region;
          }
        }
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    async editVacancy() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        if (this.$store.state.users.user.role <= 2) {
          this.adding = true;

          let vacancy = {
            name: this.data_fields.name,
            position_id: this.data_fields.position_id,
            company_id: this.data_fields.company_id,
            start_date: this.data_fields.start_date,
            vacancy_close_date: this.data_fields.vacancy_close_date,
            desc: this.data_fields.desc,
            people_needed: this.data_fields.people_needed,
            sal_indication: this.data_fields.sal_indication,
            tariff: this.data_fields.tariff,
            employment_type: this.data_fields.employment_type,
            employment_length: this.data_fields.employment_length,
            terms: {
              min_days: this.data_fields.min_days,
              extra_remarks: this.data_fields.extra_remarks,
              dresscode: this.data_fields.dresscode,
              extra_requirements: this.data_fields.extra_requirements,
              cao: this.data_fields.cao,
              initial_scale: this.data_fields.initial_scale,
              surcharches: this.data_fields.surcharches,
            },
            other_info: this.data_fields.other_info,
            cm_id: this.data_fields.cm_id,
            rec_id: this.data_fields.rec_id,
            address: {
              region: this.data_fields.region,
              street: this.data_fields.street,
              houseno: this.data_fields.houseno,
              addition: this.data_fields.addition,
              postalcode: this.data_fields.postalcode,
            },
            region: this.data_fields.region,
            status: this.data_fields.status,
            vacancy_link: this.data_fields.vacancy_link,
            cp: this.data_fields.cp,
            job_id: this.data_fields.job_id,
          };

          await calculateLocation(vacancy.address).then((res) => {
            vacancy.address.lat = res.lat;
            vacancy.address.lng = res.lng;
          });

          vacancy.terms = JSON.stringify(vacancy.terms);
          vacancy.address = JSON.stringify(vacancy.address);
          let id = this.data_fields.id;

          if (this.formData.job !== null) {
            if (
              this.data_fields.role != this.formData.job.role.id ||
              this.data_fields.department != this.formData.job.department.id ||
              this.data_fields.industry != this.formData.job.industry.id
            ) {
              const job = {
                role_id: this.data_fields.role,
                department_id: this.data_fields.department,
                industry_id: this.data_fields.industry,
              };
              await this.createJob(job).then((res) => {
                vacancy.job_id = res.id;
              });
            }
          } else {
            const job = {
              role_id: this.data_fields.role,
              department_id: this.data_fields.department,
              industry_id: this.data_fields.industry,
            };
            await this.createJob(job).then((res) => {
              vacancy.job_id = res.id;
            });
          }

          await dispatch("vacancy/updateVacancy", { vacancy, id }).then(
            (vacancy) => {
              dispatch("alerts/success", "Vacature aangepast");
              this.adding = false;
              dispatch("vacancy/getVacancy", id);
              this.$emit("close-dialog");
            }
          );
        } else {
          // Add notification
          dispatch("alerts/warn", "Je bent niet bevoegd om dit te doen");
        }
      } else {
        dispatch("alerts/warn", "Niet alle benodigde velden zijn ingevuld");
      }
      this.adding = false;
    },

    async addVacancy() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        if (this.$store.state.users.user.role <= 2) {
          if (!this.use_existing_contact) {
            this.adding = true;

            let contact = {
              first_name: this.contact_fields.first_name,
              last_name: this.contact_fields.last_name,
              department: this.contact_fields.department,
              mail: this.contact_fields.mail,
              phone: this.contact_fields.phone,
              comp_id: this.data_fields.company_id,
            };
            await dispatch("contact/createContact", contact).then((contact) => {
              this.data_fields.cp = contact.contact_person.id;
            });
          }

          let vacancy = {
            name: this.data_fields.name,
            position_id: this.data_fields.position_id,
            company_id: this.data_fields.company_id,
            start_date: this.data_fields.start_date,
            vacancy_close_date: this.data_fields.vacancy_close_date,
            desc: this.data_fields.desc,
            people_needed: this.data_fields.people_needed,
            sal_indication: this.data_fields.sal_indication,
            tariff: this.data_fields.tariff,
            employment_type: this.data_fields.employment_type,
            employment_length: this.data_fields.employment_length,
            terms: {
              min_days: this.data_fields.min_days,
              extra_remarks: this.data_fields.extra_remarks,
              cao: this.data_fields.cao,
              initial_scale: this.data_fields.initial_scale,
              surcharches: this.data_fields.surcharches,
            },
            other_info: this.data_fields.other_info,
            cm_id: this.data_fields.cm_id,
            rec_id: this.data_fields.rec_id,
            address: {
              region: this.data_fields.region,
              street: this.data_fields.street,
              houseno: this.data_fields.houseno,
              addition: this.data_fields.addition,
              postalcode: this.data_fields.postalcode,
            },
            region: this.data_fields.region,
            status: this.data_fields.status,
            vacancy_link: this.data_fields.vacancy_link,
            cp: this.data_fields.cp,
            job_id: this.data_fields.job_id,
          };

          await calculateLocation(vacancy.address).then((res) => {
            vacancy.address.lat = res.lat;
            vacancy.address.lng = res.lng;
          });

          vacancy.address = JSON.stringify(vacancy.address);
          vacancy.terms = JSON.stringify(vacancy.terms);

          const job = {
            role_id: this.data_fields.role,
            department_id: this.data_fields.department,
            industry_id: this.data_fields.industry,
          };
          await this.createJob(job).then((res) => {
            vacancy.job_id = res.id;
          });

          await dispatch("vacancy/createVacancy", vacancy).then((vacancy) => {
            //dispatch("alerts/success", "Vacature aangemaakt");
            if (this.activeCompany) {
              dispatch(
                "vacancy/getVacanciesByCompanyId",
                this.activeCompany.company.id
              );
            }
            dispatch("vacancy/getVacancies");
            this.clear();
            this.adding = false;
            this.$emit("close-dialog");
          });
        } else {
          // Add notification
          dispatch("alerts/warn", "Je bent niet bevoegd om dit te doen");
        }
      } else {
        dispatch("alerts/warn", "Niet alle benodigde velden zijn ingevuld");
      }
      this.adding = false;
    },
    clear() {
      this.$refs.form.reset();
    },
    loadComponent() {
      this.initialize();
      if (this.type === "1" || this.type === "2") {
        this.use_existing_contact = true;
        this.data_fields = this.formData;
        if (this.type === "2") {
          this.data_fields.name = "";
        }
        this.data_fields.min_days = this.formData.terms.min_days;
        this.data_fields.extra_remarks = this.formData.terms.extra_remarks;
        this.data_fields.dresscode = this.formData.terms.dresscode;
        this.data_fields.extra_requirements = this.formData.terms.extra_requirements;
        this.data_fields.cao = this.formData.terms.cao;
        this.data_fields.initial_scale = this.formData.terms.initial_scale;
        this.data_fields.surcharches = this.formData.terms.surcharches;
        this.data_fields.street = this.data_fields.address.street;
        this.data_fields.postalcode = this.formData.address.postalcode;
        this.data_fields.houseno = this.formData.address.houseno;
        this.data_fields.addition = this.formData.address.addition;
        if (this.formData.job_id !== null) {
          this.data_fields.job_id = this.formData.job_id;
        }
        if (this.formData.job !== null) {
          this.data_fields.role = this.formData.job.role.id;
          this.data_fields.department = this.formData.job.department.id;
          this.data_fields.industry = this.formData.job.industry.id;
        }
        this.setContacts();
      }
      if (this.activeCompany) {
        this.data_fields.company_id = this.activeCompany.company.id;
        this.setContacts();
      }
    },
  },
  created() {
    this.loadComponent();
  },
};
</script>
