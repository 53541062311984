import config from "../../config";
import { authHeader } from "../utils/auth-header";
export const fileUploadService = {
  downloadDocument,
  uploadDocument,
};

function downloadDocument(docID) {
  let headersCustom = authHeader();
  headersCustom.responseType = "blob";
  const request = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/file-upload/${docID}`, request).then(
    (response) => {
      const contentDip = response.headers.get("Content-Disposition");
      const filename = contentDip.substring(
        contentDip.indexOf("filename=") + 10,
        contentDip.length - 1
      );
      response.blob().then((blob) => download(blob, filename));
      return;
    }
  );
}

function download(blob, filename) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  // the filename you want
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

function uploadDocument(file, uploadData) {
  let alteredHeader = authHeader();
  delete alteredHeader["Content-Type"];
  const formData = new FormData();
  formData.append("file", file);
  formData.append("data", JSON.stringify(uploadData));
  const request = {
    method: "POST",
    headers: alteredHeader,
    body: formData,
  };
  return fetch(`${config.apiUrl}/file-upload`, request)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
