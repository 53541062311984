import { positionsService } from '../../services/positions.service'

export const positions = {
    namespaced: true,
    state: {
        status: {},
        positions: {

        }
    },
    actions: {
        createPosition({ dispatch, commit }, position) {
            return new Promise((resolve, reject) => {
                positionsService.createPosition(position)
                    .then(resolve(position))
            })
        },
        getAllPositions({ commit }) {
            commit('getAllRequest');
            return new Promise((resolve, reject) => {
                positionsService.getAll()
                    .then(
                        positions => {
                            commit('getAllSuccess', positions)
                            resolve(positions)
                        },
                        error => commit('getAllFailure', error)
                    );
            })
        },
        addPositionToCompany({ commit, dispatch }, compPosition) {
            commit('getAllRequest');
            return new Promise((resolve, reject) => {
                positionsService.addPositionToCompany(compPosition)
                    .then(
                        newCompanypos => {
                            commit('addPositionSuccess', newCompanypos)
                            resolve(newCompanypos)
                        },
                        error => {
                            commit('getAllFailure', error)
                            dispatch('alerts/error', error, { root: true });
                        }
                    );
            })
        },
        removePositionFromCompany({ commit }, { comp_id, pos_id }) {
            commit('getAllRequest');
            return new Promise((resolve, reject) => {
                positionsService.removePositionFromCompany(comp_id, pos_id)
                    .then(
                        deleted => {
                            commit('removePositionSuccess', deleted)
                            resolve(deleted)
                        },
                        error => commit('getAllFailure', error)
                    );
            })
        }
    },
    mutations: {
        getAllRequest(state) {
            state.status = { loading: true };
        },
        getAllSuccess(state, positions) {
            state.status = { loading: false };
            state.positions = positions;
        },
        getAllFailure(state, error) {
            state.status = { loading: false };
            state.all = { error };
        },
        addPositionSuccess(state, company) {
            state.status = { loading: false };
        },
        removePositionSuccess(state, company) {
            state.status = { loading: false };
        },
    }
}