<template>
  <v-app :style="cssProps">
    <div v-if="!authLoading">
      <NavDrawer v-if="userHasBeenLoaded" />
    </div>
    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container :fill-height="!isAuthenticated" fluid>
        <!-- Global Alerts -->
        <v-alert
          :value="alert.display"
          transition="slide-x-transition"
          border="left"
          dismissible
          :type="alert.type"
          max-width="30%"
          style="position: fixed; top: 15px; z-index: 20001"
          >{{ alert.message }}
        </v-alert>
        <!-- Notifications -->
        <NotificationPanel
          v-if="getPanelState"
          style="position: fixed; top: 10px; z-index: 20000"
        />
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<script>
import NavDrawer from "./components/NavDrawer";
import NotificationPanel from "./components/NotificationPanel.vue";
import { getInstance } from "./auth/authWrapper";
import { socketAuth } from "./utils/auth-header";
import SocketIO from "socket.io-client";
import VueSocketIO from "vue-socket.io";
import { mapActions, mapGetters } from "vuex";
import config from "../config";
import Vue from "vue";
export default {
  name: "App",

  components: {
    NavDrawer,
    NotificationPanel,
  },
  sockets: {
    connect() {
      this.isConnected = true;
    },

    disconnect() {
      this.isConnected = false;
    },

    notification(data) {
      this.addNotification(data);
      this.incrementNotifCount();
    },
  },
  watch: {
    $route(to, from) {
      // clear alert on location change
      this.$store.dispatch("alerts/clear");
    },
  },
  created() {
    document.title = "CLLBR";
    this.init(this.loadTokenIntoStore);
  },
  computed: {
    ...mapGetters("notifications", ["getPanelState"]),
    authLoading: function () {
      return this.$auth.loading;
    },
    isAuthenticated: function () {
      return this.$auth.isAuthenticated;
    },
    userLoaded: function () {
      return this.$store.state.users.user;
    },
    alert() {
      return this.$store.state.alerts;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    cssProps() {
      var themeColors = {};
      Object.keys(this.$vuetify.theme.themes[this.theme]).forEach((color) => {
        themeColors[`--v-${color}`] =
          this.$vuetify.theme.themes[this.theme][color];
      });
      return themeColors;
    },
  },
  methods: {
    ...mapActions("notifications", ["addNotification", "incrementNotifCount"]),
    ...mapActions("filters", ["syncLists"]), //This is used to get values for lists on launch
    async getSelf(oAuthID) {
      const { dispatch } = this.$store;
      await dispatch("users/getSelf", oAuthID);
      this.userHasBeenLoaded = true;
      Vue.prototype.$socket.auth = socketAuth();
    },
    init(fn) {
      // have to do this nonsense to make sure auth0Client is ready
      var instance = getInstance();
      instance.$watch("loading", (loading) => {
        if (loading === false) {
          if (this.$auth.isAuthenticated) {
            fn(instance);
          }
        }
      });
    },
    async loadTokenIntoStore(instance) {
      await instance.getTokenSilently().then((authToken) => {
        localStorage.setItem("user", this.$auth.user.sub);
        localStorage.setItem("token", JSON.stringify(authToken));
        this.getSelf(this.$auth.user.sub);
        this.initialize();
      });
    },
    async initialize() {
      this.syncLists(false);
    },
  },
  mounted() {
    this.$vuetify.theme.themes.light = {
      primary: "#009EE3",
      secondary: "#383838",
      accent: "#A7E8E8",
      error: "#FF5252",
      info: "#2196F3",
      success: "#4CAF50",
      warning: "#FFC107",
      background: "#F5F5F5",
      text: "#000000",
      navBackground: "#EEEEEE",
      tableRow: "#DCDCDC",
    };
    this.$vuetify.theme.themes.dark = {
      primary: "#009EE3",
      secondary: "#383838",
      accent: "#A7E8E8",
      error: "#FF5252",
      info: "#2196F3",
      success: "#4CAF50",
      warning: "#FFC107",
      background: "#121212",
      text: "#ffffff",
      navBackground: "#424242",
      tableRow: "#464646",
    };

    var isTrueSet = localStorage.getItem("dark") == "true";
    isTrueSet
      ? (this.$vuetify.theme.dark = true)
      : (this.$vuetify.theme.dark = false);
  },
  data() {
    return {
      isConnected: false,
      drawer: true,
      userHasBeenLoaded: false,
      user: { first_name: null },
      items: [
        { title: "Home", icon: "mdi-home-city" },
        { title: "Vacatures", icon: "mdi-account" },
        { title: "Leads", icon: "mdi-account" },
        { title: "Bedrijven", icon: "mdi-account" },
        { title: "Admin", icon: "mdi-account-group-outline" },
      ],
      mini: true,
    };
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Fugaz+One&display=swap");
.fugaz {
  font-family: "Fugaz One";
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background: rgba(33, 150, 243, 1);
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(33, 150, 243, 1);
}
.v-data-table__wrapper tbody tr:nth-of-type(even) {
  background-color: var(--v-tableRow);
}
</style>
