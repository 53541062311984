<template>
  <v-container class="pa-5">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col>
          <v-text-field
            v-model="formDataFields.name"
            label="Lead status naam"
            required
            :loading="adding"
          ></v-text-field>
        </v-col>
        <v-col class="mt-0 pt-0">
          <v-color-picker
            dot-size="25"
            hide-canvas
            hide-mode-switch
            hide-sliders
            mode="hexa"
            v-model="formDataFields.color"
            show-swatches
            swatches-max-height="250"
            class="mt-0"
          ></v-color-picker>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-btn
          :disabled="!valid || adding"
          color="success"
          class="mr-4"
          @click="buttonClick"
        >
          {{ types[type].buttonText }}
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  props: {
    type: String,
    formData: Object,
    parentID: Number,
  },
  data: () => ({
    adding: false,
    valid: false,
    data_fields: {
      name: "",
      color: "",
    },
    types: [
      {
        buttonText: "Lead Status Aanmaken",
      },
      {
        buttonText: "Lead Status Aanpassen",
      },
    ],
  }),

  computed: {
    formDataFields() {
      this.updateFormFields();
      return this.data_fields;
    },
  },

  created() {
    if (this.type == 1) {
      this.data_fields = this.formData;
    }
  },

  methods: {
    updateFormFields() {
      if (this.type == 1) {
        this.data_fields = this.formData;
      }
    },

    buttonClick() {
      if (this.type == 0) {
        this.createLeadStatus();
      } else if (this.type == 1) {
        this.editLeadStatus();
      }
    },

    async editLeadStatus() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        this.adding = true;
        let lead_status = {
          name: this.data_fields.name,
          color: this.data_fields.color,
        };
        let statusId = this.formData.id;
        if (this.$store.state.users.user.role == 0) {
          await dispatch("statuses/updateLeadStatus", {
            lead_status,
            statusId,
          }).then((lead_status) => {
            dispatch("alerts/success", "Lead status aangepast");
            dispatch("statuses/getAllLeadStatus");
            this.$emit("close-dialog");
          });
        } else {
          dispatch("alerts/warn", "Je bent niet bevoegd om dit te doen");
        }
      } else {
        dispatch("alerts/warn", "Niet alle velden zijn ingevuld");
      }
      this.adding = false;
    },

    async createLeadStatus() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        this.adding = true;
        let lead_status = {
          name: this.data_fields.name,
          color: this.data_fields.color,
        };
        if (this.$store.state.users.user.role == 0) {
          await dispatch("statuses/createLeadStatus", lead_status).then(
            (lead_status) => {
              dispatch("alerts/success", "Lead status aangemaakt");
              dispatch("statuses/getAllLeadStatus");
              this.clear();
              this.$emit("close-dialog");
            }
          );
        } else {
          dispatch("alerts/warn", "Je bent niet bevoegd om dit te doen");
        }
      } else {
        dispatch("alerts/warn", "Niet alle velden zijn ingevuld");
      }
      this.adding = false;
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped></style>
