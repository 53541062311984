import Vue from "vue";
import SocketIO from "socket.io-client";
import VueSocketIO from "vue-socket.io";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import Axios from "./plugins/axios";
// import VueGmaps from "vue-gmaps";
import * as GmapVue from "gmap-vue";
import { Auth0Plugin } from "./auth";
import { domain, clientId, audience } from "../auth_config.json";
import config from "../config";
import { socketAuth } from "./utils/auth-header";

// Vue.use(new VueSocketIO({
//   connection: SocketIO(`${config.socketUrl}?token=${authHeader().Authorization}`),//
// }));

Vue.config.productionTip = false;
Vue.use(GmapVue, {
  load: {
    key: "AIzaSyAolMmz-Tq6_qEe0GJa9-aFkDFA2_iAjmw",
    libraries: "places",
    region: "NL",
    language: "nl",
  },
  installComponents: true,
});

// Vue.use(VueGmaps, {
//   key: "AIzaSyAolMmz-Tq6_qEe0GJa9-aFkDFA2_iAjmw",
// });
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

Vue.use(Axios);

const options = {
  auth: {
    token: socketAuth().token,
    user: socketAuth().user,
    groups: socketAuth().groups,
  },
};
Vue.use(
  new VueSocketIO({
    connection: SocketIO(config.socketUrl, options), //
  })
);

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
