<template>
  <v-container>
    <v-autocomplete
      v-model="computedLanguages"
      @change="newLanguages"
      :items="languages"
      chips
      hide-details
      hide-selected
      item-text="name"
      item-value="symbol"
      label="Talen"
      multiple
    >
      <template v-slot:selection="{ attr, on, item, selected }">
        <v-chip
          v-bind="attr"
          :input-value="selected"
          v-on="on"
          close
          @click:close="remove(item)"
        >
          <CountryFlag :country="item.flag" />
          <span v-text="item.name" style="padding-left: 5px" />
        </v-chip>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-avatar>
          <CountryFlag :country="item.flag" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-text="item.name"></v-list-item-title>
          <v-list-item-subtitle v-text="item.code"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </v-container>
</template>
<script>
import CountryFlag from "vue-country-flag";
export default {
  props: ["value"],
  components: {
    CountryFlag,
  },

  computed: {
    computedLanguages: {
      get() {
        return this.chosenLanguages;
      },
      set(updatedChosenLanguages) {
        this.updateLanguages(updatedChosenLanguages);
        return this.chosenLanguages;
      },
    },
  },

  created() {
    this.setChosenLanguages();
  },

  data: () => ({
    chosenLanguages: [],
    languages: [
      { code: "af", name: "Afrikaans", flag: "za" },
      { code: "nl", name: "Nederlands", flag: "nl" },
      { code: "en", name: "Engels", flag: "gb" },
      { code: "fr", name: "Frans", flag: "fr" },
      { code: "de", name: "Duits", flag: "de" },
      { code: "pl", name: "Pools", flag: "pl" },
      { code: "it", name: "Italiaans", flag: "it" },
      { code: "es", name: "Spaans", flag: "es" },
      { code: "bg", name: "Bulgaars", flag: "bg" },
      { code: "ro", name: "Roemeens", flag: "ro" },
      { code: "pt", name: "Portugees", flag: "pt" },
      { code: "tr", name: "Turks", flag: "tr" },
      { code: "ae", name: "Arabisch", flag: "ae" },
    ],
  }),

  methods: {
    remove(item) {
      let index = -1;
      this.computedLanguages.find(function (lang, i) {
        if (lang.code === item.code) {
          index = i;
        }
      });
      if (index >= 0) {
        this.computedLanguages = this.computedLanguages.splice(index, 1);
      }
      this.newLanguages();
    },
    newLanguages() {
      this.$emit("change", this.computedLanguages);
    },
    setChosenLanguages() {
      this.chosenLanguages = this.value;
    },
    updateLanguages(updatedChosenLanguages) {
      let index = -1;
      this.languages.find(function (lang, i) {
        if (
          lang.name ===
          updatedChosenLanguages[updatedChosenLanguages.length - 1]
        ) {
          index = i;
        }
      });
      if (index >= 0) {
        this.chosenLanguages.push(this.languages[index]);
      }
    },
  },
};
</script>