import { clientService } from '../../../services/analytical/clients.service'

export const clients = {
    namespaced: true,
    state: {
        clients: {}
    },
    actions: {
        getAllClients({ commit }) {
            return new Promise((resolve, reject) => {
                clientService.getAll()
                .then(
                    clients => {
                        commit('getAllSuccess', clients)
                        resolve(clients)
                    }
                );
            })
        },
        getData({ commit }, {clientId, startDate, endDate}) {
            return new Promise((resolve, reject) => {
                clientService.getData(clientId, startDate, endDate)
                .then(
                    data => {
                        resolve(data)
                    }
                );
            })
        },
        
    },
    mutations: {
        getAllSuccess(state, clients) {
            state.clients = clients;
        },
    },
}