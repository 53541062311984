import config from '../../config';
import { authHeader } from '../utils/auth-header';
export const positionsService = {
    createPosition,
    getAll,
    addPositionToCompany,
    removePositionFromCompany
};
function createPosition(position) {
    const request = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(position)
    }
    return fetch(`${config.apiUrl}/postitions`, request)
        .then(handleResponse)
        .then(position => {
            return position;
        });
}

function getAll() {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/postitions`, request)
        .then(handleResponse)
        .then(positions => {
            return positions;
        });
}

function addPositionToCompany(compPosition) {
    const request = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(compPosition)
    }
    return fetch(`${config.apiUrl}/companies/position`, request)
        .then(handleResponse)
        .then(newCompanypos => {
            return newCompanypos;
        });
}

function removePositionFromCompany(comp_id, pos_id) {
    const request = {
        method: 'DELETE',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/companies/position/${comp_id}/${pos_id}`, request)
        .then(handleResponse)
        .then(deleted => {
            return deleted;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}