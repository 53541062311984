<template>
  <v-container fluid>
    <v-dialog v-model="dialogCreate" max-width="600px">
      <CreateLead
        v-on:close-dialog="dialogCreate = false"
        type="0"
        :period="this.filters.application_dates"
      />
    </v-dialog>
    <v-dialog v-model="dialogAddContactMoment" max-width="600px">
      <v-card>
        <CreateContactMoment
          v-if="selectedRow"
          v-on:close-dialog="dialogAddContactMoment = false"
          v-on:reload="reload"
          type="0"
          :parentID="selectedRow.user_id"
        ></CreateContactMoment>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogPlace" max-width="600px">
      <PlaceLead
        v-on:close-dialog="dialogPlace = false"
        :leadID="activeLeadId"
        :leadUserID="activeLeadUserId"
        :cmID="activeFlowCmId"
      />
    </v-dialog>
    <v-dialog v-model="dialogIntake" max-width="600px">
      <Intake
        v-on:close-dialog="dialogIntake = false"
        :leadID="activeLeadId"
        :leadUserID="activeLeadUserId"
      />
    </v-dialog>
    <v-dialog v-model="dialogColumns" max-width="600px">
      <SelectColumns
        :headers="headers"
        type="Leads"
        v-on:headers-changed="setHeaders($event)"
        v-on:close-dialog="dialogColumns = false"
      />
    </v-dialog>
    <v-bottom-sheet v-model="dialogSpeak" inset>
      <v-sheet class="text-center">
        <SpokenDatePicker
          :type="5"
          v-on:close-dialog="dialogSpeak = false"
          :leadID="activeLeadId"
          :leadUserID="activeLeadUserId"
          :leadName="activeLeadName"
          :leadContactNo="activeLeadContactNo"
          :vmCount="activeLeadVmCount"
          :additional="leadsPeriodString"
        />
      </v-sheet>
    </v-bottom-sheet>
    <v-card class="pa-4" :loading="!loaded">
      <v-row>
        <v-col md="8">
          <v-row class="pa-3">
            <h2>Leads</h2>
            <v-divider
              vertical
              inset
              style="padding-left: 10px; padding-left: 10px"
            ></v-divider>
            <v-text-field
              style="padding-top: 0px; padding-left: 10px"
              v-model="filters.search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @change="storeFilterToState"
            ></v-text-field>
          </v-row>
        </v-col>
        <v-col align="end" md="4">
          <v-btn
            icon
            color="primary"
            class="mb-2"
            @click="dialogColumns = true"
          >
            <v-icon dark>mdi-bookmark</v-icon>
          </v-btn>
          <v-btn icon color="primary" class="mb-2" @click="excelExport()">
            <v-icon dark>mdi-table-arrow-right</v-icon>
          </v-btn>
          <v-btn icon color="primary" class="mb-2" @click="reload">
            <v-icon dark>mdi-reload</v-icon>
          </v-btn>
          <v-btn icon color="primary" class="mb-2" @click="dialogCreate = true">
            <v-icon dark>mdi-plus-box</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-menu
        v-model="showMenu"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
      >
        <v-list dense>
          <v-list-item @click="open" dense>
            <v-list-item-title>Open</v-list-item-title>
          </v-list-item>
          <v-list-item @click="openNewTab" dense>
            <v-list-item-title>Nieuw tabblad</v-list-item-title>
          </v-list-item>
          <v-list-item @click="dialogAddContactMoment = true" dense>
            <v-list-item-title>Voeg contactmoment toe</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-data-table
        v-if="loaded"
        :page.sync="pagination.page"
        :items-per-page="pagination.itemsPerPage"
        :footer-props="{ itemsPerPageOptions: [20, 35, 50, -1] }"
        :items="leadsAdjustedList"
        :headers="headers"
        :search="filters.search"
        :custom-sort="customSort"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="elevation-1"
        @contextmenu:row="show"
        @pagination="storePaginationToState"
        @current-items="getFiltered"
        @update:sort-by="updateSortBy"
        @update:sort-desc="updateSortDesc"
      >
        <template v-slot:top>
          <v-expansion-panels focusable flat :value="collapsed">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-4 pt-4"
                ><h2>Filter</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container fluid>
                  <v-row dense>
                    <v-col align="end" class="mr-2 mt-2 mb-0">
                      <v-btn icon color="red" @click="clearFilters()"
                        ><span class="mr-1">Clear</span
                        ><v-icon dark>mdi-backspace</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-select
                        dense
                        class="pt-1"
                        :items="lead_statuses"
                        v-model="filters.statuses"
                        chips
                        deletable-chips
                        multiple
                        item-text="name"
                        return-object
                        label="Status"
                        @change="storeFilterToState"
                        ><template
                          v-slot:selection="{ attr, on, item, selected }"
                        >
                          <v-chip
                            v-bind="attr"
                            :input-value="selected"
                            v-on="on"
                            close
                            :color="item.color"
                            @click:close="remove(item)"
                            ><div style="color: black">
                              <strong>{{ item.name }}</strong>
                            </div></v-chip
                          >
                        </template>
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        :items="companies"
                        v-model="filters.company"
                        clearable
                        item-text="name"
                        item-value="name"
                        label="Bedrijf"
                        @change="storeFilterToState"
                      ></v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        :items="vacancies"
                        v-model="filters.vacancy"
                        clearable
                        item-text="name"
                        item-value="name"
                        label="Vacature"
                        @change="storeFilterToState"
                      ></v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="filters.region"
                        append-icon="mdi-magnify"
                        label="Woonplaats"
                        single-line
                        hide-details
                        @change="storeFilterToState"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="filters.campaign"
                        append-icon="mdi-magnify"
                        label="Campagne ID"
                        single-line
                        hide-details
                        @change="storeFilterToState"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-autocomplete
                        :items="employees"
                        v-model="filters.leadRecruiterAss"
                        clearable
                        item-text="full_name"
                        item-value="full_name"
                        label="Recruitement Assistent"
                      ></v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        :items="employees"
                        v-model="filters.leadRecruiter"
                        clearable
                        item-text="full_name"
                        item-value="full_name"
                        label="Recruiter"
                      ></v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        :items="channels"
                        v-model="filters.channels"
                        clearable
                        multiple
                        item-text="name"
                        item-value="name"
                        label="Kanaal"
                        @change="storeFilterToState"
                      ></v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-menu
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="computedDateRangeApplication"
                            label="Sollicitatieperiode"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="filters.application_dates"
                          locale="nl-NL"
                          color="green"
                          header-color="primary"
                          range
                          @change="fetchLeadsForPeriod"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-menu
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="computedDateRangeContact"
                            label="Contactperiode"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            @change="storeFilterToState"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="filters.contact_dates"
                          locale="nl-NL"
                          color="green"
                          header-color="primary"
                          range
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="columnSelectOpen(item)">
            mdi-open-in-new
          </v-icon>
        </template>
        <template v-slot:[`item.status_name`]="{ item }">
          <v-chip :color="item.status_color"
            ><div :style="`color: ${calculateTextColor(item.status_color)}`">
              <strong>{{ item.status_name }}</strong>
            </div></v-chip
          >
        </template>
        <template v-slot:[`item.first_date`]="{ item }">
          <span v-if="item.first_date != null">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="green" dark v-bind="attrs" v-on="on">
                  mdi-phone
                </v-icon>
              </template>
              <span>{{ item.first_date }}</span>
            </v-tooltip>
          </span>
          <v-badge
            v-else-if="item.vm_date != null"
            bordered
            color="error"
            :content="item.vm_count"
            overlap
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="0"
                  color="error"
                  small
                  @click="openFlowSpoken(item)"
                  class="pl-1 pr-3"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-phone-missed</v-icon>
                </v-btn>
              </template>
              <span>{{ item.vm_date }}</span>
            </v-tooltip>
          </v-badge>
          <v-btn
            v-else
            elevation="0"
            color="primary"
            small
            @click="openFlowSpoken(item)"
            icon
          >
            <v-icon color="red" dark> mdi-phone </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import CreateLead from "../components/dialogs/CreateLead";
import CreateContactMoment from "../components/dialogs/CreateContactMoment";
import PlaceLead from "../components/dialogs/PlaceLead";
import Intake from "../components/dialogs/Intake";
import SpokenDatePicker from "../components/dialogs/SpokenDatePicker";
import SelectColumns from "../components/dialogs/SelectColumns.vue";
import { mapState } from "vuex";
export default {
  components: {
    CreateLead,
    CreateContactMoment,
    PlaceLead,
    Intake,
    SpokenDatePicker,
    SelectColumns,
  },
  data: () => ({
    loaded: false,
    excelExporting: false,
    sortBy: "applied_date",
    sortDesc: true,
    dialogCreate: false,
    dialogSpeak: false,
    dialogIntake: false,
    dialogPlace: false,
    dialogAddContactMoment: false,
    dialogColumns: false,
    appliedMenu: false,
    collapsed: 0,
    activeLeadId: null,
    activeLeadUserId: null,
    activeLeadName: null,
    activeLeadContactNo: null,
    activeFlowCmId: null,
    activeLeadVmCount: null,
    leadsPeriodString: null,
    filters: {},
    selectedRow: null,
    showMenu: false,
    x: 0,
    y: 0,
    filteredLeadUsers: [],
    selectedHeaders: [],
  }),

  computed: {
    computedDateRangeApplication: {
      get() {
        return this.splitDateRange(this.filters.application_dates);
      },
      set() {
        return null;
      },
    },
    computedDateRangeContact: {
      get() {
        return this.splitDateRange(this.filters.contact_dates);
      },
      set() {
        return null;
      },
    },
    headers() {
      let headers = [
        { text: "Actie", value: "actions", sortable: false, perma: true },
        {
          text: "Solliciatie",
          value: "applied_date",
          perma: true,
        },
        {
          text: "Status",
          value: "status_name",
          perma: true,
          filter: (value) => {
            if (!this.filters.statuses) {
              return true;
            }
            if (this.filters.statuses.length <= 0) {
              return true;
            }
            return this.filters.statuses.some((elem) => elem.name === value);
          },
        },
        { text: "Naam", value: "user_name", perma: true },
        {
          text: "Woonplaats",
          value: "region",
          align: " d-none",
          filter: (value) => {
            if (!this.filters.region) return true;
            if (!value) return false;
            return value
              .toLowerCase()
              .includes(this.filters.region.toLowerCase());
          },
        },
        {
          text: "Gesproken door",
          value: "rec_ass_full_name",
          align: " d-none",
          filter: (value) => {
            if (!this.filters.recruiter_assistent) return true;
            return value === this.filters.recruiter_assistent;
          },
        },
        {
          text: "Intake door",
          value: "rec_full_name",
          align: " d-none",
          filter: (value) => {
            if (!this.filters.recruiter) return true;
            return value === this.filters.recruiter;
          },
        },
        {
          text: "Lead Recruitment Assistent",
          value: "lead_ra",
          align: " d-none",
          filter: (value) => {
            if (!this.filters.leadRecruiterAss) return true;
            return value === this.filters.leadRecruiterAss;
          },
        },
        {
          text: "Lead Recruiter",
          value: "lead_rec",
          align: " d-none",
          filter: (value) => {
            if (!this.filters.leadRecruiter) return true;
            return value === this.filters.leadRecruiter;
          },
        },
        {
          text: "Laatste contactmoment",
          value: "contact_moment",
          align: " d-none",
          filter: (value) => {
            if (!this.filters.contact_dates) return true;
            if (this.filters.contact_dates.length <= 1) return true;
            const dbFormatDate = this.dbFormat(value);
            return (
              dbFormatDate >= this.filters.contact_dates[0] &&
              dbFormatDate <= this.filters.contact_dates[1]
            );
          },
        },
        {
          text: "Campagne ID",
          value: "campaign",
          align: " d-none",
          filter: (value) => {
            if (!value && this.filters.campaign) return false;
            if (!this.filters.campaign) return true;
            return (
              value.toString().toLowerCase() ===
              this.filters.campaign.toLowerCase()
            );
          },
        },
        { text: "Tel", align: " d-none", value: "tel_number" },
        {
          text: "Vacature",
          value: "vacancy",
          align: " d-none",
          filter: (value) => {
            if (!this.filters.vacancy) return true;
            return value === this.filters.vacancy;
          },
        },
        {
          text: "Bedrijf",
          value: "company",
          align: " d-none",
          filter: (value) => {
            if (!this.filters.company) return true;
            return value === this.filters.company;
          },
        },
        {
          text: "Kanaal",
          value: "channel",
          align: " d-none",
          filter: (value) => {
            if (!this.filters.channels) return true;
            if (this.filters.channels.length <= 0) return true;
            return this.filters.channels.some((elem) => elem === value);
          },
        },
        {
          text: "Bellen",
          value: "first_date",
          sortable: false,
          perma: true,
        },
      ];
      for (let i = 0; i < headers.length; i++) {
        if (this.selectedHeaders.some((elem) => headers[i].text === elem)) {
          headers[i].align = "";
        }
      }
      return headers;
    },
    leadsAdjustedList() {
      return this.leads.map((e) => {
        var mapped = {
          id: e.id,
          lead_rec: this.fullName(e.recruiter),
          lead_ra: this.fullName(e.recruitment_assistant),
          user_id: e.user.id,
          user_name: this.cleanUserName(e.user.first_name, e.user.last_name),
          tel_number: e.user.tel_number,
          mail: e.user.email,
          campaign: e.campaign_id,
          status_name: this.latestStatus(e).name,
          status_color: this.latestStatus(e).color,
          contact_moment: this.latestContact(e.user),
          vacancy: e.vacancy.name,
          company: e.vacancy.company.name,
          channel: e.channel.name,
          applied_date: this.formatDate(e.applied_date),
          region: e.user.region,
          first_date: null,
          vm_date: null,
          intake_date: null,
          intake_completed: null,
          placed_date: null,
          rec: null,
          rec_full_name: null,
          rec_ass_full_name: null,
          flow: false,
        };

        if (e.flow != null) {
          mapped.first_date = this.toLocalDate(e.flow.first_date);
          mapped.vm_date = this.toLocalDate(e.flow.vm_date);
          mapped.intake_date = this.toLocalDate(e.flow.intake_date);
          mapped.intake_completed = e.flow.intake_completed;
          mapped.placed_date = this.toLocalDate(e.flow.placed_date);
          mapped.rec = e.flow.rec;
          mapped.rec_full_name = this.fullName(e.flow.rec);
          mapped.rec_ass_full_name = this.fullName(e.flow.rec_ass);
          mapped.placed_cm = e.flow.placed_cm;
          mapped.vm_count = e.flow.vm_count;
          mapped.flow = true;
        }

        return mapped;
      });
    },
    ...mapState({
      leadSortBy: (state) => state.filters.leadSortBy,

      leadSortDesc: (state) => state.filters.leadSortDesc,

      stored_filters: (state) => state.filters.lead_filter,

      leads: (state) => state.leads.leadsForPeriod,

      lead_statuses: (state) => state.statuses.lead_statuses,

      vacancies: (state) =>
        state.filters.lists.vacancies.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }),

      companies: (state) =>
        state.filters.lists.companies.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }),

      employees: (state) =>
        state.filters.lists.employees
          .filter((e) => e.first_name != null)
          .sort((a, b) => {
            if (a.first_name < b.first_name) {
              return -1;
            }
            if (a.first_name > b.first_name) {
              return 1;
            }
            return 0;
          })
          .map((e) => {
            return {
              id: e.id,
              full_name: e.first_name + " " + e.last_name,
            };
          }),

      channels: (state) => state.leads.channels,

      pagination: (state) => state.leads.pagination,

      currentUser: (state) => state.users.user,
    }),
  },

  created() {
    this.filters = this.stored_filters;
    this.initialize();
    this.sortBy = this.leadSortBy;
    if (this.leadSortDesc) {
      this.sortDesc = this.leadSortDesc;
    } else {
      this.sortDesc = true;
    }
  },

  methods: {
    async initialize() {
      const { dispatch } = this.$store;
      var now = new Date();
      now = now.toISOString().substring(0, 10);
      var past = new Date();
      past.setDate(past.getDate() - 30);
      past = past.toISOString().substring(0, 10);
      let params = {
        startDate: past,
        endDate: now,
      };

      let temp = {
        application_dates: [past, now],
      };
      if (Object.keys(this.filters).length === 0) {
        this.filters = temp;
      } else {
        if (this.filters.application_dates.length !== 2) {
          this.filters.application_dates = temp.application_dates;
        } else {
          if (
            this.filters.application_dates[0] >
            this.filters.application_dates[1]
          ) {
            params = {
              startDate: this.filters.application_dates[1],
              endDate: this.filters.application_dates[0],
            };
          } else {
            params = {
              startDate: this.filters.application_dates[0],
              endDate: this.filters.application_dates[1],
            };
          }
        }
      }

      await dispatch("leads/getLeadsPerPeriod", { params });

      this.storeFilterToState();

      await dispatch("statuses/getAllLeadStatus");
      await dispatch("leads/getAllChannels");
      //await dispatch("users/getAll");
      this.selectedHeaders = this.currentUser.lead_columns.split(",");

      this.loaded = true;
    },

    async reload() {
      this.loaded = false;
      const { dispatch } = this.$store;
      dispatch("filters/syncLists", true);
      var now = new Date();
      now = now.toISOString().substring(0, 10);
      var past = new Date();
      past.setDate(past.getDate() - 30);
      past = past.toISOString().substring(0, 10);
      let params = {
        startDate: past,
        endDate: now,
      };

      let temp = {
        application_dates: [past, now],
      };
      if (Object.keys(this.filters).length === 0) {
        this.filters = temp;
      } else {
        if (this.filters.application_dates.length !== 2) {
          this.filters.application_dates = temp.application_dates;
        } else {
          if (
            this.filters.application_dates[0] >
            this.filters.application_dates[1]
          ) {
            params = {
              startDate: this.filters.application_dates[1],
              endDate: this.filters.application_dates[0],
            };
          } else {
            params = {
              startDate: this.filters.application_dates[0],
              endDate: this.filters.application_dates[1],
            };
          }
        }
      }

      await dispatch("leads/getLeadsPerPeriod", { params });

      this.storeFilterToState();

      await dispatch("statuses/getAllLeadStatus");

      //await dispatch("users/getAll");
      this.loaded = true;
    },

    async fetchLeadsForPeriod() {
      const { dispatch } = this.$store;
      let params = {
        startDate: null,
        endDate: null,
      };
      if (
        this.filters.application_dates[0] > this.filters.application_dates[1]
      ) {
        params = {
          startDate: this.filters.application_dates[1],
          endDate: this.filters.application_dates[0],
        };
      } else {
        params = {
          startDate: this.filters.application_dates[0],
          endDate: this.filters.application_dates[1],
        };
      }

      await dispatch("leads/getLeadsPerPeriod", { params });
      this.storeFilterToState();
    },

    async storeFilterToState() {
      const { dispatch } = this.$store;
      await dispatch("filters/storeLeadFilter", this.filters);
    },

    async storePaginationToState(val) {
      const { dispatch } = this.$store;
      await dispatch("leads/updatePagination", val);
    },

    async updateSortBy(sortBy) {
      const { dispatch } = this.$store;
      await dispatch("filters/updateLeadSortBy", sortBy);
    },

    async updateSortDesc(sortDesc) {
      const { dispatch } = this.$store;
      await dispatch("filters/updateLeadSortDesc", sortDesc);
    },

    setHeaders(e) {
      this.selectedHeaders = e;
    },

    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === "applied_date") {
          const dataA = this.dbFormat(a.applied_date);
          const dataB = this.dbFormat(b.applied_date);
          if (!isDesc[0]) {
            return this.compare(dataA, dataB);
          } else {
            return this.compare(dataB, dataA);
          }
        } else if (
          index[0] === "first_date" ||
          index[0] === "intake_date" ||
          index[0] === "intake_completed" ||
          index[0] === "placed_date"
        ) {
          if (a[index] === null) {
            return !isDesc[0] ? -1 : 1;
          }
          if (b[index] === null) {
            return !isDesc[0] ? 1 : -1;
          }
          if (!isDesc[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
          }
        } else {
          if (!isDesc[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },

    compare(item1, item2) {
      if (item1 < item2) return -1;
      if (item1 > item2) return 1;
      return 0;
    },

    async clearFilters() {
      const { dispatch } = this.$store;
      var now = new Date();
      now = now.toISOString().substring(0, 10);
      var past = new Date();
      past.setDate(past.getDate() - 30);
      past = past.toISOString().substring(0, 10);
      let params = {
        startDate: past,
        endDate: now,
      };

      let temp = {
        application_dates: [past, now],
      };
      this.filters = {};

      this.filters = temp;
      await dispatch("leads/getLeadsPerPeriod", { params });
      this.storeFilterToState();
    },

    fullName(item) {
      if (item != undefined) {
        return item.first_name + " " + item.last_name;
      }
      return "Niet toegewezen";
    },

    cleanUserName(first, last) {
      return first.trim() + " " + last.trim();
    },

    calculateTextColor(bgColor) {
      var color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
      var r = parseInt(color.substring(0, 2), 16); // hexToR
      var g = parseInt(color.substring(2, 4), 16); // hexToG
      var b = parseInt(color.substring(4, 6), 16); // hexToB
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#ffffff";
    },

    remove(item) {
      let index = -1;
      this.filters.statuses.find(function(status, i) {
        if (status.name === item.name) {
          index = i;
        }
      });
      if (index >= 0) {
        this.filters.statuses.splice(index, 1);
      }
    },

    dbFormat(date) {
      if (!date) return null;

      const [day, month, year] = date.split("-");
      return `${year}-${month}-${day}`;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    toLocalDate(date) {
      if (!date) return null;
      var utcDate = new Date(date);
      const localeString = utcDate.toLocaleString("nl-NL");
      return localeString.substring(0, localeString.length - 3);
    },

    latestLead(item) {
      if (item.leads.length > 0) {
        return item.leads[item.leads.length - 1];
      } else {
        return undefined;
      }
    },

    splitDateRange(date) {
      if (!date) return null;
      if (date.length === 0) return [];
      const date1 = this.formatDate(date[0]);
      const date2 = this.formatDate(date[1]);
      return `${date1}~${date2}`;
    },

    latestStatus(item) {
      if (item.user.lead_statuses.length > 0) {
        for (let i = 1; i <= item.user.lead_statuses.length; i++) {
          if (
            item.user.lead_statuses[item.user.lead_statuses.length - i]
              .status != null
          ) {
            return item.user.lead_statuses[item.user.lead_statuses.length - i]
              .status;
          }
        }
      } else {
        return { name: "Geen", color: "#FFD700" };
      }
    },
    itemExists(item) {
      if (item == null || item == "") return "Onbekend";
      return item;
    },
    latestContact(item) {
      if (item.contact_moments.length > 0) {
        return this.formatDate(
          item.contact_moments[
            item.contact_moments.length - 1
          ].createdAt.substring(0, 10)
        );
      } else {
        return "Geen";
      }
    },

    positions() {
      this.activeCompanyPositions = [];
      for (var position in this.activeCompany.company.positions) {
        this.activeCompanyPositions.push(
          this.activeCompany.company.positions[position].position
        );
      }
    },

    editItem(item) {
      this.$router.push(`/profiel/${item.user_id}`);
    },

    columnSelectOpen(item) {
      this.$router.push(`/profiel/${item.user_id}`);
    },

    open() {
      this.$router.push(`/profiel/${this.selectedRow.user_id}`);
    },

    openNewTab() {
      let routeData = this.$router.resolve({
        path: `/profiel/${this.selectedRow.user_id}`,
      });
      window.open(routeData.href, "_blank");
    },

    show(event, row) {
      event.preventDefault();
      this.showMenu = false;
      this.x = event.clientX;
      this.y = event.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
      this.selectedRow = row.item;
    },

    openFlowSpoken(item) {
      this.activeLeadId = item.id;
      this.activeLeadUserId = item.user_id;
      this.activeLeadName = item.user_name;
      this.activeLeadContactNo = item.tel_number;
      this.activeLeadVmCount = item.vm_count;
      this.leadsPeriodString = JSON.stringify(this.filters.application_dates);
      this.dialogSpeak = true;
    },

    openFlowIntake(item) {
      this.activeLeadId = item.id;
      this.activeLeadUserId = item.user_id;
      this.dialogIntake = true;
    },

    openFlowPlace(item) {
      this.activeLeadId = item.id;
      this.activeLeadUserId = item.user_id;
      this.activeFlowCmId = item.placed_cm;
      this.dialogPlace = true;
    },

    excelExport() {
      if (this.$store.state.users.user.role == 0) {
        this.excelExporting = true;
        let csvContent = "data:text/csv;charset=utf-8,";
        let headings = `id,current_status,name,tel_number,email,campaign`;
        csvContent += headings + "\r\n";
        this.filteredLeadUsers.forEach((lead) => {
          let row = `${lead.id},${lead.status_name},${lead.user_name},tel:${lead.tel_number},${lead.mail},${lead.campaign}`;
          csvContent += row + "\r\n";
        });
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "cllbr_export.csv");
        document.body.appendChild(link);
        link.click();
        this.excelExporting = false;
      }
    },

    getFiltered(e) {
      this.filteredLeadUsers = e;
    },
  },
};
</script>
