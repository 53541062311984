<template>
  <v-card class="pa-5">
    <v-container class="pa-5">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-model="formDataFields.first_name"
                :rules="[(v) => !!v || 'Voornaam is vereist']"
                label="Voornaam"
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="formDataFields.last_name"
                :rules="[(v) => !!v || 'Achternaam is vereist']"
                label="Achternaam"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col>
              <v-text-field
                v-model="formDataFields.email"
                :rules="[(v) => !!v || 'Email is vereist']"
                label="Email"
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="formDataFields.tel_number"
                label="Telefoon"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-select
            class="mb-2"
            :items="genders"
            v-model="formDataFields.gender"
            required
            label="Geslacht"
          ></v-select>
          <v-row>
            <v-col>
              <v-menu
                v-model="dobMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedDOB"
                    label="Geboortedatum"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="nl-NL"
                  v-model="formDataFields.dob"
                  @input="dobMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col>
              <v-text-field
                v-model="formDataFields.address.street"
                label="Straatnaam"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="formDataFields.region"
                label="Plaats"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col>
              <v-text-field
                v-model="formDataFields.address.postalcode"
                label="Postcode"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="formDataFields.address.houseno"
                label="Huisnummer"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="formDataFields.address.addition"
                label="Toevoeging"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <LanguageSelector v-model="formDataFields.languages" />
            <v-col>
              <v-row>
                <v-checkbox
                  v-model="formDataFields.car"
                  style="padding-left: 10px"
                  label="Auto"
                ></v-checkbox>
                <v-checkbox
                  v-model="formDataFields.drivers_license"
                  style="padding-left: 10px"
                  label="Rijbewijs"
                ></v-checkbox>
                <v-checkbox
                  v-model="formDataFields.bullhorn"
                  style="padding-left: 10px"
                  label="Bullhorn"
                ></v-checkbox>
                <v-checkbox
                  v-model="formDataFields.contract_signed"
                  style="padding-left: 10px"
                  label="Contract getekend"
                ></v-checkbox>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="formDataFields.contract_signed">
            <v-col>
              <v-menu
                v-model="contractMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedContract"
                    label="Ondertekeningsdatum"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="formDataFields.contract"
                  @input="contractMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-select
            :items="platforms"
            v-model="formDataFields.platform"
            required
            label="Platform"
          ></v-select>
          <v-row class="mt-0">
            <v-col>
              <v-select
                :items="contract_terms"
                v-model="formDataFields.contract_term"
                label="Contracttermijn"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                :items="contract_types"
                v-model="formDataFields.contract_type"
                label="Betrekkingsomvang"
              ></v-select>
            </v-col>
          </v-row>
          <v-text-field
            v-model="formDataFields.sal_indication"
            label="Salarisindicatie"
          ></v-text-field>
          <v-textarea
            v-model="formDataFields.notes"
            outlined
            label="Notities"
          ></v-textarea>
        </v-container>
        <v-row justify="center">
          <v-btn
            :disabled="!valid || adding"
            color="success"
            class="mr-4"
            @click="buttonClick"
          >
            {{ types[type].buttonText }}
          </v-btn>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import LanguageSelector from "../LanguageSelector";
import { calculateLocation } from "../../helpers/google";
export default {
  components: {
    LanguageSelector,
  },
  props: {
    type: String,
    formData: Object,
    parentID: Number,
  },
  data: () => ({
    dobMenu: false,
    contractMenu: false,
    adding: false,
    valid: false,
    genders: ["Man", "Vrouw", "Transgender", "Non-binair"],
    platforms: ["Doen", "Zoomer"],
    contract_terms: ["Flexibel", "Vast"],
    contract_types: ["Fulltime", "Parttime", "Fulltime/Parttime", "Bijbaan"],
    data_fields: {
      first_name: "",
      last_name: "",
      email: "",
      tel_number: "",
      address: {
        street: "",
        postalcode: "",
        houseno: "",
        addition: "",
        region: "",
      },
      region: "",
      languages: [],
      platform: "",
      bullhorn: false,
      drivers_license: false,
      car: false,
      contract_signed: false,
      contract: "",
      dob: "",
      notes: "",
      contract_term: "",
      contract_type: "",
    },
    types: [
      {
        buttonText: "Persoon Aanmaken",
      },
      {
        buttonText: "Persoon Aanpassen",
      },
    ],
  }),

  computed: {
    formDataFields() {
      this.updateFormFields();
      return this.data_fields;
    },
    leadState() {
      return this.$store.state.leads;
    },
    leadUsersState() {
      return this.$store.state.lead_users;
    },
    computedDOB: {
      get() {
        return this.formatDate(this.data_fields.dob);
      },
      set() {
        return null;
      },
    },
    computedContract: {
      get() {
        return this.formatDate(this.data_fields.contract);
      },
      set() {
        return null;
      },
    },
  },

  created() {
    if (this.type == 1) {
      this.data_fields = this.formData;
      if (this.data_fields.languages == null) {
        this.data_fields.languages = [];
      }
    }
  },

  methods: {
    fullName(item) {
      return item.first_name + " " + item.last_name;
    },

    updateFormFields() {
      if (this.type == 1) {
        this.data_fields = this.formData;
        if (this.data_fields.address == null) {
          this.data_fields.address = {
            street: "",
            postalcode: "",
            houseno: "",
            addition: "",
            region: "",
          };
        }
      }
    },

    buttonClick() {
      if (this.type == 0) {
        this.createLeadUser();
      } else if (this.type == 1) {
        this.editLeadUser();
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    async editLeadUser() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        this.adding = true;
        let lead_user = {
          first_name: this.data_fields.first_name,
          last_name: this.data_fields.last_name,
          dob: this.data_fields.dob,
          gender: this.data_fields.gender,
          address: this.data_fields.address,
          tel_number: this.data_fields.tel_number,
          languages: this.data_fields.languages,
          region: this.data_fields.region,
          bullhorn: this.data_fields.bullhorn,
          email: this.data_fields.email,
          drivers_license: this.data_fields.drivers_license,
          car: this.data_fields.car,
          platform: this.data_fields.platform,
          sal_indication: this.data_fields.sal_indication,
          contract: this.data_fields.contract,
          contract_signed: this.data_fields.contract_signed,
          notes: this.data_fields.notes,
          contract_term: this.data_fields.contract_term,
          contract_type: this.data_fields.contract_type,
        };

        lead_user.address.region = lead_user.region;

        if (!lead_user.contract_signed) lead_user.contract = null;

        await calculateLocation(lead_user.address).then((res) => {
          lead_user.address.lat = res.lat;
          lead_user.address.lng = res.lng;
        });

        lead_user.address = JSON.stringify(lead_user.address);
        lead_user.languages = JSON.stringify(lead_user.languages);

        let userId = this.formData.id;

        if (this.$store.state.users.user.role <= 4) {
          await dispatch("lead_users/updateLeadUser", {
            lead_user,
            userId,
          }).then((lead) => {
            dispatch("alerts/success", "Persoon aangepast");
            dispatch("lead_users/getLeadUser", userId);
          });
          this.$emit("close-dialog");
        } else {
          dispatch("alerts/warn", "Je bent niet bevoegd om dit te doen");
        }
      } else {
        dispatch("alerts/warn", "Niet alle velden zijn ingevuld");
      }
      this.adding = false;
    },

    async createLeadUser() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        this.adding = true;
        let lead_user = {
          first_name: this.data_fields.first_name,
          last_name: this.data_fields.last_name,
          dob: this.data_fields.dob,
          gender: this.data_fields.gender,
          address: this.data_fields.address,
          tel_number: this.data_fields.tel_number,
          languages: this.data_fields.languages,
          region: this.data_fields.region,
          bullhorn: this.data_fields.bullhorn,
          email: this.data_fields.email,
          drivers_license: this.data_fields.drivers_license,
          car: this.data_fields.car,
          platform: this.data_fields.platform,
          sal_indication: this.data_fields.sal_indication,
          contract: this.data_fields.contract,
          contract_signed: this.data_fields.contract_signed,
          notes: this.data_fields.notes,
          contract_term: this.data_fields.contract_term,
          contract_type: this.data_fields.contract_type,
        };
        if (!lead_user.contract_signed) lead_user.contract = null;

        await calculateLocation(lead_user.address).then((res) => {
          lead_user.address.lat = res.lat;
          lead_user.address.lng = res.lng;
        });

        lead_user.address = JSON.stringify(lead_user.address);
        lead_user.languages = JSON.stringify(lead_user.languages);
        if (this.$store.state.users.user.role <= 4) {
          await dispatch("lead_users/createLeadUser", lead_user).then(
            (lead) => {
              dispatch("lead_users/getAllLeadUsers");
              dispatch("alerts/success", "Persoon aangemaakt");
            }
          );
          this.clear();
          this.$emit("close-dialog");
        } else {
          dispatch("alerts/warn", "Je bent niet bevoegd om dit te doen");
        }
      } else {
        dispatch("alerts/warn", "Niet alle velden zijn ingevuld");
      }
      this.adding = false;
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>
