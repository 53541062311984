import config from '../../config';
import { authHeader } from '../utils/auth-header';
export const companyService = {
    createCompany,
    getAllWithPlatformName,
    getAll,
    getCompany,
    updateCompany
};
function createCompany(company) {
    const request = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(company)
    }
    return fetch(`${config.apiUrl}/companies`, request)
        .then(handleResponse)
        .then(company => {
            return company;
        });
}

function updateCompany(company, compId) {
    const request = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(company)
    }
    return fetch(`${config.apiUrl}/companies/${compId}`, request)
        .then(handleResponse)
        .then(company => {
            return company;
        });
}

function getAll() {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/companies`, request)
        .then(handleResponse)
        .then(companies => {
            return companies;
        });
}

function getAllWithPlatformName(platform_name) {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/companies/platform/${platform_name}`, request)
        .then(handleResponse)
        .then(companies => {
            return companies;
        });
}

function getCompany(id) {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/companies/${id}`, request)
        .then(handleResponse)
        .then(company => {
            return company;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}