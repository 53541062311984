import { intakeDataService } from "../../services/intake_data.service";
import axios from "axios";
import config from "../../../config";
import { authHeader } from "../../utils/auth-header";

export const intake_data = {
  namespaced: true,
  state: {},
  actions: {
    createIntake({ dispatch, commit }, intakeData) {
      return new Promise((resolve, reject) => {
        intakeDataService
          .createIntake(intakeData)
          .then((intakeData) => resolve(intakeData));
      });
    },
    getIntakeByUser({ commit }, userId) {
      return new Promise((resolve, reject) => {
        intakeDataService.getIntakeByUser(userId).then((intake_data) => {
          if (intake_data.intake !== null) {
            intake_data.intake.practicalMatters = JSON.parse(
              intake_data.intake.practicalMatters
            );
            intake_data.intake.summary = JSON.parse(intake_data.intake.summary);
            intake_data.intake.swot = JSON.parse(intake_data.intake.swot);
            intake_data.intake.education = JSON.parse(
              intake_data.intake.education
            );
            intake_data.intake.workExpectations = JSON.parse(
              intake_data.intake.workExpectations
            );
            intake_data.intake.workExperience = JSON.parse(
              intake_data.intake.workExperience
            );
          }
          resolve(intake_data);
        });
      });
    },
  },
  mutations: {},
};
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
