import config from '../../config';
import { authHeader } from '../utils/auth-header';
export const leadService = {
    createLead,
    updateLead,
    getAllLeads,
    getLeadsByVacancyId,
    getAllChannels,
    getAllVacancies,
    getLead,
    addStatusToLead,
    getAllLeadsWithFlow
};
function createLead(lead) {
    const request = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(lead)
    }
    return fetch(`${config.apiUrl}/leads`, request)
        .then(handleResponse)
        .then(lead => {
            return lead;
        });
}

function updateLead(lead, leadId) {
    const request = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(lead)
    }
    return fetch(`${config.apiUrl}/leads/${leadId}`, request)
        .then(handleResponse)
        .then(lead => {
            return lead;
        });
}

function getAllLeads() {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/leads`, request)
        .then(handleResponse)
        .then(leads => {
            return leads;
        });
}

function getLead(leadId) {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/leads/${leadId}`, request)
        .then(handleResponse)
        .then(lead => {
            return lead;
        });
}
function addStatusToLead(leadStatus) {
    const request = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(leadStatus)
    }
    return fetch(`${config.apiUrl}/leads/status`, request)
        .then(handleResponse)
        .then(leadStatus => {
            return leadStatus;
        });
}

function getLeadsByVacancyId(vacancyId) {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    
    return fetch(`${config.apiUrl}/leads/vacancy/${vacancyId}`, request)
    .then(handleResponse)
    .then(leads => {
        return leads;
    })
}

function getAllLeadsWithFlow() {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/leadsWithFlow`, request)
        .then(handleResponse)
        .then(leads => {
            return leads;
        });
}
// -----------------------------------------------------------------

function getAllChannels() {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/lead-channel`, request)
        .then(handleResponse)
        .then(lead_channels => {
            return lead_channels;
        });
}

function getAllVacancies() {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/vacancies`, request)
        .then(handleResponse)
        .then(vacancies => {
            return vacancies;
        });
}

// ---------------------------------------------------------------

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}