import config from '../../config';
import { authHeader } from '../utils/auth-header';

export const contactMomentService = {
    createContactMoment,
    updateContactMoment,
    getContactMomentsForLeadUser
};

function createContactMoment(contact_moment) {
    const request = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(contact_moment)
    }
    return fetch(`${config.apiUrl}/contact-moments`, request)
        .then(handleResponse)
        .then(contact_moment => {
            return contact_moment;
        });
}

function updateContactMoment(contact_moment, contact_momentId) {
    const request = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(contact_moment)
    }
    return fetch(`${config.apiUrl}/contact-moments/${contact_momentId}`, request)
        .then(handleResponse)
        .then(contact_moment => {
            return contact_moment;
        });
}

function getContactMomentsForLeadUser(leadUserId) {
    const request = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${config.apiUrl}/contact-moments/leadUser/${leadUserId}`, request)
        .then(handleResponse)
        .then(contact_moments => {
            return contact_moments;
        });
}

// ---------------------------------------------------------------

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}