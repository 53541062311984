<template>
  <v-container fluid class="pa-5">
    <v-bottom-sheet v-model="flowSpeak.dialogSpeak" inset>
      <v-sheet class="text-center">
        <SpokenDatePicker
          v-on:close-dialog="flowSpeak.dialogSpeak = false"
          :type="flowSpeak.type"
          :leadID="flowSpeak.activeLeadId"
          :leadUserID="flowSpeak.activeLeadUserId"
          :leadName="flowSpeak.activeLeadName"
          :leadContactNo="flowSpeak.activeLeadContactNo"
          :vmCount="flowSpeak.activeLeadVmCount"
          :additional="flowSpeak.searchTerm"
        />
      </v-sheet>
    </v-bottom-sheet>
    <v-dialog v-model="dialogFilters" max-width="900px">
      <v-card>
        <VacancySearchTerms
          v-on:close-dialog="dialogFilters = false"
          :parentID="parentID"
          :searchFilters="filters"
          :leadStatuses="this.lead_statuses"
        ></VacancySearchTerms>
      </v-card>
    </v-dialog>
    <v-card class="pa-4" elevation="4">
      <v-row class="pb-4">
        <v-col md="8">
          <v-row class="pa-3">
            <h2>Recommended</h2>
            <v-divider
              vertical
              inset
              style="padding-left: 10px; padding-left: 10px"
            ></v-divider>
            <v-text-field
              style="padding-top: 0px; padding-left: 10px"
              v-model="basicSearch"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-row>
        </v-col>
        <v-col align="end" md="4">
          <v-btn
            :loading="!getLoading"
            @click="openFilterDialog()"
            color="primary"
            class="ml-4"
          >
            Filters
          </v-btn>
        </v-col>
      </v-row>
      <!-- <v-row class="pb-0 px-2">
        <v-checkbox
          class="ml-3"
          v-model="filterDistance"
          @change="filterByDistance()"
        ></v-checkbox>
        <v-col class="d-flex" cols="12" sm="6">
          <gmap-autocomplete
            :class="
              this.$vuetify.theme.dark ? 'gmapField-dark' : 'gmapField-light'
            "
            :v-model="place"
            placeholder="Input address"
            @place_changed="setPlace"
            :select-first-on-enter="true"
            :disabled="!filterDistance"
          >
          </gmap-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="6" sm="2">
          <v-select
            dense
            v-model="selectedRadius"
            :items="radius"
            item-text="distance"
            item-value="value"
            label="Radius"
            :hint="this.selectedRadius != null ? 'Radius' : ''"
            persistent-hint
            :disabled="!filterDistance"
            solo
            @change="saveFilter()"
          ></v-select>
        </v-col>
      </v-row> -->

      <v-progress-linear
        v-if="!getLoading"
        color="primary"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>

      <v-data-table
        v-if="getLoading === true && getDisplayData === true"
        :headers="headers"
        :search="basicSearch"
        :items="leadsuserAdjustedList"
        :items-per-page="5"
        sort-by="name"
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="columnSelectOpen(item)">
            mdi-open-in-new
          </v-icon>
        </template>
        <template v-slot:[`item.lead_status_name`]="{ item }">
          <v-chip :color="item.lead_status_color"
            ><div style="color: black">
              <strong>{{ item.lead_status_name }}</strong>
            </div></v-chip
          >
        </template>
        <template v-slot:[`item.has_driverslicense`]="{ item }">
          <v-checkbox
            :input-value="item.has_driverslicense"
            value
            disabled
          ></v-checkbox>
        </template>
        <template v-slot:[`item.has_car`]="{ item }">
          <v-checkbox :input-value="item.has_car" value disabled></v-checkbox>
        </template>

        <template v-slot:[`item.first_date`]="{ item }">
          <span v-if="item.first_date != null">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="green" dark v-bind="attrs" v-on="on">
                  mdi-phone
                </v-icon>
              </template>
              <span>{{ item.first_date }}</span>
            </v-tooltip>
          </span>
          <v-badge
            v-else-if="item.vm_date != null"
            bordered
            color="error"
            :content="item.vm_count"
            overlap
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="0"
                  color="error"
                  small
                  @click="openFlowSpoken(item)"
                  class="pl-1 pr-3"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-phone-missed</v-icon>
                </v-btn>
              </template>
              <span>{{ item.vm_date }}</span>
            </v-tooltip>
          </v-badge>
          <v-btn
            v-else
            elevation="0"
            color="primary"
            small
            @click="openFlowSpoken(item)"
            icon
          >
            <v-icon color="red" dark>
              mdi-phone
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Vue from "vue";
import { calculateDistance } from "../../helpers/google";
import VacancySearchTerms from "../../components/dialogs/VacancySearchTerms.vue";
import SpokenDatePicker from "../../components/dialogs/SpokenDatePicker";
export default {
  components: {
    SpokenDatePicker,
    VacancySearchTerms,
  },
  props: {
    parentID: Number,
  },
  data: () => ({
    basicSearch: "",
    dialogFilters: false,

    radius: [
      {
        distance: "2 km",
        value: 2000,
      },
      {
        distance: "5 km",
        value: 5000,
      },
      {
        distance: "10 km",
        value: 10000,
      },
      {
        distance: "20 km",
        value: 20000,
      },
      {
        distance: "50 km",
        value: 50000,
      },
      {
        distance: "100 km",
        value: 100000,
      },
      {
        distance: "200 km",
        value: 200000,
      },
    ],
    place: "",
    latLng: {
      lat: "",
      lng: "",
    },
    filterDistance: false,
    selectedRadius: null,
    filters: {
      statuses: [],

      has_car: null,
      has_license: null,
      gender: [],
      range: [10, 70],
      search: "",
    },
    genderSelect: ["Man", "Vrouw", "Transgender", "Non-binair"],
    companies: [],
    lead_statuses: [],
    flowSpeak: {
      activeLeadId: null,
      activeLeadUserId: null,
      activeLeadName: null,
      activeLeadContactNo: null,
      activeLeadVmCount: null,
      type: null,
      searchTerm: null,
      dialogSpeak: false,
    },
  }),

  computed: {
    headers() {
      return [
        {
          text: "Distance",
          value: "distanceFromCity",
          align: " d-none",
          filter: (value) => {
            if (!this.filterDistance) return true;
            return value < this.selectedRadius;
          },
        },
        { text: "Actie", value: "actions", sortable: false, align: "start" },
        {
          text: "Naam",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Status",
          sortable: true,
          value: "lead_status_name",
          filter: (value) => {
            if (!this.filters.statuses) return true;
            if (this.filters.statuses.length <= 0) return true;
            return this.filters.statuses.some((elem) => elem.name === value);
          },
        },
        {
          text: "Geslacht",
          sortable: true,
          value: "gender",
        },
        {
          text: "Woonplaats",
          sortable: true,
          value: "region",
          align: " d-none",
        },
        {
          text: "Rijbewijs",
          sortable: true,
          value: "has_driverslicense",
          filter: (value) => {
            if (this.filters.has_license === null) return true;

            return value === this.filters.has_license;
          },
        },
        {
          text: "Auto",
          sortable: true,
          value: "has_car",
          filter: (value) => {
            if (this.filters.has_car === null) return true;

            return value === this.filters.has_car;
          },
        },
        {
          text: "Travel Time",
          value: "travelDuration",
          sortable: true,
        },

        {
          text: "Geboortedatum",
          value: "dob",
          sortable: true,
        },
        {
          text: "Regio",
          sortable: true,
          value: "user_region",
          align: " d-none",
        },
        {
          text: "Contracttermijn",
          sortable: true,
          value: "contract_term",
          align: " d-none",
        },
        {
          text: "Recruiter",
          value: "recruiter_name",
          sortable: true,
        },
        {
          text: "Recruiter-assistent",
          value: "recruitment_assistant_name",
          sortable: true,
        },
        {
          text: "",
          value: "channel",
          align: " d-none",
        },
        {
          text: "",
          value: "gender",
          align: " d-none",
          filter: (value) => {
            if (!this.filters.gender) return true;
            if (this.filters.gender.length <= 0) return true;
            return this.filters.gender.some((elem) => elem === value);
          },
        },
        {
          text: "Bellen",
          value: "first_date",
          sortable: false,
        },
        {
          text: "Age",
          value: "age",
          align: " d-none",
          filter: (value) => {
            if (this.filters.length === 0) return true;
            if (
              value >= this.filters.range[0] &&
              value <= this.filters.range[1]
            ) {
              return value;
            }
          },
        },
      ];
    },
    leadsuserAdjustedList() {
      return this.lead_users.map((e) => {
        var mapped = {
          id: e.id,
          address: e.address,
          distanceFromCity: 200001,
          travelDuration: "-",
          user_region: e.region,
          name: `${e.first_name} ${e.last_name}`,
          gender: this.itemExists(e.gender),
          dob: this.itemExists(this.formatDate(e.dob)),
          age: this.getAge(this.itemExists(e.dob)),
          tel_number: this.itemExists(e.tel_number),
          region: this.itemExists(e.region),
          has_car: e.car,
          channel: this.itemExists(e.leads[e.leads.length - 1].channel).name,
          has_driverslicense: e.drivers_license,
          rec_id: this.itemExists(e.leads[e.leads.length - 1].recruiter).id,
          recruiter_name: this.fullName(e.leads[e.leads.length - 1].recruiter),
          rec_ass_id: this.itemExists(
            e.leads[e.leads.length - 1].recruitment_assistant
          ).id,
          recruitment_assistant_name: this.fullName(
            e.leads[e.leads.length - 1].recruitment_assistant
          ),
          active_lead_id: this.itemExists(e.leads[e.leads.length - 1].id),
          lead_status_name: this.itemExists(
            e.lead_statuses[e.lead_statuses.length - 1].status
          ).name,
          lead_status_color: this.itemExists(
            e.lead_statuses[e.lead_statuses.length - 1].status
          ).color,
          contract_term: this.itemExists(e.contract_term),
          contract_type: this.itemExists(e.contract_type),
          sal_indication: this.itemExists(e.sal_indication),
          email: this.itemExists(e.email),
          applied_date: this.formatDate(
            e.leads[e.leads.length - 1].applied_date
          ),
          flow: false,
        };

        if (e.leads[e.leads.length - 1].flow != null) {
          mapped.first_date = this.toLocalDate(
            e.leads[e.leads.length - 1].flow.first_date
          );
          mapped.vm_date = this.toLocalDate(
            e.leads[e.leads.length - 1].flow.vm_date
          );
          mapped.intake_date = this.toLocalDate(
            e.leads[e.leads.length - 1].flow.intake_date
          );
          mapped.intake_completed =
            e.leads[e.leads.length - 1].flow.intake_completed;
          mapped.placed_date = this.toLocalDate(
            e.leads[e.leads.length - 1].flow.placed_date
          );
          mapped.rec = e.leads[e.leads.length - 1].flow.rec;
          mapped.rec_full_name = this.fullName(
            e.leads[e.leads.length - 1].flow.rec
          );
          mapped.rec_ass_full_name = this.fullName(
            e.leads[e.leads.length - 1].flow.rec_ass
          );
          mapped.placed_cm = e.leads[e.leads.length - 1].flow.placed_cm;
          mapped.vm_count = e.leads[e.leads.length - 1].flow.vm_count;
          mapped.flow = true;
        }

        return mapped;
      });
    },
    ...mapState({
      lead_users: (state) => state.lead_users.filtered_lead_user,
      stored_filters: (state) => state.leads.filter,

      employees: (state) =>
        state.filters.lists.employees
          .filter((e) => e.first_name != null)
          .sort((a, b) => {
            if (a.first_name < b.first_name) {
              return -1;
            }
            if (a.first_name > b.first_name) {
              return 1;
            }
            return 0;
          })
          .map((e) => {
            return {
              id: e.id,
              full_name: e.first_name + " " + e.last_name,
            };
          }),
    }),
    ...mapGetters("lead_users", [
      "getLoading",
      "getFilteredLeadUser",
      "getDisplayData",
    ]),
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.setLoading(false);

      const { dispatch } = this.$store;

      await dispatch("leads/getAllChannels").then((res) => {
        this.channels = res.lead_channels;
      });

      await dispatch("statuses/getAllLeadStatus").then((res) => {
        this.lead_statuses = res.lead_statuses;
      });

      await dispatch("vacancy/getVacancy", this.$route.params.id).then(
        (res) => {
          if (res.vacancy.searchTerms) {
            this.filters = res.vacancy.searchTerms;
            this.fetchUsers();
            if (
              this.filters.statuses.length === 0 &&
              this.filters.has_car === null &&
              this.filters.has_license === null &&
              this.filters.gender.length === 0 &&
              this.filters.range[0] === 10 &&
              this.filters.range[1] === 70 &&
              this.filters.search === ""
            ) {
              this.setDisplayData(false);
            }
          } else {
            this.saveFilter();
            this.fetchUsers();
            this.setDisplayData(false);
            this.setLoading(true);
          }
        }
      );

      // this.filterByDistance();
    },
    async fetchUsers() {
      const { dispatch } = this.$store;
      if (this.filters.search !== "") {
        await dispatch(
          "lead_users/searchLeadUsersWithLeads",
          this.filters.search
        );
        this.setLoading(true);
      } else {
        await dispatch("lead_users/getLeadUsers");

        this.setLoading(true);
      }
    },
    async setLoading(bool) {
      const { dispatch } = this.$store;
      await dispatch("lead_users/setLoadingState", bool);
    },
    async setDisplayData(bool) {
      const { dispatch } = this.$store;
      await dispatch("lead_users/setDisplayDataState", bool);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    toLocalDate(date) {
      if (!date) return null;
      var utcDate = new Date(date);
      const localeString = utcDate.toLocaleString("nl-NL");
      return localeString.substring(0, localeString.length - 3);
    },
    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    fullName(item) {
      if (item == null || item == "") return "Niet toegewezen";
      return item.first_name + " " + item.last_name;
    },
    columnSelectOpen(item) {
      this.$router.push(`/profiel/${item.id}`);
    },
    openFlowSpoken(item) {
      this.flowSpeak.activeLeadId = item.active_lead_id;
      this.flowSpeak.activeLeadUserId = item.id;
      this.flowSpeak.activeLeadName = item.name;
      this.flowSpeak.activeLeadContactNo = item.tel_number;
      this.flowSpeak.activeLeadVmCount = item.vm_count;
      if (this.filters.search !== "") {
        this.flowSpeak.type = 3;
        this.flowSpeak.searchTerm = this.filters.search;
      } else {
        this.flowSpeak.type = 4;
        this.flowSpeak.searchTerm = "";
      }

      this.flowSpeak.dialogSpeak = true;
    },
    itemExists(item) {
      if (item == null || item == "") return "Onbekend";
      return item;
    },
    // setPlace(place) {
    //   if (!place) return;

    //   this.latLng = {
    //     lat: place.geometry.location.lat(),
    //     lng: place.geometry.location.lng(),
    //   };
    //   this.filterByDistance();
    // },
    remove(item) {
      let index = -1;
      this.filters.statuses.find(function(status, i) {
        if (status.name === item.name) {
          index = i;
        }
      });
      if (index >= 0) {
        this.filters.statuses.splice(index, 1);
      }
      this.saveFilter();
    },
    removeGender(item) {
      let index = -1;
      this.filters.gender.find(function(gender, i) {
        if (gender === item) {
          index = i;
        }
      });
      if (index >= 0) {
        this.filters.gender.splice(index, 1);
      }
      this.saveFilter();
    },
    async saveFilter() {
      const { dispatch } = this.$store;

      let id = this.$route.params.id;
      let searchTerms = JSON.parse(JSON.stringify(this.filters));
      await dispatch("vacancy/updateVacancySearchTerms", { searchTerms, id });
    },
    // async filterByDistance() {
    //   //Calculating distance
    //   if (this.filterDistance) {
    //     this.setLoading(false);
    //     for (let i = 0; i < this.leadsuserAdjustedList.length; i++) {
    //       let address = JSON.parse(this.leadsuserAdjustedList[i].address);
    //       if (address) {
    //         if (address.region == "" || address.region == null) {
    //           if (this.leadsuserAdjustedList[i].region != "Onbekend") {
    //             address.region = this.leadsuserAdjustedList[i].region;
    //           }
    //         }
    //       }
    //       if (this.latLng.lat !== "" && this.latLng.lng !== "") {
    //         await calculateDistance(
    //           address,
    //           this.latLng.lat,
    //           this.latLng.lng
    //         ).then((res) => {
    //           if (res != false) {
    //             if (res.rows[0].elements[0].status != "ZERO_RESULTS") {
    //               this.leadsuserAdjustedList[i].distanceFromCity =
    //                 res.rows[0].elements[0].distance.value;
    //               if (this.leadsuserAdjustedList[i].has_car) {
    //                 this.leadsuserAdjustedList[i].travelDuration =
    //                   res.rows[0].elements[0].duration.text;
    //               }
    //             } else {
    //               this.leadsuserAdjustedList[i].distanceFromCity = 200001;
    //             }
    //           } else {
    //             this.leadsuserAdjustedList[i].distanceFromCity = 200001;
    //           }
    //         });
    //       }
    //     }
    //     this.setLoading(true);
    //   }
    // },
    openFilterDialog() {
      this.dialogFilters = true;
    },
  },
};
</script>
