import { dashboardService } from '../../services/dashboard.service';

export const dashboard = {
    namespaced: true,
    state: {},
    actions: {
        getGlobalAnalytics({commit}, {startDate, endDate}) {
            return new Promise((resolve, reject) => {
                dashboardService.getGlobalAnalytics(startDate, endDate)
                    .then(
                        dashboard => resolve(dashboard)
                    )
            });
        },
        getRecruiterAnalytics({commit}, {recId, startDate, endDate}) {
            return new Promise((resolve, reject) => {
                dashboardService.getRecruiterAnalytics(recId, startDate, endDate)
                    .then(
                        dashboard => resolve(dashboard)
                    )
            });
        },
        getRecruitmentAssistentAnalytics({commit}, {raId, startDate, endDate}) {
            return new Promise((resolve, reject) => {
                dashboardService.getRecruitmentAssistentAnalytics(raId, startDate, endDate)
                    .then(
                        dashboard => resolve(dashboard)
                    )
            });
        }
    }
}