<template>
  <v-card :loading="!loaded">
    <v-container class="pa-5">
      <v-form ref="form" v-model="valid" lazy-validation v-if="loaded">
        <v-row>
          <v-col md="6">
            <v-autocomplete
              :items="contactMomentStatusesList"
              item-text="name"
              item-value="id"
              required
              :rules="[(v) => !!v || 'Type is vereist']"
              v-model="formDataFields.type"
              label="Contact Moment Type"
              persistent-hint
              hint="Wat voor soort contact moment"
            ></v-autocomplete>
          </v-col>
          <v-col md="6">
            <v-autocomplete
              :items="vacanciesList"
              item-text="name"
              item-value="id"
              v-model="formDataFields.vacancy_id"
              label="Vacature"
              persistent-hint
              hint="Ging dit moment over een vacature?"
            ></v-autocomplete>
          </v-col>
          <v-col md="6" v-if="noStatus || type === '0'">
            <v-autocomplete
              :items="leadStatusesList"
              item-text="name"
              item-value="id"
              v-model="data_fields_status.lead_status_id"
              label="Persoon Status"
              persistent-hint
              hint="Status van lead na afloop van gesprek (Niet verplicht)"
            ></v-autocomplete>
          </v-col>
          <v-col md="6"
            ><v-checkbox
              v-model="flowChanges.oldLead"
              label="Geen flow gebruiken (alleen voor oude leads)"
            ></v-checkbox
          ></v-col>
        </v-row>
        <div v-if="this.type == 0 && !flowChanges.oldLead">
          <v-card v-if="data_fields_status.lead_status_id == 3" class="ma-4">
            <h4 class="pl-4 pt-4">Plan Intake</h4>
            <v-card-text>
              <v-row class="pt-0">
                <v-col class="pt-0">
                  <v-select
                    :items="employees"
                    v-model="flowChanges.intakeRecruiter"
                    label="Recruiter"
                    :rules="[(v) => !!v || 'Medewerker is vereist']"
                    required
                  >
                    <template v-slot:item="{ item }">
                      {{ item.full_name }}
                    </template>
                    <template v-slot:selection="{ item }">
                      {{ item.full_name }}
                    </template></v-select
                  >
                </v-col>
                <v-col class="pt-0">
                  <DateTimePicker v-model="flowChanges.intakeDateTime" />
                </v-col>
              </v-row>
              <v-row class="pt-0 mt-0">
                <v-checkbox
                  v-model="flowChanges.addToTeamUp"
                  label="Add to calendar"
                ></v-checkbox>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card
            class="ma-4"
            v-if="!flowHasIntake && data_fields_status.lead_status_id > 3"
          >
            <v-banner elevation="3" icon="mdi-alert" color="warning"
              >Let op er is nog geen intake gepland</v-banner
            >
          </v-card>
          <v-card
            v-if="
              data_fields_status.lead_status_id > 3 &&
                flowHasIntake &&
                !flowIntaked
            "
            class="ma-4"
          >
            <h4 class="pl-4 pt-4">Intake Afhandelen</h4>
            <v-card-text>
              <v-row class="pt-0">
                <v-col class="pt-0">
                  <v-checkbox
                    v-model="flowChanges.intaked"
                    label="Intake afgerond"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card
            v-if="
              data_fields_status.lead_status_id == 5 ||
                data_fields_status.lead_status_id == 16 ||
                data_fields_status.lead_status_id == 17
            "
            class="ma-4"
          >
            <v-banner
              elevation="3"
              icon="mdi-alert"
              color="error"
              v-if="!flowIntaked"
              >Intake nog niet afgerond doe dit hierboven</v-banner
            >
            <h4 class="pl-4 pt-4">Plaatsen</h4>
            <v-card-text>
              <v-row class="pt-0">
                <v-col class="pt-0">
                  <v-select
                    :items="communityManagers"
                    v-model="flowChanges.placedCm"
                    item-text="full_name"
                    item-value="id"
                    label="Community Manager"
                    :rules="[(v) => !!v || 'Medewerker is vereist']"
                    required
                  ></v-select>
                </v-col>
                <v-col class="pt-0">
                  <DatePicker v-model="flowChanges.placedDate" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
        <v-row>
          <v-col md="12">
            <v-textarea
              v-model="data_fields.notes"
              filled
              label="Notities"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-btn
            :disabled="!valid || adding"
            color="success"
            class="mr-4"
            @click="buttonClick"
          >
            {{ types[type].buttonText }}
          </v-btn>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import DateTimePicker from "./DateTimePicker";
import DatePicker from "./DatePicker";
export default {
  components: { DateTimePicker, DatePicker },
  props: {
    type: String,
    formData: Object,
    parentID: Number,
    lead: Object,
    leadUserName: String,
  },
  data: () => ({
    loaded: false,
    contactMomentStatusesList: [],
    leadStatusesList: [],
    vacanciesList: [],
    adding: false,
    valid: false,
    data_fields_status: {
      lead_status_id: 0,
    },
    data_fields: {
      lead_user_id: null,
      lead_user: [],
      type: null,
      comunicator_id: null,
      user_status_id: null,
      notes: null,
      vacancy_id: null,
    },
    flowChanges: {
      intakeRecruiter: null,
      intakeDateTime: null,

      intaked: false,
      placedDate: null,
      placedCm: null,
      oldLead: false,
      addToTeamUp: true,
    },

    noStatus: false,
    types: [
      {
        buttonText: "Contact Moment Aanmaken",
      },
      {
        buttonText: "Contact Moment Aanpassen",
      },
    ],
  }),

  computed: {
    formDataFields() {
      this.updateFormFields();
      return this.data_fields;
    },

    ...mapState({
      employees: (state) =>
        state.users.all.items.employees
          .filter((e) => e.role !== null) //Removed this as roles are a bit odd at the momment
          .sort((a, b) => {
            if (a.first_name < b.first_name) {
              return -1;
            }
            if (a.first_name > b.first_name) {
              return 1;
            }
            return 0;
          })
          .map((e) => {
            return {
              id: e.id,
              full_name: e.first_name + " " + e.last_name,
              cal_id: e.cal_id,
            };
          }),
      communityManagers: (state) =>
        state.users.all.items.employees
          .filter((e) => e.role !== null) //Removed this as roles are a bit odd at the momment
          .sort((a, b) => {
            if (a.first_name < b.first_name) {
              return -1;
            }
            if (a.first_name > b.first_name) {
              return 1;
            }
            return 0;
          })
          .map((e) => {
            return {
              id: e.id,
              full_name: e.first_name + " " + e.last_name,
            };
          }),
    }),

    leadHasFlow() {
      if (this.lead.flow != null) {
        return true;
      }
      return false;
    },

    flowHasIntake() {
      if (this.leadHasFlow) {
        if (this.lead.flow.intake_date != null) {
          return true;
        }
      }
      return false;
    },

    flowIntaked() {
      if (this.leadHasFlow) {
        if (this.lead.flow.intake_completed != null) {
          return true;
        }
      }
      return false;
    },
  },
  created() {
    if (this.type == 1) {
      this.data_fields = this.formData;
      if (this.formData.user_status) {
        this.data_fields_status.lead_status_id = this.formData.user_status.lead_status_id;
        this.noStatus = false;
      } else {
        this.data_fields_status.lead_status_id = null;
        this.noStatus = true;
      }
      this.userSwitch = true;
      this.editMode = true;
    }
    this.initialize();
  },

  methods: {
    ...mapActions("teamup", ["createEvent"]),
    fullName(item) {
      return item.first_name + " " + item.last_name;
    },

    async initialize() {
      const { dispatch } = this.$store;
      await dispatch("leads/getAllVacancies").then((res) => {
        this.vacanciesList = res.vacancies.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      });
      await dispatch("statuses/getAllContactMomentStatus").then((res) => {
        this.contactMomentStatusesList = res.contact_moment_statuses;
      });
      await dispatch("statuses/getAllLeadStatus").then((res) => {
        this.leadStatusesList = res.lead_statuses;
      });

      await dispatch("users/getAll").then((res) => {});
      this.loaded = true;
    },

    updateFormFields() {
      if (this.type == "1") {
        this.data_fields = this.formData;
        if (this.formData.user_status) {
          this.data_fields_status.lead_status_id = this.formData.user_status.lead_status_id;
          this.noStatus = false;
        } else {
          this.data_fields_status.lead_status_id = null;
          this.noStatus = true;
        }
      } else {
        this.data_fields_status = {
          lead_status_id: null,
        };
        this.data_fields = {
          lead_user_id: null,
          type: null,
          comunicator_id: null,
          user_status_id: null,
          notes: null,
          vacancy_id: this.lead.vacancy_id,
        };
        this.flowChanges.intakeRecruiter = this.$store.state.users.user;
        this.flowChanges.placedCm = this.lead.vacancy.cm_id;
      }
    },

    buttonClick() {
      if (this.type == 0) {
        this.createContactMoment();
      } else if (this.type == 1) {
        this.editContactMoment();
      }
    },

    async editContactMoment() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        if (this.$store.state.users.user.role <= 2) {
          this.adding = true;

          let contact_moment = {
            lead_user_id: this.parentID,
            user_status_id: this.data_fields_status.lead_status_id,
            type: this.data_fields.type,
            notes: this.data_fields.notes,
            vacancy_id: this.data_fields.vacancy_id,
          };

          const contact_momentId = this.formData.id;

          if (this.noStatus && this.contact_moment.lead_user_id) {
            let leadUserStatus = {
              lead_user_id: contact_moment.lead_user_id,
              lead_status_id: this.data_fields_status.lead_status_id,
            };

            await dispatch(
              "lead_users/addStatusToLeadUser",
              leadUserStatus
            ).then((res) => {
              contact_moment.user_status_id = res.newLeadUserStatus.id;
            });
          }

          await dispatch("contact_moments/updateContactMoment", {
            contact_moment,
            contact_momentId,
          }).then((res) => {
            dispatch("alerts/success", "Contact moment aangepast");
            dispatch("lead_users/getLeadUser", this.parentID);
          });
          this.$emit("close-dialog");
        } else {
          dispatch("alerts/warn", "Je bent niet bevoegd om dit te doen");
        }
      } else {
        dispatch("alerts/warn", "Niet alle velden zijn ingevuld");
      }
      this.adding = false;
    },

    async createContactMoment() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        if (this.$store.state.users.user.role <= 4) {
          this.adding = true;
          let newContactMoment = {
            lead_user_id: this.parentID,
            type: this.data_fields.type,
            comunicator_id: this.$store.state.users.user.id,
            user_status_id: null,
            notes: this.data_fields.notes,
            vacancy_id: this.data_fields.vacancy_id,
          };

          if (this.data_fields_status.lead_status_id) {
            let leadUserStatus = {
              lead_user_id: newContactMoment.lead_user_id,
              lead_status_id: this.data_fields_status.lead_status_id,
            };

            await dispatch(
              "lead_users/addStatusToLeadUser",
              leadUserStatus
            ).then((res) => {
              newContactMoment.user_status_id = res.newLeadUserStatus.id;
            });
            let flowChanged = false;
            let newFlow = this.lead.flow;
            if (this.data_fields_status.lead_status_id == 3) {
              // If status is plan intake, the fields for intake planning will be required
              if (this.flowChanges.addToTeamUp) {
                let tempStartDate = new Date(this.flowChanges.intakeDateTime);
                let x = new Date().getTimezoneOffset() * 60000;
                let startDate = new Date(tempStartDate - x).toISOString();
                let tempEndDate = new Date(
                  tempStartDate.setHours(tempStartDate.getHours() + 1)
                );
                let endDate = new Date(tempEndDate - x).toISOString();
                let fullName = this.leadUserName;

                this.createEvent({
                  sub_calendar_id: this.flowChanges.intakeRecruiter.cal_id,
                  startTime: startDate.slice(0, -5),
                  endTime: endDate.slice(0, -5),
                  title: fullName,
                });
              }

              newFlow.intake_date = this.flowChanges.intakeDateTime;
              newFlow.intake_plan_ass = this.$store.state.users.user.id;
              newFlow.intake_rec = this.flowChanges.intakeRecruiter.id;

              flowChanged = true;
            } else if (this.data_fields_status.lead_status_id > 3) {
              // The other entry fields
              if (this.flowHasIntake && !this.flowIntaked) {
                // Finish intake
                if (this.flowChanges.intaked == true) {
                  newFlow.intake_completed = this.flowChanges.intaked;
                  flowChanged = true;
                }
              }
              if (
                this.data_fields_status.lead_status_id == 5 ||
                this.data_fields_status.lead_status_id == 16 ||
                this.data_fields_status.lead_status_id == 17
              ) {
                // Geplaatst, DOEN Poule, Zoemer Poule
                let temp = null;
                if (this.flowChanges.placedDate === null) {
                  this.flowChanges.placedDate = new Date(
                    Date.now()
                  ).toISOString();
                  temp = this.flowChanges.placedDate.slice(0, 10);
                } else {
                  temp = this.flowChanges.placedDate;
                }
                newFlow.placed_cm = this.flowChanges.placedCm;
                newFlow.placed_date = temp;
                flowChanged = true;
              }
            }
            if (flowChanged) {
              const leadId = newFlow.lead_id;
              const flow = newFlow;
              await dispatch("flow/updateFlow", { flow, leadId });
            }
          }

          await dispatch(
            "contact_moments/createContactMoment",
            newContactMoment
          ).then((res) => {
            dispatch("alerts/success", "Contact moment aangemaakt");
            dispatch("lead_users/getLeadUser", newContactMoment.lead_user_id);
            this.clear();
            this.$emit("close-dialog");
            this.$emit("reload");
          });
        } else {
          dispatch("alerts/warn", "Je bent niet bevoegd om dit te doen");
        }
      } else {
        dispatch("alerts/warn", "Niet alle verplichte velden zijn ingevuld");
      }
      this.adding = false;
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>
