import { vacancyService } from "../../services/vacancy.service";

export const vacancy = {
  namespaced: true,
  state: {
    status: {},
    activeVacancy: null,
    vacancies: {},
    statuses: {},
    filter: {},
    pagination: {},
  },
  actions: {
    getVacancies({ commit }) {
      commit("getAllRequest");
      return new Promise((resolve, reject) => {
        vacancyService.getAll().then(
          (vacancies) => {
            commit("getAllSuccess", vacancies);
            resolve(vacancies);
          },
          (error) => commit("getAllFailure", error)
        );
      });
    },
    createVacancy({ dispatch, commit }, vacancy) {
      return new Promise((resolve, reject) => {
        vacancyService.createVacancy(vacancy).then(resolve(vacancy));
      });
    },
    getVacancy({ commit }, id) {
      return new Promise((resolve, reject) => {
        vacancyService.getVacancy(id).then(
          (vacancy) => {
            vacancy.vacancy.searchTerms = JSON.parse(
              vacancy.vacancy.searchTerms
            );
            vacancy.vacancy.address = JSON.parse(vacancy.vacancy.address);
            vacancy.vacancy.terms = JSON.parse(vacancy.vacancy.terms);
            commit("getVacancySuccess", vacancy);
            resolve(vacancy);
          },
          (error) => commit("getAllFailure", error)
        );
      });
    },

    getVacancyStatuses({ commit }) {
      return new Promise((resolve, reject) => {
        vacancyService.getVacancyStatuses().then(
          (statuses) => {
            commit("getVacancyStatusesSuccess", statuses);
            resolve(statuses);
          },
          (error) => commit("getAllFailure", error)
        );
      });
    },

    getVacanciesByCompanyId({ commit }, id) {
      return new Promise((resolve, reject) => {
        vacancyService.getVacanciesByCompanyId(id).then(
          (vacancies) => {
            commit("getAllSuccess", vacancies);
            resolve(vacancies);
          },
          (error) => commit("getAllFailure", error)
        );
      });
    },
    updateVacancy({ dispatch, commit }, { vacancy, id }) {
      return new Promise((resolve, reject) => {
        vacancyService.updateVacancy(vacancy, id).then(resolve(vacancy));
      });
    },
    updateVacancySearchTerms({ dispatch, commit }, { searchTerms, id }) {
      return new Promise((resolve, reject) => {
        vacancyService
          .updateVacancySearchTerms(searchTerms, id)
          .then(resolve(vacancy));
      });
    },
    updatePagination({ commit }, pagination) {
      commit("setPaginationState", pagination);
    },
  },
  mutations: {
    getAllRequest(state) {
      state.status = { loading: true };
    },
    getAllSuccess(state, vacancies) {
      state.status = { loading: false };
      state.vacancies = vacancies;
    },
    getAllFailure(state, error) {
      state.status = { loading: false };
      state.all = { error };
    },
    getVacancyRequest(state) {
      state.status = { loading: true };
    },
    getVacancySuccess(state, vacancy) {
      state.status = { loading: false };
      state.activeVacancy = vacancy;
    },
    getVacancyStatusesSuccess(state, statuses) {
      state.status = { loading: false };
      state.statuses = statuses;
    },
    setPaginationState(state, pagination) {
      state.pagination = pagination;
    },
  },
};
