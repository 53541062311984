<template>
  <v-container>
    <div v-if="!$auth.loading">
      <div v-if="!$auth.isAuthenticated">
        <Login/>
      </div>
      <div v-if="$auth.isAuthenticated">
        <v-container v-if="userHasBeenLoaded">
          <v-row justify="center">
            <h1>Hallo {{user.first_name}} welkom in het CRM systeem</h1>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-container>
</template>

<script>
// @ is an alias to /src
import Login from "../components/Login"
export default {
  name: "Home",
  components: {
    Login
  },
  methods: {

  },
  computed: {
    user() {
      return this.$store.state.users.user;
    },
    userHasBeenLoaded(){
      return this.$store.state.users.status.loggedIn;
    },
  },
};
</script>
