<template>
  <v-container class="pa-5">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-text-field
          v-model="formDataFields.name"
          label="Contact moment status naam"
          required
          :loading="adding"
        ></v-text-field>
      </v-row>
      <v-row justify="center">
        <v-btn
          :disabled="!valid || adding"
          color="success"
          class="mr-4"
          @click="buttonClick"
        >
          {{ types[type].buttonText }}
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  props: {
    type: String,
    formData: Object,
    parentID: Number,
  },
  data: () => ({
    adding: false,
    valid: false,
    data_fields: {
      name: "",
    },
    types: [
      {
        buttonText: "Contact Moment Status Aanmaken",
      },
      {
        buttonText: "Contact Moment Status Aanpassen",
      },
    ],
  }),

  computed: {
    formDataFields() {
      this.updateFormFields();
      return this.data_fields;
    },
  },

  created() {
    if (this.type == 1) {
      this.data_fields = this.formData;
    }
  },

  methods: {
    updateFormFields() {
      if (this.type == 1) {
        this.data_fields = this.formData;
      }
    },

    buttonClick() {
      if (this.type == 0) {
        this.createContactMomentStatus();
      } else if (this.type == 1) {
        this.editContactMomentStatus();
      }
    },

    async editContactMomentStatus() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        this.adding = true;
        let contact_moment_status = {
          name: this.data_fields.name,
        };
        let contact_moment_status_id = this.formData.id;
        if (this.$store.state.users.user.role <= 1) {
          await dispatch("statuses/updateContactMomentStatus", {
            contact_moment_status,
            contact_moment_status_id,
          }).then((contact_moment_status) => {
            dispatch("alerts/success", "Contact moment status aangepast");
            dispatch("statuses/getAllContactMomentStatus");
            this.$emit('close-dialog');
          });
        } else {
          dispatch("alerts/warn", "Je bent niet bevoegd om dit te doen");
        }
      } else {
        dispatch("alerts/warn", "Niet alle velden zijn ingevuld");
      }
      this.adding = false;
    },

    async createContactMomentStatus() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        this.adding = true;
        let contact_moment_status = {
          name: this.data_fields.name,
        };
        if (this.$store.state.users.user.role <= 1) {
          await dispatch(
            "statuses/createContactMomentStatus",
            contact_moment_status
          ).then((contact_moment_status) => {
            dispatch("alerts/success", "Contact moment status aangemaakt");
            dispatch("statuses/getAllContactMomentStatus");
            this.clear();
            this.$emit('close-dialog');
          });
        } else {
          dispatch("alerts/warn", "Je bent niet bevoegd om dit te doen");
        }
      } else {
        dispatch("alerts/warn", "Niet alle velden zijn ingevuld");
      }
      this.adding = false;
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped>
</style>