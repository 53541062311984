import { userService } from '../../services';

export const users = {
    namespaced: true,
    state: {
        all: {},
        status: {
            loggedIn: false,
        }
    },
    getters: {
        getLoggedIn: (state) => state.status.loggedIn,
    },
    actions: {
        getAll({ commit }) {
            return new Promise((resolve, reject) => {
                userService.getAll()
                    .then(
                        users => {
                            commit('getAllSuccess', users)
                            resolve(users)
                        },
                        error => commit('getAllFailure', error)
                    );
            })
        },
        getSelf({ dispatch, commit }, oAuthID) {
            return new Promise((resolve, reject) => {
                userService.getSelf(oAuthID)
                    .then(response => {
                        commit('setSelf', response)
                        resolve(response)
                    })
                    .catch(error => {
                        commit('getAllFailure', error);
                        dispatch('alerts/error', "Failed to get user details", { root: true });
                    })
            })
        },
        createEmployee({ dispatch, commit }, employee) {
            return new Promise((resolve, reject) => {
                userService.createEmployee(employee)
                    .then(
                        employee => {
                            resolve(employee)
                        })
            })
        },
        updateEmployee({ dispatch, commit }, { employee, empId }) {
            return new Promise((resolve, reject) => {
                userService.updateEmployee(employee, empId)
                    .then(
                        employee => {
                            resolve(employee)
                        })
            })
        },
    },
    mutations: {
        getAllRequest(state) {
            state.all = { loading: true };
        },
        getAllSuccess(state, users) {
            state.all = { items: users };
        },
        getAllFailure(state, error) {
            state.all = { error };
        },
        setSelf(state, user) {
            state.status = { loggedIn: true };
            state.user = user;
        },
    }
}
