<template>
  <v-card class="pa-8">
    <v-select
      v-model="selectedHeaders"
      :items="this.headers.filter(nonPerma)"
      label="Selecteer Kolommen"
      multiple
      item-text="text"
      item-value="text"
      @change="headersChanged($event)"
    >
      <template v-slot:selection="{ item, index }">
        <v-chip v-if="index === 0">
          <span>{{ item.text }}</span>
        </v-chip>
        <span v-if="index === 1" class="grey--text caption"
          >(+{{ selectedHeaders.length - 1 }} andere)</span
        >
      </template>
    </v-select>
    <v-btn class="ml-2 mr-2" color="primary" @click="saveColumnPreferences"
      >Sla op</v-btn
    >
  </v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    headers: Array,
    type: String,
  },
  data: () => ({
    selectedHeaders: [],
  }),
  computed: {
    ...mapState({
      currentUser: (state) => state.users.user,
    }),
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      switch (this.type) {
        case "Leads":
          {
            if (this.currentUser.lead_columns !== "") {
              this.selectedHeaders = this.currentUser.lead_columns.split(",");
            }
          }
          break;
        case "Search":
          {
            if (this.currentUser.search_columns !== "") {
              this.selectedHeaders = this.currentUser.search_columns.split(",");
            }
          }
          break;
      }
    },

    async saveColumnPreferences() {
      let employee = this.currentUser;
      const empId = this.currentUser.id;
      const { dispatch } = this.$store;
      switch (this.type) {
        case "Leads":
          {
            employee.lead_columns = this.selectedHeaders.toString();
          }
          break;
        case "Search":
          {
            employee.search_columns = this.selectedHeaders.toString();
          }
          break;
      }
      await dispatch("users/updateEmployee", { employee, empId }).then(() => {
        dispatch("alerts/success", "Opgeslagen");
        this.$emit("close-dialog");
        this.dialogColumns = false;
      });
    },

    nonPerma(item){
      if(item.perma){
        return false;
      }
      return true;
    },

    headersChanged(e) {
      this.$emit("headers-changed", e);
    },
  },
};
</script>