<template>
  <v-container fluid>
    <v-card class="pa-4">
      <v-card-title class="pt-0 mt-0 pb-0">
        <v-container class="pt-0 mt-0 ml-0 pb-0 mr-0" fluid>
          <v-row class="pr-1">
            <v-col class="mt-6 pl-0 pb-0">
              <h3>Lead Flow Dashboard</h3>
            </v-col>
            <v-spacer />
            <v-col class="pb-0" align-self="end">
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="selection.year"
                    label="Jaar"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="selection.week"
                    label="Week"
                  ></v-text-field>
                </v-col>
                <v-btn
                  class="pl-2 pr-2 mt-3 mt-6"
                  color="blue"
                  @click="getData()"
                >
                  Haal data <v-icon>mdi-text-box-search</v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-container fluid v-if="filterDataLoaded">
        <v-row dense class="mr-1">
          <v-col>
            <v-autocomplete
              :items="vacancies"
              v-model="filters.vacancy"
              clearable
              item-text="name"
              item-value="id"
              label="Vacature"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :items="channels"
              v-model="filters.source"
              clearable
              item-text="name"
              item-value="id"
              label="Kanaal"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :items="employees"
              v-model="filters.rec"
              clearable
              item-text="full_name"
              item-value="id"
              label="Recruiter"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :items="employees"
              v-model="filters.recAss"
              clearable
              item-text="full_name"
              item-value="id"
              label="Assistent"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :items="employees"
              v-model="filters.cm"
              clearable
              item-text="full_name"
              item-value="id"
              label="Community Manager"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row dense class="mt-0">
          <v-col align="end" class="mr-2 mt-0 mb-0">
            <v-btn
              color="blue"
              @click="finalCalculations()"
              class="pr-2 pl-2 mr-2"
              ><span class="mr-1">Apply Filters</span
              ><v-icon dark>mdi-magnify</v-icon></v-btn
            >
            <v-btn color="red" @click="clearFilters()" class="pr-2 pl-2 ml-2"
              ><span class="mr-1">Clear Filters</span
              ><v-icon dark>mdi-backspace</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="computedWeekData.length != 0">
        <div v-for="item in computedWeekData" :key="item.weekNo">
          <h2>Week: {{ item.weekNo }}</h2>
          <v-row class="pb-4">
            <v-col md="2">
              <v-card elevation="5" height="100%">
                <v-card-title>Leads</v-card-title>
                <v-card-subtitle>Solliciaties</v-card-subtitle>
                <v-container class="pt-0 ml-4">
                  <v-row class="pb-0 pr-4">
                    <v-col class="pl-0 pb-0 pt-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-equal-box
                          </v-icon>
                        </template>
                        <span>Hoeveel</span>
                      </v-tooltip>
                    </v-col>
                    <v-spacer />
                    <v-col
                      align-self="end"
                      class="pb-0 pt-0"
                      style="text-align: right"
                    >
                      <h3>{{ item.leadsCount }}</h3>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
            <v-col md="2">
              <v-card elevation="5" height="100%">
                <v-card-title>Contact</v-card-title>
                <v-card-subtitle>Eerste contact</v-card-subtitle>
                <v-container class="pt-0 ml-4">
                  <v-row class="pb-0 pr-4">
                    <v-col class="pl-0 pb-0 pt-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-equal-box
                          </v-icon>
                        </template>
                        <span>Hoeveel</span>
                      </v-tooltip>
                    </v-col>
                    <v-spacer />
                    <v-col
                      align-self="end"
                      class="pb-0 pt-0"
                      style="text-align: right"
                    >
                      <h3>{{ item.contactCount }}</h3>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pb-0 pr-4">
                    <v-col class="pl-0 pb-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-percent
                          </v-icon>
                        </template>
                        <span>Door stroom van vorige fase (%)</span>
                      </v-tooltip>
                    </v-col>
                    <v-spacer />
                    <v-col
                      align-self="end"
                      class="pb-0"
                      style="text-align: right"
                    >
                      <h3>
                        {{
                          phasePercentage(item.leadsCount, item.contactCount)
                        }}
                      </h3>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pb-0 pr-4">
                    <v-col class="pl-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-calendar-clock
                          </v-icon>
                        </template>
                        <span>Gem. daggen tussen vorige fase (dagen)</span>
                      </v-tooltip>
                    </v-col>
                    <v-spacer />
                    <v-col align-self="end" style="text-align: right">
                      <h3>{{ phaseNeatDayDif(item.toContactDays) }}</h3>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
            <v-col md="2">
              <v-card elevation="5" height="100%">
                <v-card-title>Intake</v-card-title>
                <v-card-subtitle>Intake gepland</v-card-subtitle>
                <v-container class="pt-0 ml-4">
                  <v-row class="pb-0 pr-4">
                    <v-col class="pl-0 pb-0 pt-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-equal-box
                          </v-icon>
                        </template>
                        <span>Hoeveel</span>
                      </v-tooltip>
                    </v-col>
                    <v-spacer />
                    <v-col
                      align-self="end"
                      class="pb-0 pt-0"
                      style="text-align: right"
                    >
                      <h3>{{ item.intakeCount }}</h3>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pb-0 pr-4">
                    <v-col class="pl-0 pb-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-percent
                          </v-icon>
                        </template>
                        <span>Door stroom van vorige fase (%)</span>
                      </v-tooltip>
                    </v-col>
                    <v-spacer />
                    <v-col
                      align-self="end"
                      class="pb-0"
                      style="text-align: right"
                    >
                      <h3>
                        {{
                          phasePercentage(item.contactCount, item.intakeCount)
                        }}
                      </h3>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
            <v-col md="2">
              <v-card elevation="5" height="100%">
                <v-card-title>Intaked</v-card-title>
                <v-card-subtitle>Intake plaats gevonden</v-card-subtitle>
                <v-container class="pt-0 ml-4">
                  <v-row class="pb-0 pr-4">
                    <v-col class="pl-0 pb-0 pt-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-equal-box
                          </v-icon>
                        </template>
                        <span>Hoeveel</span>
                      </v-tooltip>
                    </v-col>
                    <v-spacer />
                    <v-col
                      align-self="end"
                      class="pb-0 pt-0"
                      style="text-align: right"
                    >
                      <h3>{{ item.intakedCount }}</h3>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pb-0 pr-4">
                    <v-col class="pl-0 pb-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-percent
                          </v-icon>
                        </template>
                        <span>Door stroom van vorige fase (%)</span>
                      </v-tooltip>
                    </v-col>
                    <v-spacer />
                    <v-col
                      align-self="end"
                      class="pb-0"
                      style="text-align: right"
                    >
                      <h3>
                        {{
                          phasePercentage(item.intakeCount, item.intakedCount)
                        }}
                      </h3>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pb-0 pr-4">
                    <v-col class="pl-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-calendar-clock
                          </v-icon>
                        </template>
                        <span>Gem. daggen tussen vorige fase (dagen)</span>
                      </v-tooltip>
                    </v-col>
                    <v-spacer />
                    <v-col align-self="end" style="text-align: right">
                      <h3>{{ phaseNeatDayDif(item.toIntakedDays) }}</h3>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
            <v-col md="2">
              <v-card elevation="5" height="100%">
                <v-card-title>Geplaatst</v-card-title>
                <v-card-subtitle>Begonnen met werken</v-card-subtitle>
                <v-container class="pt-0 ml-4">
                  <v-row class="pb-0 pr-4">
                    <v-col class="pl-0 pb-0 pt-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-equal-box
                          </v-icon>
                        </template>
                        <span>Hoeveel</span>
                      </v-tooltip>
                    </v-col>
                    <v-spacer />
                    <v-col
                      align-self="end"
                      class="pb-0 pt-0"
                      style="text-align: right"
                    >
                      <h3>{{ item.placedCount }}</h3>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pb-0 pr-4">
                    <v-col class="pl-0 pb-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-percent
                          </v-icon>
                        </template>
                        <span>Door stroom van vorige fase (%)</span>
                      </v-tooltip>
                    </v-col>
                    <v-spacer />
                    <v-col
                      align-self="end"
                      class="pb-0"
                      style="text-align: right"
                    >
                      <h3>
                        {{
                          phasePercentage(item.intakedCount, item.placedCount)
                        }}
                      </h3>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pb-0 pr-4">
                    <v-col class="pl-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-calendar-clock
                          </v-icon>
                        </template>
                        <span>Gem. daggen tussen vorige fase (dagen)</span>
                      </v-tooltip>
                    </v-col>
                    <v-spacer />
                    <v-col align-self="end" style="text-align: right">
                      <h3>{{ phaseNeatDayDif(item.toPlacedDays) }}</h3>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
            <v-col md="2">
              <v-card elevation="5" height="100%">
                <v-card-title>Exit</v-card-title>
                <v-card-subtitle>Gestopt met werken</v-card-subtitle>
                <v-container class="pt-0 ml-4">
                  <v-row class="pb-0 pr-4">
                    <v-col class="pl-0 pb-0 pt-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-equal-box
                          </v-icon>
                        </template>
                        <span>Hoeveel</span>
                      </v-tooltip>
                    </v-col>
                    <v-spacer />
                    <v-col
                      align-self="end"
                      class="pb-0 pt-0"
                      style="text-align: right"
                    >
                      <h3>{{ item.exitCount }}</h3>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pb-0 pr-4">
                    <v-col class="pl-0 pb-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-percent
                          </v-icon>
                        </template>
                        <span>Door stroom van vorige fase (%)</span>
                      </v-tooltip>
                    </v-col>
                    <v-spacer />
                    <v-col
                      align-self="end"
                      class="pb-0"
                      style="text-align: right"
                    >
                      <h3>
                        {{ phasePercentage(item.placedCount, item.exitCount) }}
                      </h3>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pb-0 pr-4">
                    <v-col class="pl-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-calendar-clock
                          </v-icon>
                        </template>
                        <span>Gem. daggen tussen vorige fase (dagen)</span>
                      </v-tooltip>
                    </v-col>
                    <v-spacer />
                    <v-col align-self="end" style="text-align: right">
                      <h3>{{ phaseNeatDayDif(item.toExitDays) }}</h3>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
//import Global from "../components/dashboard/Global.vue";
//import DoughnutChart from "../components/dashboard/DoughnutChart.vue";
//import BarChart from "../components/dashboard/BarChart.vue";
import { mapState } from "vuex";
export default {
  components: {
    /*Global, BarChart*/
  },
  data: () => ({
    selection: {
      year: 2021,
      week: null,
    },
    weekData: [],
    computedWeekData: [],
    filters: {
      source: null,
      vacancy: null,
      rec: null,
      recAss: null,
      cm: null,
    },
    filterDataLoaded: false,

    tab: null,
    loaded: false,
    dialogDate: false,
    periodSelected: false,
    recruitment_assistent: null,
    recruiter: null,
    date: [],
    contact_moments: [],
    lead_users: [],
    leads: [],
    sollicitants: [],
    intakes: [],
    noshows: [],
    intakes_occured: [],
    contracts: [],
    placed: [],
    rejected: [],
    no_av_vacancy: [],
  }),
  computed: {
    ...mapState({
      channels: (state) => state.leads.channels,

      employees: (state) =>
        state.users.all.items.employees
          .filter((e) => e.role != null)
          .sort((a, b) => {
            if (a.first_name < b.first_name) {
              return -1;
            }
            if (a.first_name > b.first_name) {
              return 1;
            }
            return 0;
          })
          .map((e) => {
            return {
              id: e.id,
              full_name: e.first_name + " " + e.last_name,
            };
          }),
      vacancies: (state) =>
        state.vacancy.vacancies.vacancies.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }),
    }),
    computedDateRange: {
      get() {
        return this.splitDateRange(this.date);
      },
      set() {
        return null;
      },
    },
    intakesOccured() {
      let occurances = 0;
      for (let i = 0; i < this.intakes_occured.length; i++) {
        for (let k = 0; k < this.intakes_occured[i].lead_statuses.length; k++) {
          if (
            this.intakes_occured[i].lead_statuses[k].lead_status_id ===
              3 /* Intake */ &&
            k + 1 < this.intakes_occured[i].lead_statuses.length
          ) {
            occurances++;
          }
        }
      }
      return occurances;
    },
    // chartData() {
    //   return {
    //     hoverBackgroundColor: "red",
    //     hoverBorderWidth: 10,
    //     labels: [
    //       "Intakes",
    //       "Contract",
    //       "Geplaatst",
    //       "Afgewezen",
    //       "No-Show",
    //       "Geen geschikte vac.",
    //     ],
    //     datasets: [
    //       {
    //         backgroundColor: [
    //           "#007BFF",
    //           "#0F03FF",
    //           "#139100",
    //           "#A60202",
    //           "#FF0000",
    //           "#FF6F00",
    //         ],
    //         data: [
    //           this.intakesOccured,
    //           this.contracts.length,
    //           this.placed.length,
    //           this.rejected.length,
    //           this.noshows.length,
    //           this.no_av_vacancy.length,
    //         ],
    //       },
    //     ],
    //   };
    // },
    chartData() {
      return {
        labels: [""],
        datasets: [
          {
            label: "Intakes",
            backgroundColor: "#007BFF",
            data: [this.intakesOccured],
          },
          {
            label: "Contract",
            backgroundColor: "#0F03FF",
            data: [this.contracts.length],
          },
          {
            label: "Geplaatst",
            backgroundColor: "#139100",

            data: [this.placed.length],
          },
          {
            label: "Afgewezen",
            backgroundColor: "#A60202",

            data: [this.rejected.length],
          },
          {
            label: "No-Show",
            backgroundColor: "#FF0000",
            data: [this.noshows.length],
          },
          {
            label: "Geen geschikte vac.",
            backgroundColor: "#FF6F00",

            data: [this.no_av_vacancy.length],
          },
        ],
      };
    },
  },
  async created() {
    const { dispatch } = this.$store;
    await dispatch("users/getAll");
    await dispatch("vacancy/getVacancies");
    await dispatch("leads/getAllChannels");
    this.filterDataLoaded = true;
  },
  watch: {
    recruiter() {
      if (!this.recruiter) this.getGlobalAnalytics();
    },
    recruitment_assistent() {
      if (!this.recruitment_assistent) this.getGlobalAnalytics();
    },
  },
  methods: {
    async getData() {
      const { dispatch } = this.$store;
      const week = this.selection.week;
      const year = this.selection.year;
      this.computedWeekData = [];
      this.weekData = [];
      await dispatch("flow/getDashboardFlowLeads", {
        week,
        year,
      }).then((res) => {
        this.processData(res);
      });
    },

    processData(weeks) {
      for (const [key, value] of Object.entries(weeks)) {
        var newWeekData = {
          weekNo: value.weekNo,
          flows: [],
          leadsCount: value.leads.length,
          vmCount: 0,
          contactCount: 0,
          toContactDays: 0,
          intakeCount: 0,
          toIntakeDays: 0,
          intakedCount: 0,
          toIntakedDays: 0,
          placedCount: 0,
          toPlacedDays: 0,
          exitCount: 0,
          toExitDays: 0,
        };

        value.leads.forEach((lead) => {
          const flow = lead.flow;
          const appDate = lead.applied_date;
          var cleanFlow = {
            ra_id: lead.ra_id,
            rec_id: lead.rec_id,
            intake_plan_ass: null,
            intake_rec: null,
            placed_cm: null,
            channel_id: lead.channel_id,
            vacancy_id: lead.vacancy_id,
            contacted: false,
            intakePlanned: false,
            intaked: false,
            placed: false,
            exit: false,
            dayDif: {
              appliedToContact: null,
              contactToIntake: null,
              intakeToPlace: null,
              intakeToExit: null,
            },
          };
          if (flow != null) {
            if (flow.first_date != null) {
              cleanFlow.dayDif.appliedToContact = Math.round(
                this.dateDifference(appDate, flow.first_date)
              );
              cleanFlow.contacted = true;
            }
            if (flow.intake_date != null) {
              cleanFlow.intakePlanned = true;
              cleanFlow.intake_plan_ass = flow.intake_plan_ass;
            }
            if (flow.intake_completed) {
              cleanFlow.dayDif.contactToIntake = Math.round(
                this.dateDifference(flow.first_date, flow.intake_date)
              );
              cleanFlow.intaked = true;
              cleanFlow.intake_rec = flow.intake_rec;
            }
            if (flow.placed_date != null) {
              cleanFlow.dayDif.intakeToPlace = Math.round(
                this.dateDifference(flow.intake_date, flow.placed_date)
              );
              cleanFlow.placed = true;
              cleanFlow.placed_cm = flow.placed_cm;
            }
            if (flow.exit_date != null) {
              cleanFlow.dayDif.intakeToExit = Math.round(
                this.dateDifference(flow.placed_date, flow.exit_date)
              );
              cleanFlow.exit = true;
            }
          }
          newWeekData.flows.push(cleanFlow);
        });
        this.weekData.push(newWeekData);
      }
      this.finalCalculations();
    },

    dateDifference(firstDate, secondDate) {
      var date1 = new Date(firstDate);
      var date2 = new Date(secondDate);
      var Difference_In_Time = date2.getTime() - date1.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      return Difference_In_Days;
    },

    finalCalculations() {
      this.computedWeekData = [];
      this.weekData.forEach((weekOG) => {
        //Filtering the array, this can probably be done in a better way
        var week = Object.assign({}, weekOG);
        var tempArray = week.flows;
        tempArray = tempArray.filter(this.filterFlow);
        week.flows = tempArray;
        week.leadsCount = week.flows.length;
        week.flows.forEach((flow) => {
          if (flow.contacted) {
            week.contactCount++;
            week.toContactDays += flow.dayDif.appliedToContact;
          }
          if (flow.intakePlanned) {
            week.intakeCount++;
          }
          if (flow.intaked) {
            week.intakedCount++;
            week.toIntakedDays += flow.dayDif.contactToIntake;
          }
          if (flow.placed) {
            week.placedCount++;
            week.toPlacedDays += flow.dayDif.intakeToPlace;
          }
          if (flow.exit) {
            week.exitCount++;
            week.toExitDays += flow.dayDif.intakeToExit;
          }
        });
        week.toContactDays =
          Math.round((week.toContactDays / week.contactCount) * 100) / 100;
        week.toIntakedDays =
          Math.round((week.toIntakedDays / week.intakedCount) * 100) / 100;
        week.toPlacedDays =
          Math.round((week.toPlacedDays / week.placedCount) * 100) / 100;
        week.toExitDays =
          Math.round((week.toExitDays / week.exitCount) * 100) / 100;
        this.computedWeekData.push(week);
      });
    },

    filterFlow(flow) {
      var pass = true;
      if (this.filters.source != null) {
        if (flow.channel_id != this.filters.source) {
          pass = false;
        }
      }
      if (this.filters.vacancy != null) {
        if (flow.vacancy_id != this.filters.vacancy) {
          pass = false;
        }
      }
      if (this.filters.rec != null) {
        if (flow.rec_id != this.filters.rec) {
          pass = false;
        }
      }
      if (this.filters.recAss != null) {
        if (flow.ra_id != this.filters.recAss) {
          pass = false;
        }
      }
      if (this.filters.cm != null) {
        if (flow.placed_cm != this.filters.cm) {
          pass = false;
        }
      }
      return pass;
    },

    phasePercentage(count1, count2) {
      const result = Math.round((count2 / count1) * 10000) / 100;
      if (!isNaN(result)) {
        return result;
      } else {
        return "n.v.t.";
      }
    },

    phaseNeatDayDif(dayCount) {
      if (!isNaN(dayCount)) {
        return dayCount;
      } else {
        return "n.v.t.";
      }
    },

    clearFilters() {
      this.filters = {
        source: null,
        vacancy: null,
        rec: null,
        recAss: null,
        cm: null,
      };
      this.finalCalculations();
    },

    //OLD CODE NEEDS CLEANUP
    async setDate(date) {
      this.$refs.dialog.save(date);
      this.periodSelected = true;
      if (this.recruitment_assistants) this.getRecruitmentAssistentAnalytics();
      if (this.recruiter) this.getRecruiterAnalytics();
      this.getGlobalAnalytics();
    },

    async getGlobalAnalytics() {
      const { dispatch } = this.$store;
      const startDate = this.date[0];
      const endDate = this.date[1];

      await dispatch("dashboard/getGlobalAnalytics", {
        startDate,
        endDate,
      }).then((res) => {
        this.contact_moments = res.contact_moments;
        this.lead_users = res.lead_users;
        this.leads = res.leads;
        this.sollicitants = res.sollicitants;
        this.intakes = res.intakes;
        this.noshows = res.noshows;
        this.intakes_occured = res.intakes_occured;
        this.contracts = res.contracts;
        this.placed = res.placed;
        this.rejected = res.rejected;
        this.no_av_vacancy = res.no_av_vacancy;

        this.loaded = true;
      });
    },

    async getRecruiterAnalytics(recId) {
      if (recId === null) {
        this.getGlobalAnalytics();
      } else {
        const { dispatch } = this.$store;

        const startDate = this.date[0];
        const endDate = this.date[1];

        await dispatch("dashboard/getRecruiterAnalytics", {
          recId,
          startDate,
          endDate,
        }).then((res) => {
          this.contact_moments = res.contact_moments;
          this.lead_users = res.lead_users;
          this.sollicitants = res.sollicitants;
          this.intakes = res.intakes;
          this.noshows = res.noshows;
          this.intakes_occured = res.intakes_occured;
          this.contracts = res.contracts;
          this.placed = res.placed;
          this.rejected = res.rejected;
          this.no_av_vacancy = res.no_av_vacancy;

          this.loaded = true;
        });
      }
    },

    async getRecruitmentAssistentAnalytics(raId) {
      if (raId === null) {
        this.getGlobalAnalytics();
      } else {
        const { dispatch } = this.$store;

        const startDate = this.date[0];
        const endDate = this.date[1];
        await dispatch("dashboard/getRecruitmentAssistentAnalytics", {
          raId,
          startDate,
          endDate,
        }).then((res) => {
          this.contact_moments = res.contact_moments;
          this.lead_users = res.lead_users;
          this.sollicitants = res.sollicitants;
          this.intakes = res.intakes;
          this.noshows = res.noshows;

          this.loaded = true;
        });
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    splitDateRange(date) {
      if (!date) return null;
      if (date.length === 0) return "Selecteer een periode";
      const date1 = this.formatDate(date[0]);
      const date2 = this.formatDate(date[1]);
      return `${date1}~${date2}`;
    },
  },
};
</script>
