import { contactMomentService } from '../../services/contact_moments.service'

export const contact_moments /* candidates */ = {
    namespaced: true,
    state: {
    },
    actions: {
        createContactMoment({ dispatch, commit }, contact_moment) {
            return new Promise((resolve, reject) => {
                contactMomentService.createContactMoment(contact_moment)
                    .then(contact_moment => resolve(contact_moment))
            })
        },

        updateContactMoment({ dispatch, commit }, { contact_moment, contact_momentId }) {
            return new Promise((resolve, reject) => {
                contactMomentService.updateContactMoment(contact_moment, contact_momentId)
                    .then(contact_moment => resolve(contact_moment))
            })
        },

        getContactMomentsForLeadUser({ dispatch, commit }, leadUserId) {
            return new Promise((resolve, reject) => {
                contactMomentService.getContactMomentsForLeadUser(leadUserId)
                    .then(contact_moments => resolve(contact_moments))
            })
        },

    },
    mutations: {
    }
}