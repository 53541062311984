import config from '../../config';
import { authHeader } from '../utils/auth-header';

export const jobsService = {
    getJobs,
    getJobById,
    createJob,
    updateJob
}

function getJobs() {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/jobs`, request)
        .then(handleResponse)
        .then(jobs => {
            return jobs;
        });
}

function getJobById(jobId) {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/jobs/${jobId}`, request)
        .then(handleResponse)
        .then(job => {
            return job;
        });
}

function createJob(job) {
    const request = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(job)
    }
    return fetch(`${config.apiUrl}/jobs`, request)
        .then(handleResponse)
        .then(job => {
            return job;
        });
}

function updateJob(job, jobId) {
    const request = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(job)
    }
    return fetch(`${config.apiUrl}/jobs/${jobId}`, request)
        .then(handleResponse)
        .then(job => {
            return job;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}