import { flowService } from "../../services/flow.service";

export const flow = {
  namespaced: true,
  state: {
    pagination: {},
  },
  actions: {
    updateFlow({ dispatch, commit }, { flow, leadId }) {
      return new Promise((resolve, reject) => {
        flowService.updateFlow(flow, leadId).then((flow) => resolve(flow));
      });
    },

    getDashboardFlowLeads({ dispatch, commit }, { week, year }) {
      return new Promise((resolve, reject) => {
        flowService
          .getDashboardFlowLeads(week, year)
          .then((weeks) => resolve(weeks));
      });
    },

    updatePagination({ commit }, pagination) {
      commit("setPaginationState", pagination);
    },
  },
};
