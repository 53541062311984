import Vue from "vue";
import Vuex from "vuex";
//import { authentication } from './modules/authentication';
import { users } from "./modules/users";
import { alerts } from "./modules/alerts";
import { company } from "./modules/company";
import { positions } from "./modules/positions";
import { contact } from "./modules/contact";
import { statuses } from "./modules/statuses";
import { leads } from "./modules/leads";
import { lead_users } from "./modules/lead_users";
import { vacancy } from "./modules/vacancy";
import { dashboard } from "./modules/dashboard";
import { file_upload } from "./modules/file_upload";
import { utility } from "./modules/utility";
import { clients } from "./modules/analytical/clients";
import { contact_moments } from "./modules/contact_moments";
import { filters } from "./modules/filters";
import { flow } from "./modules/flow";
import { teamup } from "./modules/teamup";
import { notifications } from "./modules/notifications";
import { interests } from "./modules/interests";
import { intake_data } from "./modules/intake_data";
import { marketing_spending } from "./modules/marketing_spending";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    //authentication,
    users,
    alerts,
    company,
    positions,
    contact,
    statuses,
    leads,
    lead_users,
    vacancy,
    dashboard,
    file_upload,
    utility,
    clients,
    contact_moments,
    filters,
    flow,
    interests,
    teamup,
    notifications,
    intake_data,
    marketing_spending,
  },
});
