var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-5",attrs:{"fluid":""}},[_c('v-card',{staticClass:"pa-1",attrs:{"elevation":"0"}},[_c('v-row',[_c('v-col',{attrs:{"md":"8"}},[_c('v-row',{staticClass:"pa-3"},[_c('h2',[_vm._v("Recommended")]),_c('v-divider',{staticStyle:{"padding-left":"10px"},attrs:{"vertical":"","inset":""}}),_c('v-text-field',{staticStyle:{"padding-top":"0px","padding-left":"10px"},attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","loading":_vm.disableSearchBtn},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.deepSearch(_vm.filters.search)}},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}}),_c('v-btn',{staticClass:"ml-4",attrs:{"disabled":_vm.disableSearchBtn,"color":"primary"},on:{"click":function($event){return _vm.deepSearch(_vm.filters.search)}}},[_vm._v(" Zoek ")])],1)],1),_c('v-col',{attrs:{"align":"end","md":"4"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.clear()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-autorenew")])],1)],1)],1),_c('v-row',{staticClass:"pb-0 px-2"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{staticClass:"pt-1",attrs:{"dense":"","items":this.lead_statuses,"chips":"","deletable-chips":"","multiple":"","item-text":"name","item-value":"name","return-object":"","label":"Status"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"input-value":selected,"close":"","color":item.color},on:{"click:close":function($event){return _vm.remove(item)}}},'v-chip',attr,false),on),[_c('div',{staticStyle:{"color":"black"}},[_c('strong',[_vm._v(_vm._s(item.name))])])])]}}]),model:{value:(_vm.filters.statuses),callback:function ($$v) {_vm.$set(_vm.filters, "statuses", $$v)},expression:"filters.statuses"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{attrs:{"items":this.booleans,"clearable":"","item-text":"text","item-value":"value","label":"Car"},model:{value:(_vm.filters.has_car),callback:function ($$v) {_vm.$set(_vm.filters, "has_car", $$v)},expression:"filters.has_car"}})],1)],1),_c('v-row',{staticClass:"pb-0 px-2"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{attrs:{"items":this.booleans,"clearable":"","item-text":"text","item-value":"value","label":"Rijbewijs"},model:{value:(_vm.filters.has_license),callback:function ($$v) {_vm.$set(_vm.filters, "has_license", $$v)},expression:"filters.has_license"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{staticClass:"pt-1",attrs:{"dense":"","items":this.genderSelect,"chips":"","deletable-chips":"","multiple":"","return-object":"","label":"Gender"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"input-value":selected,"close":""},on:{"click:close":function($event){return _vm.removeGender(item)}}},'v-chip',attr,false),on),[_c('div',{staticStyle:{"color":"black"}},[_c('strong',[_vm._v(_vm._s(item))])])])]}}]),model:{value:(_vm.filters.gender),callback:function ($$v) {_vm.$set(_vm.filters, "gender", $$v)},expression:"filters.gender"}})],1)],1),_c('v-row',{staticClass:"pb-0 px-2"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"2"}},[_c('v-subheader',{},[_vm._v("Age Range:")])],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"10"}},[_c('v-range-slider',{staticStyle:{"margin-bottom":"10px !important"},attrs:{"thumb-label":"","max":_vm.max,"min":_vm.min,"hide-details":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-text-field',{staticClass:"mt-0 pt-0",staticStyle:{"width":"60px"},attrs:{"value":_vm.filters.range[0],"hide-details":"","single-line":"","type":"number"},on:{"change":function($event){return _vm.$set(_vm.filters.range, 0, $event)}}})]},proxy:true},{key:"append",fn:function(){return [_c('v-text-field',{staticClass:"mt-0 pt-0",staticStyle:{"width":"60px"},attrs:{"value":_vm.filters.range[1],"hide-details":"","single-line":"","type":"number"},on:{"change":function($event){return _vm.$set(_vm.filters.range, 1, $event)}}}),_c('v-btn',{staticClass:"ml-3",staticStyle:{"margin-top":"-5px !important"},attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v("Save")])]},proxy:true}]),model:{value:(_vm.filters.range),callback:function ($$v) {_vm.$set(_vm.filters, "range", $$v)},expression:"filters.range"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }