import config from "../../config";
import { authHeader } from "../utils/auth-header";
export const leadUserService = {
  createLeadUser,
  getAllLeadUsers,
  getAllLeadUsersWithLead,
  updateLeadUser,
  getLeadUser,
  addStatusToLeadUser,
};
function createLeadUser(lead_user) {
  const request = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(lead_user),
  };
  return fetch(`${config.apiUrl}/lead-users`, request)
    .then(handleResponse)
    .then((lead_user) => {
      return lead_user;
    });
}

function updateLeadUser(lead_user, userId) {
  const request = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(lead_user),
  };
  return fetch(`${config.apiUrl}/lead-users/${userId}`, request)
    .then(handleResponse)
    .then((lead_user) => {
      return lead_user;
    });
}

function getAllLeadUsers() {
  const request = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/lead-users`, request)
    .then(handleResponse)
    .then((lead_users) => {
      return lead_users;
    });
}

function getAllLeadUsersWithLead() {
  const request = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/lead-users/leads`, request)
    .then(handleResponse)
    .then((lead_users) => {
      return lead_users;
    });
}

function getLeadUser(userId) {
  const request = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/lead-users/${userId}`, request)
    .then(handleResponse)
    .then((lead_user) => {
      return lead_user;
    });
}

function addStatusToLeadUser(leadUserStatus) {
  const request = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(leadUserStatus),
  };
  return fetch(`${config.apiUrl}/lead-users/status`, request)
    .then(handleResponse)
    .then((leadUserStatus) => {
      return leadUserStatus;
    });
}

// ---------------------------------------------------------------

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
