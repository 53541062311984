import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import axios from "axios";

export const notifications = {
  namespaced: true,
  state: {
    count: 0,
    list: [],
    groups: {},
    panel: false,
    fetched: false,
  },
  getters: {
    getNotifCount: (state) => state.count,
    getPanelState: (state) => state.panel,
    getNotifList: (state) => state.list,
    notifsFetched: (state) => state.fetched,
    notifGroups: (state) => state.groups,
  },

  actions: {
    incrementNotifCount({ state }) {
      state.count++;
    },
    decreaseNotifCount({ state }) {
      state.count--;
    },
    clearNotifCount({ state }) {
      state.count = 0;
    },
    togglePanel({ state }) {
      state.panel = !state.panel
    },
    addNotification({ state }, notification) {
      state.list.push(notification)
    },
    async getAllNotifications({ commit }) {
      try {
        const request = {
          method: 'GET',
          headers: authHeader(),
        }
        await fetch(`${config.apiUrl}/notification`, request)
          .then(handleResponse)
          .then(data => {
            commit("saveNotifications", data);
          });
        // axios.defaults.headers.common["Authorization"] = `${
        //   authHeader().Authorization
        // }`;
        // const { data } = await axios.get(`${config.apiUrl}/notification`);
        // commit("saveNotifications", data);
      } catch (error) {
        console.log("error");
      }
      //axios.defaults.headers.common = { Accept: "application/json, text/plain, */*" }
    },
    async markNotificationRead({ commit, state }, notifId) {
      try {
        const request = {
          method: 'GET',
          headers: authHeader(),
        }
        await fetch(`${config.apiUrl}/notification/read/${notifId}`, request)
          .then(handleResponse);
        // axios.defaults.headers.common["Authorization"] = `${authHeader().Authorization
        //   }`;
        // await axios.get(`${config.apiUrl}/notification/read/${notifId}`);
        for (const notif in state.list) {
          if (notif.id == notifId) {
            state.list.indexOf(notif)
          }
        }
        let index = -1;
        state.list.find(function (notif, i) {
          if (notif.id == notifId) {
            index = i;
          }
        });
        if (index >= 0) {
          state.list.splice(index, 1);
        }
      } catch (error) {
        console.log("error");
      }
    },
    async readAllNotifications({ commit, state }) {
      try {
        const request = {
          method: 'GET',
          headers: authHeader(),
        }
        await fetch(`${config.apiUrl}/notification/readAll`, request)
          .then(handleResponse);
        // axios.defaults.headers.common["Authorization"] = `${authHeader().Authorization
        //   }`;
        // await axios.get(`${config.apiUrl}/notification/readAll`);
        state.list = []
      } catch (error) {
        console.log("error");
      }
    },
    async getAllNotificationGroups({ commit }) {
      try {
        const request = {
          method: 'GET',
          headers: authHeader(),
        }
        await fetch(`${config.apiUrl}/notification/groups`, request)
          .then(handleResponse)
          .then(data => {
            commit("saveNotificationGroups", data);
          });
        // axios.defaults.headers.common["Authorization"] = `${authHeader().Authorization
        //   }`;
        // const { data } = await axios.get(`${config.apiUrl}/notification/groups`);
        // commit("saveNotificationGroups", data);
      } catch (error) {
        console.log("error");
      }
    },
    async updateEmployeeGroups({ commit }, { empId, groups }) {
      try {
        const request = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(groups)
        }
        await fetch(`${config.apiUrl}/notification/groups/${empId}`, request)
          .then(handleResponse);
        // axios.defaults.headers.common["Authorization"] = `${authHeader().Authorization
        //   }`;
        // await axios.post(`${config.apiUrl}/notification/groups/${empId}`, groups);
      } catch (error) {
        console.log("error");
      }
    },
  },
  mutations: {
    updateNotifCount(state, count) {
      state.count = count
    },
    saveNotifications(state, notifs) {
      state.fetched = true
      state.list = notifs
      state.count = notifs.length;
    },
    saveNotificationGroups(state, groups) {
      state.groups = groups
    },
  },
};

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}