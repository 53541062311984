<template>
  <v-container fluid>
    <v-dialog v-model="dialogCreate" max-width="600px">
      <v-card>
        <CreateCompany
          v-on:close-dialog="dialogCreate = false"
          type="0"
        ></CreateCompany>
      </v-card>
    </v-dialog>
    <v-card class="pa-4" :loading="!companiesLoaded">
      <v-row>
        <v-col md="8">
          <v-row class="pa-3">
            <h2>Bedrijven</h2>
            <v-divider
              vertical
              inset
              style="padding-left: 10px; padding-left: 10px"
            ></v-divider>
            <v-text-field
              style="padding-top: 0px; padding-left: 10px"
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-row>
        </v-col>
        <v-col align="end" md="4">
          <v-btn icon color="primary" class="mb-2" @click="dialogCreate = true">
            <v-icon dark> mdi-plus-box </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="pl-3 pb-3 mb-4">
        <h2>Filter</h2>
        <v-divider
          vertical
          inset
          style="padding-left: 10px; padding-left: 10px"
        ></v-divider>
        <v-btn class="ml-2" color="primary" @click="filterAgency('DOEN')"
          >DOEN</v-btn
        >
        <v-btn class="ml-2" color="primary" @click="filterAgency('Zoomer')"
          >Zoomer</v-btn
        >
      </v-row>

      <v-menu
        v-model="showMenu"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
      >
        <v-list dense>
          <v-list-item @click="open" dense>
            <v-list-item-title>Open</v-list-item-title>
          </v-list-item>
          <v-list-item @click="openNewTab" dense>
            <v-list-item-title>Nieuw tabblad</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-data-table
        v-if="companiesLoaded"
        :page.sync="pagination.page"
        :items-per-page="pagination.itemsPerPage"
        :footer-props="{ itemsPerPageOptions: [20, 35, 50, -1] }"
        :headers="headers"
        :items="companies"
        :search="search"
        sort-by="name"
        class="elevation-1 pointerOnHover"
        @click:row="editItem"
        @contextmenu:row="show"
        @pagination="storePaginationToState"
      >
        <template v-slot:[`item.platform_name`]="{ item }">
          <v-img
            v-if="item.platform_name === 'DOEN'"
            max-width="50"
            src="../assets/doen.png"
          ></v-img>
          <v-img
            class="mb-1"
            v-if="item.platform_name === 'Zoomer'"
            max-width="50"
            src="../assets/zoomer.png"
          ></v-img>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import CreateCompany from "../components/dialogs/CreateCompany";
import { mapState } from "vuex";
export default {
  components: { CreateCompany },
  data: () => ({
    companiesLoaded: false,
    activeCompanyPositions: [],
    dialog: false,
    dialogCreate: false,
    search: "",
    headers: [
      {
        text: "Platform",
        align: "start",
        value: "platform_name",
      },
      {
        text: "Naam",
        align: "start",
        value: "name",
      },
      { text: "KVK", value: "kvk" },
      { text: "Telefoon", value: "phone" },
      { text: "Regio", value: "region" },
      { text: "Website", value: "site_url" },
    ],
    editedIndex: -1,
    selectedRow: null,
    showMenu: false,
    x: 0,
    y: 0,
  }),

  computed: {
    ...mapState({
      companies: (state) => state.company.companies.companies.companies,

      pagination: (state) => state.company.pagination,
    }),
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      const { dispatch } = this.$store;
      await dispatch("company/getAllCompanies").then((companies) => {
        this.companiesLoaded = true;
      });
    },

    async editItem(item) {
      this.$router.push(`/bedrijf/${item.id}`);
    },

    open() {
      this.$router.push(`/bedrijf/${this.selectedRow.id}`);
    },

    openNewTab() {
      let routeData = this.$router.resolve({
        path: `/bedrijf/${this.selectedRow.id}`,
      });
      window.open(routeData.href, "_blank");
    },

    show(event, row) {
      event.preventDefault();
      this.showMenu = false;
      this.x = event.clientX;
      this.y = event.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
      this.selectedRow = row.item;
    },

    async filterAgency(name) {
      const { dispatch } = this.$store;
      this.companiesLoaded = false;
      await dispatch("company/getAllCompaniesWithAgencyName", name).then(
        (companies) => {
          this.companiesLoaded = true;
        }
      );
    },

    async storePaginationToState(val) {
      const { dispatch } = this.$store;
      await dispatch("company/updatePagination", val);
    },
  },
};
</script>
