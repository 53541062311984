<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant.sync="mini"
    app
    permanent
    :style="{ background: $vuetify.theme.themes[theme].navBackground }"
  >
    <v-dialog v-model="bugDialog" max-width="600px">
      <v-card>
        <BugAndFeatureReport v-on:close-dialog="bugDialog = false" />
      </v-card>
    </v-dialog>
    <v-list-item class="px-2">
      <v-list-item-avatar>
        <v-img :src="require('../assets/defaultProfile.png')"></v-img>
      </v-list-item-avatar>

      <v-list-item-title v-if="user">{{
        user.first_name + " " + user.last_name
      }}</v-list-item-title>
      <v-list-item-title v-else>Username</v-list-item-title>

      <v-btn icon @click.stop="mini = !mini">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-list dense class="pt-0">
      <v-list-item v-on:click="openNotifications" link>
        <v-list-item-icon>
          <v-badge :content="getNotifCount" :value="getNotifCount" color="blue" overlap>
            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Notificaties</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item-group v-model="selectedItem" color="primary">
        <v-list-item
          v-for="item in allowedItemsByRole"
          :key="item.title"
          v-on:click="gotoPage(item.link)"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <v-divider></v-divider>

      <v-list-item v-on:click="darkSwitch()" link>
        <v-list-item-icon>
          <v-icon v-if="$vuetify.theme.dark">mdi-toggle-switch</v-icon>
          <v-icon v-else>mdi-toggle-switch-off</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Dark Theme</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-on:click="bugDialog = true" link>
        <v-list-item-icon>
          <v-icon>mdi-bug</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Bug/Idee Melder</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-on:click="logout()" link>
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import BugAndFeatureReport from "../components/BugAndFeatureReport";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NavDrawer",
  components: {
    BugAndFeatureReport,
  },
  data() {
    return {
      bugDialog: false,
      drawer: true,
      currentNavItem: null,
      items: [
        { title: "Home", icon: "mdi-home", link: "/home", role: 3 },
        {
          title: "Vacatures",
          icon: "mdi-clipboard-text",
          link: "/vacatures",
          role: 4,
        }, //Vacancies
        {
          title: "Leads",
          icon: "mdi-clipboard-account",
          link: "/leads",
          role: 4,
        }, //Leads
        {
          title: "Kandidaten",
          icon: "mdi-account-multiple",
          link: "/kandidaten",
          role: 4,
        }, //Lead_users
        {
          title: "Bedrijven",
          icon: "mdi-office-building",
          link: "/bedrijven",
          role: 4,
        }, //Companies
        {
          title: "Search",
          icon: "mdi-account-search",
          link: "/search",
          role: 4,
        },
        {
          title: "Analyse",
          icon: "mdi-chart-line",
          link: "/analytiek",
          role: 0,
        }, //Analytics page
        {
          title: "Dashboard",
          icon: "mdi-monitor-dashboard",
          link: "/dashboard",
          role: 0,
        },
        {
          title: "Marketing",
          icon: "mdi-account-cash",
          link: "/marketing",
          role: 0,
        },
        {
          title: "Admin",
          icon: "mdi-briefcase-account",
          link: "/admin",
          role: 2,
        }, //Admin panel for managing employees, postions and statuses
      ],
      mini: true,
    };
  },
  methods: {
    ...mapActions("notifications", ["togglePanel", "clearNotifCount","getAllNotifications"]),
    gotoPage: function (url) {
      this.$router.push(url).catch(() => {});
    },
    async intialize(){
      await this.getAllNotifications();
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    darkSwitch() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark", this.$vuetify.theme.dark);
      localStorage.setItem("theme_selected_color", "dark:blueolympic");
    },
    activePage(path) {
      if (path == this.currentRouteName) {
        return;
      }
    },
    openNotifications(){
      this.clearNotifCount();
      this.togglePanel();
    }
  },

  created() {
    if(!this.notifsFetched){
      this.intialize();
    }
  },

  computed: {
    ...mapGetters("notifications", ["getNotifCount", "notifsFetched"]),
    user() {
      return this.$store.state.users.user;
    },
    allowedItemsByRole() {
      var allowedItems = [];
      for (var item in this.items) {
        if (this.items[item].role >= this.$store.state.users.user.role) {
          allowedItems.push(this.items[item]);
        }
      }
      return allowedItems;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    currentRouteName() {
      return this.$route.path;
    },
    selectedItem: {
      get: function () {
        if (this.currentNavItem) {
          return this.currentNavItem;
        } else {
          var newIndex = 0;
          this.items.forEach((listItem) => {
            if (this.currentRouteName == listItem.link) {
              newIndex = this.items.indexOf(listItem);
            }
          });
          return newIndex;
        }
      },
      set: function (newValue) {
        this.currentNavItem = newValue;
      },
    },
  },
};
</script>
