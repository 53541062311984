import config from '../../config';
import { authHeader } from '../utils/auth-header';

export const flowService = {
    updateFlow,
    getDashboardFlowLeads
};

function updateFlow(flow, leadId) {
    const request = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(flow)
    }
    return fetch(`${config.apiUrl}/flow/${leadId}`, request)
        .then(handleResponse)
        .then(flow => {
            return flow;
        });
}

function getDashboardFlowLeads(week, year){
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/flow-dashboard/${week}/${year}`, request)
        .then(handleResponse)
        .then(weeks => {
            return weeks;
        });
}

// ---------------------------------------------------------------

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}