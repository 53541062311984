<template>
  <v-container class="pa-5" v-if="parsedCv !== null">
    <div class="mb-3 pt-4" style="width: 100%">
      <v-row class="justify-center">
        <h4>Job Type Predictive Index</h4>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="black" small
              >mdi-information-outline</v-icon
            >
          </template>
          <span
            >This is a measure of whether the candidate is seeking/best suited
            for part-time jobs or full-time/direct-hire jobs.
          </span>
        </v-tooltip>
      </v-row>
      <div v-if="typeof parsedCv.EmploymentHistory == 'undefined'"></div>
      <div
        v-else
        class="d-flex justify-end pt-4"
        :style="{
          width:
            (parsedCv.EmploymentHistory.ExperienceSummary
              .FulltimeDirectHirePredictiveIndex === 0
              ? 50
              : parsedCv.EmploymentHistory.ExperienceSummary
                  .FulltimeDirectHirePredictiveIndex) + '%',
        }"
      >
        <v-icon color="black" small>mdi-arrow-down</v-icon>
      </div>
      <v-progress-linear
        rounded
        value="100"
        color="light-blue"
        height="10"
      ></v-progress-linear>
      <v-row class="justify-space-between ma-2">
        <div>part-time/temp/contractor</div>
        <div>traditional full-time/direct-hire</div>
      </v-row>
    </div>
    <h4 class="mb-3">Employment History</h4>
    <div
      style="background-color: #d64550; color: white; text-align: center"
      v-if="typeof parsedCv.EmploymentHistory == 'undefined'"
    >
      No Employment History Found
    </div>
    <v-expansion-panels v-else>
      <v-expansion-panel style="border-radius: 0px">
        <v-expansion-panel-header class="resumeTitles">
          General Info
        </v-expansion-panel-header>
        <v-expansion-panel-content class="" style="border-radius: 0px">
          <div class="row resumeDiv pt-5">
            <p class="resumeHeadings">Resume Summary:</p>
            <v-textarea
              :value="
                parsedCv.EmploymentHistory.ExperienceSummary.Description
                  ? parsedCv.EmploymentHistory.ExperienceSummary.Description
                  : 'Undefined'
              "
              auto-grow
              filled
              rounded
              rows="2"
              row-height="25"
              readonly
            ></v-textarea>

            <br />
          </div>
          <div class="row resumeDiv pt-5">
            <p class="resumeHeadings">Avg. months per employer:</p>
            <v-text-field
              :value="
                parsedCv.EmploymentHistory.ExperienceSummary
                  .AverageMonthsPerEmployer
                  ? parsedCv.EmploymentHistory.ExperienceSummary
                      .AverageMonthsPerEmployer
                  : 'Undefined'
              "
              filled
              rounded
              dense
              readonly
            ></v-text-field>

            <br />
          </div>
          <div class="row resumeDiv pt-5">
            <p class="resumeHeadings">Months of work exp:</p>
            <v-text-field
              :value="
                parsedCv.EmploymentHistory.ExperienceSummary
                  .MonthsOfWorkExperience
              "
              filled
              rounded
              dense
              readonly
            ></v-text-field>

            <br />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div v-if="typeof parsedCv.EmploymentHistory == 'undefined'"></div>
    <v-expansion-panels v-else>
      <v-expansion-panel
        v-for="(i, idx) in parsedCv.EmploymentHistory.Positions"
        :key="idx"
        style="border-radius: 0px"
      >
        <v-expansion-panel-header class="resumeTitles">
          <div>
            <p
              v-if="
                typeof i.JobTitle == 'undefined' ||
                typeof i.JobTitle.Normalized == 'undefined'
              "
            >
              <v-chip color="indigo" text-color="white"> Job title: </v-chip>
              undefined
            </p>
            <p v-else>
              <v-chip color="indigo" text-color="white"> Job title: </v-chip>
              {{ i.JobTitle.Normalized }}
            </p>
            <p
              v-if="
                typeof i.Employer == 'undefined' ||
                typeof i.Employer.Name == 'undefined' ||
                typeof i.Employer.Name.Normalized == 'undefined'
              "
            >
              <v-chip color="teal" text-color="white"> Employer: </v-chip>
              undefined
            </p>
            <p v-else>
              <v-chip color="teal" text-color="white"> Employer: </v-chip>
              {{ i.Employer.Name.Normalized.toUpperCase() }}
            </p>
          </div>
          <v-chip
            :color="i.IsCurrent ? 'green' : 'orange'"
            class="ma-2 chip-size"
            text-color="white"
          >
            <v-avatar
              left
              :class="i.IsCurrent ? 'green' : 'orange'"
              class="darken-4"
            >
              {{ getMonthsWorked(i.StartDate.Date, i.EndDate.Date) }}
            </v-avatar>
            Months
          </v-chip>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="" style="border-radius: 0px">
          <div class="row resumeDiv pt-5">
            <p class="resumeHeadings">Employer:</p>
            <div
              class="helperDiv"
              v-if="
                typeof i.Employer == 'undefined' ||
                typeof i.Employer.Name == 'undefined' ||
                typeof i.Employer.Name.Normalized == 'undefined'
              "
            >
              <v-text-field
                value="Undefined"
                filled
                rounded
                dense
                readonly
              ></v-text-field>
            </div>
            <div class="helperDiv" v-else>
              <v-text-field
                :value="i.Employer.Name.Normalized"
                filled
                rounded
                dense
                readonly
              ></v-text-field>
            </div>
            <br />
          </div>
          <div class="row resumeDiv">
            <p class="resumeHeadings">Title:</p>
            <div
              class="helperDiv"
              v-if="
                typeof i.JobTitle == 'undefined' ||
                typeof i.JobTitle.Normalized == 'undefined'
              "
            >
              <v-text-field
                value="Undefined"
                filled
                rounded
                dense
                readonly
              ></v-text-field>
            </div>
            <div class="helperDiv" v-else>
              <v-text-field
                :value="i.JobTitle.Normalized"
                filled
                rounded
                dense
                readonly
              ></v-text-field>
            </div>

            <br />
          </div>
          <div class="row resumeDiv">
            <p class="resumeHeadings">Start Date:</p>
            <div class="helperDiv" v-if="typeof i.StartDate == 'undefined'">
              <v-text-field
                value="Undefined"
                filled
                rounded
                dense
                readonly
              ></v-text-field>
            </div>
            <div class="helperDiv" v-else>
              <v-text-field
                :value="i.StartDate.Date"
                filled
                rounded
                dense
                readonly
              ></v-text-field>
            </div>
            <br />
          </div>
          <div class="row resumeDiv">
            <p class="resumeHeadings">End Date</p>
            <div class="helperDiv" v-if="typeof i.EndDate == 'undefined'">
              <v-text-field
                value="Undefined"
                filled
                rounded
                dense
                readonly
              ></v-text-field>
            </div>
            <div class="helperDiv" v-else>
              <v-text-field
                :value="i.EndDate.Date"
                filled
                rounded
                dense
                readonly
              ></v-text-field>
            </div>
            <br />
          </div>
          <div class="row resumeDiv">
            <p class="resumeHeadings">Desc:</p>
            <div class="helperDiv" v-if="typeof i.Description == 'undefined'">
              <v-text-field
                value="Undefined"
                filled
                rounded
                dense
                readonly
              ></v-text-field>
            </div>
            <div class="helperDiv" v-else>
              <v-textarea
                :value="i.Description"
                auto-grow
                filled
                rounded
                rows="2"
                row-height="25"
                readonly
              ></v-textarea>
            </div>
            <br />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <h4 class="mb-2 mt-8">Education</h4>
    <div
      style="background-color: #d64550; color: white; text-align: center"
      v-if="typeof parsedCv.Education == 'undefined'"
    >
      No Education Found
    </div>
    <v-expansion-panels v-else>
      <v-expansion-panel
        v-for="(i, ida) in parsedCv.Education.EducationDetails"
        :key="ida"
        style="border-radius: 0px"
      >
        <v-expansion-panel-header class="resumeTitles">
          <span
            v-if="
              typeof i.Degree == 'undefined' ||
              typeof i.Degree.Name == 'undefined' ||
              typeof i.Degree.Name.Normalized == 'undefined'
            "
          >
            Undefined
          </span>
          <span v-else>
            {{
              i.Id +
              " - " +
              i.Degree.Name.Normalized.charAt(0).toUpperCase() +
              i.Degree.Name.Normalized.slice(1)
            }}</span
          >

          <div
            v-if="
              typeof i.LastEducationDate == 'undefined' ||
              typeof i.LastEducationDate.IsCurrentDate == 'undefined'
            "
          ></div>
          <h5
            v-else-if="i.LastEducationDate.IsCurrentDate"
            class="ma-2 currentJobText"
            text-color="white"
          >
            Ongoing Education
          </h5>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="" style="border-radius: 0px">
          <div class="row resumeDiv pt-5">
            <p class="resumeHeadings">Degree</p>
            <div
              class="helperDiv"
              v-if="
                typeof i.Degree == 'undefined' ||
                typeof i.Degree.Name == 'undefined' ||
                typeof i.Degree.Name.Normalized == 'undefined'
              "
            >
              <v-text-field
                value="Undefined"
                filled
                rounded
                dense
                readonly
              ></v-text-field>
            </div>
            <div class="helperDiv" v-else>
              <v-text-field
                :value="i.Degree.Name.Normalized"
                filled
                rounded
                dense
                readonly
              ></v-text-field>
            </div>
            <br />
          </div>
          <div class="row resumeDiv">
            <p class="resumeHeadings">Majors</p>
            <div class="helperDiv" v-if="typeof i.Majors == 'undefined'">
              <v-text-field
                value="Undefined"
                filled
                rounded
                dense
                readonly
              ></v-text-field>
            </div>
            <div class="helperDiv" v-else>
              <div v-for="(major, idMajor) in i.Majors" :key="idMajor">
                <v-text-field
                  :value="major"
                  filled
                  rounded
                  dense
                  readonly
                ></v-text-field>
              </div>
            </div>
            <br />
          </div>
          <div class="row resumeDiv">
            <p class="resumeHeadings">Last Education Date</p>
            <div
              class="helperDiv"
              v-if="typeof i.LastEducationDate == 'undefined'"
            >
              <v-text-field
                value="Undefined"
                filled
                rounded
                dense
                readonly
              ></v-text-field>
            </div>
            <div class="helperDiv" v-else>
              <v-text-field
                :value="i.LastEducationDate.Date"
                filled
                rounded
                dense
                readonly
              ></v-text-field>
            </div>
            <br />
          </div>
          <div class="row resumeDiv">
            <p class="resumeHeadings">School Type</p>
            <div class="helperDiv" v-if="typeof i.SchoolType == 'undefined'">
              <v-text-field
                value="Undefined"
                filled
                rounded
                dense
                readonly
              ></v-text-field>
            </div>
            <div class="helperDiv" v-else>
              <v-text-field
                :value="i.SchoolType"
                filled
                rounded
                dense
                readonly
              ></v-text-field>
            </div>
            <br />
          </div>
          <div class="row resumeDiv">
            <p class="resumeHeadings">Text:</p>
            <div class="helperDiv" v-if="typeof i.Text == 'undefined'">
              <v-text-field
                value="Undefined"
                filled
                rounded
                dense
                readonly
              ></v-text-field>
            </div>
            <div class="helperDiv" v-else>
              <v-textarea
                :value="i.Text"
                auto-grow
                filled
                rounded
                rows="2"
                row-height="25"
                readonly
              ></v-textarea>
            </div>
            <br />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <h4 class="mb-2 mt-8">Skills</h4>
    <div
      style="background-color: #d64550; color: white; text-align: center"
      v-if="typeof parsedCv.SkillsData == 'undefined'"
    >
      No Skills Found
    </div>
    <v-expansion-panels v-else>
      <v-expansion-panel style="border-radius: 0px">
        <v-expansion-panel-header class="resumeTitles">
          Main Skills
        </v-expansion-panel-header>
        <v-expansion-panel-content class="" style="border-radius: 0px">
          <div
            class="pt-5"
            v-for="(x, idx) in parsedCv.SkillsData[0].Taxonomies"
            :key="idx"
          >
            <div>{{ x.Name }}</div>
            <v-progress-linear
              rounded
              :value="x.PercentOfOverall"
              :color="colorArray[Math.floor(Math.random() * colorArray.length)]"
              height="10"
            ></v-progress-linear>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <h4 class="mb-2 mt-8">Certifications</h4>
    <div
      style="background-color: #d64550; color: white; text-align: center"
      v-if="typeof parsedCv.Certifications == 'undefined'"
    >
      No Certifications Found
    </div>
    <v-expansion-panels v-else>
      <v-expansion-panel style="border-radius: 0px">
        <v-expansion-panel-header class="resumeTitles">
          Certifications
        </v-expansion-panel-header>
        <v-expansion-panel-content class="" style="border-radius: 0px">
          <div
            class="pt-5"
            v-for="(cert, idCert) in parsedCv.Certifications"
            :key="idCert"
          >
            <div>{{ cert.Name }}</div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <h4 class="mb-2 mt-8">Qualifications Summary</h4>
    <div
      style="background-color: #d64550; color: white; text-align: center"
      v-if="typeof parsedCv.QualificationsSummary == 'undefined'"
    >
      No Qualifications Found
    </div>
    <v-expansion-panels v-else>
      <v-expansion-panel style="border-radius: 0px">
        <v-expansion-panel-header class="resumeTitles">
          Qualifications Desc
        </v-expansion-panel-header>
        <v-expansion-panel-content class="" style="border-radius: 0px">
          <div class="pt-5">
            <v-textarea
              :value="parsedCv.QualificationsSummary"
              auto-grow
              filled
              rounded
              rows="2"
              row-height="25"
              readonly
            ></v-textarea>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
export default {
  props: {
    parsedCv: {},
  },
  data: () => ({
    colorArray: [
      "#264653",
      "#2a9d8f",
      "#e9c46a",
      "#f4a261",
      "#e76f51",
      "#52b69a",
      "#00afb9",
      "#ffbe0b",
      "#99FF99",
      "#ee6c4d",

      "#F94144",
      "#F3722C",
      "#F8961E",
      "#F9844A",
      "#F9C74F",
      "#277DA1",
      "#577590",
    ],
  }),

  created() {},

  methods: {
    getMonthsWorked(startDateStr, endDateStr) {
      const [startYear, startMonth, startDay] = startDateStr.split("-");
      const [endYear, endMonth, endDay] = endDateStr.split("-");
      const startDate = new Date(startYear, startMonth, startDay);
      const endDate = new Date(endYear, endMonth, endDay);
      const year1 = startDate.getFullYear();
      const year2 = endDate.getFullYear();
      const month1 = startDate.getMonth();
      const month2 = endDate.getMonth();
      const numberOfMonths = (year2 - year1) * 12 + (month2 - month1);
      return numberOfMonths;
    },
  },
};
</script>

<style lang="scss">
.resumeTitles {
  background-color: #495057;
  color: white;
  font-size: 1rem;
  font-weight: 500;
}
.resumeHeadings {
  font-size: 1rem;
  font-weight: 400;
  margin-right: 10px;
}
.resumeDiv {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: -12px;
  align-items: baseline;
}
.currentJobText {
  color: #34e065;
  font-weight: 600;
}
.avgEmployerTime {
  font-weight: 600;
  font-size: 2rem;
}
.helperDiv {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;

  align-items: baseline;
}
.chip-size {
  max-width: 100px;
}
</style>
