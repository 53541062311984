var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"pa-4"},[_c('v-row',[_c('h2',{staticClass:"pa-6"},[_vm._v("Month")]),_c('v-col',{attrs:{"cols":"11","sm":"5"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Select month","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"type":"month","no-title":"","scrollable":""},on:{"change":_vm.getWebsitesEuros},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"align":"right"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.excelExport()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-table-arrow-right")])],1)],1)],1),(_vm.websiteEuros)?_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.websiteEuros,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.facebookEuros",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.facebookEuros},on:{"update:returnValue":function($event){return _vm.$set(item, "facebookEuros", $event)},"update:return-value":function($event){return _vm.$set(item, "facebookEuros", $event)},"save":_vm.save,"cancel":_vm.cancel,"open":_vm.open,"close":_vm.close},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"rules":[_vm.max25chars],"label":"Edit","single-line":"","counter":"","type":"number"},model:{value:(item.facebookEuros),callback:function ($$v) {_vm.$set(item, "facebookEuros", $$v)},expression:"item.facebookEuros"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.roundToTwoDecimals(item.facebookEuros).toFixed(2))+" € ")])]}},{key:"item.indeedEuros",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.indeedEuros},on:{"update:returnValue":function($event){return _vm.$set(item, "indeedEuros", $event)},"update:return-value":function($event){return _vm.$set(item, "indeedEuros", $event)},"save":_vm.save,"cancel":_vm.cancel,"open":_vm.open,"close":_vm.close},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"rules":[_vm.max25chars],"label":"Edit","single-line":"","counter":"","type":"number"},model:{value:(item.indeedEuros),callback:function ($$v) {_vm.$set(item, "indeedEuros", $$v)},expression:"item.indeedEuros"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.roundToTwoDecimals(item.indeedEuros).toFixed(2))+" € ")])]}},{key:"item.linkedInEuros",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.linkedInEuros},on:{"update:returnValue":function($event){return _vm.$set(item, "linkedInEuros", $event)},"update:return-value":function($event){return _vm.$set(item, "linkedInEuros", $event)},"save":_vm.save,"cancel":_vm.cancel,"open":_vm.open,"close":_vm.close},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"rules":[_vm.max25chars],"label":"Edit","single-line":"","counter":"","type":"number"},model:{value:(item.linkedInEuros),callback:function ($$v) {_vm.$set(item, "linkedInEuros", $$v)},expression:"item.linkedInEuros"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.roundToTwoDecimals(item.linkedInEuros).toFixed(2))+" € ")])]}},{key:"item.werkezoekenEuros",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.werkezoekenEuros},on:{"update:returnValue":function($event){return _vm.$set(item, "werkezoekenEuros", $event)},"update:return-value":function($event){return _vm.$set(item, "werkezoekenEuros", $event)},"save":_vm.save,"cancel":_vm.cancel,"open":_vm.open,"close":_vm.close},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"rules":[_vm.max25chars],"label":"Edit","single-line":"","counter":"","type":"number"},model:{value:(item.werkezoekenEuros),callback:function ($$v) {_vm.$set(item, "werkezoekenEuros", $$v)},expression:"item.werkezoekenEuros"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.roundToTwoDecimals(item.werkezoekenEuros).toFixed(2))+" € ")])]}},{key:"item.werkEuros",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.werkEuros},on:{"update:returnValue":function($event){return _vm.$set(item, "werkEuros", $event)},"update:return-value":function($event){return _vm.$set(item, "werkEuros", $event)},"save":_vm.save,"cancel":_vm.cancel,"open":_vm.open,"close":_vm.close},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"rules":[_vm.max25chars],"label":"Edit","single-line":"","counter":"","type":"number"},model:{value:(item.werkEuros),callback:function ($$v) {_vm.$set(item, "werkEuros", $$v)},expression:"item.werkEuros"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.roundToTwoDecimals(item.werkEuros).toFixed(2))+" € ")])]}},{key:"item.doenSiteEuros",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.doenSiteEuros},on:{"update:returnValue":function($event){return _vm.$set(item, "doenSiteEuros", $event)},"update:return-value":function($event){return _vm.$set(item, "doenSiteEuros", $event)},"save":_vm.save,"cancel":_vm.cancel,"open":_vm.open,"close":_vm.close},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"rules":[_vm.max25chars],"label":"Edit","single-line":"","counter":"","type":"number"},model:{value:(item.doenSiteEuros),callback:function ($$v) {_vm.$set(item, "doenSiteEuros", $$v)},expression:"item.doenSiteEuros"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.roundToTwoDecimals(item.doenSiteEuros).toFixed(2))+" € ")])]}},{key:"item.zoomerSiteEuros",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.zoomerSiteEuros},on:{"update:returnValue":function($event){return _vm.$set(item, "zoomerSiteEuros", $event)},"update:return-value":function($event){return _vm.$set(item, "zoomerSiteEuros", $event)},"save":_vm.save,"cancel":_vm.cancel,"open":_vm.open,"close":_vm.close},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"rules":[_vm.max25chars],"required":"","label":"Edit","single-line":"","counter":"","type":"number"},model:{value:(item.zoomerSiteEuros),callback:function ($$v) {_vm.$set(item, "zoomerSiteEuros", $$v)},expression:"item.zoomerSiteEuros"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.roundToTwoDecimals(item.zoomerSiteEuros).toFixed(2))+" € ")])]}}],null,true)}):_vm._e(),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")]),(_vm.websiteEurosTotal)?_c('v-data-table',{staticClass:"elevation-1 mt-8 mb-6",attrs:{"headers":_vm.headersTotal,"items":_vm.websiteEurosTotal,"hide-default-footer":""}}):_vm._e(),(_vm.websiteEurosAverage)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersAverage,"items":_vm.websiteEurosAverage,"hide-default-footer":""}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }