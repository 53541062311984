import config from '../../config';
import { authHeader } from '../utils/auth-header';

export const departmentsService = {
    getDepartments,
    getDepartmentById,
    createDepartment,
    updateDepartment
}

function getDepartments() {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/departments`, request)
        .then(handleResponse)
        .then(departments => {
            return departments;
        });
}

function getDepartmentById(departmentId) {
    const request = {
        method: 'GET',
        headers: authHeader(),
    }
    return fetch(`${config.apiUrl}/departments/${departmentId}`, request)
        .then(handleResponse)
        .then(department => {
            return department;
        });
}

function createDepartment(department) {
    const request = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(department)
    }
    return fetch(`${config.apiUrl}/departments`, request)
        .then(handleResponse)
        .then(department => {
            return department;
        });
}

function updateDepartment(department, departmentId) {
    const request = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(department)
    }
    return fetch(`${config.apiUrl}/departments/${departmentId}`, request)
        .then(handleResponse)
        .then(department => {
            return department;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}